import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';
import { SegmentButton } from './SegmentButton';

interface SegmentButtonProps {
  label: React.ReactNode;
  value: string | number;
  disabled?: boolean;
  width?: number;
}

interface SegmentButtonGroupProps {
  selected: string | number;
  items: SegmentButtonProps[];
  disabled?: boolean;
  className?: string;
  onChange: (input: string | number) => void;
}

export const SegmentButtonGroup: React.FC<SegmentButtonGroupProps> = ({
  selected,
  items,
  disabled: allDisabled = false,
  className,
  onChange,
}) => {
  return (
    <StyledSegmentButtonGroup className={className}>
      {items.map(({ label, value, disabled = false, width, ...restProps }, i) => (
        <StyledSegmentButton
          type='button'
          className={classNames('segment-button', selected === value && !disabled && 'segment-button--selected')}
          width={width}
          key={value.toString() + i}
          selected={selected === value}
          disabled={allDisabled || disabled}
          {...restProps}
          onClick={() => {
            onChange(value);
          }}
        >
          {label}
        </StyledSegmentButton>
      ))}
    </StyledSegmentButtonGroup>
  );
};

const StyledSegmentButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const StyledSegmentButton = styled(SegmentButton)<{ width?: number; selected: boolean }>`
  margin: 0 -1px 0 0;
  min-width: 55px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  z-index: ${({ selected }) => (selected ? 1 : 0)};
`;
