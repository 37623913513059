import { HStack, semantic_colors, shapes, VStack, BaseText, buildCSSWithLength } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { MenuChildItem } from './MenuChildItem';
import { MenuEntry } from './types';
import useMediaQuery from '@/hooks/useMediaQuery';
interface searchHistoryModalProps {
  saved_menu_list: MenuEntry[];
  selected_id: string | null;
  onClick?: (entry: MenuEntry) => void;
}

export const SearchHistoryModal: React.FC<searchHistoryModalProps> = ({ saved_menu_list, selected_id, onClick }) => {
  const { is_desktop } = useMediaQuery();

  return (
    <Modal is_desktop={is_desktop}>
      <VStack width='100%'>
        <Container>
          <HStack mb={4} p='0 10px'>
            <BaseText color={semantic_colors.content.tertiary} kind='Caption_11_SemiBold'>
              최근 이용 메뉴
            </BaseText>
          </HStack>
          {saved_menu_list.map((child) => (
            <MenuChildItem key={child.id} entry={child} onClick={onClick} selected_id={selected_id} with_descendant />
          ))}
        </Container>
      </VStack>
    </Modal>
  );
};

const Modal = styled.div<{ is_desktop: boolean }>`
  position: absolute;
  top: 32px;
  z-index: 50;
  display: flex;
  margin: 0;
  ${({ is_desktop }) => buildCSSWithLength('width', is_desktop ? 177 : 248)};
  background-color: ${semantic_colors.background.surface};
  ${shapes.shadow.low};
  ${shapes.border_radius.small};
`;

const Container = styled(VStack)`
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  max-height: 382px;
`;
