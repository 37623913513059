import { MenuEntry } from '../types';
import { createAdMenuTree } from './ad';
import { createCatalogMenuTree } from './catalog';
import { createInfoMenuTree } from './info';
import { createInquiryMenuTree } from './inquiry';
import { createInsightMenuTree } from './insight';
import { createOrderItemMenuTree } from './order_item';
import { createPromotionMenuTree } from './promotion';
import { createSettlementMenuTree } from './settlement';
import { createStoreHomeMenuTree } from './store_home';
import { CreateMenuTreeOptions } from './types';
import { createZigzinMenuTree } from './zigzin';

export function createMenuTree(options: CreateMenuTreeOptions): MenuEntry[] {
  return [
    ...createCatalogMenuTree(options),
    ...createOrderItemMenuTree(options),
    ...createZigzinMenuTree(options),
    ...createAdMenuTree(options),
    ...createPromotionMenuTree(options),
    ...createSettlementMenuTree(options),
    ...createInquiryMenuTree(options),
    ...createInsightMenuTree(options),
    ...createStoreHomeMenuTree(options),
    ...createInfoMenuTree(options),
  ];
}
