import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: CUSTOM_VARS.sentry_dsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  sampleRate: 0.1,
  ignoreErrors: [
    '로그인을 해주세요.',
    '권한이 없거나 존재하지 않습니다.',
    'ResizeObserver loop limit exceeded',
    'Loading chunk',
    `Can't find variable: ResizeObserver`,
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
  ],
  beforeSend: (event) => {
    const url = new URL(event.request?.url ?? '');
    if (new URLSearchParams(url.search).get('lala')) {
      return null;
    }
    return event;
  },
});
