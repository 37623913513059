import React, { useEffect } from 'react';
import GreetingModal from './GreetingModal';
import { Config } from '@/config';
import { useModalState } from 'rui/pastel/unstable/modal';

export interface PartnerPlusGreetingContainerProps {
  children?: React.ReactNode;
}

const PartnerPlusGreetingContainer: React.FC<PartnerPlusGreetingContainerProps> = ({ children }) => {
  const [greeting_modal_props, toggleGreetingModal] = useModalState();

  useEffect(() => {
    const LOCAL_STORAGE_CODE = `InsightSubscriptionNoticePopup.${Config.shop_main_domain}.LastConfirmed`;
    if (!localStorage.getItem(LOCAL_STORAGE_CODE)) {
      toggleGreetingModal(true);
    }
  }, []);

  return (
    <>
      {children}
      <GreetingModal is_open={greeting_modal_props.isOpen} onClose={greeting_modal_props.onClose} />
    </>
  );
};

export default PartnerPlusGreetingContainer;
