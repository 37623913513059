import { Alert, AlertModalProps, Button, Confirm, ConfirmModalProps } from '@croquiscom/pds';
import { SetStateAction, useAtom } from 'jotai';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { entry_request_family } from '@/components/entry_requests/atoms';
import { EntryRequestForm, EntryRequestStepsType } from '@/components/entry_requests/types';
import useMediaQuery from '@/hooks/useMediaQuery';

interface NextButtonProps {
  name?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const useEntryRequest = () => {
  const { is_desktop } = useMediaQuery();
  const { t } = useTranslation('entry_requests');

  const getNextButton = (onNextCallback: () => void, button_props?: NextButtonProps) => {
    return (
      <div>
        <Button
          kind='primary'
          disabled={button_props?.disabled}
          loading={button_props?.loading}
          fill={!is_desktop}
          onClick={onNextCallback}
        >
          {button_props?.name ?? t('다음')}
        </Button>
      </div>
    );
  };

  const showErrorMessage = (props: Pick<AlertModalProps, 'title' | 'subtext'>) => {
    return Alert({
      kind: 'error',
      dense: !is_desktop,
      ...props,
    });
  };

  const showConfirmMessage = (
    props: Pick<ConfirmModalProps, 'title' | 'text' | 'subtext' | 'confirmText' | 'cancelText' | 'onConfirm'>,
  ) => {
    return Confirm({
      dense: !is_desktop,
      ...props,
    });
  };

  const useEntryRequestFormAtom = <T extends EntryRequestForm[keyof EntryRequestForm]>(key: EntryRequestStepsType) =>
    useAtom<T | undefined, SetStateAction<T>, void>(entry_request_family(key));

  return {
    getNextButton,
    showConfirmMessage,
    showErrorMessage,
    useEntryRequestFormAtom,
  };
};
