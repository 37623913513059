import { Alert } from '@croquiscom/pds';
import { useQuery } from 'react-query';
import { api_getSellerInfo } from '@/api';

export function useGetSellerInfoQuery() {
  return useQuery(
    ['useGetSellerInfoQuery'],
    async () => {
      const {
        data: { seller_account },
      } = await api_getSellerInfo();

      return seller_account?.has_new_notice;
    },
    {
      onError: () => {
        Alert({
          title: '알림',
          text: '데이터 가져오기를 실패했습니다.',
        });
      },
    },
  );
}
