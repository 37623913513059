import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { CountryCode, SellerResource } from '@/api';
import Info, { ShopDepartment } from '@/types/info';
import { SiteId } from 'rui/../../constants/site';

export const isAllowed = (res: SellerResource | SellerResource[], allowed_resource_list?: SellerResource[]) => {
  if (allowed_resource_list == null) {
    return false;
  }
  if (allowed_resource_list.includes(SellerResource.ALL)) {
    return true;
  }
  const res_list = Array.isArray(res) ? res : [res];
  return res_list.some((res) => allowed_resource_list.includes(res));
};

export const checkRes = (res: SellerResource | SellerResource[], allowed_resource_list?: SellerResource[]) => {
  if (res.length > 0) {
    return isAllowed(res, allowed_resource_list);
  }
  return true;
};

export const isAllowedMenuGroupBySiteId = (info: Info, menu_group_site_id_list: SiteId[]) => {
  const { is_parent, child_shop_site_country_list, site_country_list } = info;
  const site_id_list = (is_parent
    ? child_shop_site_country_list?.map((item) => item.site_id)
    : site_country_list?.map((item) => item.site_id)) ?? [SiteId.지그재그];

  if (!menu_group_site_id_list.some((item) => site_id_list.includes(item))) {
    return false;
  }

  return true;
};

export const isAllowedMenuBySiteId = (info: Info, menu_site_id_list: SiteId[]) => {
  const { is_parent, child_shop_site_country_list, site_country_list } = info;
  const country_list = (is_parent ? child_shop_site_country_list : site_country_list) ?? [];
  return country_list.some((item) => menu_site_id_list.includes(item.site_id as SiteId));
};

export const isAllowedMenuByCountry = (info: Info, menu_country_list: CountryCode[]) => {
  const { site_country_list, child_shop_site_country_list, is_parent } = info;
  const shop_site_country_list = is_parent ? child_shop_site_country_list : site_country_list;
  return (shop_site_country_list ?? []).some((sc) => menu_country_list.includes(sc.country_code as CountryCode));
};

export const isAllowedSubscription = (info: Info, subscription_list: string[]) => {
  const { shop_subscription_resource } = info;
  return subscription_list.some((subscription) => shop_subscription_resource.name?.startsWith(subscription));
};
export const isNotAllowedSubscription = (info: Info, subscription_list: string[]) => {
  const { shop_subscription_resource } = info;
  return !subscription_list.some(
    (subscription) => shop_subscription_resource.name?.toUpperCase().startsWith(subscription),
  );
};

export const isNotAllowedByShopDepartment = (info: Info, department_list: ShopDepartment[]) => {
  const {
    department_list: { item_list },
  } = info;
  return !item_list.some((item) => department_list.includes(item.department));
};

export const isAllowedByShopDepartment = (info: Info, department_list: ShopDepartment[]) => {
  const {
    department_list: { item_list },
  } = info;
  return item_list.some((item) => department_list.includes(item.department));
};

export const AlertIconWrapper = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
`;

export const NewIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.zigzag_pink500};
  margin-left: 4px;
  width: 13px;
  height: 13px;

  svg {
    display: inline-block;
    margin-top: 2px;
    margin-left: -1px;
  }
`;
