import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { canUseDom } from 'rui/utils/canUseDom';

export interface PortalProps {
  layer_id?: string;
  children?: React.ReactNode;
}

function getLayerElement(layer_id: string): HTMLElement | undefined {
  if (!canUseDom()) {
    return;
  }

  let layerElement = document.getElementById(layer_id);

  if (!layerElement) {
    layerElement = document.createElement('div');
    layerElement.setAttribute('id', layer_id);
    document.body.appendChild(layerElement);
  }

  return layerElement;
}

export const Portal: React.FC<PortalProps> = ({ children, layer_id = 'pastel_portal_layer' }) => {
  const [containerElement, setContainerElement] = useState<HTMLElement>();

  useEffect(() => {
    if (!canUseDom()) {
      return;
    }

    const layerElement = getLayerElement(layer_id);
    setContainerElement(layerElement);
  }, [layer_id]);

  if (!canUseDom() || !containerElement) {
    return null;
  }

  return createPortal(children, containerElement);
};
