import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export const CAFE24_GOODS_API_FAQ_URL =
  'https://docs.google.com/presentation/d/1N4vV_d0H1dqQahyuUzzasg9zD2d-3kbKR8n3Xf1q9lw';
export const MAKESHOP_GOODS_API_FAQ_URL =
  'https://docs.google.com/presentation/d/1j6wvHWiKIlF74foRFuGoE9X3AWthh9mmc7FXnKVv084';
export const WISA_GOODS_API_FAQ_URL = 'https://cf.res.s.zigzag.kr/guide/seller/wisa-goods-api-guide-20200908.pdf';

export const SHOP_SYNC_OPTIONS = createHelpConstantOptions({
  name: '스토어 연동',
  defaults: {
    '도움말 보기': 'https://partners.kakaostyle.com/help?id=788',
  },
});

export const SHOP_SOLUTION_NAME = {
  CAFE24: '카페24',
  MAKESHOP: '메이크샵',
  WISA: '위사',
};

export const SHOP_SOLUTION_TITLES = {
  CAFE24: '카페24 상품 연동이 중단되었어요',
  MAKESHOP: '메이크샵 상품 연동이 중단되었어요',
  WISA: '위사 상품 연동이 중단되었어요',
};
