export class Parser {
  private state: 'text' | 'end';
  private buffer: string;
  private expectedMarker: string;
  private outputBuffer: string;
  private markerMap: Record<string, string>;

  public onText: (text: string) => void;
  public onUrl: (url: string) => void;

  constructor() {
    this.state = 'text';
    this.buffer = '';
    this.outputBuffer = '';
    this.markerMap = {
      text: '<<END_OF_ANSWER>>',
    };
    this.expectedMarker = this.markerMap[this.state];

    this.onText = () => {};
  }

  public ingest(char: string) {
    this.buffer += char;
    while (this.buffer.length > 0) {
      if (this.expectedMarker.startsWith(this.buffer)) {
        if (this.buffer === this.expectedMarker) {
          this.transitionState();
          this.buffer = '';
          break;
        } else {
          break;
        }
      } else {
        const outputChar = this.buffer[0];
        this.output(outputChar);
        this.buffer = this.buffer.slice(1);
      }
    }
  }

  private output(char: string) {
    if (this.state === 'text') {
      this.onText(char);
    }
  }

  private transitionState() {
    if (this.state === 'text') {
      this.state = 'end';
      this.expectedMarker = this.markerMap[this.state];
    }
  }
}
