import { FloatingModal, IconBeta, semantic_colors, FloatingButton, HStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useZendesk } from '../hooks/useZendesk';
import { ChatBotContents } from './ChatBotContents';
import { ChatBotFooter } from './ChatBotFooter';
import { ChatbotGuideBottomSheet } from './ChatbotGuideBottomSheet';
import { ChatBotHeader } from './ChatBotHeader';
import { ChatBotLogo } from './ChatBotLogo';
import { ChatBotTooltip } from './ChatBotTooltip';
import { CHAT_BOT_FEEDBACK_KEY } from './constants';
import { ChatBotFeedback } from './feedback/ChatBotFeedback';
import { SSE_STATE, useChatBotRequest } from './hooks/useChatBotRequest';
import { useChatBotState } from './hooks/useGetChatBotState';
import { useGetChatBotTooltip } from './hooks/useGetChatBotTooltip';
import { useIsWindowScroll } from './hooks/useIsWindowScroll';
import { ChatBotText, ChatBotTextShadow } from './styled';
import { chat_bot_session } from './utils/session';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';
import { useOnceTooltip } from '@/hooks/useOnceTooltip';
import { usePbl } from '@/hooks/usePbl';

const navigation = 'partner_center_chatbot';

export const ChatBot = () => {
  const { is_scrolled } = useIsWindowScroll();
  const { close } = useZendesk({
    onOpen() {
      if (modal_state.feedback_opened && is_show) {
        hideFeedback();
      }
      setModalState((prev) => {
        return {
          ...prev,
          chatbot_opened: false,
        };
      });
    },
  });
  const history = useHistory();
  const is_not_allowed = history.location.pathname.includes('/ad/zigzag');
  const { is_show: is_show_tooltip, text, hideTooltip } = useGetChatBotTooltip(!is_not_allowed);
  const { is_show, hideTooltip: hideFeedback } = useOnceTooltip({
    key: CHAT_BOT_FEEDBACK_KEY,
    compare_value: dayjs().format('YYYYMMDD'),
  });
  const { pbl } = usePbl();
  const {
    modal_state,
    current_question,
    thread_data,
    setModalState,
    setCurrentQuestion,
    onDone,
    startNewThread,
    onSelectMenu,
  } = useChatBotState();
  const { answer, state, sendMessage } = useChatBotRequest({
    onDone,
  });
  return (
    <SC.Container>
      <ChatBotTooltip is_show={is_show_tooltip} hideTooltip={hideTooltip} text={text} />
      <ChatBotFeedback
        opened={modal_state.feedback_opened && is_show}
        onClose={() => {
          setModalState((prev) => ({
            ...prev,
            feedback_opened: false,
          }));
          if (modal_state.feedback_opened && is_show) {
            hideFeedback();
          }
        }}
      />
      <FloatingModal
        title={<ChatBotHeader state={state} onSelectMenu={onSelectMenu} />}
        footer={
          <ChatBotFooter
            state={state}
            question={current_question.input_question}
            setQuestion={(input_question) => {
              setCurrentQuestion((prev) => ({
                ...prev,
                input_question,
              }));
            }}
            sendMessage={(question) => {
              if (chat_bot_session.isExpired()) {
                startNewThread();
              }

              pbl({
                navigation,
                navigation_sub: {
                  sub: 'chat',
                },
                category: 'click',
                object_id: 'question',
              });
              sendMessage(question, thread_data);
              setCurrentQuestion({
                input_question: '',
                request_question: question,
              });
              setModalState((prev) => ({
                ...prev,
                preset_opened: false,
              }));
            }}
          />
        }
        closeButton={false}
        width={380}
        opened={modal_state.chatbot_opened}
        onOpen={() => {
          hideTooltip();
          pbl({
            navigation,
            navigation_sub: {
              sub: 'chat',
            },
            category: 'pageview',
          });
          setModalState((prev) => ({
            ...prev,
            feedback_opened: !prev.feedback_opened,
            chatbot_opened: true,
          }));
          close();
          if (chat_bot_session.isExpired()) {
            startNewThread();
          } else {
            chat_bot_session.refreshTime();
          }
        }}
        onClose={() => {
          setModalState((prev) => {
            return {
              ...prev,
              chatbot_opened: false,
              ...(prev.chatbot_opened === true ? { feedback_opened: true } : {}),
            };
          });
        }}
        trigger={
          <FloatingButton>
            {is_scrolled ? (
              <HStack height={24}>
                <img width={24} src={ChatBotIcon} alt='' />
              </HStack>
            ) : (
              <HStack alignment='center'>
                <ChatBotTextShadow>
                  <img width={24} src={ChatBotIcon} alt='' />
                  <ChatBotText width={118} spacing={4}>
                    <HStack pl={8}>
                      <ChatBotLogo />
                    </HStack>
                    <SC.BetaIcon size={21} color={semantic_colors.content.on_color} />
                  </ChatBotText>
                </ChatBotTextShadow>
              </HStack>
            )}
          </FloatingButton>
        }
      >
        <>
          <ChatBotContents
            state={state}
            thread={thread_data}
            preset_opened={modal_state.preset_opened}
            current_question={current_question.request_question}
            current_answer={answer}
            retry={
              state === SSE_STATE.ERROR
                ? () => {
                    sendMessage(current_question.request_question, thread_data);
                  }
                : undefined
            }
            onSelectPreset={(value) => {
              pbl({
                navigation,
                navigation_sub: {
                  sub: 'chat',
                },
                category: 'click',
                object_id: 'question',
              });
              pbl({
                navigation,
                navigation_sub: {
                  sub: 'chat',
                },
                category: 'click',
                object_id: 'question_preset',
                extra: JSON.stringify({
                  preset: value,
                }),
              });
              sendMessage(value, thread_data);
              setCurrentQuestion({
                request_question: value,
                input_question: '',
              });
              setModalState((prev) => ({
                ...prev,
                preset_opened: false,
              }));
            }}
            startNewThread={startNewThread}
          />
          <ChatbotGuideBottomSheet
            opened={modal_state.guide_opened}
            onClose={() =>
              setModalState((prev) => ({
                ...prev,
                guide_opened: false,
              }))
            }
          />
        </>
      </FloatingModal>
    </SC.Container>
  );
};

const SC = {
  BetaIcon: styled(IconBeta)`
    opacity: 0.7;
  `,
  Container: styled.div`
    position: relative;
  `,
};
