import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import DynamicImage from './DynamicImage';
import { AspectRatio, AspectRatioBody } from 'rui/pastel/unstable/aspect-ratio';

interface ProductThumbImageProps {
  /** @default 1 */
  ratio?: number;
  width?: number;
  image_url: string;
  fallback_image_url?: string;
  alt?: string;
  /** @default true */
  covered?: boolean;
  /** @default true */
  fixed?: boolean;
  onLoadImage?: HTMLAttributes<HTMLImageElement>['onLoad'];
}

const ProductThumbImage = ({
  width,
  ratio = 1,
  image_url,
  fallback_image_url,
  alt,
  covered = true,
  fixed = true,
  onLoadImage,
  ...props
}: ProductThumbImageProps & Omit<JSX.IntrinsicElements['div'], 'width' | 'height'>) => {
  return (
    <Container $width={width} covered={covered} fixed={fixed} {...props}>
      <AspectRatio ratio={ratio}>
        <AspectRatioBody>
          {/* MEMO(chiabi): 캐시된 preset을 사용하기 위해 width, quality등을 PLP용으로 고정합니다. */}
          {(image_url || fallback_image_url) && (
            <DynamicImage
              width={400}
              image_url={image_url}
              fallback_image_url={fallback_image_url}
              quality={80}
              alt={alt}
              onLoadImage={onLoadImage}
            />
          )}
        </AspectRatioBody>
      </AspectRatio>
    </Container>
  );
};

const Container = styled.div<{ $width?: number; fixed: boolean; covered: boolean }>`
  width: ${({ $width, fixed }) => (fixed ? `${$width}px` : '100%')};

  & img {
    width: 100%;
    height: 100%;
    object-fit: ${({ covered }) => (covered ? 'cover' : 'contain')};
  }
`;

export default ProductThumbImage;
