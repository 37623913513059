import { useInfo } from './useInfo';
import { PblParams, sendPbl } from '@/utils/pbl';

const DEFAULT_PARTNER_ID = 'kakaostyle';

/**
 * info를 의존하지 않는 Meta영역에서 pbl을 사용할 경우 useMetaPbl을 사용해 주세요
 */
const useEachPbl = <
  TParams extends PblParams & { [K in keyof TParams]?: K extends keyof PblParams ? PblParams[K] : string } = PblParams,
>() => {
  const { info } = useInfo();

  return {
    eachPbl: (pbl_params: TParams) => {
      const seller_account_id = info.seller_account.id;
      const shop_id = info.id;

      return sendPbl({
        partner_id: info.identifier?.includes('kakaostyle.com')
          ? DEFAULT_PARTNER_ID
          : seller_account_id ?? DEFAULT_PARTNER_ID,
        ...pbl_params,
        navigation_sub: { shop_id, ...pbl_params.navigation_sub },
      });
    },
  };
};

export default useEachPbl;
