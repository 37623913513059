import { HStack, LineTab, LineTabs } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { StockUrl as Url } from 'rui/components/URL';

interface StockIntroNavItemData {
  id: 'stock' | 'carry_out';
  label: React.ReactNode;
  href: string;
}

const NAV_ITEMS: StockIntroNavItemData[] = [
  {
    id: 'stock',
    label: '재고 전체 목록',
    href: Url.STOCK,
  },
  {
    id: 'carry_out',
    label: '반출 필요 대상',
    href: Url.CARRY_OUT,
  },
];

interface StockIntroNavProps {
  current_tab?: 'stock' | 'carry_out';
}

const StockIntroNav: FC<StockIntroNavProps> = ({ current_tab }) => {
  const history = useHistory();
  const prefix = useShopPrefix();

  return (
    <LineTabs activeTabId={current_tab}>
      {NAV_ITEMS.map((item) => (
        <LineTab
          key={item.id}
          id={item.id}
          onClick={() => {
            history.push(`${prefix}${item.href}`);
          }}
        >
          <HStack spacing={2} alignment='center'>
            {item.label}
          </HStack>
        </LineTab>
      ))}
    </LineTabs>
  );
};

export default StockIntroNav;
