import { colors, semantic_colors } from '@croquiscom/pds';
import { css, Keyframes } from '@emotion/react';
import { CSSProperties } from 'react';

export const mt = (px: number) => css`
  margin-top: ${px}px;
`;

export const ml = (px: number) => css`
  margin-left: ${px}px;
`;

export const mr = (px: number) => css`
  margin-right: ${px}px;
`;

export const mb = (px: number) => css`
  margin-bottom: ${px}px;
`;

export const mv = (px: number) => css`
  margin: ${px}px 0;
`;

export const mh = (px: number) => css`
  margin: 0 ${px}px;
`;

export const margin = (value: CSSProperties['margin']) => css`
  margin: ${typeof value === 'number' ? `${value}px` : value};
`;

export const padding = (value: CSSProperties['padding']) => css`
  padding: ${typeof value === 'number' ? `${value}px` : value};
`;

export const pt = (px: number) => css`
  padding-top: ${px}px;
`;

export const pl = (px: number) => css`
  padding-left: ${px}px;
`;

export const pr = (px: number) => css`
  padding-right: ${px}px;
`;

export const pb = (px: number) => css`
  padding-bottom: ${px}px;
`;

export const pv = (px: number) => css`
  padding: ${px}px 0;
`;

export const ph = (px: number) => css`
  padding: 0 ${px}px;
`;

interface CardConfig {
  radius?: number;
  color?: string;
  border?: CSSProperties['border'];
}

export const card = ({ border = 'none', radius = 16, color = colors.white }: CardConfig = {}) => css`
  border: ${border};
  border-radius: ${radius}px;
  background-color: ${color};
`;

export interface FlexConfig {
  inline?: boolean;
  direction?: CSSProperties['flexDirection'];
  alignment?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
  gap?: CSSProperties['gap'];
}

export const flex = ({
  inline = false,
  direction = 'row',
  alignment = 'normal',
  justify = 'normal',
  wrap = 'nowrap',
  gap = 'normal',
}: FlexConfig = {}) => css`
  display: ${inline ? 'inline-flex' : 'flex'};
  flex-direction: ${direction};
  align-items: ${alignment};
  justify-content: ${justify};
  flex-wrap: ${wrap};
  gap: ${typeof gap === 'number' ? `${gap}px` : gap};
`;

export const expand = (flex: CSSProperties['flex']) => css`
  flex: ${flex};
`;

interface AnimationConfig {
  keyframe: Keyframes;
  duration: CSSProperties['animationDuration'];
  iteration?: CSSProperties['animationIterationCount'];
  mode?: CSSProperties['animationFillMode'];
  easing?: CSSProperties['animationTimingFunction'];
  delay?: CSSProperties['animationDelay'];
}

export const animation = ({
  keyframe,
  duration,
  easing = 'ease',
  iteration = '1',
  mode = 'none',
  delay = '0s',
}: AnimationConfig) => css`
  animation-name: ${keyframe};
  animation-duration: ${duration};
  animation-timing-function: ${easing};
  animation-iteration-count: ${iteration};
  animation-fill-mode: ${mode};
  animation-delay: ${delay};
`;

interface PositionConfig {
  type?: CSSProperties['position'];
  top?: CSSProperties['left'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
}

export const position = ({
  type = 'static',
  top = 'auto',
  right = 'auto',
  bottom = 'auto',
  left = 'auto',
}: PositionConfig = {}) => css`
  position: ${type};
  top: ${typeof top === 'number' ? `${top}px` : top};
  right: ${typeof right === 'number' ? `${right}px` : right};
  bottom: ${typeof bottom === 'number' ? `${bottom}px` : bottom};
  left: ${typeof left === 'number' ? `${left}px` : left};
`;

interface DividerProps {
  color?: string;
  size?: number;
  direction?: 'vertical' | 'horizontal';
}

export const divider = ({
  color = semantic_colors.border.default,
  size = 1,
  direction = 'horizontal',
}: DividerProps = {}) => css`
  display: block;
  border: none;
  background-color: ${color};
  width: ${direction === 'vertical' ? `${size}px` : 'auto'};
  height: ${direction === 'horizontal' ? `${size}px` : 'auto'};
  margin: 0;
`;

export const width = (value: CSSProperties['width']) => css`
  width: ${typeof value === 'number' ? `${value}px` : value};
`;

export const height = (value: CSSProperties['height']) => css`
  height: ${typeof value === 'number' ? `${value}px` : value};
`;
