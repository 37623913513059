export function checkPassword(password: string): string {
  if (!password) {
    return '';
  } else if (!/[A-Za-z]/.test(password)) {
    return '하나 이상의 영문자를 포함해주세요.';
  } else if (!/[0-9]/.test(password)) {
    return '하나 이상의 숫자를 포함해주세요.';
  } else {
    if (/[`~\!@#\$%\^\&\*\(\)\-_\=\+\[\{\}\]\\\|;:'",<.>\/\?€£¥₹]+/.test(password)) {
      if (password.length < 8) {
        return '8자 이상을 입력해주세요.';
      }
    } else {
      if (password.length < 10) {
        return '특수문자를 포함하거나 10자 이상을 입력해주세요.';
      }
    }
  }
  return '';
}
