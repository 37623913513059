export const BUDGET_LOW_LIMIT = 10000;

export const STATUS_ENABLED = 'ENABLED';
export const STATUS_DISABLED = 'DISABLED';
export const SITE_ID_FBK = '3';
export const SITE_ID_ZIGZAG = '1';

export const SORT_OPTS_DEFAULT = 'DEFAULT';
export const SORT_OPTS_CLICK_COUNT = 'CLICK_COUNT';
export const SORT_OPTS_VIEW_COUNT = 'VIEW_COUNT';
export const SORT_OPTS_CLICK_RATE = 'CLICK_RATE';
export const SORT_OPTS_BILLING_AMOUNT = 'BILLING_AMOUNT';
export const SORT_OPTS_CREATIVE_GROUP_INDEX = 'CREATIVE_GROUP_INDEX';
export const SORT_AD_SET_TYPE = 'AD_SET_TYPE';

const IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE = `아래 사유로 노출불가 처리되었습니다.\n\n사유 `;
const REJECTED_INSPECTION_VALUE = {
  subtext: [
    '해당 광고 그룹에 검수 보류된 소재가 있습니다.',
    '광고 그룹 페이지에 방문하여 검수 보류 사유를 확인해주세요.',
  ].join('\n'),
};

export const AD_DISPLAY_STATUS_ALERT_VALUES = {
  DISPLAY_ISSUED: REJECTED_INSPECTION_VALUE,
  // 서버 오타 대응
  WAITING_INSPECTION_ISSUED: REJECTED_INSPECTION_VALUE,
  WAITTING_INSPECTION_ISSUED: REJECTED_INSPECTION_VALUE,
  REJECTED_INSPECTION: REJECTED_INSPECTION_VALUE,
  SCHEDULED_DISPLAY_ISSUED: REJECTED_INSPECTION_VALUE,
  IMPOSSIBLE_DISPLAY_PRODUCT_INEXPENSIVE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '상품의 가격이 1,000원 미만입니다.',
      '(광고 노출을 원하실 경우, 자사 사이트에서 상품의 금액을',
      '광고 조건에 맞게 변경해주세요.)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_PRODUCT_HIDDEN: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '파트너센터, 혹은 자사 사이트에서 삭제 또는 미진열 처리된 상품입니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_PRODUCT_DELETED: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '지그재그에서 삭제된 상품입니다.'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_PRODUCT_SOLD_OUT: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '파트너센터, 혹은 자사 사이트에서 품절된 상품입니다.',
      '(파트너센터, 혹은 자사 사이트에서 품절 상태가 풀리면 이전 상태로 다시 변경됩니다.)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_PRODUCT_UNKNOWN: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '알 수 없는 문제 발생', '(문의전화:1670-8051 > 내선2번)'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_PRODUCT_HIDDEN_BY_DUPLICATED: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 목록에 해당 상품과 동일한 상품이 중복으로 등록되어있습니다',
      '(동일 상품이 Z-only 상품일 경우, Z-only 상품만 광고 노출 가능합니다.)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_AD_DISABLED: {
    text: '노출불가',
    subtext: [
      '상품이 OFF 상태로 되어있어 소재를 노출할 수 없습니다.',
      '노출을 원하실 경우 상품을 ON 상태로 변경해주세요.',
    ].join('\n'),
  },
  NONE: {
    text: '오류발생',
    subtext: ['알 수 없는 문제 발생', '(문의전화:1670-8051 > 내선2번)'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_NONE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '그룹 내에 모든 소재가 OFF이거나 노출불가 상태입니다.',
      '광고 그룹 상세 페이지에 방문하여 확인해주세요.',
    ].join('\n'),
  },
  AD_GROUP_PAUSED_DISPLAY: {
    text: '노출중단',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '설정하신 ‘광고일정’이 만료되어 광고가 중단되었습니다.',
      "지금 바로 재개하려면 '광고일정'을 수정해주세요.",
    ].join('\n'),
  },
  PAUSED_DISPLAY: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고그룹이 OFF 상태로 되어있어 소재를 노출할 수 없습니다.\n노출을 원하실 경우 광고그룹을 ON 상태로 변경해주세요.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '광고 소재 혜택문구에 문제가 있습니다.'].join('\n'), // 임시텍스트
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_DISCOUNT_RATE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘할인율’이 표시되어있으나',
      '상품에 할인율이 적용되어있지 않거나 할인율이 동일하지 않습니다',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_ALL_DISCOUNT_RATE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘전 상품 동일 할인율’이 표시되어있으나',
      '전 상품 할인율이 동일하지 않거나 상품에 할인율이 적용되어있지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_PRODUCT_INEXPENSIVE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '아래 사유로 노출불가 처리되었습니다.',
      '상품의 가격이 1,000원 미만입니다.',
      '(광고 노출을 원하실 경우, 자사 사이트에서 상품의 금액을 광고 조건에 맞게 변경해주세요.)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_PRODUCT_SOLD_OUT: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '자사 사이트에서 품절된 상품입니다.',
      '(자사 사이트에서 품절 상태가 풀리면 이전 상태로 다시 변경됩니다.)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_PRODUCT_DELETED: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '지그재그에서 삭제된 상품입니다.'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_PRODUCT_HIDDEN: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '자사 사이트에서 삭제 또는 미진열 처리된 상품입니다.'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_STORE_PICK_NOT_IN_PROGRESS: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      `등록하신 스토어픽의 상태가 ‘진행중’이 아닙니다.`,
      `'진행중’ 상태일때만 광고 노출 가능합니다.`,
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_MAX_COUPON_AMOUNT: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘쿠폰 최대 금액’이 표시되어있으나',
      '지그재그에 등록된 쿠폰이 존재하지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_COUPON_CNT: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘발급 가능한 쿠폰 수’가 표시되어있으나',
      '지그재그에 등록된 쿠폰이 존재하지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_FREE_SHIPPING: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘무료배송’이라 표시되어있으나',
      '상품 또는 쇼핑몰이 무료배송 설정되어있지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_MAX_DISCOUNT_RATE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘최대 할인율’이 표시되어있으나',
      '기획전 상품에 할인율이 적용되어있지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_MIN_DISCOUNT_RATE: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘최소 할인율’이 표시되어있으나',
      '기획전 상품에 할인율이 적용되어있지 않습니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_STORE_PICK_PERIOD: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '등록하신 스토어픽의 상태가 ‘진행중’이 아닙니다.',
      `‘진행중’ 상태일때만 광고 노출 가능합니다.'`,
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_INVALID_Z_PAYMENT_PERIOD: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '광고 카피에 ‘Z결제 할인 일정’이 표시되어있으나',
      'Z결제 할인중 상태가 아닙니다.',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_LANDING_URL_DELETED: {
    text: '노출불가',
    subtext: [IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE, '등록하신 랜딩 페이지가 삭제되었습니다.'].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_PRODUCT_ALL_UNEXPOSED: {
    text: '노출불가',
    subtext: [
      IMPOSSIBLE_DISPLAY_PRODUCT_MESAGE,
      '랜딩 페이지에 진열하신 상품이 모두 광고 노출 제한 상태가 되었습니다.',
      '(삭제, 품절, 미진열 등)',
    ].join('\n'),
  },
  IMPOSSIBLE_DISPLAY_LANDING_UNKNOWN: {
    text: '노출불가',
    subtext: ['알 수 없는 문제 발생', '(문의전화:1670-8051 > 내선2번)'].join('\n'),
  },
  PAUSED_DISPLAY_BAD_OPERATION: {
    text: '노출중단',
    subtext: [
      '고객 대응 및 운영 문제로 인해 광고 서비스 이용이 제한되었습니다.',
      '문제해결을 위해 고객센터로 문의해주세요. (02-1670-8051)',
    ].join('\n'),
  },
  PAUSED_DISPLAY_SHOP_BUDGET_EXHAUSTED: {
    text: '노출중단',
    subtext: ['광고 코인이 부족하여 광고가 중단되었습니다.', '광고 코인을 충전해주세요.'].join('\n'),
  },
  PAUSED_DISPLAY_BUDGET_EXHAUSTED: {
    text: '노출중단',
    subtext: [
      '‘하루예산’이 모두 소진되어 광고가 노출중단 되었습니다.',
      '내일 00시가 되면 자동으로 광고가 재개됩니다.',
      `지금 바로 재개하려면 '하루예산'을 수정해주세요.`,
    ].join('\n'),
  },
  PAUSED_DISPLAY_SCHEDULE_EXPIRED: {
    text: '노출중단',
    subtext: [
      '설정하신 ‘광고일정’이 만료되어 광고가 중단되었습니다.',
      `지금 바로 재개하려면 '광고일정'을 수정해주세요.`,
    ].join('\n'),
  },
};

export const CREATIVE_STATUS_ALERT_VALUES = {
  ...AD_DISPLAY_STATUS_ALERT_VALUES,
};

// 1차 오픈 : 14일 14시 / 노출 : 20일 14시
// 2차 오픈 : 23일 오후 2시 / 노출 27일 12시(정오)
// 2차 오픈 : 30일 오후 2시 / 노출 03일 12시(정오)
export const PREOPEN_INFOS = {
  1: {
    openTime: 1639371600000,
    adEnableTime: 1639371600000,
  },
  2: {
    openTime: 1640530800000,
    adEnableTime: 1640530800000,
  },
  3: {
    openTime: 1641135600000,
    adEnableTime: 1641135600000,
  },
};

export const MAX_SPECIAL_SHOP_OPERATION_COUNT = 3;
export const MAX_AD_ITEM_COUNT = 4;
export const MAX_AD_CREATIVE_COUNT = 6;

export const ATTENTION_INSPECT_GUIDE_PAGE = 'https://cf.res.s.zigzag.kr/notice/feca4351e8674c2d8036e8e86d5449bf.pdf';

export const CONVERSION_SORT_KEY = [
  'bookmark_count',
  'my_product_count',
  'roas',
  'click_order_amount',
  'click_order_count',
];

export const EMOJI_REGEX =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
