import {
  Alert,
  BaseText,
  Divider,
  Drawer,
  HStack,
  IconArrowChevronLeft,
  IconArrowChevronRight,
  IconCircleWarningFillBold,
  TextButton,
  TimeRangePicker,
  TopNavigation,
  VStack,
  colors,
  semantic_colors,
  shapes,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import AppPushNotificationDetailSetting from './AppPushNotificationDetailSetting';
import SettingListItem from './SettingListItem';
import {
  GetPartnerCenterAppPushAgreementQuery,
  GetPartnerCenterAppPushIgnoreTimeQuery,
  useGetPartnerCenterAppPushAgreementQuery,
  useGetPartnerCenterAppPushIgnoreTimeQuery,
  useUpdatePartnerCenterAppPushAgreementMutation,
  useUpdatePartnerCenterAppPushIgnoreTimeMutation,
} from '@/api';
import { app_push_system_enabled_atom } from '@/atoms/app_push_system_enabled';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';
import { PartnersBridge } from '@/utils/app_utils';

const getTime = (HHmm: string) => {
  const [hh, mm] = HHmm.split(':');

  return dayjs().utc().hour(Number(hh)).minute(Number(mm)).toDate();
};

interface AppPushNotificationSettingModalProps {
  opened: boolean;
  onClose: () => void;
}

const AppPushNotificationSettingModal = ({ opened, onClose }: AppPushNotificationSettingModalProps) => {
  const { pbl } = usePbl();
  const { info } = useInfo();
  const is_app_push_notification_enabled = useAtomValue(app_push_system_enabled_atom);
  const query_client = useQueryClient();

  const handleCommonError = () => {
    Alert({
      kind: 'error',
      title: '네트워크 오류',
      text: '이용에 불편을 드려 죄송합니다. 수 분 내에 복구 예정이며,\n잠시 후 다시 시도해주세요.',
    });
  };

  const handleMutationError = (error: any) => {
    if (error?.message) {
      Alert({
        kind: 'error',
        title: '알림',
        text: error?.message,
      });
    } else {
      handleCommonError();
    }
  };

  const { data: app_push_agreement_data, refetch: refetchAppPushAgreement } = useGetPartnerCenterAppPushAgreementQuery(
    undefined,
    {
      onError: handleCommonError,
    },
  );
  const { data: app_push_ignore_time_data, refetch: refetchAppPushIgnoreTime } =
    useGetPartnerCenterAppPushIgnoreTimeQuery(undefined, {
      onError: handleCommonError,
    });

  const { mutateAsync: updateAppPushAgreement } = useUpdatePartnerCenterAppPushAgreementMutation({
    onMutate() {
      query_client.setQueryData(
        ['GetPartnerCenterAppPushAgreement'],
        (old: GetPartnerCenterAppPushAgreementQuery | undefined): GetPartnerCenterAppPushAgreementQuery => ({
          getPartnerCenterAppPushAgreement: !old?.getPartnerCenterAppPushAgreement,
        }),
      );
    },
    onError: handleMutationError,
  });
  const { mutateAsync: updateAppPushIgnoreTime } = useUpdatePartnerCenterAppPushIgnoreTimeMutation({
    onMutate() {
      query_client.setQueryData(
        ['GetPartnerCenterAppPushIgnoreTime'],
        (old: GetPartnerCenterAppPushIgnoreTimeQuery): GetPartnerCenterAppPushIgnoreTimeQuery => ({
          getPartnerCenterAppPushIgnoreTime: {
            ...old?.getPartnerCenterAppPushIgnoreTime,
            is_ignore: !old?.getPartnerCenterAppPushIgnoreTime.is_ignore,
          },
        }),
      );
    },
    onError: handleMutationError,
  });

  const ignore_time = app_push_ignore_time_data?.getPartnerCenterAppPushIgnoreTime;
  const is_agreed = Boolean(app_push_agreement_data?.getPartnerCenterAppPushAgreement);

  const toggleSwitchAgreement = async () => {
    const is_agree = !is_agreed;
    try {
      await updateAppPushAgreement({
        input: { is_agree },
      });

      refetchAppPushAgreement();

      pbl({
        category: 'click',
        navigation: 'notification_settings',
        navigation_sub: { shop_id: info?.id || '' },
        object_type: 'button',
        object_id: 'app_push_agree',
        extra: JSON.stringify({ is_selected: is_agree }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSwitchIgnoreTime = async (is_ignore: boolean) => {
    try {
      await updateAppPushIgnoreTime({
        input: {
          is_ignore,
          ignore_date_hh_mm_started: ignore_time?.ignore_date_hh_mm_started,
          ignore_date_hh_mm_ended: ignore_time?.ignore_date_hh_mm_ended,
        },
      });
      refetchAppPushIgnoreTime();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (opened) {
      pbl({
        category: 'pageview',
        navigation: 'notification_settings',
        navigation_sub: { shop_id: info?.id || '' },
      });
    }
  }, [opened]);

  return (
    <Drawer opened={opened} animationOnClose={false} onClose={onClose} width='100%'>
      <VStack height='100%'>
        <TopNavigation title='앱 푸시 알림 설정' left={<IconArrowChevronLeft size={24} onClick={() => onClose()} />} />
        <SC.Container>
          <VStack p={16} spacing={16}>
            {!is_app_push_notification_enabled && (
              <SC.Banner>
                <HStack spacing={8} alignment='center'>
                  <IconCircleWarningFillBold color={semantic_colors.accent.primary} />
                  <BaseText kind='Body_14_SemiBold' color={semantic_colors.accent.primary}>
                    파트너센터 앱 알림을 켜주세요
                  </BaseText>
                  <HStack ml='auto'>
                    <TextButton
                      endIcon={<IconArrowChevronRight />}
                      kind='link'
                      size='medium'
                      onClick={() => {
                        PartnersBridge.startAppNotificationSettings();
                      }}
                    >
                      켜기
                    </TextButton>
                  </HStack>
                </HStack>
                <BaseText color={semantic_colors.content.secondary}>
                  앱에 들어오지 않고 휴대폰 알림으로 확인하려면, 기기 설정에서 알림을 켜주세요. (알림을 끄면
                  알림함에서만 확인 가능)
                </BaseText>
              </SC.Banner>
            )}
            <SettingListItem
              disabled={!is_app_push_notification_enabled}
              title='앱 푸시 알림 받기'
              description='스토어 운영 관련 알림을 전송해드려요.'
              isSwitchOn={is_agreed}
              onSwitchClick={toggleSwitchAgreement}
            />
            <Divider color={colors.gray200} />
            <SettingListItem
              disabled={!is_app_push_notification_enabled}
              title='에티켓 시간 설정'
              description='설정된 시간동안 알림을 받지 않아요.'
              isSwitchOn={Boolean(ignore_time?.is_ignore)}
              onSwitchClick={() => {
                toggleSwitchIgnoreTime(!ignore_time?.is_ignore);

                pbl({
                  category: 'click',
                  navigation: 'notification_settings',
                  navigation_sub: { shop_id: info?.id || '' },
                  object_type: 'button',
                  object_id: 'push_time_settings',
                  extra: JSON.stringify({ is_selected: !ignore_time?.is_ignore }),
                });
              }}
            >
              {ignore_time?.is_ignore && (
                <TimeRangePicker
                  fill
                  disabled={!is_app_push_notification_enabled}
                  value={{
                    from: ignore_time?.ignore_date_hh_mm_started
                      ? getTime(ignore_time.ignore_date_hh_mm_started)
                      : null,
                    to: ignore_time?.ignore_date_hh_mm_ended ? getTime(ignore_time.ignore_date_hh_mm_ended) : null,
                  }}
                  onChange={async (start, end) => {
                    try {
                      await updateAppPushIgnoreTime({
                        input: {
                          is_ignore: ignore_time?.is_ignore ?? false,
                          ignore_date_hh_mm_started: dayjs(start).utcOffset(0).format('HH:mm'),
                          ignore_date_hh_mm_ended: dayjs(end).utcOffset(0).format('HH:mm'),
                        },
                      });
                      refetchAppPushIgnoreTime();
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                />
              )}
            </SettingListItem>
          </VStack>
          <Divider size='medium' color={semantic_colors.background.base} />
          <AppPushNotificationDetailSetting is_app_push_notification_enabled={is_app_push_notification_enabled} />
        </SC.Container>
      </VStack>
    </Drawer>
  );
};

export default AppPushNotificationSettingModal;

const SC = {
  Banner: styled(VStack)`
    padding: 16px;
    row-gap: 8px;
    ${shapes.border_radius.small};
    background-color: ${semantic_colors.accent.light};
  `,
  Container: styled(VStack)`
    flex-grow: 1;
    overflow-y: auto;
  `,
};
