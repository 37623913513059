import { colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import LoadingWithBox from '@/components/common/LoadingWithBox';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 16px;
`;

const scrollbar = css`
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray200};
`;

export const ModalBody = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

export const ScrollArea = styled.div<{ nodata?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  ${scrollbar}
  ${({ nodata }) =>
    nodata &&
    `
    overflow: hidden;
    justify-content: center;
    align-items: center;
    `}
`;

export const NoDataContainer = styled.div`
  flex: 1 0 auto;
  justify-content: center;
  align-content: center;
`;

export const LoadingBox = styled(LoadingWithBox)`
  margin-top: 16px;
`;
