import dayjs from 'dayjs';
import numeral from 'numeral';

const formatNumber = <T extends number>(data: T) => {
  if (typeof data === 'number') {
    return numeral(data).format('0,0');
  }
  return data;
};

interface Wallet {
  cash: number;
  ad_display_point: number;
  fbk_ad_point: number;
  attention_ad_point: number;
  message_point: number;
}

const getBalanceFromWallet = (wallet: Wallet): number => {
  return Object.values(wallet).reduce((acc, cur) => acc + cur, 0);
};

const getDateTextFromDateYmd = (date_ymd: string | number) => {
  return dayjs(`${date_ymd}+09`, 'YYYYMMDDZ').utcOffset(9).format('YYYY.MM.DD');
};

export default {
  formatNumber,
  getBalanceFromWallet,
  getDateTextFromDateYmd,
};
