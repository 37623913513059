import { useQuery } from 'react-query';
import {
  api_getDailyReportTargetAchievement,
  api_getMetaDailyReportTargetAchievement,
  api_getMetaDailyReportTargetAchievementBlur,
  GetDailyReportTargetAchievementQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportTargetAchievementQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportTargetAchievement = ({
  date_ymd,
  use_free_view_count,
  main_domain = '',
  token = '',
}: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportTargetAchievement', { date_ymd, use_free_view_count, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportTargetAchievement({ date_ymd, use_free_view_count }, { no_alert: true })
        : use_free_view_count
          ? await api_getMetaDailyReportTargetAchievement(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            )
          : await api_getMetaDailyReportTargetAchievementBlur(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
