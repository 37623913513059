import { VStack, semantic_colors, shapes } from '@croquiscom/pds';
import styled from '@emotion/styled';

export const Card = styled(VStack)`
  padding: 16px;
  background-color: ${semantic_colors.background.surface};
  ${shapes.border_radius.xsmall};
`;
export const ProductRankCard = styled(VStack)`
  position: relative;
  padding: 16px;
  row-gap: 12px;
  background-color: ${semantic_colors.background.surface};
  border-radius: 4px;
`;

export const ContentContainer = styled(VStack)<{ bg?: string; pb?: number }>`
  row-gap: 16px;
  background-color: ${({ bg }) => bg || semantic_colors.background.base};
  padding: 50px 16px ${({ pb }) => `${pb || 0}px`} 16px;
`;
