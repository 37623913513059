import { Button, IconCirclePlus, colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStartAdditionalSellerEntryRequest } from '@/components/entry_requests/hooks/useStartAdditionalSellerEntryRequest';
import useEachPbl from '@/hooks/useEachPbl';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export const ChannelExtensionSection: React.FC = React.memo(() => {
  const { info } = useInfo();
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { eachPbl } = useEachPbl();
  const startAdditionalSellerEntryRequest = useStartAdditionalSellerEntryRequest();
  return (
    <StyledContainer>
      {info.site_expansion_allowed && (
        <StyledRow>
          <Button
            kind='primary'
            fill
            size='small'
            type='button'
            onClick={() => {
              eachPbl({
                navigation: 'lnb',
                category: 'click',
                object_type: 'button',
                object_id: 'channel_extension_one_click',
              });
              history.push(shop_prefix + '/channel_extension/intro');
            }}
            startIcon={<IconCirclePlus size={14} color={colors.white} />}
          >
            원클릭 판매채널 확장
          </Button>
        </StyledRow>
      )}
      <StyledRow>
        <Button
          kind='black'
          fill
          size='small'
          type='button'
          onClick={() => {
            startAdditionalSellerEntryRequest();
          }}
          startIcon={<IconCirclePlus size={14} color={colors.white} />}
        >
          추가 입점 신청
        </Button>
      </StyledRow>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 16px 20px 0;
`;

const StyledRow = styled.div`
  margin-bottom: 8px;
`;
