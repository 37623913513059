import { useResetAtom } from 'jotai/utils';
import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { entry_request_form_atom } from '../atoms';
import { BusinessInformationFormType, SearchBusinessInformationFormType } from '../types';
import { useEntryRequest } from './useEntryRequest';
import { api_getRecentBusinessLicense } from '@/api';

export function useStartAdditionalSellerEntryRequest(): () => void {
  const resetSellerEntryRequest = useResetAtom(entry_request_form_atom);

  const { useEntryRequestFormAtom } = useEntryRequest();
  const [, setSearchBusinessInformationForm] =
    useEntryRequestFormAtom<SearchBusinessInformationFormType>('SEARCH_BUSINESS');
  const [, setBusinessInformationForm] = useEntryRequestFormAtom<BusinessInformationFormType>('BUSINESS');

  const history = useHistory();

  useLayoutEffect(() => {
    resetSellerEntryRequest();
  }, []);

  return async () => {
    try {
      const { data } = await api_getRecentBusinessLicense(undefined, { no_alert: true, no_shop_domain: true });
      if (data?.getRecentBusinessLicense) {
        const {
          registration_number,
          representative_name,
          company_name,
          business_category,
          business_subcategory,
          company_address_postcode,
          company_address,
          company_address_detail,
        } = data.getRecentBusinessLicense;
        setSearchBusinessInformationForm({
          business_registration_number: registration_number || '',
          business_representative_name: representative_name || '',
          business_commencement_date: '',
          has_business_license_info_validated: false,
        });
        setBusinessInformationForm({
          company_name: company_name || '',
          business_category: business_category || '',
          business_subcategory: business_subcategory || '',
          company_address: {
            postcode: company_address_postcode || '',
            address1: company_address || '',
            address2: company_address_detail || '',
          },
        });
      }
    } catch (e) {
      //
    }

    history.push('/entry_requests/guide');
  };
}
