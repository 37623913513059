import { semantic_colors, VStack, IconButton, IconArrowDirectionDown, getAutoColorCode } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { fixed_bottom_area_atom } from '../../atom';
import { ChatBotMessage } from './ChatBotMessage';
import { ChatBotPreset } from './ChatBotPreset';
import { SSE_STATE } from './hooks/useChatBotRequest';
import { ChatBotQnA } from './types';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ChatBotContentsProps {
  thread: ChatBotQnA[];
  current_question?: string;
  current_answer?: string;
  preset_opened: boolean;
  state: SSE_STATE;
  retry?: () => void;
  onSelectPreset: (value: string) => void;
  startNewThread: () => void;
}

export const ChatBotContents = ({
  thread,
  current_answer,
  current_question,
  preset_opened,
  state,
  retry,
  onSelectPreset,
  startNewThread,
}: ChatBotContentsProps) => {
  const fixed_bottom_area = useAtomValue(fixed_bottom_area_atom);
  const { is_desktop } = useMediaQuery();
  const [content_scroll, setContentScroll] = useState({
    is_top: true,
    is_bottom: true,
  });
  const content_ref = useRef<HTMLDivElement>(null);

  const moveBottom = () => {
    const scrollToBottom = () => {
      if (content_ref.current) {
        content_ref.current.scrollTo({ top: content_ref.current.scrollHeight });
        if (content_ref.current.scrollTop + content_ref.current.clientHeight < content_ref.current.scrollHeight) {
          requestAnimationFrame(scrollToBottom);
        }
      }
    };
    requestAnimationFrame(scrollToBottom);
  };

  useEffect(() => {
    const handleContentScroll = () => {
      setContentScroll({
        is_top: content_ref.current?.scrollTop === 0,
        is_bottom: Boolean(
          content_ref.current &&
            Math.round(content_ref.current?.scrollTop + content_ref.current?.clientHeight) >=
              Math.round(content_ref.current?.scrollHeight),
        ),
      });
    };
    content_ref.current?.addEventListener('scroll', handleContentScroll);
    return () => {
      content_ref.current?.removeEventListener('scroll', handleContentScroll);
    };
  }, []);

  useEffect(() => {
    moveBottom();
  }, [current_answer, state, current_question, thread, preset_opened]);

  return (
    <SC.ContentContainer
      alignment='center'
      ref={content_ref}
      fixed_bottom_area={fixed_bottom_area}
      is_desktop={is_desktop}
      is_top={content_scroll.is_top}
      is_bottom={content_scroll.is_bottom}
    >
      <ChatBotMessage
        state={state}
        messages={thread}
        current_question={current_question}
        current_answer={current_answer}
        startNewThread={startNewThread}
        retry={retry}
      />
      <ChatBotPreset is_show={preset_opened} onSelect={onSelectPreset} />
      {[SSE_STATE.DONE, SSE_STATE.ERROR, SSE_STATE.READY].includes(state) && !content_scroll.is_bottom && (
        <SC.MoveBottom>
          <IconButton
            shape='circle'
            size='medium'
            icon={<IconArrowDirectionDown color='currentColor' />}
            onClick={moveBottom}
          />
        </SC.MoveBottom>
      )}
    </SC.ContentContainer>
  );
};

const SC = {
  ContentContainer: styled(VStack)<{
    is_desktop: boolean;
    is_top: boolean;
    is_bottom: boolean;
    fixed_bottom_area: number;
  }>`
    margin: 0 -24px;
    padding: 8px 24px;
    ${({ is_desktop, fixed_bottom_area }) =>
      is_desktop
        ? `
      max-height: 524px;
      height: calc(100vh - ${262 + fixed_bottom_area}px);
    `
        : ''}
    border-top: ${({ is_top }) => (!is_top ? '1px solid #e5e5e5' : 'none')};
    border-bottom: ${({ is_bottom }) => (!is_bottom ? '1px solid #e5e5e5' : 'none')};
    flex-grow: 1;
    overflow-y: auto;
  `,
  MoveBottom: styled.div`
    position: absolute;
    bottom: 110px;
    right: 16px;
    > button {
      color: ${semantic_colors.content.primary};
      background-color: ${semantic_colors.background.surface};
      box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.2);

      &:hover {
        color: ${getAutoColorCode(semantic_colors.content.primary).hover};
        background-color: ${getAutoColorCode(semantic_colors.background.surface).hover};
      }
      &:active {
        color: ${getAutoColorCode(semantic_colors.content.primary).pressed};
        background-color: ${getAutoColorCode(semantic_colors.background.surface).pressed};
      }
    }
  `,
};
