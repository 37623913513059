import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { ElementType } from 'react';

import { defaultLine } from 'styles';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  alertNode?: React.ReactNode;
  as?: ElementType;
}

const Card: React.FC<CardProps> = ({ className, children, title, alertNode, as }) => (
  <StyledCard as={as} className={className}>
    {title && <div className='card-title'>{title}</div>}
    {alertNode}
    <div className='card-content'>{children}</div>
  </StyledCard>
);

const StyledCard = styled.div`
  margin: 20px;
  background: ${colors.white};
  border: ${defaultLine};
  .card-title {
    border-bottom: ${defaultLine};
    font-weight: 700;
    font-size: 17px;
    letter-spacing: -0.3px;
    color: ${colors.gray900};
  }
  .card-content {
    display: flex;
  }
`;

export default Card;
