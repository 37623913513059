import { atomWithQuery } from 'jotai/query';
import { main_domain_atom } from './main_domain';
import Info from '@/types/info';
import axios, { handleCatchedErrorDefault } from '@/utils/axios';

export const info_atom = atomWithQuery<Info, Error>((get) => ({
  queryKey: ['getInfo', get(main_domain_atom)],
  queryFn: async ({ queryKey: [, main_domain] }) => {
    if (main_domain) {
      try {
        const res = await axios.get(`/api/provider/${main_domain}/info`, {
          params: { time: Date.now() },
        });
        return { ...res.data };
      } catch (err) {
        handleCatchedErrorDefault(err);
      }
    } else {
      return null;
    }
  },
}));
