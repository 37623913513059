import React, { HTMLAttributes, useEffect, useState } from 'react';
import {
  ConvertedImageFormat,
  DynamicImageType,
  getDynamicImageUrl,
  getImageFormat,
} from '../utils/getDynamicImageUrl';
interface DynamicImageProps {
  image_url: string;
  fallback_image_url?: string;
  width: number;
  /** @default width 값과 동일 */
  height?: number;
  /** @default 100 */
  quality?: number;
  /** @default outside */
  type?: DynamicImageType;
  alt?: string;
  /** @default webp */
  formats?: ConvertedImageFormat | ConvertedImageFormat[];
  onLoadImage?: HTMLAttributes<HTMLImageElement>['onLoad'];
}

const DynamicImage = ({
  alt = '',
  width,
  height = width,
  image_url,
  fallback_image_url,
  quality = 100,
  type = 'outside',
  formats = 'webp',
  onLoadImage,
  ...props
}: DynamicImageProps & Omit<JSX.IntrinsicElements['picture'], 'width' | 'height'>) => {
  const [error_item_count, setErrorItemCount] = useState(0);
  const [is_fallback, setFallback] = useState(false);
  const available_formats = Array.isArray(formats) ? formats : [getImageFormat(image_url) === 'gif' ? 'webp' : formats];

  useEffect(() => {
    if (error_item_count > 0) {
      setErrorItemCount(0);
    }
  }, [image_url]);

  if (image_url.indexOf('blob:') === 0) {
    return <img src={image_url} alt={alt} onLoad={onLoadImage} />;
  }

  return (
    <picture {...props}>
      {image_url &&
        available_formats.map((format, index) => {
          const dynamic_image_url = getDynamicImageUrl({
            width,
            height,
            image_url,
            quality,
            format,
            type,
          });

          if (index + 1 <= error_item_count) {
            return null;
          }
          return <source key={dynamic_image_url} srcSet={dynamic_image_url} type={`image/${format}`} />;
        })}
      <img
        src={image_url}
        alt={alt}
        onLoad={onLoadImage}
        onError={(e: any) => {
          if (is_fallback) {
            return;
          }
          if (e.target.previousElementSibling && e.target.parentNode.firstChild !== e.target) {
            setErrorItemCount((prev) => prev + 1);
          } else if (e.target.src !== fallback_image_url && fallback_image_url) {
            e.target.src = fallback_image_url;
            setFallback(true);
          }
        }}
      />
    </picture>
  );
};

export default DynamicImage;
