import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import EcStoreAllMonthlySettlementListPage from '@/pages/settlement/EcStoreAllMonthlySettlementListPage';
import { EcTaxInvoiceUrl } from 'rui/components/URL';

const EcStoreAllotmentAmountSettlementPage = lazy(
  () => import('@/pages/settlement/EcStoreAllotmentAmountSettlementPage'),
);
const EcFeeSettlementPage = lazy(() => import('@/pages/settlement/EcFeeSettlementPage'));
const EcTaxInvoiceListPage = lazy(() => import('@/pages/settlement/EcTaxInvoiceListPage'));
const EcVatReferenceListPage = lazy(() => import('@/pages/settlement/EcVatReferenceListPage'));
const ForeignVatReferencePage = lazy(() => import('@/pages/settlement/ForeignVatReferencePage'));
const OrderSettlementListPage = lazy(() => import('@/pages/settlement/OrderSettlementListPage'));
const EcStoreDailySettlementDescription = lazy(
  () => import('@/components/settlement/ec_store_daily_settlement/common/EcStoreDailySettlementDescription'),
);
const EcStoreDailySettlementDetailPage = lazy(() => import('@/pages/settlement/EcStoreDailySettlementDetailPage'));
const EcStoreDailySettlementPage = lazy(() => import('@/pages/settlement/EcStoreDailySettlementPage'));
const EcStoreDeductionAccPage = lazy(() => import('@/pages/settlement/EcStoreDeductionAccPage'));
const EcStoreDeductionSettlementDetailPage = lazy(
  () => import('@/pages/settlement/EcStoreDeductionSettlementDetailPage'),
);
const EcStoreDeductionSettlementListPage = lazy(() => import('@/pages/settlement/EcStoreDeductionSettlementListPage'));
const EcStoreRebateSettlementPage = lazy(() => import('@/pages/settlement/EcStoreRebateSettlementPage'));
const EcTaxInvoiceDeductionDetailPage = lazy(() => import('@/pages/settlement/EcTaxInvoiceDeductionDetailPage'));
const EcTaxInvoiceRebateDetailPage = lazy(() => import('@/pages/settlement/EcTaxInvoiceRebateDetailPage'));

export const SETTLEMENT_ROUTES: RoutesType[] = [
  {
    current_menu: 'ec_store_daily_settlement_list',
    path: '/ec_store_daily_settlement',
    params: '',
    component: (
      <>
        <EcStoreDailySettlementDescription />
        <EcStoreDailySettlementPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_daily_settlement_list',
    path: '/ec_store_daily_settlement/detail',
    params: '/:date_scheduled_ymd',
    component: (
      <>
        <EcStoreDailySettlementDescription />
        <EcStoreDailySettlementDetailPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_deduction_settlement_list',
    path: '/ec_store_deduction_settlement',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='일별 스토어 공제 정산'
            description={
              <>
                - 스토어 공제 정산 내역을 일별로 조회할 수 있습니다.
                <br />- 공제 정산 번호를 기준으로 해당 공제 정산의 상세 내역을 확인할 수 있습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <EcStoreDeductionSettlementListPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_deduction_settlement_list',
    path: '/ec_store_deduction_settlement/detail',
    params: '/:date_scheduled_ymd',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='일별 스토어 공제 정산'
            description={
              <>
                - 스토어 공제 정산 내역을 일별로 조회할 수 있습니다.
                <br />- 공제 정산 번호를 기준으로 해당 공제 정산의 상세 내역을 확인할 수 있습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <EcStoreDeductionSettlementDetailPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_rebate_settlement_list',
    path: '/ec_store_rebate_settlement',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='일별 스토어 환급 정산'
            description={
              <>
                - 스토어 환급 정산 내역을 일별로 조회할 수 있습니다.
                <br />- 환급 정산 번호에 따른 항목별 환급 상세 정보를 확인할 수 있습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <EcStoreRebateSettlementPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_deduction_acc_list',
    path: '/ec_store_deduction_acc',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='스토어 공제 등록 현황'
            description={
              <>
                - 스토어 정산을 위해 등록된 공제 건의 기초 정보들을 조회할 수 있습니다.
                <br />- 현재 시점에 등록된 전체 공제 리스트를 확인할 수 있습니다. (조회 시점에 따라 공제 잔액이 변동될
                수 있습니다.)
              </>
            }
            mb={0}
          />
        </PageHeader>
        <EcStoreDeductionAccPage />
      </>
    ),
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_foreign_vat_reference_list',
    path: '/ec_foreign_vat_reference',
    params: '',
    component: <ForeignVatReferencePage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_tax_invoice_list',
    path: EcTaxInvoiceUrl.LIST,
    params: '',
    component: <EcTaxInvoiceListPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_tax_invoice_list',
    path: `${EcTaxInvoiceUrl.LIST}${EcTaxInvoiceUrl.DEDUCTION_DETAIL}`,
    params: '/:id',
    component: <EcTaxInvoiceDeductionDetailPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_tax_invoice_list',
    path: `${EcTaxInvoiceUrl.LIST}${EcTaxInvoiceUrl.REBATE_DETAIL}`,
    params: '/:id',
    component: <EcTaxInvoiceRebateDetailPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_fee_settlement',
    path: '/ec_fee_settlement',
    params: '',
    component: <EcFeeSettlementPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_store_allotment_amount_settlement',
    path: '/ec_store_allotment_amount_settlement',
    params: '',
    component: <EcStoreAllotmentAmountSettlementPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_vat_reference_list',
    path: '/ec_vat_reference',
    params: '',
    component: <EcVatReferenceListPage />,
    res: [SellerResource.ZPAY_SETTLEMENT_MANAGEMENT],
  },
  {
    current_menu: 'ec_settlement_list_scheduled',
    path: '/ec_settlement/scheduled',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='정산예정내역'
            description={
              <>
                - 구매 확정된 모든 주문건을 기준으로 정산이 진행됩니다.
                <br />- 자세한 정산일은 여기를 참고해주세요
                <br />- 글로벌(일본/미국/캐나다) 판매 서비스는 23년 11월 30일자로 종료되었습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <OrderSettlementListPage type='scheduled' />
      </>
    ),
    res: SellerResource.ZPAY_SETTLEMENT_MANAGEMENT,
  },
  {
    current_menu: 'ec_settlement_list_based',
    path: '/ec_settlement/based',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='정산기준내역'
            description={
              <>
                - 구매 확정된 모든 주문건을 기준으로 정산이 진행됩니다.
                <br />- 자세한 정산일은 여기를 참고해주세요
                <br />- 글로벌(일본/미국/캐나다) 판매 서비스는 23년 11월 30일자로 종료되었습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <OrderSettlementListPage type='based' />
      </>
    ),
    res: SellerResource.ZPAY_SETTLEMENT_MANAGEMENT,
  },
  {
    current_menu: 'ec_store_all_monthly_settlement_list',
    path: '/ec_store_all_monthly_settlement_list',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='월별 정산 내역 다운로드'
            description={
              <>
                - 월별 정산 내역은 매월 1일~말일에 구매확정된 모든 하위 스토어의 정산 내역을 집계합니다.
                <br />- 구매확정일(정산기준일)을 기준으로 정산 내역을 월별 집계합니다.
                <br />- 매월 전체 하위 스토어의 정산 내역을 엑셀 파일로 다운로드할 수 있습니다.
                <br />- ‘정산 금액’은 공제 및 환급 금액이 적용되지 않은 개별 정산 대상 아이템에 대한 정산 금액의
                합계입니다.
                <br />
                &nbsp; (공제 및 환급 금액은 각 하위스토어의 ‘일별 스토어 정산’ 메뉴에서 확인하실 수 있습니다.)
              </>
            }
            mb={0}
          />
        </PageHeader>
        <EcStoreAllMonthlySettlementListPage />
      </>
    ),
    res: SellerResource.ZPAY_SETTLEMENT_MANAGEMENT,
  },
];
