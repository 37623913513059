import { Banner, Dropdown, HStack, Modal, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CatalogSiteCountry,
  DEFAULT_SELECTABLE_SITE_COUNTRY,
  getCatalogSiteCountryKey,
  getSelectableSiteCountries,
  ShopSiteCountryKey,
} from '../utils';
import { getPDPPreviewUrl } from '../utils/getPDPPreviewUrl';
import { CountryCode } from '@/api';
import useMediaQuery from '@/hooks/useMediaQuery';

// MEMO(@chiabi): 글로벌은 PDP 작업 완료후 적용 예정입니다.
const PREVIEW_SITE_COUNTRY_LIST: CatalogSiteCountry[] = [
  {
    site_id: 1,
    country: CountryCode.KR,
  },
  {
    site_id: 2,
    country: CountryCode.KR,
  },
  {
    site_id: 3,
    country: CountryCode.KR,
  },
  {
    site_id: 5,
    country: CountryCode.KR,
  },
];

const PREVIEW_SITE_COUNTRY_LIST_KEYS = PREVIEW_SITE_COUNTRY_LIST.map(({ site_id, country }) => `${site_id}_${country}`);

function isPreviewSiteCountryList(site_country) {
  const key = getCatalogSiteCountryKey(site_country);
  return PREVIEW_SITE_COUNTRY_LIST_KEYS.includes(key);
}

interface PreviewModalProps {
  preview_id: string;
  site_country_list: CatalogSiteCountry[];
  url?: string;
  is_open: boolean;
  onClose: () => void;
}

const PreviewModal = ({ preview_id, site_country_list = [], url = '', is_open, onClose }: PreviewModalProps) => {
  const { t } = useTranslation(['catalog', 'common']);
  const { is_desktop } = useMediaQuery();
  const select_options = useMemo(() => {
    const selectable_site_country_list = getSelectableSiteCountries(
      (site_country_list ?? []).filter(isPreviewSiteCountryList),
    );
    return selectable_site_country_list.length > 0 ? selectable_site_country_list : DEFAULT_SELECTABLE_SITE_COUNTRY;
  }, [site_country_list]);
  const default_site_country_key = select_options[0]?.value;
  const [site_country_key, setSiteCountryKey] = useState<ShopSiteCountryKey>(default_site_country_key);

  useEffect(() => {
    if (is_open) {
      setSiteCountryKey(default_site_country_key);
    }
  }, [is_open, default_site_country_key]);

  const handleChange = (key: ShopSiteCountryKey | '') => {
    if (!key) {
      window.open(url, '_blank', 'noreferrer');
    } else {
      setSiteCountryKey(key);
    }
  };

  const site_options = [...select_options, ...(url ? [{ label: t('쇼핑몰(자사몰)'), value: '' }] : [])];

  return (
    <Modal
      width={450}
      dense={!is_desktop}
      fullScreen={!is_desktop}
      title={
        <HStack alignment='center' spacing={12}>
          <span>{t('미리보기')}</span>
          {site_options.length > 1 && (
            <Dropdown
              size={is_desktop ? undefined : 'medium'}
              value={site_country_key}
              options={site_options}
              width={160}
              onChange={(key: ShopSiteCountryKey | '') => handleChange(key)}
            />
          )}
        </HStack>
      }
      opened={is_open}
      onCancel={onClose}
      contentProps={{ p: '0 0 8px' }}
    >
      <VStack height={is_desktop ? 'calc(100vh - 202px) ' : '100%'}>
        <VStack p='24px 24px 16px'>
          <Banner kind='neutral' size='small' message={t('현재 판매되고 있는 상품의 미리보기가 제공됩니다.')} />
        </VStack>
        <SC.IframeContainer>
          <iframe
            // MEMO(@chiabi): src만 변경될 때 iframe을 리마운트 시키지 않으면,
            // 콘텐츠 탐색으로 처리되어 뒤로 가기 동작이 원활하지 않을 수 있어 key를 추가합니다.
            key={site_country_key}
            title={t('상품 상세페이지 미리보기')}
            src={getPDPPreviewUrl(preview_id, site_country_key.split('_')[0])}
            width={is_desktop ? '450' : '100%'}
            height='100%'
          />
        </SC.IframeContainer>
      </VStack>
    </Modal>
  );
};

export default PreviewModal;

const SC = {
  IframeContainer: styled.div`
    flex-grow: 1;
  `,
};
