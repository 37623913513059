import { t } from 'i18next';
import React from 'react';

import TransPlainText from '@/components/common/TransPlainText';
import { SiteId } from '@/constants/site';

export const NoticeCategoryLabel = {
  ALL: <TransPlainText ns='notice' i18nKey='전체' />,
  NORMAL: <TransPlainText ns='notice' i18nKey='일반' />,
  POLICY: <TransPlainText ns='notice' i18nKey='정책' />,
  AD: <TransPlainText ns='notice' i18nKey='광고' />,
  DEAL: <TransPlainText ns='notice' i18nKey='기획전' />,
  ZONLY: <TransPlainText ns='notice' i18nKey='직진배송' />,
  SYSTEM: <TransPlainText ns='notice' i18nKey='시스템' />,
};

export const SellerNoticeSiteName = {
  [SiteId.지그재그]: <TransPlainText ns='notice' i18nKey='지그재그' />,
  [SiteId.포스티]: <TransPlainText ns='notice' i18nKey='포스티' />,
  [SiteId.패션_바이_카카오]: <TransPlainText ns='notice' i18nKey='패션 바이 카카오' />,
  [SiteId.도매]: <TransPlainText ns='notice' i18nKey='직잭메이트' />,
};

export enum SellerNoticeFilterCountryCode {
  ALL = 'ALL',
  KR = 'KR',
  GLOBAL = 'GLOBAL',
}

export const SellerNoticeCountryCodeName = {
  [SellerNoticeFilterCountryCode.ALL]: <TransPlainText ns='notice' i18nKey='전체' />,
  [SellerNoticeFilterCountryCode.GLOBAL]: <TransPlainText ns='notice' i18nKey='글로벌' />,
  [SellerNoticeFilterCountryCode.KR]: <TransPlainText ns='notice' i18nKey='대한민국' />,
};

export const SellerNoticeCountryOptions = [
  { value: SellerNoticeFilterCountryCode.ALL, label: <TransPlainText ns='notice' i18nKey='전체 국가' /> },
  { value: SellerNoticeFilterCountryCode.GLOBAL, label: <TransPlainText ns='notice' i18nKey='글로벌' /> },
  { value: SellerNoticeFilterCountryCode.KR, label: <TransPlainText ns='notice' i18nKey='대한민국' /> },
];

export const NOTICE_SITE_NAME_I18N: Record<SiteId, () => string> = {
  [SiteId.지그재그]: () => t('common::지그재그'),
  [SiteId.포스티]: () => t('common::포스티'),
  [SiteId.패션_바이_카카오]: () => t('common::패션 바이 카카오'),
  [SiteId.도매]: () => t('common::직잭메이트'),
  [SiteId.마이스토어]: () => t('common::마이스토어'),
  [SiteId.카카오톡_쇼핑하기]: () => t('common::카카오톡 쇼핑하기'),
};
