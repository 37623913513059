import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { api_getSimpleDataList } from '@/api';

export interface HelpConstantOptions<T extends Record<string, string>> {
  name: string;
  defaults: T;
}

export interface HelpConstantProps<T extends Record<string, string>> {
  data: T;
}

export function createHelpConstantOptions<T extends Record<string, string>>(
  options: HelpConstantOptions<T>,
): HelpConstantOptions<T> {
  return options;
}

/**
 * 도움말에 상수값을 넣어서 관리할 수 있게 해주는 훅입니다. 자세한 내용은 여기를 참고해주세요:
 * https://www.notion.so/croquis/URL-1bd3b7ab08ba47dc9ff897a6f25d2f0d
 */
export function useHelpConstants<T extends Record<string, string>>({
  name,
  defaults,
}: HelpConstantOptions<T>): HelpConstantProps<T> {
  const { data, error: list_error } = useQuery(
    ['GetSimpleData', name],
    () => api_getSimpleDataList({ type: name }, { no_shop_domain: true, no_alert: true }),
    {
      suspense: true,
      useErrorBoundary: false,
      staleTime: 3600000,
      cacheTime: 3600000,
    },
  );
  const link = useMemo(
    () =>
      data?.data.frontend_collection_list.item_list.reduce((acc, item) => {
        acc[item.label] = item.value;
        return acc;
      }, {}),
    [data],
  );

  const missing_keys = link ? Object.keys(defaults).filter((key) => !(key in link)) : [];

  if (missing_keys.length > 0) {
    const err = new Error(`(${name})에 키가 누락되었습니다: ${missing_keys.join(', ')}`);
    console.error(err);
    Sentry.captureException(err);
  }

  useEffect(() => {
    if (list_error != null) {
      Sentry.captureException(list_error);
      return;
    }
    if (data == null) {
      const err = new Error(`${name}이 존재하지 않습니다.`);
      console.error(err);
      Sentry.captureException(err);
      return;
    }
  }, [data, list_error, name]);
  return {
    data: { ...defaults, ...link },
  };
}
