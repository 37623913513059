import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';

import Spinner from 'rui/pastel/unstable/Spinner';

interface LoadingWithBoxProps {
  className?: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

const LoadingWithBox = (props: LoadingWithBoxProps) => {
  return <StyledLoadingWrapper children={<Spinner />} {...props} />;
};

export default LoadingWithBox;

const StyledLoadingWrapper = styled.div<LoadingWithBoxProps>`
  width: ${({ width }) => width || '100%;'};
  height: ${({ height }) => height || '100%;'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
