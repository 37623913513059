import axios from 'axios';
import analytics from './analytics';
import { Config } from '@/config';

enum PblUrl {
  PRODUCTION = 'https://pbl.data.zigzag.kr/log',
  ALPHA = 'https://pbl.data.zigzag.kr/log-alpha',
}

enum Env {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  PRODUCTION = 'production',
}

const LOCAL_PORT = '8080';

const getEnv = () => {
  if (Config.alpha) {
    return Env.ALPHA;
  } else if (Config.beta) {
    return Env.BETA;
  } else if (Config.dev) {
    return Env.DEV;
  } else {
    return Env.PRODUCTION;
  }
};

export interface PblParams {
  partner_id?: string;
  navigation: string;
  navigation_sub?: Record<string, string>;
  /**
   * @description
   * 'pageview' 'click' 'impression' 'deeplink' 로 제한해서 사용하는 것을 권장합니다.
   */
  category:
    | 'pageview'
    | 'click'
    | 'impression'
    | 'deeplink'
    | 'focus_out'
    | 'alert'
    | 'toggle_off'
    | 'toggle_on'
    | 'filter'
    | 'complete_setting';
  object_section?: string;
  object_type?: string;
  object_id?: string;
  object_idx?: string;
  object_url?: string;
  extra?: string;
}

interface PblSession {
  id: string;
  expire: number;
}

const PBL_SID_KEY = 'pbl_sid';

const getSessionId = (date: Date, pbl_sid_key: string) => {
  const session = sessionStorage.getItem(pbl_sid_key);
  if (!session) {
    return date.getDate();
  } else {
    const pbl_session: PblSession = JSON.parse(session);
    // MEMO: Session 저장 후 30분간 Log호출이 없을 시 Session을 현재 시간으로 재생성합니다.
    return Number(pbl_session.expire) < date.getTime() ? pbl_session.id : date.getTime();
  }
};

const renewSession = (id, expire) => {
  sessionStorage.setItem(PBL_SID_KEY, JSON.stringify({ id, expire }));
};

export const sendPbl = async <
  TParams extends Partial<PblParams> & { [K in keyof TParams]?: K extends keyof PblParams ? PblParams[K] : string },
>(
  pbl_params: TParams,
) => {
  try {
    const env = getEnv();
    const pbl_url = env === Env.PRODUCTION ? PblUrl.PRODUCTION : PblUrl.ALPHA;
    const page_url = window.location.href.split('?')[0];
    const date = new Date();
    const session_id = getSessionId(date, PBL_SID_KEY);

    renewSession(session_id, date.setMinutes(30));

    if (window.location.port === LOCAL_PORT) {
      return;
    }
    const query_string = new URLSearchParams({
      user_agent: navigator.userAgent,
      referrer: document.referrer,
      env,
      session: String(session_id),
      client_access_time: String(date.getTime()),
      ...pbl_params,
      navigation_sub: JSON.stringify({ page_url, ...pbl_params.navigation_sub }),
    } as Record<string, string>);
    await axios.get(`${pbl_url}?${query_string}`);
    analytics.sendEvent(pbl_params.navigation ?? '-', pbl_params.category ?? '-');
  } catch (error) {
    console.error(error);
  }
};
