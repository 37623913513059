import {
  colors,
  semantic_colors,
  BaseText,
  LineTabs,
  LineTab,
  HStack,
  IconColoredSiren,
  IconSetting,
  IconX,
  shapes,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppPushNotificationSettingModal from '../AppPushNotificationSetting/AppPushNotificationSettingModal';
import {
  ALERT_BOX_TAB,
  ALERT_BOX_TAB_NAME,
  NEWS_2ND_CATEGORY_CHIPS,
  NOTIFICATION_2ND_CATEGORY_CHIPS,
  ZIGZIN_2ND_CATEGORY_CHIPS,
} from '../constants';
import { AlertItemType, GroupInfo } from '../types';
import { GroupCardList } from './GroupCardList';
import { ImportantCardList } from './ImportantCardList';
import { NotiCardList } from './NotificationCardList';
import { ModalWrapper } from './styles';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';
import { isApp } from '@/utils/app_utils/conditions';
import { PartnerCenterNotification1stCategory } from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

export interface AlertBoxModalProps {
  counts: {
    important: number;
    notification: number;
    news: number;
    zigzin: number;
  };
  items: Array<AlertItemType>;
  error?: unknown;
  refetch?: () => void;
  close: (no_move?: boolean) => void;
  dismiss: (key: string) => void;
  is_group_open: boolean;
  onGroupOpen: () => void;
  onGroupClose: () => void;
}

export const AlertBoxModal: React.FC<AlertBoxModalProps> = ({
  counts,
  items,
  error,
  close,
  refetch,
  dismiss,
  is_group_open,
  onGroupOpen: groupOpen,
  onGroupClose: groupClose,
}) => {
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const { info } = useInfo();
  const [active_tab, setActiveTab] = useState(
    counts.important > 0
      ? ALERT_BOX_TAB.TAB_IMPORTANT
      : counts.notification > 0
        ? ALERT_BOX_TAB.TAB_NOTIFICATION
        : counts.zigzin > 0
          ? ALERT_BOX_TAB.TAB_ZIGZIN
          : counts.news > 0
            ? ALERT_BOX_TAB.TAB_NEWS
            : ALERT_BOX_TAB.TAB_IMPORTANT,
  );
  const [group_info, setGroupInfo] = useState<GroupInfo | undefined>();
  const [{ isOpen, onOpen, onClose }] = useModalState();

  const tabItems = [
    {
      id: ALERT_BOX_TAB.TAB_IMPORTANT,
      name: ALERT_BOX_TAB_NAME.TAB_IMPORTANT,
      label: (
        <HStack alignment='center'>
          <IconColoredSiren size={16} style={{ marginRight: 5 }} />
          {t('중요')}
          {counts.important > 0 && <NotiDot />}
        </HStack>
      ),
    },
    {
      id: ALERT_BOX_TAB.TAB_NOTIFICATION,
      name: ALERT_BOX_TAB_NAME.TAB_NOTIFICATION,
      label: (
        <HStack alignment='center'>
          {t('알림')}
          {counts.notification > 0 && <NotiDot />}
        </HStack>
      ),
    },
    {
      id: ALERT_BOX_TAB.TAB_ZIGZIN,
      name: ALERT_BOX_TAB_NAME.TAB_ZIGZIN,
      label: (
        <HStack alignment='center'>
          {t('직진배송')}
          {counts.zigzin > 0 && <NotiDot />}
        </HStack>
      ),
    },
    {
      id: ALERT_BOX_TAB.TAB_NEWS,
      name: ALERT_BOX_TAB_NAME.TAB_NEWS,
      label: (
        <HStack alignment='center'>
          {t('소식')}
          {counts.news > 0 && <NotiDot />}
        </HStack>
      ),
    },
  ];

  const handleChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleTabClick = (tab_name: string) => {
    pbl({
      navigation: 'partnercenter_notification_inbox',
      category: 'click',
      object_id: 'tab',
      object_section: ALERT_BOX_TAB_NAME[active_tab],
      extra: JSON.stringify({ tab: tab_name }),
    });
  };

  const handleShowGroupClick = (group_info: GroupInfo) => {
    setGroupInfo(group_info);
    groupOpen();
  };

  const handleSettingClick = () => {
    onOpen();
    pbl({
      navigation: 'partnercenter_notification_inbox',
      navigation_sub: { shop_id: info?.id || '' },
      category: 'click',
      object_type: 'button',
      object_id: 'settings',
    });
  };

  return (
    <>
      <ModalWrapper>
        <ModalTabs>
          <Title>
            <IconWrapperButton onClick={() => close(true)}>
              <IconX size={24} color='currentColor' />
            </IconWrapperButton>
            <BaseText kind='Heading_18_Bold' as='h1'>
              {t('알림')}
            </BaseText>
            {isApp() ? (
              <IconWrapperButton onClick={handleSettingClick}>
                <IconSetting size={24} color='currentColor' />
              </IconWrapperButton>
            ) : (
              <div style={{ width: 24 }} />
            )}
          </Title>
          <LineTabs activeTabId={active_tab} dense onChange={handleChange}>
            {tabItems.map(({ id, label, name }) => (
              <LineTab id={id} key={id} onClick={() => handleTabClick(name)}>
                {label}
              </LineTab>
            ))}
          </LineTabs>
        </ModalTabs>
        {active_tab === ALERT_BOX_TAB.TAB_IMPORTANT && (
          <ImportantCardList items={items} error={error} refetch={refetch} close={close} dismiss={dismiss} />
        )}
        {active_tab === ALERT_BOX_TAB.TAB_NOTIFICATION && (
          <NotiCardList
            category={PartnerCenterNotification1stCategory.NOTIFICATION}
            sub_categories={NOTIFICATION_2ND_CATEGORY_CHIPS}
            onShowGroupClick={handleShowGroupClick}
            pbl_section={ALERT_BOX_TAB_NAME.TAB_NOTIFICATION}
            onCardClick={close}
          />
        )}
        {active_tab === ALERT_BOX_TAB.TAB_ZIGZIN && (
          <NotiCardList
            category={PartnerCenterNotification1stCategory.ZIGZIN}
            sub_categories={ZIGZIN_2ND_CATEGORY_CHIPS}
            onShowGroupClick={handleShowGroupClick}
            pbl_section={ALERT_BOX_TAB_NAME.TAB_ZIGZIN}
            onCardClick={close}
          />
        )}
        {active_tab === ALERT_BOX_TAB.TAB_NEWS && (
          <NotiCardList
            category={PartnerCenterNotification1stCategory.NEWS}
            sub_categories={NEWS_2ND_CATEGORY_CHIPS}
            onShowGroupClick={handleShowGroupClick}
            pbl_section={ALERT_BOX_TAB_NAME.TAB_NEWS}
            onCardClick={close}
          />
        )}
      </ModalWrapper>
      <GroupCardList
        opened={is_group_open && !!group_info?.group_id}
        onClose={() => {
          if (is_group_open) {
            groupClose();
          }
        }}
        onReadAll={() => group_info?.refetch()}
        group_info={group_info}
        onCardClick={close}
      />
      {isApp() && <AppPushNotificationSettingModal opened={isOpen} onClose={onClose} />}
    </>
  );
};

const ModalTabs = styled.div`
  background-color: ${colors.white};
`;

const Title = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
`;

const IconWrapperButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  ${shapes.border_radius.small};
  text-decoration: none;
  padding: 0;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.primary};
  }
`;

const NotiDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${semantic_colors.graph.red};
  margin-left: 4px;
  align-self: flex-start;
`;
