import { BaseText, HStack, IconCircleWarningFillBold, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

export interface AppNotSupportedBannerProps {}

export const AppNotSupportedBanner: React.FC<AppNotSupportedBannerProps> = () => {
  return (
    <StyledContainer spacing={8} p='12px 16px' alignment='top'>
      <VStack p='4px 0'>
        <IconCircleWarningFillBold color={semantic_colors.accent.primary} />
      </VStack>
      <VStack>
        <BaseText color={semantic_colors.accent.primary} kind='Body_14_SemiBold'>
          PC 환경에 최적화된 화면입니다.
        </BaseText>
        <BaseText color={semantic_colors.content.secondary} kind='Body_12_Regular'>
          모바일 환경에서는 일부 기능이 지원되지 않을 수 있습니다.
        </BaseText>
      </VStack>
    </StyledContainer>
  );
};

const StyledContainer = styled(HStack)`
  background-color: ${semantic_colors.accent.light};
`;
