import { IconBoxFbk, IconBoxPosty, IconBoxZigzag, IconZigzagWeb } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteId, SITE_NAME_I18N } from '@/constants/site';

interface SiteTagProps {
  site_id: string;
  icon_only?: boolean;
  width?: number;
  height?: number;
}

const SiteTag: FC<SiteTagProps> = ({ site_id, icon_only, width = 16, height = 16 }) => {
  useTranslation('common');
  const title = SITE_NAME_I18N[site_id]?.() || '';

  const getIcon = () => {
    switch (site_id) {
      case SiteId.지그재그:
        return <IconBoxZigzag width={width} height={height} />;
      case SiteId.패션_바이_카카오:
        return <IconBoxFbk width={width} height={height} />;
      case SiteId.포스티:
        return <IconBoxPosty width={width} height={height} />;
      default:
        return <IconZigzagWeb width={width} height={height} />;
    }
  };

  return (
    <>
      <SiteIcon>{getIcon()}</SiteIcon>
      {!icon_only && <span>{title}</span>}
    </>
  );
};

export default SiteTag;

const SiteIcon = styled.span`
  display: flex;
  align-items: center;
  margin: 0 4px 0 0;
`;
