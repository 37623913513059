import { t } from 'i18next';
import { NotificationSubCategoryChip } from './types';
import { PartnerCenterNotification2ndCategory } from 'api';

export const ALERT_BOX_TAB = {
  TAB_IMPORTANT: 'TAB_IMPORTANT',
  TAB_NOTIFICATION: 'TAB_NOTIFICATION',
  TAB_ZIGZIN: 'TAB_ZIGZIN',
  TAB_NEWS: 'TAB_NEWS',
};

export const ALERT_BOX_TAB_NAME = {
  [ALERT_BOX_TAB.TAB_IMPORTANT]: t('main_frame::중요'),
  [ALERT_BOX_TAB.TAB_NOTIFICATION]: t('main_frame::알림'),
  [ALERT_BOX_TAB.TAB_ZIGZIN]: t('main_frame::직진배송'),
  [ALERT_BOX_TAB.TAB_NEWS]: t('main_frame::소식'),
} as const;

export const NOTIFICATION_2ND_CATEGORY_CHIPS: NotificationSubCategoryChip[] = [
  { label: t('main_frame::전체'), value: '' },
  { label: t('main_frame::일반'), value: PartnerCenterNotification2ndCategory.BASIC },
  { label: t('main_frame::광고'), value: PartnerCenterNotification2ndCategory.AD },
];

export const ZIGZIN_2ND_CATEGORY_CHIPS: NotificationSubCategoryChip[] = [
  { label: t('main_frame::전체'), value: '' },
  { label: t('main_frame::일반'), value: PartnerCenterNotification2ndCategory.ZIGZIN_MANAGEMENT },
  {
    label: t('main_frame::입고/반출'),
    value: PartnerCenterNotification2ndCategory.ZIGZIN_PRODUCT_AND_IMPORT_MANAGEMENT,
  },
  {
    label: t('main_frame::정산/비용'),
    value: PartnerCenterNotification2ndCategory.ZIGZIN_SETTLEMENT_AND_FEE_MANAGEMENT,
  },
];

export const NEWS_2ND_CATEGORY_CHIPS: NotificationSubCategoryChip[] = [
  { label: t('main_frame::전체'), value: '' },
  { label: t('main_frame::공지사항'), value: PartnerCenterNotification2ndCategory.NOTICE },
  { label: t('main_frame::파트너라운지'), value: PartnerCenterNotification2ndCategory.PARTNER_LOUNGE },
];

export const NOTIFICATION_2ND_CATEGORY_LABEL = [...NOTIFICATION_2ND_CATEGORY_CHIPS, ...NEWS_2ND_CATEGORY_CHIPS].reduce(
  (acc, current) => ({ ...acc, ...(current.value && { [current.value]: current.label }) }),
  {} as {
    [key in PartnerCenterNotification2ndCategory]: string;
  },
);

export enum PushPreferenceCategory {
  GENERAL = 'GENERAL',
  ORDER = 'ORDER',
  ZIGZIN_ORDER = 'ZIGZIN_ORDER',
  ZIGZIN_GENERAL = 'ZIGZIN_GENERAL',
  ZIGZIN_STOCK_AND_CARRY_OUT = 'ZIGZIN_STOCK_AND_CARRY_OUT',
}
