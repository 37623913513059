import { useEffect, useState } from 'react';

export function useScrollDebounce(enabled: boolean): boolean {
  const [isIdle, setIdle] = useState(false);

  useEffect(() => {
    if (!enabled) return;
    const handleTimer = () => {
      setIdle(true);
      timerId = null;
    };
    let timerId: NodeJS.Timeout | null = setTimeout(handleTimer, 1000);
    const handleScroll = () => {
      setIdle(false);
      if (timerId != null) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(handleTimer, 1000);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      if (timerId != null) {
        clearTimeout(timerId);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, [enabled]);

  return isIdle && enabled;
}
