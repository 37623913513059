import { useToast } from '@croquiscom/pds';
import { useAtom } from 'jotai';
import isNil from 'lodash/isNil';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { app_push_system_enabled_atom } from '@/atoms/app_push_system_enabled';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnersBridge } from '@/utils/app_utils';
import { isAndroid } from '@/utils/app_utils/conditions';
import {
  api_updatePartnerCenterAppFcmToken,
  useGetPartnerCenterAppPushSystemAgreementQuery,
  useUpdatePartnerCenterAppPushSystemAgreementMutation,
} from 'api';

const useSystemPushNotificationCheckEffect = () => {
  const { is_logined } = useAccount();
  const prefix = useShopPrefix();
  const { data, refetch } = useGetPartnerCenterAppPushSystemAgreementQuery(undefined, {
    enabled: is_logined,
    onError() {},
  });
  const { mutateAsync } = useUpdatePartnerCenterAppPushSystemAgreementMutation({ onError() {} });
  const [is_app_push_system_enabled, setIsAppPushSystemEnabled] = useAtom(app_push_system_enabled_atom);

  const checkEnabled = async () => {
    const { enabled } = await PartnersBridge.getPushNotificationEnabled();
    setIsAppPushSystemEnabled(enabled);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      refetch();
      checkEnabled();
    }
  };

  useEffect(() => {
    checkEnabled();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!is_logined || isNil(is_app_push_system_enabled)) return;

    if (
      isNil(data?.getPartnerCenterAppPushSystemAgreement) ||
      (typeof data?.getPartnerCenterAppPushSystemAgreement === 'boolean' &&
        data?.getPartnerCenterAppPushSystemAgreement !== is_app_push_system_enabled)
    ) {
      mutateAsync({ input: { is_agree: is_app_push_system_enabled } }).then(() => {
        refetch();
      });
      return;
    }
  }, [data, is_logined, is_app_push_system_enabled]);

  useEffect(() => {
    // NOTE: meta 라우트인 경우 fcm token 갱신 안함
    if (!is_logined || !prefix) return;

    PartnersBridge.getFcmTokenWithDeviceInfo().then(async (data) => {
      try {
        await api_updatePartnerCenterAppFcmToken(
          {
            input: {
              fcm_token: data.fcm_token,
              app_version: data.app_version,
              os_version: data.os_version,
              device_model: data.device_model,
              device_language: data.device_language,
            },
          },
          { no_alert: true },
        );
      } catch (error) {
        console.error(error);
      }
    });
  }, [is_logined, prefix]);
};

const useAppBottomNaviCheckEffect = () => {
  const location = useLocation();
  const { is_logined } = useAccount();
  const prefix = useShopPrefix();

  useEffect(() => {
    // NOTE: 스토어 진입 이전에는 하단 네비게이션을 숨김
    // 도움말 페이지 진입시에는 스토어 진입 이전임에도 불구하고 노출
    const is_help = location.pathname.startsWith('/help');
    PartnersBridge.setVisibleBottomNavi(Boolean(is_logined && (prefix || is_help)));
  }, [location, is_logined, prefix]);
};

const useDeepLinkPblEffect = () => {
  const { pbl } = usePbl();
  const { info } = useInfo();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('partners_referrer') === 'push') {
        pbl({
          category: 'deeplink',
          navigation: 'push',
          navigation_sub: { shop_id: info.id || '', page_url: window.location.href },
          object_type: 'link',
          extra: JSON.stringify({
            notification_id: params.get('partners_extra_notification_id'),
            notification_group_id: params.get('partners_extra_notification_group_id'),
          }),
        });
      }
    }
  }, []);
};

const useConvertLinksToDeepLinkEffect = () => {
  const toast = useToast();
  const getUrlType = useCallback((href: string, blank?: boolean): 'browser' | 'inapp' | 'system' | 'link' | null => {
    const url = new URL(href, window.location.href);
    if (href === 'about:blank' || href === '') {
      // 빈 창을 여는 경우 (세금계산서, 굿스플로) (미지원)
      return null;
    }
    if (url.protocol === 'blob:') {
      // 엑셀 다운로드 (blob)
      return null;
    }
    if (url.host === window.location.host || url.host === 'partners.kakaostyle.com') {
      if (url.pathname.startsWith('/api/provider')) {
        // 엑셀 다운로드 (미지원)
        return null;
      }
      if (!blank) {
        // 새 창으로 여는 경우가 아니면 핸들링 X
        return 'link';
      }
      if (url.pathname.startsWith('/help')) {
        // 도움말 (로그인이 필요하므로 인앱)
        return 'inapp';
      }
      if (['/privacy_policy', '/service_policy', '/zigzin_policy_terms'].includes(url.pathname)) {
        // 약관 (시스템 브라우저)
        return 'system';
      }
      // 기타 페이지는 인앱으로 처리
      return 'inapp';
    }
    if (url.hostname === 'partnerlounge.kakaostyle.com') {
      // 파트너라운지 (시스템 브라우저)
      return 'system';
    }
    if (/goodsflow\.com$/.test(url.hostname)) {
      // 굿스플로
      return 'system';
    }
    return 'system';
  }, []);
  const handleUrlOpen = useCallback(
    (href: string, blank?: boolean): boolean => {
      const type = getUrlType(href, blank);
      switch (type) {
        case 'browser':
          PartnersBridge.callBrowserDeepLink(href);
          return true;
        case 'inapp':
          PartnersBridge.callInAppBrowserDeepLink(href);
          return true;
        case 'system':
          PartnersBridge.callSystemBrowserDeepLink(href);
          return true;
        case 'link':
          // 브라우저 기본 동작 사용
          return false;
        case null:
          toast.show({
            kind: 'error',
            position: 'bottom-center',
            content: '앱에서 지원하지 않는 기능입니다.',
          });
          return true;
      }
    },
    [getUrlType, toast],
  );
  useEffect(() => {
    const oldWindowOpen = window.open;
    (window as any).open = (url: string) => {
      handleUrlOpen(url, true);
      return null;
    };
    // 문서의 <a> 캡쳐링
    document.addEventListener('click', (e) => {
      const el = e.target as HTMLElement;
      const a_el = el?.closest('a');
      if (a_el != null && !e.defaultPrevented) {
        const cancelled = handleUrlOpen(a_el.href, a_el.target === '_blank');
        if (cancelled) {
          e.preventDefault();
        }
      }
      if (el.tagName === 'INPUT') {
        const input_el = el as HTMLInputElement;
        if (input_el.type === 'file' && isAndroid()) {
          // 파일 업로드 미지원
          toast.show({
            kind: 'error',
            position: 'bottom-center',
            content: '앱에서 지원하지 않는 기능입니다.',
          });
        }
      }
    });
    // 모달 내부의 <a target='_blank'> 캡쳐링
    // 모달에서는 e.stopPropagation()으로 더 이상의 전파를 막고 있기 때문에 bubble phase
    // 에서 a 태그의 클릭을 감지할 수 없습니다. 하지만 모달 안에서 a 태그로 이동하는 경우도
    // 존재하기 때문에, 이를 감지하기 위해 capture phase를 활용합니다.
    document.addEventListener(
      'click',
      (e) => {
        const el = e.target as HTMLElement;
        const a_el = el?.closest('a');
        if (a_el != null && a_el.target === '_blank') {
          const cancelled = handleUrlOpen(a_el.href, true);
          if (cancelled) {
            e.preventDefault();
          }
        }
      },
      { capture: true },
    );
    return () => {
      (window as any).open = oldWindowOpen;
    };
  }, [handleUrlOpen]);
};

const AppChecker = () => {
  useSystemPushNotificationCheckEffect();
  useAppBottomNaviCheckEffect();
  useDeepLinkPblEffect();
  useConvertLinksToDeepLinkEffect();

  return null;
};

export default AppChecker;
