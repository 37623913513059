import React, { useMemo } from 'react';

import { AppNotSupportedBanner } from './AppNotSupportedBanner';
import AccountBar from '@/app/rui/components/main_frame/accountbar';
import { Footer } from '@/app/rui/components/main_frame/footer/Footer';
import { Layout } from '@/app/rui/components/main_frame/layout';
import { isApp } from '@/utils/app_utils/conditions';

export interface MetaMainFrameProps {
  title?: string;
  is_support_mobile?: boolean;
  is_support_app?: boolean;
  is_fullscreen?: boolean;
  hide_accountbar?: boolean;
  hide_footer?: boolean;
  has_mobile_back_button?: boolean;
  children: React.ReactNode;
}

export const MetaMainFrame: React.FC<MetaMainFrameProps> = ({
  title = '',
  hide_footer = false,
  hide_accountbar = false,
  is_fullscreen = false,
  has_mobile_back_button = false,
  is_support_app,
  children,
}) => {
  const renderChildren = useMemo(() => {
    return (
      <>
        {isApp() && !is_support_app && <AppNotSupportedBanner />}
        {children}
        {!hide_footer && <Footer />}
      </>
    );
  }, [is_support_app, children, hide_footer]);
  return (
    <Layout.MainFrame fullscreen={is_fullscreen}>
      {!hide_accountbar && <AccountBar title={title} has_mobile_back_button={has_mobile_back_button} />}
      <Layout.MetaContainer hide_accountbar={hide_accountbar} fullscreen={is_fullscreen}>
        {renderChildren}
      </Layout.MetaContainer>
    </Layout.MainFrame>
  );
};
