import { VStack, VStackProps } from '@croquiscom/pds';
import React from 'react';
import { ResponsiveStackType } from './type';
import { getReponsiveValue } from './utils';
import useMediaQuery from '@/hooks/useMediaQuery';

export interface ResponsiveVStackProps
  extends Omit<VStackProps, 'spacing' | 'p' | 'm' | 'width' | 'height'>,
    ResponsiveStackType<VStackProps> {
  children: React.ReactNode;
}

export default function ResponsiveVStack({ spacing, width, height, p, m, children, ...props }: ResponsiveVStackProps) {
  const { is_desktop } = useMediaQuery();

  return (
    <VStack
      spacing={getReponsiveValue(spacing, is_desktop)}
      m={getReponsiveValue(m, is_desktop)}
      p={getReponsiveValue(p, is_desktop)}
      width={getReponsiveValue(width, is_desktop)}
      height={getReponsiveValue(height, is_desktop)}
      {...props}
    >
      {children}
    </VStack>
  );
}
