/** @jsxRuntime classic */
/** @jsx jsx */
import { IconProps, IconLoadingDonuts } from '@croquiscom/pds';
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import { ButtonIcon } from './ButtonIcon';
import { button_base_css, button_size_css, getButtonKind, button_icon_size_css } from './styles';
import { ButtonSize, ButtonKind } from './types';

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  fill?: boolean;
  /** @default outlined_secondary */
  kind?: ButtonKind;
  /** @default medium */
  size?: ButtonSize;
  /** @default button */
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /** @default false */
  loading?: boolean;
  /** 좌측 영역에 렌더링 될 아이콘 요소 */
  startIcon?: ReactElement<IconProps>;
  startIconColor?: string;
  /** 우측 영역에 렌더링 될 아이콘 요소 */
  endIcon?: ReactElement<IconProps>;
}

export interface ButtonIconProps {
  size: ButtonSize;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = 'button',
      size = 'medium',
      kind = 'outlined_secondary',
      fill,
      disabled,
      className,
      loading = false,
      startIcon,
      startIconColor,
      endIcon,
      ...props
    },
    ref,
  ) => {
    const icon_props = {
      size: button_icon_size_css[size],
      color: 'currentColor',
    };
    const loading_indicator = loading && (
      <ButtonIcon {...icon_props}>
        <IconLoadingDonuts size={30} />
      </ButtonIcon>
    );
    const start_icon_node = startIcon && (
      <ButtonIcon {...icon_props} {...(startIconColor && { color: startIconColor })}>
        {loading ? <IconLoadingDonuts size={30} /> : startIcon}
      </ButtonIcon>
    );
    const end_icon_node = endIcon && (
      <ButtonIcon {...icon_props}>{loading ? <IconLoadingDonuts size={30} /> : endIcon}</ButtonIcon>
    );

    return (
      <button
        type={type}
        className={classNames('pds-button', className)}
        css={[
          button_base_css,
          getButtonKind(kind as ButtonKind),
          button_size_css[size],
          css`
            ${fill && 'width: 100%;'}
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
          `,
        ]}
        disabled={disabled || loading}
        {...props}
        ref={ref}
      >
        {start_icon_node || (!end_icon_node && loading_indicator)}
        {children}
        {end_icon_node}
      </button>
    );
  },
);
