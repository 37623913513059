import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { SellerResource } from '@/api';
import { info_atom } from '@/atoms/info';

export type isAllowedFunction = (res: SellerResource | SellerResource[]) => boolean;

export function useInfo() {
  const [info, dispatch] = useAtom(info_atom);

  const isAllowed = useCallback<isAllowedFunction>(
    (res: SellerResource | SellerResource[]): boolean => {
      const { allowed_resource_list, shop_subscription_resource } = info;
      if (allowed_resource_list == null) {
        return false;
      }
      if (allowed_resource_list.includes(SellerResource.ALL)) {
        return true;
      }
      const res_list = Array.isArray(res) ? res : [res];

      //TODO: 스타터 플랜쪽 권한을 info의 allowed_resource_list로 안내려주셔서 임시 방편으로 shop_subscription_resource에서 권한 체크
      return (
        res_list.some((res) => allowed_resource_list.includes(res)) ||
        res_list.some((res) => shop_subscription_resource.resources.includes(res))
      );
    },
    [info],
  );

  const requestInfo = useCallback(() => {
    return dispatch({ type: 'refetch' });
  }, [dispatch]);

  // '직진배송'샵 인 경우
  const isZigzinShop = info?.name === '직진배송';

  // 진직배송 가입 셀러
  const is_zigzin = info?.zonly_status === 'NORMAL';

  return { info, isAllowed, requestInfo, isZigzinShop, is_zigzin };
}
