import { colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import SidebarToggleButton from '@/app/rui/components/main_frame/SidebarToggleButton';
import { CustomScrollContainer } from '@/components/common/styled';
import { hotkey_map } from '@/constants/keyMap';
import { DESKTOP_MEDIA_QUERY_STRING } from '@/hooks/useMediaQuery';

interface Props extends PropsWithChildren {}

const AsideMenu: FC<Props> = ({ children }) => {
  const [left, setLeft] = useState<number>(0);
  const [is_fold, setIsFold] = useState<boolean>(false);

  const onClickSidebarToggleButton = () => {
    setIsFold((is_fold) => !is_fold);
  };

  useHotkeys(hotkey_map.LNB, onClickSidebarToggleButton);

  useEffect(() => {
    /**
     * 1280px 미만 디바이스 가로 스크롤 지원
     * - GNB 노출시: 1510px(230px + 1280px) 미만일경우
     * - GNB 미노출시: 1280px 미만일경우
     */
    const handleScroll = () => {
      setLeft(-Math.round(window.scrollX));
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledSidebarContainer is_fold={is_fold}>
      <StyledSidebar is_fold={is_fold} style={{ left: `${left}px` }}>
        <CustomScrollContainer>{children}</CustomScrollContainer>
        <SidebarToggleButton is_fold={is_fold} onClick={onClickSidebarToggleButton} />
      </StyledSidebar>
    </StyledSidebarContainer>
  );
};

export default AsideMenu;

const StyledSidebarContainer = styled.div<{ is_fold?: boolean }>`
  display: none;
  width: 230px;
  flex: 0 0 auto;
  position: relative;
  ${({ is_fold }) =>
    is_fold &&
    css`
      width: 0;
    `}
  @media ${DESKTOP_MEDIA_QUERY_STRING} {
    display: block;
  }
`;

const StyledSidebar = styled.div<{ is_fold?: boolean }>`
  position: fixed;
  width: 230px;
  top: 42px;
  left: 0;
  bottom: 0;
  background-color: ${colors.gray100};
  border-right: 1px solid #eaeafc;
  transition: margin 0.2s;
  margin: ${({ is_fold }) => (is_fold ? '0 0 0 -230px' : 0)};
  z-index: 90;
`;
