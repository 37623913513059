import { useState, useEffect } from 'react';

export function useResizeObserver(target: HTMLElement | undefined | null = document.body) {
  const width = target?.clientWidth;
  const [screen_size, setScreenSize] = useState(width);
  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setScreenSize(entry.contentRect.width);
      });
    });
    if (target) {
      ro.observe(target);
    }
    return () => {
      ro.disconnect();
    };
  }, [target]);
  return {
    screen_size,
  };
}
