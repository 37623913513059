import { useAtom, useAtomValue } from 'jotai';
import { queryClientAtom } from 'jotai/query';
import { account_atom } from '@/atoms/account';
import { Config } from '@/config';
import Account, { AgencyAccountType, AgencyInfo, Resource } from '@/types/account';

export type AccountType = ReturnType<typeof useAccount>;

export function useAccount() {
  const queryClient = useAtomValue(queryClientAtom);
  const [account, dispatch] = useAtom(account_atom);

  const identifier = account?.identifier;
  const is_logined = !!identifier;

  // 권한과 관련된 필드
  const is_agency = account?.role === 'agency';
  const is_seller = account?.role === 'seller';
  const is_zigzag_alliance = account?.role === 'zigzag_alliance_expert';
  const is_zigzag = account?.role === 'zigzag' || account?.role === 'zigzag_alliance_expert';
  const is_sellmate = account?.role === 'sellmate';
  const is_ezadmin = account?.role === 'ezadmin';

  // 환경과 관련된 필드
  const is_dev = Config.dev || Config.isStoryBook;
  const is_alpha = Config.alpha || Config.isStoryBook;
  const is_beta = (Config.beta || Config.isStoryBook) && is_zigzag;

  // agency type 관련된 필드
  const agency_account_type = account?.agency_account_type;
  const is_agency_manager = account?.agency_account_type === AgencyAccountType.MANAGER;
  const is_agency_marketer = account?.agency_account_type === AgencyAccountType.MARKETER;
  const agency_info: AgencyInfo | null =
    is_agency && account
      ? {
          type: account.agency_account_type!,
          name: account.name || '',
          phone_number: account.phone_number || '',
        }
      : null;

  const is_allowed_shop = Config.shop_main_domain === 'crealfit' && !is_sellmate && !is_ezadmin;
  const has_new_notice = account?.has_new_notice || false;

  return {
    identifier,
    is_logined,
    is_agency,
    is_seller,
    is_zigzag_alliance,
    is_zigzag,
    is_sellmate,
    is_ezadmin,
    is_dev,
    is_alpha,
    is_beta,
    is_agency_manager,
    is_agency_marketer,
    has_new_notice,
    agency_info,
    agency_account_type,

    requestAccount: async () => {
      await dispatch({ type: 'refetch' });
      return queryClient.getQueryData(['useAccountQuery']) as typeof account;
    },

    clear: () => {
      queryClient.setQueryData('useAccountQuery', null);
    },

    setAccountForTest: (account: Account) => {
      if (account) {
        queryClient.setQueryData('useAccountQuery', account);
      }
    },

    canRead: (resource: Resource) => {
      if (is_allowed_shop) {
        return true;
      }
      // 얼라이언스팀은 모든 리소스를 볼 수 있다.
      if (is_zigzag_alliance) {
        return true;
      }

      if (is_agency) {
        // 대행사는 볼 수 없는 리소스 (지그재그는 볼 수 있음)
        return !['agency_info', 'customer_center', 'license', 'contact', 'shop_tax_invoice'].includes(resource);
      }

      if (is_sellmate || is_ezadmin) {
        return !['ad_display', 'seller_payment', 'trait', 'contact', 'agency_info', 'license', 'shop_sync'].includes(
          resource,
        );
      }

      // 기본적으로 리소스는 모두 볼 수 있다.
      return true;
    },

    canWrite: (resource: Resource) => {
      if (is_allowed_shop) {
        return true;
      }

      // 얼라이언스팀은 모든 리소스를 볼 수 있다. (novice의 경우 실제로는 서버에서 에러를 반환한다)
      if (is_zigzag_alliance) {
        return true;
      }

      // 셀러만 수정할 수 있는 리소스
      if (
        [
          'agency_info',
          'customer_center',
          'license',
          'contact',
          'shop_tax_invoice',
          'ad_display_registration',
          'shop_sync',
        ].includes(resource)
      ) {
        return is_seller;
      }

      // 대행사 중 일부는 상품 정보를 입력할 수 있다.
      // 현재 입력 가능 대행사: 제이에스컴퍼니
      // 코드상에 goods_info를 호출하는 부분이 어디에도 없어서.. 일단 주석 by lucas
      // if (resource === 'goods_info') {
      //   return !is_agency || isAllowedRes(SellerResource.GOODS_MANAGEMENT);
      // }

      // 기본적으로 지그재그는 리소스를 수정할 수 없다.
      return !is_zigzag;
    },
  };
}
