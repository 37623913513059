import { semantic_colors, BaseText, IconBoxX, State, Button } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface NoAlertDataProps {
  error?: unknown;
  refetch?: () => void;
}

export const NoAlertData: React.FC<NoAlertDataProps> = ({ error, refetch }) => {
  const { t } = useTranslation('main_frame');

  return error ? (
    <NoData>
      <State
        kind='warning'
        size={40}
        action={
          <Button kind='primary' onClick={refetch}>
            {t('다시 시도하기')}
          </Button>
        }
        description={t('데이터를 불러오는 중 오류가 발생했습니다.\n다시 시도해주세요.')}
      />
    </NoData>
  ) : (
    <NoData>
      <IconBoxX size={40} color='currentColor' />
      <BaseText kind='Heading_15_SemiBold'>{t('알림이 없어요')}</BaseText>
    </NoData>
  );
};

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  color: ${semantic_colors.content.tertiary};
`;
