import { BaseText, Button, Divider, HStack, IconArrowChevronRight, Modal, VStack } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSubscriptionPriceForPartnerPlus } from '../intro/hooks/useGetSubscriptionPriceForPartnerPlus';
import PartnerPlusGreetingImage from '@/assets/images/partner-plus/greeting.svg';
import { Config } from '@/config';
import { useGetSubscriptionOpenChatQuery } from 'api';

interface GreetingModalProps {
  is_open: boolean;
  onClose: () => void;
}

const GreetingModal: React.FC<GreetingModalProps> = ({ is_open, onClose }) => {
  const { t } = useTranslation('insight');
  const LOCAL_STORAGE_CODE = `InsightSubscriptionNoticePopup.${Config.shop_main_domain}.LastConfirmed`;
  const { data } = useGetSubscriptionPriceForPartnerPlus();
  const { data: openchat_data } = useGetSubscriptionOpenChatQuery();

  return (
    <Modal
      width={600}
      title={t('파트너플러스 구독 안내')}
      opened={is_open}
      contentProps={{ p: 0, scrollable: true }}
      cancelText={t('확인')}
      canClickOutside={false}
      onCancel={() => {
        onClose();
        localStorage.setItem(LOCAL_STORAGE_CODE, 'true');
      }}
    >
      <VStack>
        <img src={PartnerPlusGreetingImage} alt={''} />
      </VStack>
      <VStack p={24} spacing={16}>
        <VStack spacing={8}>
          <BaseText kind='Heading_20_SemiBold'>{t('파트너플러스 구독이 시작되었습니다!')}</BaseText>
          <BaseText kind='Body_14_Regular'>
            {t('좌측 메뉴에서 "파트너플러스" 를 누르면 언제나 구독 혜택과 결제정보를 관리할 수 있습니다.')}
          </BaseText>
        </VStack>
        <Divider />
        <VStack spacing={8}>
          <BaseText kind='Body_14_Bold'>
            {t('신규 구독사 오픈 채팅방에 참여하고 활용팁을 얻고 궁금증을 해결하세요.')}
          </BaseText>
          <HStack spacing={8} alignment='center'>
            <HStack width={68}>
              <BaseText kind='Body_14_SemiBold'>{t('• 입장코드:')}</BaseText>
            </HStack>
            <BaseText kind='Body_14_Regular'>{openchat_data?.shop_subscription_open_chat?.code}</BaseText>
          </HStack>
          <HStack spacing={8} alignment='center'>
            <HStack width={68}>
              <BaseText kind='Body_14_SemiBold'>{t('• 닉네임:')}</BaseText>
            </HStack>
            <BaseText kind='Body_14_Regular'>{openchat_data?.shop_subscription_open_chat?.nickname}</BaseText>
          </HStack>
          <BaseText kind='Body_14_Regular'>
            {t('- 오픈채팅방 입장 시에는 위에 안내드린 닉네임으로 프로필을 설정해주세요.')}
            {data?.grade === 8 && <br />}
            {data?.grade === 8 &&
              t(
                '- 최근 월 평균 거래액 700만원 이하 스토어 구독 혜택인 3만 광고 코인은 구독료 결제 다음날 지급됩니다. 카카오톡으로 안내드려요.',
              )}
          </BaseText>
        </VStack>
        <HStack>
          <Button
            size='small'
            kind='primary'
            endIcon={<IconArrowChevronRight />}
            onClick={() => {
              window.open(openchat_data?.shop_subscription_open_chat?.link_url, '_blank');
            }}
          >
            {t('오픈채팅방 입장하기')}
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};

export default GreetingModal;
