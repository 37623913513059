import { BaseText, Button, VStack } from '@croquiscom/pds';
import React from 'react';
import { DoneLottie } from 'rui/components/main_frame/floating/DoneLottie';

interface FeedbackDoneProps {
  has_confirm?: boolean;
  onDone?: () => void;
}

export const FeedbackDone = ({ has_confirm, onDone }: FeedbackDoneProps) => {
  return (
    <VStack spacing={16} alignment='center'>
      <DoneLottie
        onComplete={() => {
          !has_confirm && onDone?.();
        }}
      />
      <BaseText kind='Heading_18_Bold' alignment='center'>
        의견 제출 완료!
        <br />
        소중한 의견 감사드립니다.
      </BaseText>
      {has_confirm && (
        <Button
          kind='primary'
          size='medium'
          fill
          onClick={() => {
            onDone?.();
          }}
        >
          확인
        </Button>
      )}
    </VStack>
  );
};
