import { ShopSiteCountry } from '../constants';
import { COUNTRY_NAME_I18N, SITE_NAME_I18N } from '@/constants/site';

export function canGetChannelName({ site_id, country }: { site_id: string | number; country?: string }) {
  return ((country === 'KR' || !country) && SITE_NAME_I18N[site_id]) || (country && COUNTRY_NAME_I18N[country]);
}

export function getChannelName(shop_site: ShopSiteCountry) {
  const { site_id, country } = shop_site;

  if (!canGetChannelName(shop_site)) {
    return;
  }

  if (country === 'KR' || country === undefined) {
    return SITE_NAME_I18N[site_id]?.();
  }

  return `${SITE_NAME_I18N[site_id]?.()} ${COUNTRY_NAME_I18N[country]?.()}`;
}
