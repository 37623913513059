import { SelectedCategory } from '../category/types';
import { Attribute, ProductSizeInput, SelectedAttribute, SelectedOptionAttribute, ShopSiteCountryKey } from '../utils';
import {
  CatalogProductCategoryInput,
  CatalogProductImageInput,
  CatalogProductSearchKeyword,
  CatalogProductShippingType,
  ProductModel,
  GetCatalogProductQuery,
  CatalogItemInput,
  CountryCode,
  CatalogReservationShippingInput,
  CatalogProductOptionType,
  CatalogMetaAttribute,
  CatalogProductOptionValueInput,
  CatalogProductOptionInput,
  ImageRegistrationType,
} from '@/api';

export interface Discount {
  site_id: number;
  country: CountryCode;
  start_at: Date;
  end_at: Date | null;
  stopped: boolean;
}

type DiscountPricePolicyId = string;
export type ProceedingDiscounts = Record<DiscountPricePolicyId, Discount> | null;
export interface Price {
  sales_price: number | null;
  discount_price: number | null;
}

export interface OptionItemReservation {
  name: string;
  status: CatalogReservationShippingStateType;
  unit: CatalogReservationShippingUnitType;
  stopped?: boolean;
  option_type?: CatalogProductOptionType;
  reservation_key?: string | null;
}

export interface OptionItemReservationFormValue extends CatalogReservationShippingInput, OptionItemReservation {}

export interface CatalogReservationShippingListFormValues {
  reservation_shipping_list: OptionItemReservationFormValue[];
}

export interface CatalogItemAttributeInputFormValue {
  is_new?: boolean;
  name: string;
  value: string;
  product_option_id: string;
  product_option_value_id: string;
}

export interface OptionItemWithShippingDaysFormValue {
  id: string | null;
  option_type: CatalogProductOptionType;
  reservation_key?: string | null;
  shipping_days: number | null;
  item_attribute_list: CatalogItemAttributeInputFormValue[];
}

export interface DirectOptionItemFormValue
  extends Omit<CatalogItemInput, 'id' | 'site_country_list' | 'shipping_days' | 'option_type' | 'item_attribute_list'>,
    OptionItemWithShippingDaysFormValue {
  prices: Record<string, Price>;
}

export type ImageDataByUniqId = Record<string, { object_url: string; blob: Blob }>;

export type ImageFile = Record<string, Blob>;

export type ProductImageInput =
  | (CatalogProductImageInput & { image_url?: string; registration_type?: ImageRegistrationType })
  | GetCatalogProductQuery['catalog_product']['product_image_list'][number];

export type CatalogModelItem = ProductModel & {
  model_id: string;
  sizes_input: string;
};

export type ZONLY_STATUS = 'NORMAL' | null;
export type OriginItemList = GetCatalogProductQuery['catalog_product']['item_list'][0];

type SearchKeywordList = CatalogProductSearchKeyword & {
  is_new: boolean;
};

export const COORDINATED_PRODUCT_TYPE = {
  ONE_WAY_COORDINATION: 'ONE_WAY_COORDINATION',
  TWO_WAY_COORDINATION: 'TWO_WAY_COORDINATION',
} as const;
export type CoordinatedProductType = (typeof COORDINATED_PRODUCT_TYPE)[keyof typeof COORDINATED_PRODUCT_TYPE];
export interface CoordinatedProduct {
  type: CoordinatedProductType;
  id: string;
  is_type_editable: boolean;
  updated_at?: number;
}

export interface Brand {
  brand_id: string;
  brand_name: string;
}

export interface FashionCatalogProductFormValues {
  brand_id: string;
  product_option_list: CatalogProductOptionWithValueList[];
  attributes: Attribute[];
  category: CatalogProductCategoryInput | null;
  selected_attributes: SelectedAttribute[];
  selected_option_attributes: SelectedOptionAttribute[];
  selected_categories: SelectedCategory[];
  selected_site_country_keys: ShopSiteCountryKey[];
  size_list: ProductSizeInput;
  shipping_days: number | null;
  shipping_type: CatalogProductShippingType;
  search_keyword_list: SearchKeywordList[];
  coordinated_products: CoordinatedProduct[];
  use_coordinated_products: boolean;
  models: CatalogModelItem[];
  is_self_production: boolean;
  use_additional_option_item: boolean;
  item_list: OptionItemWithShippingDaysFormValue[];
  additional_item_list: OptionItemWithShippingDaysFormValue[];
  reservation_shipping_list?: CatalogReservationShippingInput[];
  modified_reservation_shipping_list: OptionItemReservationFormValue[];
  meta_attribute_list: CatalogMetaAttribute[];
}

export enum CatalogFormScrollPoint {
  PREORDER = 'PREORDER',
  SHIPPING_FEE = 'SHIPPING_FEE',
  OPTION_INFO = 'OPTION_INFO',
  ADDITIONAL_OPTION_INFO = 'ADDITIONAL_OPTION_INFO',
  ORIGINAL_PRICE = 'ORIGINAL_PRICE',
  SHIPPING_RESERVATION = 'SHIPPING_RESERVATION',
  PURCHASE = 'PURCHASE',
}

export const CATALOG_RESERVATION_SHIPPING_STATE = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  ONGOING_ORDER_PERIOD: 'ONGOING_ORDER_PERIOD',
  ONGOING_SHIPPING_PERIOD: 'ONGOING_SHIPPING_PERIOD',
  CLOSE: 'CLOSE',
} as const;

export type CatalogReservationShippingStateType =
  (typeof CATALOG_RESERVATION_SHIPPING_STATE)[keyof typeof CATALOG_RESERVATION_SHIPPING_STATE];

export const CATALOG_RESERVATION_SHIPPING_UNIT = {
  OPTION: 'OPTION',
  PRODUCT: 'PRODUCT',
};

export type CatalogReservationShippingUnitType =
  (typeof CATALOG_RESERVATION_SHIPPING_UNIT)[keyof typeof CATALOG_RESERVATION_SHIPPING_UNIT];

export type OptionReservationFieldKey = 'start_at' | 'end_at' | 'shipping_at';

export type CatalogProductOptionValueInputWithOption = CatalogProductOptionValueInput & {
  option_name: string;
  option_id?: string | null;
};

export type CatalogProductOption = CatalogProductOptionInput & {
  is_default?: boolean;
};

export type CatalogProductOptionWithValueList = Omit<CatalogProductOption, 'value_list'> & {
  value_list: CatalogProductOptionValueInputWithOption[];
};
