import { UploadFile } from '@croquiscom/pds';

import { SellerEntryRequestSiteCountryInput, TimeRangeInput } from '@/api';
import { InfoTraitFormData } from '@/components/info/trait/types';
import { Address } from 'rui/pastel/unstable/daum-address/DaumAddress';

export const EntryProductTypeId = {
  패션: 'fashion',
  뷰티: 'beauty',
  라이프: 'life',
  키즈: 'kids',
  푸드: 'food',
} as const;

export type EntryProductType = (typeof EntryProductTypeId)[keyof typeof EntryProductTypeId];

export const EntryStoreFashionTypeId = {
  소호: 'soho',
  브랜드: 'brand',
} as const;

export type EntryStoreFashionType = (typeof EntryStoreFashionTypeId)[keyof typeof EntryStoreFashionTypeId];

export const EntryRequestSteps = {
  GUIDE: '/entry_requests/guide',
  SEARCH_BUSINESS: '/entry_requests/search_business',
  BUSINESS: '/entry_requests/business',
  ONLINE_SALES_LICENSE: '/entry_requests/online_sales_license',
  PRODUCT_TYPE: '/entry_requests/product_type',
  STORE_CATEGORY_STYLE: '/entry_requests/store_category_style',
  TERMS: '/entry_requests/terms',
  SITE_COUNTRY: '/entry_requests/site_country',
  ACCOUNT: '/entry_requests/account',
  STORE_INFORMATION: '/entry_requests/store_information',
  MANAGER: '/entry_requests/manager',
  CUSTOMER: '/entry_requests/customer',
  ATTACHMENT: '/entry_requests/attachment',
} as const;

export type EntryRequestStepsType = keyof typeof EntryRequestSteps | 'ADDITIONAL_SELLER_ENTRY_REQUEST';

export const BusinessLicenseDuplicatedError = [
  'DUPLICATED_SHOP_BUSINESS_LICENSE',
  'DUPLICATED_SELLER_ENTRY_REQUEST_BUSINESS_LICENSE',
] as const;

export type SearchBusinessLicenseErrorType =
  | (typeof BusinessLicenseDuplicatedError)[number]
  | 'OPEN_API_SERVER_ERROR'
  | 'BUSINESS_LICENSE_AUTHENTICITY_FALSE'
  | 'NETWORK_ERROR';

export const EntryRequestTerms = {
  TERMS: 'terms',
  PRIVACY: 'privacy',
  POLICY: 'policy',
} as const;

export type EntryRequestTermsType = (typeof EntryRequestTerms)[keyof typeof EntryRequestTerms];

export type EntryRequestTypicalImage = InfoTraitFormData['typical_image'];

export interface EntryRequestAges {
  age10: boolean;
  age20early: boolean;
  age20mid: boolean;
  age20late: boolean;
  age30early: boolean;
  age30mid: boolean;
  age30late: boolean;
}

export interface EntryRequestHolidayValue {
  closed_week_day_list: string[];
  is_closed_on_holiday: boolean;
}

export type PresignedUrlFileType =
  | 'typical_image'
  | 'business_license'
  | 'online_sales_license'
  | 'cosmetic_license'
  | 'brand_license'
  | 'consignment_contract';

export interface EntryRequestAttachmentFile extends UploadFile {
  presigned_url_key: PresignedUrlFileType;
  default_path?: string;
}

export interface SearchBusinessInformationFormType {
  business_registration_number: string;
  business_representative_name: string;
  business_commencement_date: string;
  has_business_license_info_validated: boolean;
}

export interface BusinessInformationFormType {
  company_name: string;
  business_category: string;
  business_subcategory: string;
  company_address: Address;
}

export interface OnlineSalesLicenseFormType {
  online_sales_license_number: string;
}

export interface ProductTypeFormType {
  type: EntryProductType;
  fashion_type?: EntryStoreFashionType;
}

export interface StoreCategoryAndStyleFormType {
  category: number;
  style_1?: string;
  style_2?: string;
  ages?: EntryRequestAges;
}

export type TermsFormType = Partial<Record<EntryRequestTermsType, boolean>>;

export interface SiteCountryFormType {
  site_country_list: SellerEntryRequestSiteCountryInput[];
}

export interface AccountInformationFormType {
  email: string;
  password: string;
  password_confirm: string;
}

export interface StoreInformationFormType {
  typical_image: EntryRequestAttachmentFile;
  name: string;
  url: string;
  has_store_url: boolean;
}

export interface ManagerInformationFormType {
  manager_name: string;
  manager_mobile_tel: string;
  manager_landline_tel: string;
  customer_center_manager_name: string;
  customer_center_manager_tel: string;
  is_same_main_manager_contact: boolean;
}

export interface CustomerCenterFormType {
  customer_center_tel: string;
  business_time: TimeRangeInput;
  lunch_time: TimeRangeInput;
  closed_week_days: EntryRequestHolidayValue;
}

export interface AttachmentFileFormType {
  business_license?: EntryRequestAttachmentFile;
  online_sales_license?: EntryRequestAttachmentFile;
  cosmetic_license?: EntryRequestAttachmentFile;
  brand_license?: EntryRequestAttachmentFile;
  consignment_contract?: EntryRequestAttachmentFile;
}

export interface EntryRequestForm {
  ADDITIONAL_SELLER_ENTRY_REQUEST?: boolean;
  SEARCH_BUSINESS?: SearchBusinessInformationFormType;
  BUSINESS?: BusinessInformationFormType;
  ONLINE_SALES_LICENSE?: OnlineSalesLicenseFormType;
  PRODUCT_TYPE?: ProductTypeFormType;
  STORE_CATEGORY_STYLE?: StoreCategoryAndStyleFormType;
  TERMS?: TermsFormType;
  SITE_COUNTRY?: SiteCountryFormType;
  ACCOUNT?: AccountInformationFormType;
  STORE_INFORMATION?: StoreInformationFormType;
  MANAGER?: ManagerInformationFormType;
  CUSTOMER?: CustomerCenterFormType;
  ATTACHMENT?: AttachmentFileFormType;
}
