import dayjs from 'dayjs';
import { t } from 'i18next';

import { SellerNoticeCountryCode, CountryCode, GetSiteCountryListQuery } from '@/api';
import { NoticeListFilterType } from '@/components/notice/atoms';
import {
  SellerNoticeFilterCountryCode,
  SellerNoticeCountryCodeName,
  NOTICE_SITE_NAME_I18N,
} from '@/components/notice/constants';
import { SiteId } from '@/constants/site';
import { getUniqCountryCodeList } from '@/utils/siteCountry';

type ShopSiteCountry = Pick<GetSiteCountryListQuery['shop_site_country_list'][number], 'site_id' | 'country_code'>;
const notice_site_id_length = Object.keys(SiteId).length;
const notice_country_code_length = Object.keys(SellerNoticeCountryCode).length;

export const getSiteName = (site_id_list: SiteId[]) => {
  return site_id_list.length === notice_site_id_length
    ? t('notice::전체 판매채널')
    : site_id_list.map((site_id) => NOTICE_SITE_NAME_I18N[site_id]?.() || '').join('/');
};

export const getCountryName = (country_code_list: SellerNoticeCountryCode[]) => {
  return country_code_list.length === notice_country_code_length
    ? t('notice::전체 국가')
    : SellerNoticeCountryCodeName[country_code_list[0]];
};

export const convertFilterToCountryCodeList = (code: SellerNoticeFilterCountryCode): SellerNoticeCountryCode[] => {
  switch (code) {
    case SellerNoticeFilterCountryCode.ALL:
      return [SellerNoticeCountryCode.KR, SellerNoticeCountryCode.GLOBAL];
    case SellerNoticeFilterCountryCode.GLOBAL:
      return [SellerNoticeCountryCode.GLOBAL];
    case SellerNoticeFilterCountryCode.KR:
    default:
      return [SellerNoticeCountryCode.KR];
  }
};

export const convertCountryCodeToFilterList = (code_list: SellerNoticeCountryCode[]) => {
  if (code_list.length === notice_country_code_length) {
    return SellerNoticeFilterCountryCode.ALL;
  }

  if (code_list.includes(SellerNoticeCountryCode.GLOBAL)) {
    return SellerNoticeFilterCountryCode.GLOBAL;
  }

  return SellerNoticeFilterCountryCode.KR;
};

export const getNoticeCountryCodeList = (site_country_list: ShopSiteCountry[]) => {
  const uniq_country_code_list = getUniqCountryCodeList(site_country_list);
  const result: SellerNoticeCountryCode[] = [];

  if (uniq_country_code_list.includes(CountryCode.KR)) {
    result.push(SellerNoticeCountryCode.KR);
  }

  const globals = Object.keys(CountryCode)
    .filter((key) => key !== CountryCode.KR)
    .map((key) => CountryCode[key]);

  if (uniq_country_code_list.some((country_code) => globals.includes(country_code))) {
    result.push(SellerNoticeCountryCode.GLOBAL);
  }

  return result;
};

export const convertTitle = (title: string, type: NoticeListFilterType, search_keyword: string | null) => {
  if (type === 'CHANGE_KEYWORD' && !!search_keyword) {
    return title.replaceAll(search_keyword, `<em>${search_keyword}</em>`);
  }

  return title;
};

export const isNewNotice = (date_published: number) => {
  return dayjs().startOf('day').diff(dayjs(date_published, 'x').startOf('day'), 'days') < 3;
};
