import { ButtonProps, Placement } from '@croquiscom/pds';
import { PblParams } from '@/utils/pbl';

export type OnboardingFunnelStep = 'ENTRY_COMPLETE' | 'STORE_SETTING' | 'PRODUCT_REGISTRATION' | 'CONTENT_REGISTRATION';

export const REQUIRE_FUNNEL_TASKS = [
  'ENTRY_COMPLETE',
  'STORE_EXPOSURE_INFO',
  'CS_CENTER_INFO',
  'ENTER_SHIPPING_INFO',
  'ENTER_CONTACT_INFO',
  'PRODUCT_REGISTRATION',
  'MAIN_BANNER_REGISTRATION',
  'STORE_REPRESENTATIVE_IMAGE_REGISTRATION',
] as const;

export type OnboardingFunnelTask =
  | (typeof REQUIRE_FUNNEL_TASKS)[number]
  | 'SELECT_PRODUCT_REGISTRATION_TYPE'
  | 'PRODUCT_EXCEL_REGISTRATION'
  | 'COLLECTION_REGISTRATION'
  | 'COLLECTION_DISPLAY_MANAGE';

export interface TaskTooltip {
  id: string;
  message: React.ReactNode;
  placement: Exclude<Placement, 'bottom-end'>;
}

export interface Task {
  task: OnboardingFunnelTask;
  name: React.ReactNode;
  order: number;
  page_path: string;
  is_completed: boolean;
  tooltips: TaskTooltip[];
  is_hidden?: boolean;
  pbl_params?: PblParams;
}

export interface FunnelDescriptionTag {
  type: 'badge' | 'link' | 'text';
  label: React.ReactNode;
  icon?: React.ReactNode;
  path?: string;
  pbl_params?: PblParams;
}

interface FunnelDescriptionShortcut {
  title: React.ReactNode;
}

interface FunnelDescriptionAction extends Pick<ButtonProps, 'kind'> {
  type: 'link' | 'skip_onboarding' | 'next_task';
  label: React.ReactNode;
  allowed?: boolean | ((funnels: Funnel[]) => boolean);
  path?: string;
  pbl_params?: PblParams;
}

interface FunnelDescriptionController {
  title: React.ReactNode;
  sub_title: React.ReactNode;
}

export interface FunnelDescription {
  title: React.ReactNode;
  sub_title: React.ReactNode;
  tags: FunnelDescriptionTag[];
  actions: FunnelDescriptionAction[];
  shortcut: FunnelDescriptionShortcut | null;
  controller: FunnelDescriptionController | null;
}

export interface Funnel {
  step: OnboardingFunnelStep;
  name: React.ReactNode;
  description: FunnelDescription;
  order: number;
  is_completed: boolean;
  date_completed: number | null;
  in_progress_task: Task | null;
  tasks: Task[];
}

export interface Onboarding {
  last_funnel: Pick<Funnel, 'step' | 'name'> & { task: Pick<Task, 'task' | 'name'> };
  total_funnel_count: number;
  completed_funnel_count: number;
  is_completed: boolean;
  is_displayed: boolean;
  funnels: Funnel[];
  last_fetch_time: number;
}
