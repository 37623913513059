import { atom } from 'jotai';
import {
  AdCampaignExposureMethod,
  AdCampaignNonExposureReason,
  AdCampaignStatus,
  AdCreativeInspectionStatus,
  AdCreativeMediaType,
  AdCreativeStatus,
} from '@/api';
type CampaginListType = Array<{
  goods_quality_index_group?: number | null;
  ad_creative_quality_index_group?: number | null;
  ad_campaign: {
    ad_campaign_id: string;
    exposed_ad_creative_id?: string | null;
    inspecting_ad_creative_id?: string | null;
    shop_id: string;
    goods_id: string;
    status: AdCampaignStatus;
    exposure_method: AdCampaignExposureMethod;
    non_exposure_reason: AdCampaignNonExposureReason;
    dateCreated: any;
    dateUpdated?: any | null;
    dateNonExposed?: any | null;
    goodsCreated: any;
    url: string;
    price: number;
    autoAdSyncCreative: boolean;
    catalog_product_type: string;
    is_brand: boolean;
    zpay_price?: number | null;
  };
  exposed_ad_creative?: {
    id: string;
    ad_campaign_id: string;
    media_type: AdCreativeMediaType;
    title: string;
    image_url: string;
    status: AdCreativeStatus;
    inspection_status: AdCreativeInspectionStatus;
    date_created: any;
    date_deleted?: any | null;
    date_inspection_passed?: any | null;
    doer?: string | null;
    reject_reason?: string | null;
    exposed_from?: any | null;
    exposed_to?: any | null;
    video_url?: string | null;
    video_duration?: number | null;
    lazy_inspection: boolean;
    inspection_comment?: string | null;
  } | null;
  inspecting_ad_creative?: {
    id: string;
    ad_campaign_id: string;
    media_type: AdCreativeMediaType;
    title: string;
    image_url: string;
    status: AdCreativeStatus;
    inspection_status: AdCreativeInspectionStatus;
    date_created: any;
    date_deleted?: any | null;
    date_inspection_passed?: any | null;
    doer?: string | null;
    reject_reason?: string | null;
    exposed_from?: any | null;
    exposed_to?: any | null;
    video_url?: string | null;
    video_duration?: number | null;
    lazy_inspection: boolean;
    inspection_comment?: string | null;
  } | null;
  ad_campaign_performance: {
    view: number;
    click: number;
    click_rate: number;
    ad_billing_amount: number;
    from?: any | null;
    to?: any | null;
  };
  ad_creative_quality: {
    book_mark_conversion: number;
    my_goods_conversion: number;
    order_amount?: number | null;
    order_count?: number | null;
    roas_and_predicted: { roas?: number | null; predicted_roas?: number | null };
  };
}>;

export const campaignListAtom = atom<CampaginListType | undefined>([]);
