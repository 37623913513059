import { IconColoredEyes } from '@croquiscom/pds';
import styled from '@emotion/styled';
import type dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlurArea from './common/BlurArea';
import Nodata from './common/Nodata';
import ProductSwiper from './common/ProductSwiper';
import SectionTitle from './common/SectionTitle';
import { ContentContainer } from './common/styles';
import { useGetDailyReportSalesChangeTopRanking } from './hooks/useGetDailyReportSalesChangeTopRanking';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const SalesTop: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const [show_freeview, setShowFreeview] = useState(false);
  const { data, isLoading } = useGetDailyReportSalesChangeTopRanking({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: show_freeview,
    main_domain,
    token,
  });
  const sales_data = data?.partner_plus_daily_report?.sales_change_top_ranking_report;
  const has_data = sales_data && sales_data.product_ranking_list.length > 0;

  return (
    <ContentContainer>
      <SectionTitle
        object_section='베스트 상품 성과 분석'
        onClickLink={onClose}
        main_domain={main_domain}
        Icon={IconColoredEyes}
        is_partner_plus={is_partner_plus}
        is_partner_plus_only={sales_data?.plus_only}
        link='/insight/sales-performance?ug=product-trend'
        description={t('최근 7일 주문수 TOP 10 상품의 어제 성과를 분석해드려요.')}
      >
        {t('베스트 상품 성과 분석')}
      </SectionTitle>
      {has_data ? (
        <ProductSwiper
          type='sales'
          is_partner_plus={is_partner_plus}
          object_section='베스트 상품 성과 분석'
          is_blurred={sales_data.is_blurred}
          width={208}
          product_ranking_list={sales_data?.product_ranking_list || []}
          base_date={date}
        >
          {sales_data.is_blurred && (
            <AbsoluteBox length={sales_data.product_ranking_list.length - 1}>
              <BlurArea
                is_partner_plus={is_partner_plus}
                is_loading={isLoading}
                main_domain={main_domain}
                object_section='베스트 상품 성과 분석'
                onRequestFreeView={() => setShowFreeview(true)}
                is_blur={sales_data.is_blurred}
                used_free_view_count={sales_data.free_view_count?.used_free_view_count || 0}
                max_free_view_count={sales_data.free_view_count?.max_free_view_count || 0}
                postfix_text={t('판매예측량 TOP 10 상품을 확인할 수 있어요.')}
              >
                <></>
              </BlurArea>
            </AbsoluteBox>
          )}
        </ProductSwiper>
      ) : (
        <Nodata />
      )}
    </ContentContainer>
  );
};

export default SalesTop;

const AbsoluteBox = styled.div<{ length: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ length }) =>
    length === 0 ? '96px' : length === 1 ? '100px' : length ? `${Math.min(length, 4) * 68 + 16}px` : '100%'};
  z-index: 1;
`;
