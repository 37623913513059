import { BaseText, semantic_colors, text_styles, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';

interface ChatBotQuestionProps {
  message: string;
  timestamp?: number;
}

export const ChatBotQuestion = ({ message, timestamp }: ChatBotQuestionProps) => {
  return (
    <VStack alignment='trailing' spacing={4} width='100%'>
      <SC.MessageBox>{message}</SC.MessageBox>
      <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
        {dayjs(timestamp).format('A hh:mm')}
      </BaseText>
    </VStack>
  );
};

const SC = {
  MessageBox: styled.div`
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${semantic_colors.border.default};
    ${text_styles.Body_14_Regular};
    line-height: 1.6;
    white-space: pre-line;
  `,
};
