const api_base_url = '/api/provider';
// https://partners.kakaostyle.com/shop/imvely/ 와 같은 주소에서 main_domain를 얻는다
const shop_main_domain = /\/iframe.html/.test(window.location.pathname)
  ? 'crealfit'
  : window.location.pathname.split('/')[2];
const dev = /^partners-dev-[a-zA-Z0-9-]+\.dev\.zigzag.kr$/.test(window.location.host) || process.env.STAGE === 'dev';
const alpha = window.location.host === 'alpha.partners.kakaostyle.com' || process.env.STAGE === 'alpha';
const beta = window.location.host === 'beta.partners.kakaostyle.com' || process.env.STAGE === 'beta';
const beta_rc = window.location.host === 'beta-rc.partners.kakaostyle.com' || process.env.STAGE === 'beta';
const production = window.location.host === 'partners.kakaostyle.com';
const isStoryBook = !!process.env.STORYBOOK;
const isLocalHost = window.location.port === '8080';

const Config = {
  api_base_url,
  shop_main_domain,
  dev,
  alpha,
  beta,
  beta_rc,
  production,
  isStoryBook,
  isLocalHost,
};

// from https://github.com/krzkaczor/ts-essentials
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

export type BaseConfigType = DeepPartial<typeof Config>;

export default Config;
