import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { ShopTermAgreementType } from '@/api';
import { term_agreement_atom, term_disagreement_atom } from '@/atoms/agreement';

export type TermAgreementList = { [key in keyof typeof ShopTermAgreementType]?: dayjs.Dayjs } | undefined;
export type TermDisagreementList = ShopTermAgreementType[] | undefined;

export function useAgreement() {
  // 약관 미동의 여부 확인 (agreement 사용시, 미동의/미가입 구분불가)
  const [exists_term_disagreement_list] = useAtom(term_disagreement_atom);
  // 동의된 약관정보 확인 (미동의/미가입 제외)
  const [term_agreement_date_created_list] = useAtom(term_agreement_atom);

  const isDisagreeTerm = useCallback(
    (type: ShopTermAgreementType) => {
      return exists_term_disagreement_list?.includes(type) ?? false;
    },
    [exists_term_disagreement_list],
  );

  const getTermAgreedDate = useCallback(
    (type: ShopTermAgreementType) => {
      return term_agreement_date_created_list?.[type];
    },
    [term_agreement_date_created_list],
  );

  return { isDisagreeTerm, getTermAgreedDate };
}
