import {
  IconColoredFlag,
  semantic_colors,
  BaseText,
  HStack,
  IconColoredFaceGood,
  VStack,
  colors,
  IconColoredFaceVeryGood,
  IconColoredFaceBad,
  TextButton,
  IconArrowChevronRight,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import type dayjs from 'dayjs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Nodata from './common/Nodata';
import SectionTitle from './common/SectionTitle';
import { Card, ContentContainer } from './common/styles';
import { useGetDailyReportTargetAchievement } from './hooks/useGetDailyReportTargetAchievement';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const GrowthTarget: React.FC<Props> = ({ main_domain, token, date, onClose, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const history = useHistory();
  const prefix = useShopPrefix();
  const shop_prefix = main_domain ? `/shop/${main_domain}${prefix}` : prefix;
  const { data } = useGetDailyReportTargetAchievement({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: false,
    main_domain,
    token,
  });

  const target_data = data?.partner_plus_daily_report?.target_achievement_report;
  if (!is_partner_plus) {
    return null;
  }

  return (
    <ContentContainer>
      <SectionTitle
        onClickLink={onClose}
        object_section='목표 달성 관리'
        Icon={IconColoredFlag}
        main_domain={main_domain}
        is_partner_plus={is_partner_plus}
        is_partner_plus_only={target_data?.plus_only}
        link='/insight/sales-performance?ug=growth-target'
        description={t('남은 기간에 따른 진행 상태를 매일 확인해보세요.')}
      >
        {t('목표 달성 관리')}
      </SectionTitle>
      {target_data ? (
        <Card spacing={8}>
          {(target_data?.achievement_at_date || 0) < 95 ? (
            <HStack spacing={6}>
              <IconColoredFaceBad size={24} />
              <BaseText color={semantic_colors.state.warning} kind='Heading_15_Bold'>
                {t('실적이 부족해요')}
              </BaseText>
            </HStack>
          ) : (target_data?.achievement_at_date || 0) > 105 ? (
            <HStack spacing={6}>
              <IconColoredFaceVeryGood size={24} />
              <BaseText color={semantic_colors.state.positive} kind='Heading_15_Bold'>
                {t('초과 달성 중이에요!')}
              </BaseText>
            </HStack>
          ) : (
            <HStack spacing={6}>
              <IconColoredFaceGood size={24} />
              <BaseText color={semantic_colors.state.positive} kind='Heading_15_Bold'>
                {t('목표까지 잘 진행되고 있어요!')}
              </BaseText>
            </HStack>
          )}
          <HStack>
            <VStack width='50%'>
              <BaseText kind='Body_14_SemiBold'>{t('이번달 목표')}</BaseText>
              <BaseText kind='Heading_15_Bold'>
                {target_data?.monthly_target.toLocaleString()}
                {t('원')}
              </BaseText>
            </VStack>
            <VStack width='50%' ml='auto'>
              <BaseText kind='Body_14_SemiBold'>{t('이번달 누적 거래액')}</BaseText>
              <BaseText kind='Heading_15_Bold'>
                {target_data?.accumulated_order_amount.toLocaleString()}
                {t('원')}
              </BaseText>
            </VStack>
          </HStack>
          <VStack>
            <HStack alignment='center' spacing={8}>
              <GaugeBar percentage={Math.min(target_data?.achievement || 0, 100)}></GaugeBar>
              <BaseText kind='Heading_17_Bold' color={colors.blue400}>
                {target_data?.achievement}% {t('달성')}
              </BaseText>
            </HStack>
            <BaseText kind='Body_12_Medium' color={semantic_colors.content.secondary}>
              {(target_data?.remain_order_amount || 0) > 0 ? (
                <Trans t={t}>이번달 목표까지 {(target_data?.remain_order_amount || 0).toLocaleString()}원 남음</Trans>
              ) : (
                <Trans t={t}>
                  이번달 목표를 {Math.abs(target_data?.remain_order_amount || 0).toLocaleString()}원 초과
                </Trans>
              )}
            </BaseText>
          </VStack>
        </Card>
      ) : (
        <Nodata>
          <BaseText color={semantic_colors.content.tertiary} kind='Body_12_Medium'>
            {t('목표 거래액을 설정하면 목표 달성 관리를 확인 할 수 있어요.')}
          </BaseText>
          <TextButton
            kind='link'
            size='small'
            endIcon={<IconArrowChevronRight />}
            onClick={() => {
              history.push(`${shop_prefix}/insight/sales-performance?ug=growth-target`);
              onClose?.();
            }}
          >
            {t('목표 달성 관리 시작하기')}
          </TextButton>
        </Nodata>
      )}
    </ContentContainer>
  );
};

export default GrowthTarget;

const GaugeBar = styled.div<{ percentage: number }>`
  position: relative;
  width: 100px;
  height: 8px;
  background-color: ${colors.gray350};
  border-radius: 4px;
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    width: ${({ percentage }) => percentage}%;
    height: 100%;
    background-color: ${colors.blue400};
    border-radius: 4px;
  }
`;
