export type Role = 'unknown' | 'zigzag' | 'agency' | 'seller' | 'zigzag_alliance_expert' | 'sellmate' | 'ezadmin';
export type Resource =
  | 'ad_display'
  | 'trait'
  | 'tracking_param'
  | 'customer_center'
  | 'license'
  | 'agency_info'
  | 'contact'
  | 'shop_sync'
  | 'goods'
  | 'shop_tax_invoice'
  | 'ad_display_registration'
  | 'wallet'
  | 'goods_info'
  | 'seller_payment'
  | 'manage_managers'
  | 'fulfillment_payment';

export enum AgencyAccountType {
  MANAGER = 'MANAGER',
  MARKETER = 'MARKETER',
}

export interface AgencyInfo {
  type: AgencyAccountType;
  name: string;
  phone_number: string;
}

export default interface Account {
  identifier: string;
  role: Role;
  agency_account_type: AgencyAccountType | null;
  name: string | null;
  phone_number: string | null;
  has_new_notice: boolean;
}
