import { colors, IconSort } from '@croquiscom/pds';
import React from 'react';

const SortIndicator = ({ isSorted, sortDirection }) =>
  !isSorted ? (
    <IconSort color={colors.gray600} secondaryColor={colors.gray600} />
  ) : sortDirection === 'increment' ? (
    <IconSort color={colors.blue500} secondaryColor={colors.gray600} />
  ) : (
    <IconSort color={colors.gray600} secondaryColor={colors.blue500} />
  );

export default SortIndicator;
