import { t } from 'i18next';
import {
  StoreFeePromotionDashboardTable_ItemFragment,
  StoreFeePromotionVipDashboardTable_ItemFragment,
  ZigzinFeePromotionDashboardTable_ItemFragment,
  ZigzinFeePromotionVipDashboardTable_ItemFragment,
} from '@/api';

export type ZigzinFeePromotionTabId = 'zigzin_outlet' | 'zigzin_fee_discount';

export const ZIGZIN_FEE_PROMOTION_TABS: Array<{ label: () => string; value: ZigzinFeePromotionTabId }> = [
  { label: () => t('zigzin::직진아울렛 프로모션'), value: 'zigzin_outlet' },
  { label: () => t('zigzin::물류비 할인'), value: 'zigzin_fee_discount' },
];

export type ZigzinFeeDiscountTabId = 'zigzin' | 'store';

export const ZIGZIN_FEE_DISCOUNT_TABS: Array<{ label: () => string; value: ZigzinFeeDiscountTabId }> = [
  { label: () => t('zigzin::프로모션 대상 직진배송 상품 목록'), value: 'zigzin' },
  { label: () => t('zigzin::프로모션 대상 스토어배송 상품 목록'), value: 'store' },
];

export type FeePromotionSearchType = 'product_name' | 'product_item_id' | 'option_id' | 'product_id';

export type StorePromotionSearchType = Omit<FeePromotionSearchType, 'product_item_id'>;

export interface FeePromotionSearchValue {
  product_name: string;
  product_item_id: string;
  option_id: string;
  product_id: string;
}

export type FeePromotionTableSortType = '';

export const PAGE_PER_SIZE = ['20', '50', '100'];

export type ZigzinFeePromotionDashboardTableDataType =
  | ZigzinFeePromotionVipDashboardTable_ItemFragment
  | ZigzinFeePromotionDashboardTable_ItemFragment;

export type StoreFeePromotionDashboardTableDataType =
  | StoreFeePromotionVipDashboardTable_ItemFragment
  | StoreFeePromotionDashboardTable_ItemFragment;
