import { Config } from '@/config';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';
import { SellerResource } from 'api';

const navigation = 'daily_report';

export function useSendDailyReportPbl(is_partner_plus?: boolean) {
  const { is_agency, is_zigzag } = useAccount();
  const { isAllowed } = useInfo();
  const each_main_domain = Config.shop_main_domain;
  const params = new URLSearchParams(window.location.search);
  const meta_main_domain = params.get('main_domain');
  const main_domain = each_main_domain || meta_main_domain;
  const is_master = each_main_domain && isAllowed([SellerResource.ALL]);
  const role = each_main_domain
    ? is_zigzag
      ? 'zigzag'
      : is_agency
        ? 'agency'
        : is_master
          ? 'master'
          : 'manager'
    : undefined;

  const { pbl } = usePbl();

  const sendPageView = ({ is_alimtalk = false }: { is_alimtalk?: boolean }) => {
    pbl({
      navigation,
      category: 'pageview',
      extra: JSON.stringify({
        is_alimtalk,
        is_partner_plus,
        main_domain,
        role,
      }),
    });
  };
  const sendClick = ({
    object_id,
    object_section,
    extra,
  }: {
    object_id?: string;
    object_section?: string;
    extra?: Record<string, string>;
  }) => {
    pbl({
      navigation,
      category: 'click',
      object_id,
      object_section,
      extra: JSON.stringify({
        is_partner_plus,
        role,
        main_domain,
        ...extra,
      }),
    });
  };
  const sendImpression = ({
    object_id,
    object_section,
    extra,
  }: {
    object_id: string;
    object_section: string;
    extra?: Record<string, string>;
  }) => {
    pbl({
      navigation,
      category: 'impression',
      object_id,
      object_section,
      extra: JSON.stringify({
        is_partner_plus,
        main_domain,
        role,
        ...extra,
      }),
    });
  };

  return {
    sendPageView,
    sendClick,
    sendImpression,
  };
}
