import { IconCircleInfo, Modal, TextButton } from '@croquiscom/pds';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InsightCustomerIntroBody } from '../intro/body/InsighCustomerIntroBody';
import { InsightCompetitorIntroBody } from '../intro/body/InsightCompetitorIntroBody';
import { InsightKeywordIntroBody } from '../intro/body/InsightKeywordIntroBody';
import { InsightSalesIntroBody } from '../intro/body/InsightSalesIntroBody';
import { InsightStarterPlanGuideIntroBody } from '../intro/body/InsightStarterPlanGuideIntroBody';
import { useModalState } from 'rui/pastel/unstable/modal';

interface InsightGuideMenuButtonProps {
  menu_types: 'starter' | 'sales' | 'competitor' | 'keyword' | 'customer';
}

export const InsightGuideMenuButton: FC<InsightGuideMenuButtonProps> = ({ menu_types }) => {
  const { t } = useTranslation('insight');
  const [{ isOpen, onClose, onOpen }] = useModalState();

  const renderModalContent = () => {
    switch (menu_types) {
      case 'customer':
        return <InsightCustomerIntroBody is_revealed={false} is_desktop={false} />;
      case 'competitor':
        return <InsightCompetitorIntroBody is_desktop={false} is_revealed={false} />;
      case 'starter':
        return <InsightStarterPlanGuideIntroBody is_desktop={false} is_revealed={false} />;
      case 'keyword':
        return <InsightKeywordIntroBody is_desktop={false} is_revealed={false} />;
      case 'sales':
        return <InsightSalesIntroBody is_desktop={false} is_revealed={false} />;

      default:
        return null;
    }
  };

  return (
    <>
      <TextButton kind='link' size='large' startIcon={<IconCircleInfo />} onClick={onOpen}>
        {t('메뉴 소개')}
      </TextButton>
      <Modal
        opened={isOpen}
        onCancel={onClose}
        title={<Trans ns='insight'>메뉴소개</Trans>}
        contentProps={{ p: 0 }}
        onConfirm={onClose}
        confirmText={t('확인')}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};
