import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export const AD_GUIDE_OPTIONS = createHelpConstantOptions({
  name: '광고 가이드 URL',
  defaults: {
    '기획전 이용 가이드':
      'https://docs.google.com/presentation/d/1xjz2JDhCTe_iT0GSZqR0RBzHe6g-XAKe/edit?usp=sharing&ouid=115504781333833046808&rtpof=true&sd=true',
    '프로모션 관리 소개서':
      'https://docs.google.com/presentation/d/1sfUPOPESXZb_3mwvA06-MLPX0TPkVJz_pFcubck5QOQ/edit?usp=sharing',
  },
});
