import { useState } from 'react';
import { Config } from '@/config';
import { createHelpConstantOptions, useHelpConstants } from '@/hooks/useHelpConstants';
import { useScrollDebounce } from 'rui/components/main_frame/hooks/useScrollDebounce';

const LNB_TOOLTIP_TEXT = createHelpConstantOptions({
  name: '툴팁',
  defaults: {
    CHAT_BOT: '',
  },
});

export function useGetChatBotTooltip(enabled?: boolean) {
  const TOOLTIP_KEYS = `CHAT_BOT.${Config.shop_main_domain}.Tooltip`;
  const { data: tooltip_data } = useHelpConstants(LNB_TOOLTIP_TEXT);
  const [id, setId] = useState(localStorage.getItem(TOOLTIP_KEYS));
  const is_show = Boolean(enabled) && Boolean(tooltip_data.CHAT_BOT) && id !== tooltip_data.CHAT_BOT;

  const is_show_scroll = useScrollDebounce(is_show);

  return {
    text: tooltip_data.CHAT_BOT,
    is_show: is_show_scroll,
    hideTooltip() {
      if (tooltip_data.CHAT_BOT) {
        setId(tooltip_data.CHAT_BOT);
        localStorage.setItem(TOOLTIP_KEYS, tooltip_data.CHAT_BOT);
      }
    },
  };
}
