import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React from 'react';

const ObsoleteStockPageHeader = () => {
  return (
    <>
      <PageHeader>
        <SectionTitle
          title='직진아울렛 노출 상품을 선택하세요'
          description={
            <>
              &#183;&nbsp; 할인 적용을 원하지 않는 상품은 제외하세요.
              <br />
              &#183;&nbsp; 할인가는 지정 범위 내에서 조정 가능합니다.
              <br />
              &#183;&nbsp; 할인 제외된 상품은 15일 이후 자동 반출 목록으로 이동됩니다.
              <br />
              &#183;&nbsp; 반출 목록은 재고 메뉴에서 확인이 가능하고, 추후 반출일정에 따라 순차적으로 반출이 진행됩니다.
              반출까지는 최대 30일 이상 소요될 수 있습니다.
            </>
          }
          mb={0}
        />
      </PageHeader>
    </>
  );
};

export default ObsoleteStockPageHeader;
