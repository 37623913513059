import { BaseText, Button, HStack, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { CHATBOT_PRESET } from './constants';
import { useHelpConstants } from '@/hooks/useHelpConstants';
interface ChatBotPresetProps {
  onSelect: (value: string) => void;
  is_show?: boolean;
}

export const ChatBotPreset = ({ onSelect, is_show }: ChatBotPresetProps) => {
  const { data } = useHelpConstants(CHATBOT_PRESET);
  const preset = Object.entries(data);
  if (!is_show || preset.length === 0) return null;

  return (
    <VStack alignment='trailing' spacing={12} mt={24}>
      <BaseText kind='Body_13_Medium' color={semantic_colors.content.secondary}>
        자주 묻는 질문
      </BaseText>
      <SC.ListContainer>
        {preset.map(([key, value]: [string, string]) => (
          <Button
            key={key}
            kind='secondary'
            size='small'
            onClick={() => {
              onSelect(value);
            }}
          >
            {key}
          </Button>
        ))}
      </SC.ListContainer>
    </VStack>
  );
};

const SC = {
  ListContainer: styled(HStack)`
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px;
  `,
};
