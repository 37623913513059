import { BaseText, Button, RadioGroup, RadioGroupProps, Textarea, VStack } from '@croquiscom/pds';
import React, { useState } from 'react';

interface FeedbackPresetProps<T> {
  options: RadioGroupProps<T>['items'];
  onDone?: (type: T, text?: string) => void;
}

export const FeedbackPreset = <T,>({ options, onDone }: FeedbackPresetProps<T>) => {
  const [feedback, setFeedback] = useState<T>();
  const [feedback_text, setFeedbackText] = useState<string>();
  return (
    <VStack alignment='leading' spacing={16}>
      <BaseText kind='Heading_18_Bold'>그 이유는 무엇인가요?</BaseText>
      <VStack width='100%' spacing={8} mb={8}>
        <RadioGroup<T>
          spacing={16}
          align='vertical'
          value={feedback}
          onChange={(value) => setFeedback(value)}
          items={options}
        />
        {feedback === 'CUSTOM_INPUT' && (
          <Textarea
            value={feedback_text}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder='내용을 입력해주세요.'
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
        )}
      </VStack>
      <Button
        kind='primary'
        size='medium'
        disabled={!feedback || (feedback === 'CUSTOM_INPUT' && !feedback_text)}
        fill
        onClick={() => feedback && onDone?.(feedback, feedback_text)}
      >
        의견 보내기
      </Button>
    </VStack>
  );
};
