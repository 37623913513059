import { BaseText, VStack, Button, IconUnlock, IconArrowDirectionRight } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSendDailyReportPbl } from '../hooks/useSendDailyReportPbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

interface Props {
  children: React.ReactNode;
  is_blur?: boolean;
  is_loading?: boolean;
  is_partner_plus?: boolean;
  used_free_view_count: number;
  max_free_view_count: number;
  onRequestFreeView?: () => void;
  main_domain?: string;
  postfix_text?: string;
  object_section?: string;
}

const BlurArea: React.FC<Props> = ({
  children,
  is_blur,
  is_loading,
  is_partner_plus,
  used_free_view_count,
  max_free_view_count,
  onRequestFreeView,
  main_domain,
  postfix_text,
  object_section,
}) => {
  const { t } = useTranslation('main_frame');
  const history = useHistory();

  const prefix = useShopPrefix();
  const shop_prefix = main_domain ? `/shop/${main_domain}${prefix}` : prefix;
  const { sendClick } = useSendDailyReportPbl(is_partner_plus);

  if (!is_blur) {
    return <>{children}</>;
  }
  return (
    <Container width='100%' spacing={6}>
      <BlurBox width='100%'>{children}</BlurBox>
      {max_free_view_count > used_free_view_count ? (
        <TextLayer>
          <BaseText kind='Caption_11_SemiBold'>
            <Trans t={t} values={{ max_free_view_count }}>
              한달에 최대 {{ max_free_view_count }}번까지 무료로
              <br />
              {postfix_text || t('내 스토어 구매전환율, 주문단가를 확인할 수 있어요.')}
            </Trans>
          </BaseText>
          <Button
            kind='black'
            size='xsmall'
            loading={is_loading}
            startIcon={<IconUnlock />}
            onClick={() => {
              if (object_section) {
                sendClick({
                  object_id: 'free_open',
                  object_section,
                });
              }
              onRequestFreeView?.();
            }}
          >
            {t('플러스 전용 콘텐츠 무료보기')} {used_free_view_count}/{max_free_view_count}
          </Button>
        </TextLayer>
      ) : (
        <TextLayer>
          <BaseText kind='Caption_11_SemiBold'>
            <Trans t={t}>
              이달의 무료보기를 모두 사용했어요
              <br />
              파트너플러스를 구독하면 제한없이 콘텐츠를 확인할 수 있어요.
            </Trans>
          </BaseText>
          <Button
            kind='black'
            size='xsmall'
            endIcon={<IconArrowDirectionRight />}
            onClick={() => {
              if (object_section) {
                sendClick({
                  object_id: 'about_partnerplus',
                  object_section,
                });
              }
              history.push(`${shop_prefix}${PartnerPlusNavUrl.INTRO}`);
            }}
          >
            {t('파트너플러스 살펴보기')}
          </Button>
        </TextLayer>
      )}
    </Container>
  );
};

export default BlurArea;

const Container = styled(VStack)`
  position: relative;
  align-items: center;
  justify-content: center;
`;

const BlurBox = styled(VStack)`
  filter: blur(5px);
`;
const TextLayer = styled(VStack)`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  row-gap: 8px;
  justify-content: center;
`;
