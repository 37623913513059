import { Badge, HStack, Tooltip } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { drawer_menu_open_atom } from '../atom';
import { isAllowedByShopDepartment, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../utils/menu_list';
import { LNB_TOOLTIP_TYPE, useGetLnbTooltip } from './hooks/useGetLnbTooltip';
import { ItemRow } from './ItemRow';
import { ProgramParticipationStatus } from '@/components/seller_support_program/constants';
import { useGetCustomerExpansionStateOfShop } from '@/components/seller_support_program/hooks/useGetCustomerExpansionStateOfShop';
import { SiteId } from '@/constants/site';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { ShopDepartment } from '@/types/info';
import { CountryCode } from 'api';

export interface ShopInfoCustomerExtensionProps {}

export const ShopInfoCustomerExtension: React.FC<ShopInfoCustomerExtensionProps> = () => {
  const shop_prefix = useShopPrefix();
  const { info } = useInfo();
  const is_allow =
    isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
    isAllowedMenuByCountry(info, [CountryCode.KR]) &&
    isAllowedByShopDepartment(info, [
      ShopDepartment.SOHO_FASHION,
      ShopDepartment.BEAUTY,
      ShopDepartment.BRAND_FASHION,
      ShopDepartment.LIFE,
    ]);
  const { data } = useGetCustomerExpansionStateOfShop(is_allow);
  const is_customer_extension = !!data?.current_state_of_shop;
  const state = data?.current_state_of_shop?.state;
  const pending = state === ProgramParticipationStatus.PARTICIPATION_PENDING;
  const partial = state === ProgramParticipationStatus.PARTICIPATING_ONLY_STANDBY;
  const doing =
    state &&
    [ProgramParticipationStatus.PARTICIPATING, ProgramParticipationStatus.PAUSED].includes(
      state as ProgramParticipationStatus,
    );
  const cancel_request = state === ProgramParticipationStatus.WITHDRAWAL_PENDING;
  const need_agree = !!data?.allotment_state;
  const { text, is_show, hideTooltip } = useGetLnbTooltip(LNB_TOOLTIP_TYPE.CUSTOMER_EXTENSION);
  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);

  if (!is_allow) {
    return null;
  }

  return (
    <Tooltip
      opened={is_show}
      content={<TextBox>{text}</TextBox>}
      openerTriggerEvent='none'
      placement='right'
      kind='primary'
      canClickOutside={false}
      zIndex={90}
      closeButton
      onClose={() => {
        if (is_show) {
          hideTooltip();
        }
      }}
    >
      <div>
        <ItemRow
          title={
            is_customer_extension ? (
              <HStack spacing={4} alignment='center'>
                고객 확장 프로그램
                <Badge
                  {...(() => {
                    if (pending) {
                      return { color: 'orange', children: '참여대기' };
                    }
                    if (cancel_request) {
                      return { color: 'orange', children: '철회대기' };
                    }
                    if (doing || partial) {
                      return { color: 'green', children: '참여중' };
                    }
                    if (need_agree) {
                      return { color: 'red', children: '동의필요' };
                    }
                    return { color: 'blue', children: '참여가능' };
                  })()}
                  size='small'
                  kind='fill'
                />
              </HStack>
            ) : (
              '고객 확장 프로그램 소개'
            )
          }
          show_right
          to={
            is_customer_extension
              ? `${shop_prefix}/seller_support_program/customer_extension`
              : `${shop_prefix}/seller_support_program/customer_extension/intro`
          }
          onClick={() => {
            hideTooltip();
            if (!is_desktop) {
              onDrawerOpen(false);
            }
          }}
        />
      </div>
    </Tooltip>
  );
};

const TextBox = styled.div`
  max-width: 200px;
  word-break: keep-all;
`;
