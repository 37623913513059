import { useCallback } from 'react';
import { useInfo } from './useInfo';
import { PblParams, sendPbl } from '@/utils/pbl';

const DEFAULT_PARTNER_ID = 'kakaostyle';

/**
 * each, meta 관계 없이 공통으로 사용할 수 있는 PBL입니다.
 */
export function usePbl<
  TParams extends PblParams & { [K in keyof TParams]?: K extends keyof PblParams ? PblParams[K] : string } = PblParams,
>() {
  const { info } = useInfo();
  const pbl = useCallback(
    (params: TParams) => {
      if (info != null) {
        const seller_account_id = info.seller_account.id;
        const shop_id = info.id;
        sendPbl({
          partner_id: info.identifier?.includes('kakaostyle.com')
            ? DEFAULT_PARTNER_ID
            : seller_account_id ?? DEFAULT_PARTNER_ID,
          ...params,
          navigation_sub: { shop_id, ...params.navigation_sub },
        });
      } else {
        sendPbl(params);
      }
    },
    [info],
  );
  return { pbl };
}
