import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { NoticeItem } from './NoticeItem';
import { GetImportantSellerNoticeListQuery } from '@/api';
type SellerNotice = GetImportantSellerNoticeListQuery['seller_notice_list']['item_list'][number];

export interface NoticeRollerProps {
  current_notice: SellerNotice;
}

export const NoticeRoller: React.FC<NoticeRollerProps> = ({ current_notice }) => {
  const [prev_notice, setPrevNotice] = useState<SellerNotice>(current_notice);
  const is_rolling = prev_notice !== current_notice;
  const handleTransitionEnd = useCallback(() => {
    setPrevNotice(current_notice);
  }, [current_notice]);
  return (
    <StyledNoticeWrapper>
      <StyledNoticeRoller is_rolling={is_rolling} onTransitionEnd={handleTransitionEnd}>
        {is_rolling && <NoticeItem notice={prev_notice} aria-hidden />}
        <NoticeItem notice={current_notice} />
      </StyledNoticeRoller>
    </StyledNoticeWrapper>
  );
};

const StyledNoticeWrapper = styled.div`
  height: 20px;
  overflow: hidden;
`;

const StyledNoticeRoller = styled.div<{ is_rolling: boolean }>`
  ${({ is_rolling }) =>
    is_rolling &&
    css`
      transition: 1.8s transform linear;
      transform: translateY(-20px);
    `}
`;
