import { Tooltip, TopBar, Typography } from '@croquiscom/pds';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from '@/hooks/useAccount';
import { usePbl } from '@/hooks/usePbl';
import { PartnersBridge } from '@/utils/app_utils';

const PARTNER_LOUNGE_TOOLTIP_VISIBLE = 'partner_lounge_tooltip_visible';

interface Props {
  kind: Typography;
}

const PartnerLoungeLink: FC<Props> = ({ kind }) => {
  const { t } = useTranslation('account');
  const { pbl } = usePbl();
  const { is_logined } = useAccount();
  // const [show_tooltip, setShowTooltip] = useState(localStorage.getItem(PARTNER_LOUNGE_TOOLTIP_VISIBLE) !== 'N');
  const [show_tooltip, setShowTooltip] = useState(false);

  const hideTooltip = () => {
    localStorage.setItem(PARTNER_LOUNGE_TOOLTIP_VISIBLE, 'N');
    setShowTooltip(false);
  };

  return (
    <Tooltip
      openerTriggerEvent='none'
      canClickOutside={false}
      kind='brand'
      placement='bottom'
      opened={show_tooltip}
      content={t('새로 오픈했어요!')}
      closeButton={true}
      zIndex={991}
      onClose={hideTooltip}
    >
      <TopBar.ActionButton
        kind={kind}
        onClick={() => {
          PartnersBridge.callSystemBrowserDeepLink(is_logined ? 'https://bit.ly/3Az9sTK' : 'https://bit.ly/3c3h4Ej');
          hideTooltip();
          pbl({
            navigation: 'gnb_n_top_bar',
            category: 'click',
            object_id: 'partner_lounge',
            object_section: 'top_bar',
          });
        }}
      >
        {t('파트너라운지')}
      </TopBar.ActionButton>
    </Tooltip>
  );
};

export default PartnerLoungeLink;
