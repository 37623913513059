import { FloatingButton, IconBeta, semantic_colors, HStack, FloatingButtonProps } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChatBotLogo } from './ChatBotLogo';
import { ChatBotTooltip } from './ChatBotTooltip';
import { useGetChatBotTooltip } from './hooks/useGetChatBotTooltip';
import { useIsWindowScroll } from './hooks/useIsWindowScroll';
import { ChatBotText, ChatBotTextShadow } from './styled';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';

export const ChatBotButton = ({ onClick, ...props }: FloatingButtonProps) => {
  const { is_scrolled } = useIsWindowScroll();
  const history = useHistory();
  const is_not_allowed = history.location.pathname.includes('/ad/zigzag');
  const { is_show: is_show_tooltip, text, hideTooltip } = useGetChatBotTooltip(!is_not_allowed);

  return (
    <SC.Container>
      <FloatingButton
        onClick={(event) => {
          hideTooltip();
          onClick?.(event);
        }}
        {...props}
      >
        {is_scrolled ? (
          <HStack height={24}>
            <img width={24} src={ChatBotIcon} alt='' />
          </HStack>
        ) : (
          <HStack alignment='center' spacing={8}>
            <ChatBotTextShadow>
              <img width={24} src={ChatBotIcon} alt='' />
              <ChatBotText width={118} spacing={4}>
                <HStack pl={8}>
                  <ChatBotLogo />
                </HStack>
                <SC.BetaIcon size={21} color={semantic_colors.content.on_color} />
              </ChatBotText>
            </ChatBotTextShadow>
          </HStack>
        )}
      </FloatingButton>
      <ChatBotTooltip is_show={is_show_tooltip} text={text} hideTooltip={hideTooltip} />
    </SC.Container>
  );
};

const SC = {
  BetaIcon: styled(IconBeta)`
    opacity: 0.7;
  `,
  Container: styled.div`
    position: relative;
  `,
};
