import {
  DataTable,
  FormField,
  Input,
  Modal,
  Table,
  TableCell,
  TableCol,
  TableContainer,
  TableRow,
  VStack,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { gql_request } from '@/api/request';
import {
  ACCESS_CONTROL_RESOURCE_ROW_LIST,
  ZPAY_ACCESS_CONTROL_RESOURCE_ROW_LIST,
} from '@/components/info/manage_managers/constants';

interface AccountInfoEditorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function resolveString(value: string | (() => string)): string {
  if (typeof value === 'function') {
    return value();
  }
  return value;
}

function convertResourceToString(resource: string) {
  const entry =
    ACCESS_CONTROL_RESOURCE_ROW_LIST.find((item) => item.res === resource) ??
    ZPAY_ACCESS_CONTROL_RESOURCE_ROW_LIST.find((item) => item.res === resource);
  return entry ? `${resolveString(entry.menu_name)}: ${resolveString(entry.sub_name)}` : resource;
}

const AccountInfoModal: React.FC<AccountInfoEditorModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('account');

  const { data: seller_account } = useQuery({
    queryFn: async () => {
      try {
        const query = `query GetSellerAccountInfo { seller_account {
            identifier
            login_history_list { date_created client_ip user_agent }
            permission_history_list { date_created shop { name } requester_identifier old_allowed_resource_list new_allowed_resource_list client_ip user_agent }
          } }`;
        return await gql_request<
          {
            seller_account:
              | {
                  identifier: string;
                  login_history_list: Array<{
                    date_created: number;
                    client_ip: string;
                    user_agent: string;
                  }>;
                  permission_history_list: Array<{
                    date_created: number;
                    shop: {
                      name: string;
                    } | null;
                    requester_identifier: string;
                    old_allowed_resource_list: string[];
                    new_allowed_resource_list: string[];
                    client_ip: string;
                    user_agent: string;
                  }>;
                }
              | null
              | undefined;
          },
          null
        >('GetSellerAccountInfo', query, undefined, {
          no_shop_domain: true,
          no_redirect_to_login: true,
          no_alert: true,
        });
      } catch (error) {
        return null;
      }
    },
    select: (data) => data?.data.seller_account,
    staleTime: 0,
    cacheTime: 0,
  });

  return (
    <Modal
      width={960}
      title={t('계정 정보')}
      opened={isOpen}
      contentProps={{ scrollable: true }}
      cancelText={t('닫기')}
      onCancel={onClose}
    >
      <VStack spacing={16}>
        <FormField label={t('아이디')}>
          <Input width={165} disabled value={seller_account?.identifier} />
        </FormField>
        {seller_account && seller_account.login_history_list.length > 0 && (
          <DataTable
            columns={[
              { id: 'date_created', text: t('접속 일시') },
              { id: 'client_ip', text: 'IP' },
              { id: 'user_agent', text: 'User Agent' },
            ]}
            rows={seller_account.login_history_list.map(({ date_created, client_ip, user_agent }) => ({
              date_created: dayjs(date_created).format('YYYY.MM.DD HH:mm:ss'),
              client_ip,
              user_agent,
            }))}
          />
        )}
        {seller_account && seller_account.permission_history_list.length > 0 && (
          <TableContainer>
            <Table>
              <thead>
                <TableRow>
                  <TableCol>{t('변경 일시')}</TableCol>
                  <TableCol>{t('요청자')}</TableCol>
                  <TableCol>{t('이전 권한')}</TableCol>
                </TableRow>
                <StyledTableRow>
                  <TableCol>IP</TableCol>
                  <TableCol>User Agent</TableCol>
                  <TableCol>{t('새 권한')}</TableCol>
                </StyledTableRow>
              </thead>
              <tbody>
                {seller_account.permission_history_list.map((permission_history, i) => (
                  <>
                    <TableRow key={`history-${i}-row-1`}>
                      <TableCell>{dayjs(permission_history.date_created).format('YYYY.MM.DD HH:mm:ss')}</TableCell>
                      <TableCell>{permission_history.requester_identifier}</TableCell>
                      <TableCell>
                        {permission_history.old_allowed_resource_list.map((r, i) => (
                          <div key={i}>∘ {convertResourceToString(r)}</div>
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow key={`history-${i}-row-2`}>
                      <TableCell>{permission_history.client_ip}</TableCell>
                      <TableCell>{permission_history.user_agent}</TableCell>
                      <TableCell>
                        {permission_history.new_allowed_resource_list.map((r, i) => (
                          <div key={i}>∘ {convertResourceToString(r)}</div>
                        ))}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>
    </Modal>
  );
};

export default AccountInfoModal;

const StyledTableRow = styled(TableRow)`
  th {
    border-bottom: 2px solid black;
    &:last-of-type {
      border-right: none;
    }
  }
`;
