import { BaseText, HStack, IconCheckBold, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InsightIntroHeader from '../../common/InsightIntroHeader';
import img_mobile_01 from '@/assets/images/insight/intro/keywords/img_mobile_01.png';
import img_mobile_02 from '@/assets/images/insight/intro/keywords/img_mobile_02.png';
import img_mobile_03 from '@/assets/images/insight/intro/keywords/img_mobile_03.png';
import img_pc_01 from '@/assets/images/insight/intro/keywords/img_pc_01.png';
import img_pc_02 from '@/assets/images/insight/intro/keywords/img_pc_02.png';
import img_pc_03 from '@/assets/images/insight/intro/keywords/img_pc_03.png';

interface InsightKeywordIntroBodyProps {
  button_ref?: (instance: Element | null) => void;
  is_desktop: boolean;
  is_revealed: boolean;
}

export const InsightKeywordIntroBody: FC<InsightKeywordIntroBodyProps> = ({ button_ref, is_revealed, is_desktop }) => {
  const { t } = useTranslation('insight');
  return (
    <>
      <InsightIntroHeader
        badges={[t('급상승 트랜드'), t('고객취향'), t('시즌 미리분석')]}
        title={t('트랜드를 놓치지마세요')}
        navigation='insight_keyword_intro'
        button_ref={button_ref}
        is_button_visible={is_revealed}
      />
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('최신 트랜드 기반으로 상품 설정')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 13px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_01 : img_mobile_01} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  떠오르고 지고, 시기마다 달라지는 트랜드. 지그재그 유저들의 관심사를 알려드려요.
                  <br />
                  구매와 직결되는 패션 특화 데이터로 빠르고 상세하게 분석해 보세요.
                </Trans>
              </BaseText>
            </HStack>
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t('키워드 별 선호 상품 분석과 경쟁강도 분석으로 상품명과 검색 키워드 설정에도 활용해보세요.')}
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.base}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('새 시즌 신상 타이밍 예측')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_02 : img_mobile_02} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.surface}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  여름 상품은 언제쯤 준비해야 할까요? 그때 주목해야 할 상세 키워드는 무엇일까요?
                  <br />
                  최근 1년간 주 단위로 변화하는 기온과 트랜드 키워드를 살펴보고 남들보다 앞서서 시즌을 준비하세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('고객 관심사 기반으로 마케팅 준비')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_03 : img_mobile_03} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  고객들이 어떤 키워드로 내 스토어를 찾는지 확인해보세요.
                  <br />
                  내 스토어 인지도가 많이 높아졌는지, 스토어 이름 대신 어떤 키워드를 통해 방문했는지
                  <br />
                  어떤 상품의 반응이 뜨거운지 확인하고 광고나 마케팅 전략에 반영해보세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
    </>
  );
};

const Section = styled(VStack)<{ background?: string; border_color?: string }>`
  width: 100%;
  align-items: center;
  ${({ background }) => background && `background: ${background}`};
  ${({ border_color }) =>
    border_color &&
    `
    border-top: 1px solid ${border_color};
    border-bottom: 1px solid ${border_color};
    `}
`;

export const Image = styled.img<{ is_desktop: boolean }>`
  width: 100%;
  max-width: ${({ is_desktop }) => (is_desktop ? '900px' : '600px')};
`;

const InsightIntroSectionCard = styled(VStack)<{ background?: string; is_desktop?: boolean }>`
  padding: 24px;
  margin-top: 30px;
  ${({ background }) => background && `background: ${background}`};
  ${({ is_desktop }) =>
    is_desktop &&
    `padding: 40px;
    margin-top: 40px;
    width: 910px;
  `};
  border-radius: 20px;
`;

const InsightIntroSectionCardIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colors.purple500};
  text-align: start;
  margin-top: 4px;
`;
