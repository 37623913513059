import styled from '@emotion/styled';

const StyledAspectRatioBodyWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  > * {
    flex: auto;
  }
`;

export default StyledAspectRatioBodyWrapper;
