import { ImageZexpressSimpleKor } from '@croquiscom/pds';
import { t } from 'i18next';
import * as React from 'react';
import {
  checkRes,
  isAllowedByShopDepartment,
  isAllowedMenuByCountry,
  isAllowedMenuBySiteId,
  isNotAllowedByShopDepartment,
} from '../../utils/menu_list';
import { SubMenuEntry } from '../types';
import { CreateSubMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment } from '@/types/info';
import { CountryCode } from 'api';
import { SHOP_KIDS_CATEGORY_ID } from 'rui/constants';

export function createSubMenuTree({ info, has_new_notice }: CreateSubMenuTreeOptions): SubMenuEntry[] {
  const is_zpay = info.type === 'ZPAY';
  const is_not_fulfillment = is_zpay && info.zonly_status !== 'NORMAL';
  const is_parent = info.is_parent ?? false;
  return [
    {
      id: 'notice',
      label: t('main_frame::공지사항'),
      href: '/notice',
      is_allowed:
        checkRes([], info.allowed_resource_list) &&
        isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.도매, SiteId.마이스토어]) &&
        isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
      is_new: has_new_notice,
      is_support_app: true,
    },
    {
      id: 'zigzin_intro',
      label: t('main_frame::직진배송 소개'),
      href: '/zigzin/intro',
      is_allowed: info.trait.category !== SHOP_KIDS_CATEGORY_ID && is_not_fulfillment && !is_parent,
      badge: <ImageZexpressSimpleKor size={33} />,
    },
    {
      id: 'seller_support_program',
      label: t('main_frame::중소형 성장 지원 프로그램'),
      href: '/seller_support_program',
      is_allowed:
        isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
        isAllowedMenuByCountry(info, [CountryCode.KR]) &&
        isAllowedByShopDepartment(info, [ShopDepartment.SOHO_FASHION]),
    },
  ];
}
