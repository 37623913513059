import { RoasDay } from './types';
import {
  AdCampaignQuerySort,
  AdCampaignForSearchStatus,
  AdCampaignExposureStatus,
  AdCampaignExposureMethod,
  AdCampaignQueryOrderType,
  PerformancePresetItem,
} from '@/api';
import { ExposureStatus } from '@/components/advertisement/ad_display/widgets/CampaignExposureStatusSelect';
import { Config } from '@/config';

export const addRangeParam = ({ value, direction, isAll }) => {
  if (!isAll) {
    return {
      inequality_sign: direction,
      value: parseFloat(value),
    };
  }
};

export const getImageResolution = (img: string): Promise<{ w: number; h: number }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      const w = image.width;
      const h = image.height;
      resolve({ w, h });
    };
    image.onerror = () => {
      reject(new Error('이미지 불러오기가 실패하였습니다. 잠시 후 다시 시도해주세요.'));
    };
  });
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (typeof alpha === 'number') {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
  });
  return array;
};

export const adCampaignFilterOptionsToReqParams = ({
  filters: {
    campaignStatus,
    exposureStatus,
    exposureMethod,
    viewCount,
    clickCount,
    clickRate,
    adsCost,
    price,
    searchText,
  },
  sortBy,
  sortDirection,
  dateRange,
  page,
  size,
  inventory,
}) => {
  const sort =
    sortDirection === 'decrement' ? AdCampaignQuerySort.AD_QUERY_ORDER_DESC : AdCampaignQuerySort.AD_QUERY_ORDER_ASC;

  const order = (() => {
    switch (sortBy) {
      case 'status':
      case 'exposureStatus':
      case 'exposureType':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_DEFAULT;
      case 'exposureCount':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_VIEW;
      case 'clickCount':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_CLICK;
      case 'clickRate':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_RATE;
      case 'adsAmount':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_AD_COST;
      case 'cpm':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_AD_COST_PER_VIEW;
      case 'cpc':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_AD_COST_PER_CLICK;
      case 'goodsQuality':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_GOODS_QUALITY_INDEX_GROUP;
      case 'adCreativeQuality':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_AD_CREATIVE_QUALITY_INDEX_GROUP;
      case 'addMyGoods':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_MY_GOODS_CONVERSION;
      case 'bookmark':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_BOOKMARK_CONVERSION;
      case 'orderConversion':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_ORDER_CONVERSION;
      case 'orderConversionAmount':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_ORDER_CONVERSION_AMOUNT;
      case 'roas':
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_ROAS;
      default:
        return AdCampaignQueryOrderType.AD_CAMPAIGN_QUERY_ORDER_TYPE_DEFAULT;
    }
  })();

  const ad_campaign_status = (() => {
    switch (campaignStatus) {
      case 'EXPOSED':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_EXPOSED;
      case 'TO_BE_EXPOSED':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_TO_BE_EXPOSED;
      case 'PAUSE':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_PAUSE;
      case 'EXPOSURE_BLOCK':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_EXPOSURE_BLOCK;
      case 'NON_EXPOSURE':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_NON_EXPOSURE;
      case 'INSPECTION_REJECTED':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_INSPECTION_REJECTED;
      case 'INSPECTION_WAITING':
        return AdCampaignForSearchStatus.AD_CAMPAIGN_FOR_SEARCH_STATUS_INSPECTION_WAITING;
      default:
        return undefined;
    }
  })();

  const ad_exposure_status = (() => {
    switch (exposureStatus) {
      case ExposureStatus.ON:
        return AdCampaignExposureStatus.AD_CAMPAIGN_EXPOSURE_STATUS_NORMAL;
      case ExposureStatus.OFF:
        return AdCampaignExposureStatus.AD_CAMPAIGN_EXPOSURE_STATUS_BLOCK;
      default:
        return undefined;
    }
  })();

  const exposure_method = (() => {
    switch (exposureMethod) {
      case 'NORMAL':
        return AdCampaignExposureMethod.AD_CAMPAIGN_EXPOSURE_METHOD_NORMAL;
      case 'BOOST':
        return AdCampaignExposureMethod.AD_CAMPAIGN_EXPOSURE_METHOD_BOOST;
      default:
        return undefined;
    }
  })();

  const reqParams = {
    ad_campaign_status,
    ad_exposure_status,
    exposure_method,
    view_count: addRangeParam(viewCount),
    click_count: addRangeParam(clickCount),
    click_rate: addRangeParam(clickRate),
    ad_billing_amount: addRangeParam(adsCost),
    product_price: addRangeParam(price),
    search_query: searchText,
    from: dateRange[0],
    to: dateRange[1],
    page,
    size,
    order,
    sort,
    inventory,
  };

  return reqParams;
};

export const roasDayToNumber = (roasDay: RoasDay) => {
  switch (roasDay) {
    case PerformancePresetItem.ROAS_D3:
      return 3;
    case PerformancePresetItem.ROAS_D7:
      return 7;
    case PerformancePresetItem.ROAS_D14:
    default:
      return 14;
  }
};

export const getUrlByEnvironment = ({
  production,
  alpha,
  beta,
  dev,
}: {
  production: string;
  alpha: string;
  beta: string;
  dev: string;
}) => {
  if (window.location.hostname === 'localhost' || Config.alpha) {
    return alpha;
  } else if (Config.beta) {
    return beta;
  } else if (Config.dev) {
    return dev;
  }
  return production;
};

export const getDev = () => {
  const [dev] = window.location.hostname.split('.dev.');
  return dev.replace('partners', 'zigzag-embed-web') + '.dev';
};
