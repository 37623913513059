import { BaseResponsiveType, ReponsiveType } from './type';

export const IsResponsiveType = <T>(value: BaseResponsiveType<T> | T): value is BaseResponsiveType<T> => {
  return (
    (value as BaseResponsiveType<T>)?.desktop !== undefined || (value as BaseResponsiveType<T>)?.mobile !== undefined
  );
};

export const getReponsiveValue = <T>(value: ReponsiveType<T>, is_desktop: boolean): T | undefined => {
  if (IsResponsiveType(value)) {
    return is_desktop ? value?.desktop : value?.mobile;
  }
  return value;
};
