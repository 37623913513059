import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { AD_ROUTES } from './ad';
import { BENEFIT_ROUTES } from './benefit';
import { CATALOG_ROUTES } from './catalog';
import { CHANNEL_EXTENSION_ROUTES } from './channel_extension';
import { INFO_ROUTES } from './info';
import { INSIGHT_ROUTES } from './insight';
import { NOTICE_ROUTES } from './notice';
import { ORDER_ROUTES } from './order';
import { ORDER_LINKED_MANAGEMENT_ROUTES } from './order_linked_management';
import { PARTNER_PLUS_ROUTES } from './partner_plus';
import { POLICY_ROUTES } from './policy';
import { PROMOTION_ROUTES } from './promotion';
import { REVIEW_ROUTES } from './review';
import { SELLER_SUPPORT_PROGRAM_ROUTES } from './seller_support_program';
import { SETTLEMENT_ROUTES } from './settlement';
import { SHOP_CONTRACT_ROUTES } from './shop_contract';
import { SHOP_SCORE_ROUTES } from './shop_score';
import { STORE_HOME_ROUTES } from './store_home';
import { VIDEO_ROUTES } from './video';
import { ZIGZIN_ROUTES } from './zigzin';
import { SellerResource } from '@/api';

const DailyReportPage = lazy(() => import('@/pages/daily_report/DailyReportPage'));
const DashboardMainPage = lazy(() => import('@/pages/dashboard/main'));
const OrderStatsPage = lazy(() => import('@/pages/dashboard/order_stats'));
const ProductStatsPage = lazy(() => import('@/pages/dashboard/product_stats'));
const ExtraShopInfoPage = lazy(() => import('@/pages/extra_shop_info/ExtraShopInfoPage'));
const SmartBillPage = lazy(() => import('@/pages/smart_bill/SmartBillPage'));
const RegisterNationalIdPage = lazy(() => import('@/pages/register_national_id/RegisterNationalIdPage'));
const HomePage = lazy(() => import('@/pages/home'));
const SellerSupportProgramMainPage = lazy(() => import('@/pages/seller_support_program/MainPage'));
const ShippingPenaltyPage = lazy(() => import('@/pages/shipping_penalty/ShippingPenaltyPage'));
const AlertBoxPage = lazy(() => import('@/pages/alert_box/AlertBoxPage'));
const ChatBotPage = lazy(() => import('@/pages/chat_bot/ChatBotPage'));

export const EACH_ROUTES: RoutesType[] = [
  ...AD_ROUTES,
  ...CATALOG_ROUTES,
  ...CHANNEL_EXTENSION_ROUTES,
  ...BENEFIT_ROUTES,
  ...INFO_ROUTES,
  ...INSIGHT_ROUTES,
  ...PARTNER_PLUS_ROUTES,
  ...NOTICE_ROUTES,
  ...ORDER_ROUTES,
  ...POLICY_ROUTES,
  ...PROMOTION_ROUTES,
  ...REVIEW_ROUTES,
  ...SETTLEMENT_ROUTES,
  ...STORE_HOME_ROUTES,
  ...ZIGZIN_ROUTES,
  ...VIDEO_ROUTES,
  ...ORDER_LINKED_MANAGEMENT_ROUTES,
  ...SHOP_CONTRACT_ROUTES,
  ...SELLER_SUPPORT_PROGRAM_ROUTES,
  ...SHOP_SCORE_ROUTES,
  {
    current_menu: 'order_stats',
    path: '/order_stats',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='주문 통계 내역'
            description='지그재그 앱을 통해 주문한 내역을 확인할 수 있습니다.'
            mb={0}
          />
        </PageHeader>
        <OrderStatsPage />
      </>
    ),
  },
  {
    current_menu: 'product_stats',
    path: '/product_stats',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='상품 성과 상세' mb={0} />
        </PageHeader>
        <ProductStatsPage />
      </>
    ),
  },
  {
    current_menu: 'stats',
    path: '/stats',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <DashboardMainPage />,
    res: [SellerResource.STATISTICS],
  },
  {
    path: '/shipping_penalty',
    params: '',
    current_menu: '',
    component: <ShippingPenaltyPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/extra_shop_info',
    params: '',
    is_support_mobile: true,
    component: <ExtraShopInfoPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/smart_bill',
    params: '',
    is_support_mobile: true,
    component: <SmartBillPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/register_national_id',
    params: '',
    is_support_mobile: true,
    component: <RegisterNationalIdPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/home',
    params: '',
    component: <HomePage />,
  },
  {
    current_menu: 'seller_support_program',
    path: '/seller_support_program',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='중소형 성장 지원 프로그램'
            description='단계별 파트너 맞춤 성장 지원 프로그램을 확인해보세요.'
            mb={0}
          />
        </PageHeader>
        <SellerSupportProgramMainPage />
      </>
    ),
  },
  {
    current_menu: 'alert_box',
    path: '/alert_box',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <AlertBoxPage />,
  },
  {
    current_menu: 'chatbot',
    path: '/chatbot',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <ChatBotPage />,
  },
  {
    current_menu: 'daily_report',
    path: '/partner-plus/report',
    is_support_mobile: true,
    is_support_app: true,
    params: '',
    component: <DailyReportPage />,
  },
];
