import { t } from 'i18next';
import { CountryCode, SellerResource } from '@/api';
import { AccessControlEntry } from '@/components/info/access_control/types';
import { SiteId } from '@/constants/site';

export const DEFAULT_MAX_MANAGER_COUNT = 20;

export interface InfoSiteCountry {
  country_code: CountryCode;
  site_id: SiteId;
}

export interface AccessControlEntryWithSiteIdList extends AccessControlEntry {
  site_id_list: SiteId[];
  site_country_list?: InfoSiteCountry[];
  is_insight?: boolean;
}

export const ACCESS_CONTROL_RESOURCE_ROW_LIST: AccessControlEntryWithSiteIdList[] = [
  {
    menu_name: () => t('info/access_control::대시보드'),
    sub_name: () => t('info/access_control::대시보드 및 주문/방문/상품 통계 조회'),
    res: SellerResource.STATISTICS,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::상품관리'),
    sub_name: () => t('info/access_control::상품목록'),
    res: SellerResource.GOODS_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::상품관리'),
    sub_name: () => t('info/access_control::상품등록/ 상품정보입력/ 진열여부/ 신상품갱신'),
    res: SellerResource.GOODS_MANAGEMENT,
    link: SellerResource.GOODS_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::주문정보'),
    sub_name: () => t('info/access_control::주문목록'),
    res: SellerResource.META_ORDER_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::광고 관리'),
    sub_name: () =>
      t(
        'info/access_control::[지그재그] 파워업Ai광고/[지그재그] 어텐션 광고/[패션바이카카오] 핫 광고/광고비 관리/광고 알림 관리/공유 리워드 부스트 관리',
      ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::광고 관리'),
    sub_name: () => t('info/access_control::코인 충전 및 환불/세금 계산서'),
    res: SellerResource.AD_DISPLAY_BUDGET_MANAGEMENT,
    link: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::스토어 정보'),
    sub_name: () => t('info/access_control::기본/ 담당자/ 대행사/ 라이센스/ 연동'),
    res: SellerResource.SHOP_SETTINGS,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
];

export const ZPAY_ACCESS_CONTROL_RESOURCE_ROW_LIST: AccessControlEntryWithSiteIdList[] = [
  {
    menu_name: () => t('info/access_control::대시보드'),
    sub_name: () => t('info/access_control::대시보드 및 주문/방문/상품 통계 조회'),
    res: SellerResource.STATISTICS,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::상품관리'),
    sub_name: () => t('info/access_control::상품목록'),
    res: SellerResource.GOODS_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::상품관리'),
    sub_name: () => t('info/access_control::상품등록/ 상품정보입력/ 진열여부/ 신상품갱신'),
    res: SellerResource.GOODS_MANAGEMENT,
    link: SellerResource.GOODS_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::주문관리'),
    sub_name: () => t('info/access_control::주문검색'),
    res: SellerResource.ZPAY_ORDER_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::주문관리'),
    sub_name: () => t('info/access_control::상품준비관리/ 배송현황관리/ 구매확정관리/ 취소관리/ 반품관리'),
    res: SellerResource.ZPAY_ORDER_MANAGEMENT,
    link: SellerResource.ZPAY_ORDER_SEARCH,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
  {
    menu_name: () => t('info/access_control::연동 주문 관리'),
    sub_name: () => t('info/access_control::전체 주문 검색 / 배송 관리 / 취소 관리 / 반품 관리 / 교환 관리'),
    res: SellerResource.OMS_ORDER_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
    site_country_list: [{ site_id: SiteId.카카오톡_쇼핑하기, country_code: CountryCode.KR }],
  },
  {
    menu_name: () => t('info/access_control::연동 주문 관리'),
    sub_name: () => t('info/access_control::문의 관리'),
    res: SellerResource.OMS_QNA_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
    site_country_list: [{ site_id: SiteId.카카오톡_쇼핑하기, country_code: CountryCode.KR }],
  },
  {
    menu_name: () => t('info/access_control::연동 주문 관리'),
    sub_name: () => t('info/access_control::정산 관리/세금계산서/부가세 참고자료'),
    res: SellerResource.OMS_SETTLEMENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
    site_country_list: [{ site_id: SiteId.카카오톡_쇼핑하기, country_code: CountryCode.KR }],
  },
  {
    menu_name: () => t('info/access_control::직진배송'),
    sub_name: () => t('info/access_control::직진배송 주문목록 조회'),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::직진배송'),
    sub_name: () => t('info/access_control::직진배송 등록/취소/수정'),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
    link: SellerResource.ZONLY_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::직진배송'),
    sub_name: () => t('info/access_control::직진배송 인사이트'),
    res: SellerResource.ZONLY_INSIGHT,
    link: SellerResource.ZONLY_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::직진배송'),
    sub_name: () => t('info/access_control::이용료 내역'),
    res: SellerResource.ZONLY_FEE_HISTORY,
    link: SellerResource.ZONLY_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::직진배송'),
    sub_name: () => t('info/access_control::스토어 부담 금액 정산 상세'),
    res: SellerResource.ZONLY_COUPON_SETTLEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::광고 관리'),
    sub_name: () =>
      t(
        'info/access_control::[지그재그] 파워업Ai광고/[지그재그] 어텐션 광고/[패션바이카카오] 핫 광고/광고비 관리/광고 알림 관리/공유 리워드 부스트 관리',
      ),
    res: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::광고 관리'),
    sub_name: () => t('info/access_control::코인 충전 및 환불/세금 계산서'),
    res: SellerResource.AD_DISPLAY_BUDGET_MANAGEMENT,
    link: SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::혜택 관리'),
    sub_name: () => t('info/access_control::쿠폰 목록/리포트 조회'),
    res: SellerResource.COUPON_MANAGEMENT_READ,
    link: SellerResource.MESSAGE_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::혜택 관리'),
    sub_name: () => t('info/access_control::쿠폰 발행/수정/복사/발급 중지'),
    res: SellerResource.COUPON_MANAGEMENT_WRITE,
    link: SellerResource.COUPON_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::혜택 관리'),
    sub_name: () => t('info/access_control::포인트 목록 조회'),
    res: SellerResource.POINT_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::혜택 관리'),
    sub_name: () => t('info/access_control::포인트 지급/수정/복사/발급 중지'),
    res: SellerResource.POINT_MANAGEMENT_WRITE,
    link: SellerResource.POINT_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::프로모션 관리'),
    sub_name: () => t('info/access_control::스토어픽 관리'),
    res: SellerResource.PROMOTION_EXHIBITION_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::프로모션 관리'),
    sub_name: () => t('info/access_control::스토어픽 참여신청'),
    res: SellerResource.PROMOTION_EXHIBITION_APPLY,
    link: SellerResource.PROMOTION_EXHIBITION_MANAGEMENT,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::리뷰 이벤트 관리'),
    sub_name: () => t('info/access_control::리뷰 이벤트 생성'),
    res: SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::리뷰 이벤트 관리'),
    sub_name: () => t('info/access_control::리뷰 이벤트 당첨자 선정'),
    res: SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::문의/리뷰 관리'),
    sub_name: () => t('info/access_control::주문문의'),
    res: SellerResource.ZPAY_ORDER_INQUIRY_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::문의/리뷰 관리'),
    sub_name: () => t('info/access_control::상품문의'),
    res: SellerResource.PRODUCT_QNA_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::문의/리뷰 관리'),
    sub_name: () => t('info/access_control::고객센터 문의'),
    res: SellerResource.CUSTOMER_INQUIRY_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::문의/리뷰 관리'),
    sub_name: () => t('info/access_control::리뷰 조회'),
    res: SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::문의/리뷰 관리'),
    sub_name: () => t('info/access_control::리뷰 답글 작성/수정'),
    res: SellerResource.PRODUCT_REVIEW_MANAGEMENT_WRITE,
    link: SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::정산관리'),
    sub_name: () => t('info/access_control::정산내역/세금계산서'),
    res: SellerResource.ZPAY_SETTLEMENT_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::파트너플러스 인사이트'),
    sub_name: () => t('info/access_control::판매 인사이트'),
    res: SellerResource.SALES_INSIGHT_READ,
    site_id_list: [SiteId.지그재그],
    is_insight: true,
  },
  {
    menu_name: () => t('info/access_control::파트너플러스 인사이트'),
    sub_name: () => t('info/access_control::키워드 인사이트'),
    res: SellerResource.KEYWORD_INSIGHT_READ,
    site_id_list: [SiteId.지그재그],
    is_insight: true,
  },
  {
    menu_name: () => t('info/access_control::파트너플러스 인사이트'),
    sub_name: () => t('info/access_control::고객 인사이트'),
    res: SellerResource.CUSTOMER_INSIGHT_READ,
    site_id_list: [SiteId.지그재그],
    is_insight: true,
  },
  {
    menu_name: () => t('info/access_control::파트너플러스 인사이트'),
    sub_name: () => t('info/access_control::경쟁사 인사이트'),
    res: SellerResource.COMPETITOR_INSIGHT_READ,
    site_id_list: [SiteId.지그재그],
    is_insight: true,
  },
  {
    menu_name: () => t('info/access_control::스토어 전시관리'),
    sub_name: () => t('info/access_control::스토어 전시 등록/수정'),
    res: SellerResource.STORE_DISPLAY_MANAGEMENT_WRITE,
    site_id_list: [SiteId.지그재그],
  },
  {
    menu_name: () => t('info/access_control::동영상 등록/관리'),
    sub_name: () => t('info/access_control::등록한 동영상 목록 확인 및 신규 등록'),
    res: SellerResource.VIDEO_MANAGEMENT,
    site_id_list: [SiteId.지그재그, SiteId.포스티],
  },
  {
    menu_name: () => t('info/access_control::스토어 정보'),
    sub_name: () => t('info/access_control::기본/ 담당자/ 대행사/ 배송/ 정산/ 주문,배송API/ 라이센스/ 연동'),
    res: SellerResource.SHOP_SETTINGS,
    site_id_list: [SiteId.지그재그, SiteId.포스티, SiteId.도매],
  },
];
