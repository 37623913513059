import { AxiosError } from 'axios';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { api_getInsightStaterRevenue, GetInsightStaterRevenueQuery } from '@/api';

export const useGetInsightStaterRevenue = (
  options?: Omit<
    UseQueryOptions<unknown, AxiosError, GetInsightStaterRevenueQuery['shop_insight_growth_checklist'], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    ['useGetInsightStaterRevenue'],
    async () => {
      const { data } = await api_getInsightStaterRevenue(undefined, { no_alert: true });
      return data.shop_insight_growth_checklist;
    },
    { staleTime: 30000, cacheTime: 30000, ...options },
  );
};
