import { t } from 'i18next';
import { CountryCode } from '@/api';

export enum SiteId {
  지그재그 = '1',
  포스티 = '2',
  패션_바이_카카오 = '3',
  도매 = '4',
  마이스토어 = '5',
  카카오톡_쇼핑하기 = '7',
}

export const SiteName = {
  [SiteId.지그재그]: '지그재그',
  [SiteId.포스티]: '포스티',
  [SiteId.패션_바이_카카오]: '패션 바이 카카오',
  [SiteId.도매]: '도매',
  [SiteId.마이스토어]: '마이스토어',
  [SiteId.카카오톡_쇼핑하기]: '카카오톡 쇼핑하기',
};

export const SITE_NAME_I18N: Record<SiteId | '5', () => string> = {
  [SiteId.지그재그]: () => t('common::지그재그'),
  [SiteId.포스티]: () => t('common::포스티'),
  [SiteId.패션_바이_카카오]: () => t('common::패션 바이 카카오'),
  [SiteId.도매]: () => t('common::도매'),
  '5': () => t('common::마이스토어'),
  [SiteId.카카오톡_쇼핑하기]: () => t('common::카카오톡 쇼핑하기'),
};

export const CountryName = {
  [CountryCode.KR]: '한국',
  [CountryCode.JP]: '일본',
  [CountryCode.US]: '미국',
  [CountryCode.CA]: '캐나다',
};

export const COUNTRY_NAME_I18N: Record<CountryCode, () => string> = {
  [CountryCode.KR]: () => t('common::한국'),
  [CountryCode.JP]: () => t('common::일본'),
  [CountryCode.US]: () => t('common::미국'),
  [CountryCode.CA]: () => t('common::캐나다'),
};

export const SiteKey = {
  [SiteId.지그재그]: 'zigzag',
  [SiteId.포스티]: 'posty',
  [SiteId.패션_바이_카카오]: 'fbk',
};
