import { atom } from 'jotai';
import { HelpPopupContent } from './constants';

export interface HelpPopupAtom {
  contents: HelpPopupContent[];
  opened?: boolean;
  current_index: number;
}

export const help_popup_atom = atom<HelpPopupAtom>({
  contents: [],
  opened: false,
  current_index: 0,
});

export const show_floating_button_atom = atom<boolean>(false);
