import { LocationState, Location, History, LocationDescriptor } from 'history';
import React from 'react';
import {
  useLocation as useLocationOld,
  useHistory as useHistoryOld,
  Redirect as RedirectOld,
  RedirectProps,
  useParams as useParamsOld,
} from 'react-router-dom'; // LegacyCompat 변환 X
import { ShopLink } from './ShopLink';
import { getShopPrefix, useShopPrefix } from '@/hooks/useShopPrefix';

export function convertLocationToLegacy<S = LocationState>(location: Location<S>): Location<S> {
  const shop_match = /^\/shop\/([^/#]+)(\/?|\/.+)$/.exec(location.pathname);
  if (shop_match != null) {
    return { ...location, pathname: shop_match[2] };
  }
  return location;
}

/**
 * @deprecated each/meta 통합을 위해 호환 레이어를 작성해둔 부분으로, react-router-dom의 useLocation을 사용해주세요.
 */
export function useLocation<S = LocationState>(): Location<S> {
  const location = useLocationOld<S>();
  return convertLocationToLegacy(location);
}

/**
 * @deprecated each/meta 통합을 위해 호환 레이어를 작성해둔 부분으로, react-router-dom의 useHistory를 사용해주세요.
 * 변경시 참고: https://www.notion.so/croquis/1664cd485e4346ee9b4859c4bcce6543#83683bc7e4ad4d2cab243daa23287514
 */
export function useHistory<S = LocationState>(): History<S> {
  const history = useHistoryOld<S>();
  return {
    ...history,
    push(path, state) {
      if (typeof path !== 'string') {
        if (path.pathname == null || !path.pathname.startsWith('/')) {
          return history.push(path, state);
        }
        return history.push(
          {
            ...path,
            pathname: getShopPrefix(history.location) + path.pathname,
          },
          state,
        );
      }
      if (!path.startsWith('/')) {
        // 상대경로도 무시하고 보냄
        return history.push(path, state);
      }
      return history.push(getShopPrefix(history.location) + path, state);
    },
    replace(path, state) {
      if (typeof path !== 'string') {
        if (path.pathname == null || !path.pathname.startsWith('/')) {
          return history.replace(path, state);
        }
        return history.replace(
          {
            ...path,
            pathname: getShopPrefix(history.location) + path.pathname,
          },
          state,
        );
      }
      if (!path.startsWith('/')) {
        // 상대경로도 무시하고 보냄
        return history.replace(path, state);
      }
      return history.replace(getShopPrefix(history.location) + path, state);
    },
    get location() {
      return convertLocationToLegacy(history.location);
    },
  };
}

/**
 * @deprecated each/meta 통합을 위해 호환 레이어를 작성해둔 부분으로, react-router-dom의 Link를 사용하거나, ShopLink를 사용해주세요.
 */
export const Link = ShopLink;

/**
 * @deprecated each/meta 통합을 위해 호환 레이어를 작성해둔 부분으로, react-router-dom의 Redirect를 사용해주세요.
 */
export const Redirect: React.FC<RedirectProps> = (props) => {
  const prefix = useShopPrefix();
  const old_to = props.to;
  let to: LocationDescriptor = old_to;
  if (typeof old_to === 'string') {
    if (old_to.startsWith('/')) {
      to = prefix + old_to;
    } else {
      to = old_to;
    }
  }
  return <RedirectOld {...props} to={to} />;
};

/**
 * @deprecated each/meta 통합을 위해 호환 레이어를 작성해둔 부분으로, react-router-dom의 useParams를 사용해주세요.
 */
export const useParams = useParamsOld;
