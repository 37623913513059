import { VStack, HStack, colors, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useState, useRef, useEffect } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Example_1 from '@/assets/images/promotion/price_optimization/example_1.png';
import Example_2 from '@/assets/images/promotion/price_optimization/example_2.png';
import Example_3 from '@/assets/images/promotion/price_optimization/example_3.png';
import Example_4 from '@/assets/images/promotion/price_optimization/example_4.png';
import ResponsiveVStack from '@/components/common/responsive/ResponsiveVStack';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useResizeObserver } from '@/hooks/useResizeObserver';

const ExampleSwiper: React.FC = () => {
  const review_ref = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [slide_per_view, setSlidePerView] = useState<number>(4);
  const [current_slide, setCurrentSlide] = useState<number>(0);
  const { is_desktop } = useMediaQuery();
  const { screen_size } = useResizeObserver();
  const LIST = [
    {
      emoji: '🔍',
      text: '검색 결과',
      image: Example_1,
    },
    {
      emoji: '🔖',
      text: '카테고리 별 상품 목록',
      image: Example_2,
    },
    {
      emoji: '💖',
      text: '상품 상세 페이지 추천 영역',
      image: Example_3,
    },
    {
      emoji: '💰︎',
      text: '최저가 도전 뱃지',
      image: Example_4,
    },
  ];
  useEffect(() => {
    const size = review_ref.current?.offsetWidth || Infinity;
    setSlidePerView(Math.min(size / (is_desktop ? 276 : 244), 4));
  }, [screen_size]);

  return is_desktop ? (
    <HStack p='0 60px' spacing={30}>
      {LIST.map((item, index) => (
        <ResponsiveVStack
          key={index}
          alignment='center'
          height={{ desktop: 498 }}
          width={{ desktop: 244 }}
          spacing={{ desktop: 20 }}
        >
          <SC.Badge>{item.text}</SC.Badge>
          <SC.Image src={item.image} alt='' />
        </ResponsiveVStack>
      ))}
    </HStack>
  ) : (
    <>
      <HStack spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <SC.PageDot active={current_slide === index} onClick={() => swiper?.slideTo(index)} />
        ))}
      </HStack>
      <VStack width='100%' alignment='center'>
        <SC.CarouselContainer width='100%' ref={review_ref}>
          <Swiper
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesOffsetBefore={66}
            slidesOffsetAfter={16}
            spaceBetween={20}
            onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
            slidesPerView={slide_per_view}
          >
            {LIST.map((item, index) => (
              <SwiperSlide key={index}>
                <ResponsiveVStack width={{ mobile: '100%' }} spacing={{ mobile: 10 }} alignment='center'>
                  <SC.Badge>{item.text}</SC.Badge>
                  <SC.Image src={item.image} alt='' />
                </ResponsiveVStack>
              </SwiperSlide>
            ))}
          </Swiper>
        </SC.CarouselContainer>
      </VStack>
    </>
  );
};

const SC = {
  PageDot: styled.div<{ active: boolean }>`
    width: ${({ active }) => (active ? 20 : 8)}px;
    height: 8px;
    border-radius: 100px;
    margin: 4px;
    background-color: ${semantic_colors.content.primary};
    opacity: ${({ active }) => (active ? 1 : 0.2)};
  `,
  CarouselContainer: styled(HStack)`
    position: relative;
    max-width: 1290px;
    width: 100%;
    text-align: left;
  `,
  ImageContainer: styled(VStack)`
    border-radius: 30px;
    border: 8px solid ${colors.gray200};
    overflow: hidden;
  `,
  Image: styled.img`
    width: 100%;
  `,
  Badge: styled(HStack)`
    padding: 4px 16px;
    ${text_styles.Heading_15_SemiBold};
    color: ${semantic_colors.content.secondary};
    border-radius: 100px;
    background-color: ${colors.gray300};
  `,
};

export default ExampleSwiper;
