import { useQuery } from 'react-query';
import {
  api_getDailyReportTodayNotice,
  api_getMetaDailyReportTodayNotice,
  GetDailyReportTodayNoticeQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportTodayNoticeQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportTodayNotice = ({ date_ymd, main_domain = '', token = '' }: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportTodayNotice', { date_ymd, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportTodayNotice({ date_ymd }, { no_alert: true })
        : await api_getMetaDailyReportTodayNotice(
            {
              date_ymd,
              main_domain,
              token,
            },
            { no_alert: true },
          );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000 },
  );
};
