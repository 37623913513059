import { Divider, HStack, SectionTitle, TextButton, semantic_colors } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TERMS } from '@/constants/subscription';

interface PartnerPlusIncludesTermsTitleProps {
  title: string;
}

const PartnerPlusIncludesTermsTitle: FC<PartnerPlusIncludesTermsTitleProps> = ({ title }) => {
  const { t } = useTranslation('partner_plus');

  return (
    <SectionTitle
      title={title}
      textButton={
        <HStack spacing={10} alignment='center'>
          <TextButton
            size='large'
            kind='link'
            onClick={() => {
              window.open(TERMS.이용약관, '_blank', 'noreferrer');
            }}
          >
            {t('구독 서비스 이용약관')}
          </TextButton>
          <Divider length={20} color={semantic_colors.border.default} direction='vertical' />
          <TextButton
            size='large'
            kind='link'
            onClick={() => {
              window.open(TERMS.운영정책, '_blank', 'noreferrer');
            }}
          >
            {t('구독 서비스 운영 정책')}
          </TextButton>
        </HStack>
      }
      mb={0}
    />
  );
};
export default PartnerPlusIncludesTermsTitle;
