import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from '@/components/route/LegacyRouterCompat';
import useEachPbl from '@/hooks/useEachPbl';
import { Nav, NavItem } from 'rui/pastel/unstable/nav';

interface ChannelExtensionNavItemData {
  id: 'intro' | 'global' | 'status';
  label: React.ReactNode;
  href: string;
  onClick?: () => void;
}

export interface ChannelExtensionNavProps {
  tab?: ChannelExtensionNavItemData['id'];
}

export const ChannelExtensionNav: React.FC<ChannelExtensionNavProps> = ({ tab }) => {
  const { t } = useTranslation('channel_extension');
  const history = useHistory();
  const { eachPbl } = useEachPbl();
  const NAV_ITEMS: ChannelExtensionNavItemData[] = useMemo(
    () => [
      {
        id: 'intro',
        label: t('원클릭 판매 채널 확장'),
        href: '/channel_extension/intro',
        onClick: () => {
          eachPbl({
            navigation: 'channel_extension_tab',
            category: 'click',
            object_type: 'tab',
            object_id: 'one_click',
          });
        },
      },
      {
        id: 'status',
        label: t('판매 채널 현황'),
        href: '/channel_extension/status',
        onClick: () => {
          eachPbl({
            navigation: 'channel_extension_tab',
            category: 'click',
            object_type: 'tab',
            object_id: 'status',
          });
        },
      },
    ],
    [eachPbl],
  );

  return (
    <Nav>
      {NAV_ITEMS.map((item) => (
        <NavItem
          key={item.id}
          active={item.id === tab}
          onClick={() => {
            item.onClick?.();
            history.push(item.href);
          }}
        >
          {item.label}
        </NavItem>
      ))}
    </Nav>
  );
};
