import dayjs from 'dayjs';
import { atomWithQuery } from 'jotai/query';

import { main_domain_atom } from './main_domain';
import { api_getShopTutorialList, ShopTutorialPartsFragment } from '@/api';
import { getDefaultFunnelData } from '@/constants/onboarding_funnels';
import { Funnel, Onboarding, OnboardingFunnelStep } from '@/types/onboarding';

export const onboarding_atom = atomWithQuery<Onboarding, Error>((get) => ({
  queryKey: ['getOnboarding', get(main_domain_atom)],
  queryFn: async () => {
    try {
      const { data } = await api_getShopTutorialList(undefined, { no_alert: true });

      const { is_displayed, tutorial_attainment_rate_list, tutorial_completed_list, tutorial_uncompleted_list } =
        data.shop_tutorial_list;

      const completed_funnel_index = tutorial_attainment_rate_list
        .map(({ attainment_rate }) => attainment_rate)
        .lastIndexOf(100);
      const total_funnel_count = tutorial_attainment_rate_list.length;
      const completed_funnel_count = completed_funnel_index + 1;
      const is_all_completed = completed_funnel_count === total_funnel_count;
      const last_funnel_step =
        tutorial_attainment_rate_list.find(({ attainment_rate }) => attainment_rate !== 100)?.step ??
        tutorial_attainment_rate_list.slice(-1)[0].step;

      const last_funnel = (
        [...tutorial_uncompleted_list, ...tutorial_completed_list] as ShopTutorialPartsFragment[]
      ).find(({ step }) => step === last_funnel_step);

      return tutorial_attainment_rate_list.reduce<Onboarding>(
        (acc, { step, attainment_rate, date_completed = null }) => {
          const { funnels, ...rest_acc } = acc;

          const is_completed = attainment_rate === 100;
          const DEFAULT_FUNNEL = getDefaultFunnelData(step as OnboardingFunnelStep);
          const { description, tasks: default_tasks } = DEFAULT_FUNNEL(is_completed);
          const completed_list = tutorial_completed_list.filter((tutorial) => tutorial.step === step);
          const uncompleted_list = tutorial_uncompleted_list.filter((tutorial) => tutorial.step === step);

          const order = [...completed_list, ...uncompleted_list].shift()?.step_order ?? 0;
          const name = [...completed_list, ...uncompleted_list].shift()?.step_description ?? '';
          const tasks = default_tasks
            .map((default_task) => {
              const is_completed = completed_list.some((tutorial) => tutorial.action === default_task.task);
              const task = [...completed_list, ...uncompleted_list].find(
                (tutorial) => tutorial.action === default_task.task,
              );

              return {
                ...default_task,
                ...(task
                  ? {
                      name: task.action_description,
                      order: task.action_order,
                    }
                  : {}),
                is_completed,
              };
            })
            .sort((a, b) => a.order - b.order);

          const tmp_task = tasks.filter(({ is_completed }) => !is_completed).shift();
          const in_progress_task = is_completed || !tmp_task ? null : tmp_task;

          const funnel: Funnel = {
            step: step as OnboardingFunnelStep,
            name,
            description,
            order,
            is_completed,
            date_completed,
            in_progress_task,
            tasks,
          };

          return {
            ...rest_acc,
            funnels: [...funnels, funnel].sort((a, b) => a.order - b.order),
          };
        },
        {
          last_funnel: {
            step: last_funnel!.step,
            name: last_funnel!.step_description,
            task: {
              task: last_funnel!.action,
              name: last_funnel!.action_description,
            },
          },
          total_funnel_count,
          completed_funnel_count,
          is_completed: is_all_completed,
          is_displayed,
          funnels: [],
          last_fetch_time: dayjs().valueOf(),
        } as Onboarding,
      );
    } catch (e) {
      return {
        last_funnel: {
          step: 'ENTRY_COMPLETE',
          name: '',
          task: {
            task: 'ENTRY_COMPLETE',
            name: '',
          },
        },
        total_funnel_count: 0,
        completed_funnel_count: 0,
        is_completed: true,
        is_displayed: false,
        funnels: [],
        last_fetch_time: 0,
      };
    }
  },
}));
