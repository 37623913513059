import { BaseText, semantic_colors } from '@croquiscom/pds';
import React from 'react';

interface UpDownDataBadgeProps {
  data: number;
  rate?: number;
  unit: string;
  prefix: string;
  is_short?: boolean;
  size?: 'small' | 'medium';
}

const UpDownDataBadge = ({ data, rate, unit, prefix, is_short, size = 'medium' }: UpDownDataBadgeProps) => {
  return (
    <BaseText kind={size === 'small' ? 'Caption_11_SemiBold' : 'Body_12_SemiBold'}>
      {prefix}{' '}
      <BaseText
        kind={size === 'small' ? 'Caption_11_SemiBold' : 'Body_12_SemiBold'}
        color={data > 0 ? semantic_colors.state.positive : semantic_colors.state.negative}
      >
        {is_short ? '약 ' : ''}
        {data > 0 ? '+' : ''}
        {data.toLocaleString()}
        {unit}
        {rate ? `(${data > 0 ? '+' : ''}${rate.toFixed(2)}%)` : ''}
      </BaseText>
    </BaseText>
  );
};
export default UpDownDataBadge;
