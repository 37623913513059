import React, { AnchorHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpConstantOptions, useHelpConstants } from '@/hooks/useHelpConstants';
import { LinkText } from 'components/common/styled';

export interface HelpConstantLinkProps<T extends Record<string, string>>
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  options: HelpConstantOptions<T>;
  linkKey: keyof T;
}

/**
 * 도움말에서 URL을 관리할 수 있게 해주는 링크 컴포넌트입니다. 자세한 내용은 여기를 참고해주세요:
 * https://www.notion.so/croquis/URL-1bd3b7ab08ba47dc9ff897a6f25d2f0d
 */
export function HelpConstantLink<T extends Record<string, string>>(
  props: HelpConstantLinkProps<T>,
): React.ReactElement {
  const { options, linkKey, ...restProps } = props;
  const { data } = useHelpConstants(options);
  const { t } = useTranslation('common');
  const href = data[linkKey];
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <LinkText
      target='_blank'
      rel='noreferrer'
      {...restProps}
      href={href || '#'}
      onClick={(e) => {
        if (href == null || href === '') {
          e.preventDefault();
          alert(t('준비중입니다. 나중에 다시 확인해주세요.'));
          return;
        }
        restProps.onClick?.(e);
      }}
    />
  );
}
