import { BaseText, Button, HStack, IconSearch, IconX, Input, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuListProvider, useMenuListDescendants } from './MenuChildItem';
import { MenuParentItem } from './MenuParentItem';
import { SearchHistoryModal } from './SearchHistoryModal';
import { MenuEntry } from './types';
import { filterAllowedMenuEntries, getLastMenuItems, hasSelectedChildren } from './utils';
import { Config } from '@/config';
import { usePbl } from '@/hooks/usePbl';
import { reset_button_css } from '@/utils';
import useClickOutside from 'rui/hooks/useClickOutside';

export interface MenuListProps {
  entries: MenuEntry[];
  selected_id: string | null;
  onClick?: (entry: MenuEntry) => void;
}

const MenuListImpl: React.FC<MenuListProps> = ({ entries, selected_id, onClick }) => {
  const SEARCHED_MENU_LIST_KEY = `SearchedMenuList.${Config.shop_main_domain}`;
  const stored_searched_menu_list = localStorage.getItem(SEARCHED_MENU_LIST_KEY);
  const search_element = useRef<HTMLDivElement>(null);
  const { pbl } = usePbl();
  const descendants = useMenuListDescendants();
  const location = useLocation();
  const { date: saved_date, menu_list: saved_menu_list } = stored_searched_menu_list
    ? JSON.parse(stored_searched_menu_list)
    : {
        menu_list: [],
        date: null,
      };
  const [opened_id, setOpenedId] = useState<string | null>(null);
  const [search_keyword, setSearchKeyword] = useState('');
  const [confirmed_keyword, setConfirmedKeyword] = useState('');
  const [search_menu_list, setSearchMenuList] = useState<MenuEntry[]>([]);
  const [open_searched_history, setOpenSearchedHistory] = useState(false);
  const filtered_entries = useMemo(() => filterAllowedMenuEntries(entries), [entries]);
  const flatten_last_menu_items = useMemo(() => getLastMenuItems(filtered_entries), [filtered_entries]);
  const current_menu_list = useMemo(() => {
    return confirmed_keyword ? search_menu_list : filtered_entries;
  }, [filtered_entries, search_menu_list, search_keyword]);
  useClickOutside([search_element], open_searched_history, () => setOpenSearchedHistory(false));

  const is_within_7_days = useMemo(() => {
    const today = dayjs();
    const target_date = dayjs(saved_date);
    const diff_in_days = target_date.diff(today, 'day');
    return diff_in_days >= 0 && diff_in_days <= 7;
  }, [saved_date]);

  const is_show_search_history = useMemo(() => {
    return is_within_7_days && saved_menu_list.length > 0;
  }, [is_within_7_days, saved_menu_list]);

  const handleOpenChange = useCallback((entry: MenuEntry | null) => {
    setOpenedId(entry?.id ?? null);
  }, []);

  const handleClick = (entry: MenuEntry) => {
    const include_history_entry = (search_menu_list ?? []).find(
      ({ id, label }) => id === entry.id && label === entry.label,
    );
    if (include_history_entry) {
      const updated_menu_list = is_within_7_days
        ? uniqBy([...saved_menu_list, entry], (item) => `${item.label}_${item.id}`)
        : [entry];
      const searched_data = {
        date: dayjs().toDate(),
        menu_list: updated_menu_list.slice(0, 10),
      };
      localStorage.setItem(SEARCHED_MENU_LIST_KEY, JSON.stringify(searched_data));
    }
    onClick?.(entry);
  };

  const handleSearch = () => {
    if (search_keyword !== '') {
      const filtered_menu_list = flatten_last_menu_items.filter((item) => item?.label.includes(search_keyword));
      setSearchMenuList(filtered_menu_list);
      setConfirmedKeyword(search_keyword);
      pbl({ navigation: 'gnb_n_top_bar', category: 'click', object_id: 'pages_search', object_section: 'navigations' });
    }
  };

  const HandleKeydown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const keyMap: Record<string, React.KeyboardEventHandler> = {
      Enter: handleSearch,
      ArrowDown: () => {
        descendants.first()?.node.focus();
      },
      ArrowUp: () => {
        descendants.last()?.node.focus();
      },
    };
    const action = keyMap[event.key];
    if (action && !event.nativeEvent.isComposing) {
      event.preventDefault();
      action(event);
    }
  };

  const handleReset = () => {
    setSearchKeyword('');
    setSearchMenuList([]);
    setConfirmedKeyword('');
  };

  useEffect(() => {
    const item = entries.find((entry) => hasSelectedChildren(entry, selected_id));
    setOpenedId(item?.id ?? null);
  }, [entries, selected_id]);

  useEffect(() => {
    handleReset();
    setOpenSearchedHistory(false);
  }, [location]);

  return (
    <>
      <StyledSearchArea>
        <div ref={search_element} style={{ position: 'relative' }}>
          <Input
            size='small'
            value={search_keyword}
            placeholder='메뉴 검색'
            endElement={
              (confirmed_keyword === search_keyword && search_menu_list.length > 0) ||
              (search_keyword !== '' && confirmed_keyword !== '' && confirmed_keyword === search_keyword) ||
              (search_keyword === '' && confirmed_keyword !== '') ? (
                <StyledIconButton onClick={handleReset}>
                  <IconX size={14} color={semantic_colors.content.secondary} />
                </StyledIconButton>
              ) : (
                <StyledIconButton onClick={handleSearch}>
                  <IconSearch size={14} color={semantic_colors.content.secondary} />
                </StyledIconButton>
              )
            }
            onKeyDown={HandleKeydown}
            onFocus={() => setOpenSearchedHistory(is_show_search_history)}
            onChange={(e) => {
              const value = e.target.value;
              setSearchKeyword(value);
              if (value !== '') {
                setOpenSearchedHistory(false);
              }
            }}
          />
          {open_searched_history && (
            <MenuListProvider value={descendants}>
              <SearchHistoryModal
                saved_menu_list={saved_menu_list.splice(0, 10)}
                selected_id={selected_id}
                onClick={onClick}
              />
            </MenuListProvider>
          )}
        </div>
      </StyledSearchArea>
      <StyledMenuList>
        {confirmed_keyword && (
          <HStack p='6px 20px'>
            <BaseText kind='Caption_11_SemiBold' color={semantic_colors.content.tertiary}>
              검색 결과 {search_menu_list.length}건
            </BaseText>
          </HStack>
        )}
        {current_menu_list.length > 0 ? (
          current_menu_list.map((entry) => (
            <MenuParentItem
              key={entry.id}
              entry={entry}
              selected_id={selected_id}
              is_open={entry.id === opened_id}
              is_searched={search_menu_list.length > 0}
              onOpenChange={handleOpenChange}
              onClick={handleClick}
            />
          ))
        ) : (
          <StyledEmptyArea alignment='center' spacing={8}>
            <IconSearch size={20} color={semantic_colors.content.tertiary} />
            <BaseText kind='Body_14_Medium' color={semantic_colors.content.tertiary}>
              검색 메뉴가 없어요
            </BaseText>
            <Button size='xsmall' onClick={handleReset}>
              전체 메뉴 보기
            </Button>
          </StyledEmptyArea>
        )}
      </StyledMenuList>
    </>
  );
};

export const MenuList = React.memo(MenuListImpl);

const StyledMenuList = styled.div`
  padding: 8px 0;
  background-color: ${semantic_colors.background.surface};
`;
const StyledSearchArea = styled.div`
  padding: 16px 16px 0;
  background-color: ${semantic_colors.background.surface};
  .menu_list_searched_popover {
    left: 16px !important;
  }
`;

const StyledEmptyArea = styled(VStack)`
  height: 200px;
  justify-content: center;
  padding-bottom: 20px;
`;

const StyledIconButton = styled.button`
  ${reset_button_css};
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
`;
