import React, { lazy } from 'react';
import { RoutesType } from '../types';

const NoticeDetail = lazy(() => import('@/pages/notice/detail'));
const NoticeList = lazy(() => import('@/pages/notice/main'));

export const NOTICE_ROUTES: RoutesType[] = [
  {
    current_menu: 'notice',
    path: '/notice',
    params: '/:id',
    is_support_mobile: true,
    is_support_app: true,
    component: <NoticeDetail />,
  },
  {
    current_menu: 'notice',
    path: '/notice',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <NoticeList />,
  },
];
