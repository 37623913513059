import { BaseText, HStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

interface Legend {
  label: string;
  color: string;
  border_color?: string;
}

interface ChartHeaderProps {
  title: string;
  legend: Legend[];
}

const ChartHeader = ({ title, legend }: ChartHeaderProps) => {
  return (
    <HStack width='100%' alignment='center'>
      <BaseText kind='Body_14_SemiBold'>{title}</BaseText>
      <HStack ml='auto' spacing={12}>
        {legend.map(({ color, border_color, label }) => (
          <HStack alignment='center' spacing={6}>
            <SC.Legend color={color} border_color={border_color} />
            <BaseText kind='Body_12_Medium'>{label}</BaseText>
          </HStack>
        ))}
      </HStack>
    </HStack>
  );
};

export default ChartHeader;

const SC = {
  Legend: styled.div<{ color: string; border_color?: string }>`
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color};
    border: 1px solid ${({ border_color, color }) => border_color || color};
    border-radius: 2px;
  `,
};
