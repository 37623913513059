import { BaseText, colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Path } from '@/components/ad/3.0/common/const';
import { Button } from '@/components/pdstop';
import { SiteId } from '@/constants/site';
import Modal from 'rui/pastel/Modal';
import { ModalFooter } from 'rui/pastel/ModalFooter';
import SiteTag from 'rui/pastel/SiteTag';

interface AdPageTitleProps {
  site_id?: SiteId;
  title: string;
  description: string;
  ad_intro_link: string;
  BestAd: React.ReactElement;
  isShowPopularCreative?: boolean;
}

const AdPageTitle: FC<AdPageTitleProps> = ({
  site_id,
  title,
  description,
  ad_intro_link,
  BestAd,
  isShowPopularCreative = true,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openPopularCreative = () => setShowModal(true);
  const closePopularCreative = () => setShowModal(false);

  return (
    <>
      <StyledContainer>
        <StyledLogoContainer>
          {site_id && SiteTag({ site_id, icon_only: true, width: 48, height: 48 })}
          <div className='texts'>
            <span className='texts__title'>{title}</span>
            <span className='texts__desc'>
              {description}
              <a href={ad_intro_link} target='_blank' rel='noreferrer'>
                광고 소개서
              </a>
            </span>
          </div>
        </StyledLogoContainer>
        <StyledHelp>
          <a href={Path.library} target='_blank' rel='noreferrer'>
            <BaseText kind='Body_13_Regular' color={colors.zigzag_pink500}>
              광고 라이브러리
            </BaseText>
          </a>
          {isShowPopularCreative && (
            <>
              <StyledDivider />
              <button onClick={openPopularCreative} type='button'>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  인기 소재
                </BaseText>
              </button>
            </>
          )}
          <StyledDivider />
          <a href={`${window.location.origin}/help?id=970`} target='_blank' rel='noreferrer'>
            <BaseText kind='Body_13_Regular' color={colors.gray700}>
              광고 도움말
            </BaseText>
          </a>
        </StyledHelp>
      </StyledContainer>
      <Modal title='인기 소재' isOpen={showModal} width={698} onClose={closePopularCreative}>
        {BestAd}
        <ModalFooter>
          <Button size='large' kind='primary' onClick={closePopularCreative}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  justify-content: space-between;
  padding-right: 50px;
  margin: -30px 0;
`;

const StyledHelp = styled.div`
  display: flex;
  line-height: 0px !important;
  align-self: flex-start;
  padding-top: 16px;

  & > button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`;

const StyledDivider = styled.div`
  width: 1px;
  height: 14px;
  background-color: ${colors.gray300};
  margin: 5px 14px 0px;
`;

const StyledLogoContainer = styled.header`
  display: flex;
  align-items: center;

  .texts {
    display: flex;
    margin-left: 12px;
    flex-direction: column;

    &__title {
      ${text_styles.Heading_20_Bold};
      color: ${colors.gray900};
    }
    &__desc {
      ${text_styles.Body_13_Regular};
      color: ${colors.gray700};

      a {
        border-left: 1px solid ${colors.gray300};
        ${text_styles.Body_13_Regular};
        color: ${colors.gray900};
        margin-left: 8px;
        padding-left: 8px;
      }
    }
  }
`;

export default AdPageTitle;
