import {
  Badge,
  BaseText,
  HStack,
  IconArrowChevronRight,
  IconArrowDecrease,
  IconArrowIncrease,
  IconArrowStick,
  IconColoredMoneyBag,
  VStack,
  semantic_colors,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BlurArea from './common/BlurArea';
import ComparisonBadge from './common/ComparisonBadge';
import Nodata from './common/Nodata';
import SectionTitle from './common/SectionTitle';
import { Card, ContentContainer } from './common/styles';
import { useGetDailyReportSalesCompetitor } from './hooks/useGetDailyReportSalesCompetitor';
import { useGetDailyReportSalesConversion } from './hooks/useGetDailyReportSalesConversion';
import { useSendDailyReportPbl } from './hooks/useSendDailyReportPbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const SalesPerformance: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const history = useHistory();
  const prefix = useShopPrefix();
  const shop_prefix = main_domain ? `/shop/${main_domain}${prefix}` : prefix;
  const { sendClick } = useSendDailyReportPbl(is_partner_plus);
  const [show_freeview, setShowFreeview] = useState(false);
  const [show_competitor_freeview, setShowCompetitorFreeview] = useState(false);
  const { data: competitor, isLoading } = useGetDailyReportSalesCompetitor({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: show_competitor_freeview,
    main_domain,
    token,
  });
  const { data, isLoading: isLoadingSales } = useGetDailyReportSalesConversion({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: show_freeview,
    main_domain,
    token,
  });
  const order_data = data?.partner_plus_daily_report?.sales_order_report;
  const conversion_data = data?.partner_plus_daily_report?.sales_conversion_report;
  const competitor_data = competitor?.partner_plus_daily_report?.competitor_comparison_report;
  const average_order_value = Math.round((competitor_data?.same_tier_average_order_amount || 0) / 10000) * 10000;

  return (
    <ContentContainer>
      <SectionTitle
        object_section='판매 성과 비교 분석'
        onClickLink={onClose}
        main_domain={main_domain}
        Icon={IconColoredMoneyBag}
        description={t('어제 성과를 지난 7일 평균, 지난주 같은 요일과 비교해서 객관적으로 판단해보세요.')}
        link={is_partner_plus ? '/insight/sales-performance?ug=summary' : '/stats'}
        is_partner_plus={is_partner_plus}
      >
        {t('판매 성과 비교 분석')}
      </SectionTitle>
      <VStack spacing={8}>
        {order_data ? (
          <Card alignment='leading' spacing={8}>
            {order_data?.plus_only && (
              <Badge color='blue' size='small'>
                {t('플러스 전용')}
              </Badge>
            )}
            <HStack spacing={8} width='100%'>
              <VStack width='50%'>
                <VStack mb={2}>
                  <BaseText kind='Body_14_SemiBold'>{t('판매금액')}</BaseText>
                  <BaseText kind='Heading_15_Bold'>
                    {order_data?.order_amount?.toLocaleString()}
                    <BaseText kind='Body_12_SemiBold'>{t('원')}</BaseText>
                  </BaseText>
                </VStack>
                <VStack spacing={4} alignment='leading'>
                  <ComparisonBadge
                    value={order_data?.order_amount_dod || 0}
                    unit='%'
                    josa='과'
                    prefix_text={t('전날')}
                  />
                  <ComparisonBadge
                    value={order_data?.order_amount_wow || 0}
                    unit='%'
                    prefix_text={`${t('지난주')} ${date.subtract(1, 'day').format('dddd')}`}
                  />
                  <ComparisonBadge
                    value={order_data?.order_amount_increase_rate_of_last7day_average || 0}
                    unit='%'
                    prefix_text={t('7일 평균')}
                  />
                </VStack>
              </VStack>
              <VStack width='50%'>
                <VStack mb={2}>
                  <BaseText kind='Body_14_SemiBold'>{t('주문수')}</BaseText>
                  <BaseText kind='Heading_15_Bold'>
                    {order_data?.order_count?.toLocaleString()}
                    <BaseText kind='Body_12_SemiBold'>{t('건')}</BaseText>
                  </BaseText>
                </VStack>
                <VStack spacing={4} alignment='leading'>
                  <ComparisonBadge
                    value={order_data?.order_count_dod || 0}
                    unit='%'
                    josa='과'
                    prefix_text={t('전날')}
                  />
                  <ComparisonBadge
                    value={order_data?.order_count_wow || 0}
                    unit='%'
                    prefix_text={`${t('지난주')} ${date.subtract(1, 'day').format('dddd')}`}
                  />
                  <ComparisonBadge
                    value={order_data?.order_count_increase_rate_of_last7day_average || 0}
                    unit='%'
                    prefix_text={t('7일 평균')}
                  />
                </VStack>
              </VStack>
            </HStack>
          </Card>
        ) : (
          <Nodata />
        )}
        {competitor_data ? (
          <Card alignment='leading' spacing={4}>
            <HStack width='100%'>
              {competitor_data?.plus_only && (
                <Badge color='blue' size='small'>
                  {t('플러스 전용')}
                </Badge>
              )}
              <ClickableBox
                ml='auto'
                onClick={() => {
                  onClose?.();
                  sendClick({
                    object_id: 'view_detail',
                    object_section: '경쟁사 인사이트',
                  });
                  history.push(`${shop_prefix}/insight/competitors?ug=store-level`);
                }}
              >
                <IconArrowChevronRight />
              </ClickableBox>
            </HStack>
            <VStack width='100%' alignment='leading'>
              <VStack alignment='leading'>
                <BaseText kind='Body_14_SemiBold'>{t('어제 내 등급 경쟁사 평균 판매금액')}</BaseText>
              </VStack>
              <BlurArea
                is_partner_plus={is_partner_plus}
                is_loading={isLoading}
                main_domain={main_domain}
                onRequestFreeView={() => setShowCompetitorFreeview(true)}
                object_section='어제 내 등급 경쟁사 평균 판매금액'
                is_blur={competitor_data?.is_blurred}
                used_free_view_count={competitor_data?.free_view_count?.used_free_view_count || 0}
                max_free_view_count={competitor_data?.free_view_count?.max_free_view_count || 0}
              >
                <VStack width='100%' spacing={16}>
                  <HStack spacing={8}>
                    <VStack>
                      <VStack>
                        <BaseText kind='Heading_15_Bold'>
                          {t('약 {{price}}', { price: average_order_value.toLocaleString() })}
                          <BaseText kind='Body_12_SemiBold'>{t('원')}</BaseText>
                        </BaseText>
                      </VStack>
                      <VStack spacing={4} alignment='leading'>
                        <ComparisonBadge
                          value={competitor_data?.same_tier_average_order_amount_dod || 0}
                          unit='%'
                          josa='과'
                          prefix_text={t('전날')}
                        ></ComparisonBadge>
                        <ComparisonBadge
                          value={competitor_data?.same_tier_average_order_amount_wow || 0}
                          unit='%'
                          prefix_text={`${t('지난주')} ${date.subtract(1, 'day').format('dddd')}`}
                        ></ComparisonBadge>
                        <ComparisonBadge
                          value={competitor_data?.same_tier_average_order_amount_increase_rate_of_last7day_average || 0}
                          unit='%'
                          prefix_text={t('7일 평균')}
                        />
                      </VStack>
                    </VStack>
                  </HStack>
                  <HStack width='100%' spacing={8}>
                    <VStack width='50%' spacing={4}>
                      <VStack>
                        <BaseText kind='Body_14_SemiBold'>
                          {
                            <>
                              {date.format('M')}
                              {t('월 판매액 등급')}
                            </>
                          }
                        </BaseText>
                        <BaseText kind='Heading_15_Bold'>
                          {competitor_data?.tier?.toLocaleString()}
                          {t('등급')}
                        </BaseText>
                      </VStack>

                      <HStack alignment='center' spacing={4}>
                        <HStack alignment='center'>
                          {(competitor_data?.change_tier_per_yesterday || 0) > 0 ? (
                            <IconArrowIncrease size={12} color={semantic_colors.state.positive} />
                          ) : (competitor_data?.change_tier_per_yesterday || 0) < 0 ? (
                            <IconArrowDecrease size={12} color={semantic_colors.state.negative} />
                          ) : (
                            <IconArrowStick size={12} color={semantic_colors.content.tertiary} />
                          )}

                          {competitor_data?.change_tier_per_yesterday ? (
                            <BaseText
                              kind='Heading_15_Bold'
                              color={
                                (competitor_data?.change_tier_per_yesterday || 0) > 0
                                  ? semantic_colors.state.positive
                                  : (competitor_data?.change_tier_per_yesterday || 0) < 0
                                    ? semantic_colors.state.negative
                                    : undefined
                              }
                            >
                              {Math.abs(competitor_data?.change_tier_per_yesterday || 0).toLocaleString()}
                              {t('등급')}
                            </BaseText>
                          ) : (
                            ''
                          )}
                        </HStack>
                        <BaseText kind='Caption_11_Medium' color={semantic_colors.content.tertiary}>
                          {competitor_data?.change_tier_per_yesterday ? t('어제보다') : t('변동없음')}
                        </BaseText>
                      </HStack>
                    </VStack>
                    <VStack spacing={4} width='50%'>
                      <VStack>
                        <BaseText kind='Body_14_SemiBold'>
                          {t('{{tier}}등급 내 순위', { tier: competitor_data?.tier })}
                        </BaseText>
                        <BaseText kind='Heading_15_Bold'>
                          {competitor_data?.rank_in_tiers?.toLocaleString()}
                          {t('위')}
                          <BaseText kind='Body_12_SemiBold'>
                            /{competitor_data?.total_in_tiers?.toLocaleString()}
                          </BaseText>
                        </BaseText>
                      </VStack>
                      {competitor_data?.change_tier_per_yesterday ? (
                        <HStack alignment='center' spacing={4}>
                          <HStack alignment='center'>
                            <BaseText kind='Caption_11_Bold' color={semantic_colors.accent.primary}>
                              NEW
                            </BaseText>
                          </HStack>
                          <BaseText kind='Caption_11_Medium' color={semantic_colors.content.tertiary}>
                            {t('새로진입')}
                          </BaseText>
                        </HStack>
                      ) : (
                        <HStack alignment='center' spacing={4}>
                          <HStack alignment='center'>
                            {(competitor_data?.change_rank_per_yesterday || 0) > 0 ? (
                              <IconArrowIncrease size={12} color={semantic_colors.state.positive} />
                            ) : competitor_data?.change_rank_per_yesterday || 0 ? (
                              <IconArrowDecrease size={12} color={semantic_colors.state.negative} />
                            ) : (
                              <IconArrowStick size={12} color={semantic_colors.content.tertiary} />
                            )}

                            {competitor_data?.change_rank_per_yesterday ? (
                              <BaseText
                                kind='Heading_15_Bold'
                                color={
                                  (competitor_data?.change_rank_per_yesterday || 0) > 0
                                    ? semantic_colors.state.positive
                                    : (competitor_data?.change_rank_per_yesterday || 0) < 0
                                      ? semantic_colors.state.negative
                                      : undefined
                                }
                              >
                                {Math.abs(competitor_data?.change_rank_per_yesterday || 0)?.toLocaleString()}
                                {t('위')}
                              </BaseText>
                            ) : (
                              ''
                            )}
                          </HStack>
                          <BaseText kind='Caption_11_Medium' color={semantic_colors.content.tertiary}>
                            {competitor_data?.change_rank_per_yesterday ? t('어제보다') : t('변동없음')}
                          </BaseText>
                        </HStack>
                      )}
                    </VStack>
                  </HStack>
                </VStack>
              </BlurArea>
            </VStack>
          </Card>
        ) : (
          <Nodata />
        )}
        {conversion_data ? (
          <Card alignment='leading' spacing={8}>
            {conversion_data?.plus_only && (
              <Badge color='blue' size='small'>
                {t('플러스 전용')}
              </Badge>
            )}
            <VStack width='100%'>
              <HStack width='100%' spacing={8}>
                <VStack width='50%'>
                  <BaseText kind='Body_14_SemiBold'>{t('구매전환율')}</BaseText>
                </VStack>
                <VStack width='50%'>
                  <BaseText kind='Body_14_SemiBold'>{t('주문단가')}</BaseText>
                </VStack>
              </HStack>
              <BlurArea
                is_partner_plus={is_partner_plus}
                is_loading={isLoadingSales}
                main_domain={main_domain}
                onRequestFreeView={() => setShowFreeview(true)}
                object_section='판매 성과 비교 분석'
                is_blur={conversion_data?.is_blurred}
                used_free_view_count={conversion_data?.free_view_count?.used_free_view_count || 0}
                max_free_view_count={conversion_data?.free_view_count?.max_free_view_count || 0}
              >
                <HStack width='100%' spacing={8}>
                  <VStack width='50%'>
                    <VStack mb={2}>
                      <BaseText kind='Heading_15_Bold'>
                        {conversion_data?.conversion_rate?.toLocaleString()}
                        <BaseText kind='Body_12_SemiBold'>{t('%')}</BaseText>
                      </BaseText>
                    </VStack>
                    <VStack spacing={4} alignment='leading'>
                      <ComparisonBadge
                        value={conversion_data?.conversion_rate_dod || 0}
                        unit='%'
                        josa='과'
                        prefix_text={t('전날')}
                      ></ComparisonBadge>
                      <ComparisonBadge
                        value={conversion_data?.conversion_rate_wow || 0}
                        unit='%'
                        prefix_text={`${t('지난주')} ${date.subtract(1, 'day').format('dddd')}`}
                      ></ComparisonBadge>
                      <ComparisonBadge
                        value={conversion_data?.conversion_rate_increase_rate_of_last7day_average || 0}
                        unit='%'
                        prefix_text={t('7일 평균')}
                      />
                    </VStack>
                  </VStack>
                  <VStack width='50%'>
                    <VStack mb={2}>
                      <BaseText kind='Heading_15_Bold'>
                        {conversion_data?.average_order_value?.toLocaleString()}
                        <BaseText kind='Body_12_SemiBold'>{t('원')}</BaseText>
                      </BaseText>
                    </VStack>
                    <VStack spacing={4} alignment='leading'>
                      <ComparisonBadge
                        value={conversion_data?.average_order_value_dod || 0}
                        unit='%'
                        josa='과'
                        prefix_text={t('전날')}
                      />
                      <ComparisonBadge
                        value={conversion_data?.average_order_value_wow || 0}
                        unit='%'
                        prefix_text={`${t('지난주')} ${date.subtract(1, 'day').format('dddd')}`}
                      />
                      <ComparisonBadge
                        value={conversion_data?.average_order_value_increase_rate_of_last7day_average || 0}
                        unit='%'
                        prefix_text={t('7일 평균')}
                      />
                    </VStack>
                  </VStack>
                </HStack>
              </BlurArea>
            </VStack>
          </Card>
        ) : (
          <Nodata />
        )}
      </VStack>
    </ContentContainer>
  );
};

export default SalesPerformance;

const ClickableBox = styled(HStack)`
  cursor: pointer;
`;
