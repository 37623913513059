import { HStack, SelectChip } from '@croquiscom/pds';
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ALERT_BOX_TAB_NAME } from '../constants';
import { usePartnerCenterNotificationListQuery } from '../hooks/useGetNotificationList';
import { GroupInfo, NotificationSubCategoryChip } from '../types';
import { DeferredComponent } from './DeferredComponent';
import { NoAlertData } from './NoAlertData';
import { NoMoreData } from './NoMoreData';
import { NotificationCard } from './NotificationCard';
import { SkeletonCardList } from './SkeletonCardList';
import { CardList, LoadingBox, ModalBody, ScrollArea } from './styles';
import { usePbl } from '@/hooks/usePbl';
import { PartnerCenterNotification1stCategory, PartnerCenterNotification2ndCategory } from 'api';

export interface NotiCardListProps {
  category: PartnerCenterNotification1stCategory;
  sub_categories?: NotificationSubCategoryChip[];
  onShowGroupClick?: (group_info: GroupInfo) => void;
  pbl_section: string;
  onCardClick: () => void;
}

export const NotiCardList: React.FC<NotiCardListProps> = ({
  category,
  sub_categories,
  onShowGroupClick,
  pbl_section,
  onCardClick,
}) => {
  const { pbl } = usePbl();
  const scroll_area_ref = useRef<HTMLDivElement>(null);
  const [selected_chip, setSelectedChip] = useState<PartnerCenterNotification2ndCategory | ''>('');
  const { data, error, isLoading, isFetching, fetchNextPage, hasNextPage, refetch } =
    usePartnerCenterNotificationListQuery(
      {
        notification_1st_category: category,
        ...(selected_chip && { notification_2nd_category: selected_chip }),
      },
      {
        onError: () => {},
      },
    );
  const items = data?.pages.flatMap(({ item_list }) => item_list).flat() || [];
  const has_sub_categories = sub_categories && sub_categories.length > 1;
  const handleShowGroupClick = (group_info: Omit<GroupInfo, 'refetch'>) => {
    if (onShowGroupClick && items.length) {
      onShowGroupClick({
        ...group_info,
        refetch,
      });
    }
  };
  const handleSelectChip = (chip_name: string, value: PartnerCenterNotification2ndCategory | '') => {
    pbl({
      navigation: 'partnercenter_notification_inbox',
      category: 'click',
      object_id: 'category',
      object_section: ALERT_BOX_TAB_NAME[`TAB_${category}`],
      extra: JSON.stringify({ category: chip_name }),
    });
    setSelectedChip(value);
  };

  return (
    <>
      {has_sub_categories && (
        <HStack alignment='center' spacing={8} p='10px 16px'>
          {sub_categories.map(({ label, value }) => (
            <SelectChip
              key={value}
              label={label}
              selected={selected_chip === value}
              onClick={() => handleSelectChip(label, value)}
            />
          ))}
        </HStack>
      )}
      <ModalBody>
        {isLoading ? (
          <DeferredComponent>
            <SkeletonCardList {...(has_sub_categories && { style: { paddingTop: 0 } })} />
          </DeferredComponent>
        ) : items.length ? (
          <ScrollArea>
            <InfiniteScroll
              loadMore={() => !isFetching && fetchNextPage()}
              hasMore={hasNextPage}
              useWindow={false}
              threshold={500}
              loader={<LoadingBox key={0} />}
            >
              <CardList {...(has_sub_categories && { style: { paddingTop: 0 } })}>
                {items?.map((item) => (
                  <NotificationCard
                    key={item.id}
                    item={item}
                    onClick={onCardClick}
                    onShowGroupClick={handleShowGroupClick}
                    pbl_section={pbl_section}
                  />
                ))}
              </CardList>
              {!hasNextPage && <NoMoreData getScrollParent={() => scroll_area_ref.current} />}
            </InfiniteScroll>
          </ScrollArea>
        ) : (
          <ScrollArea nodata>
            <NoAlertData error={error} refetch={refetch} />
          </ScrollArea>
        )}
      </ModalBody>
    </>
  );
};
