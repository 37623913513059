import { HStack, BaseText, BaseTextProps, Badge, TextButton, VStack } from '@croquiscom/pds';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSendDailyReportPbl } from '../hooks/useSendDailyReportPbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { scrollTop } from '@/utils/scrollTop';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

interface Props {
  Icon: React.ComponentType<{ size: number }>;
  children: React.ReactNode;
  is_partner_plus_only?: boolean;
  is_partner_plus?: boolean;
  color?: BaseTextProps['color'];
  link?: string;
  description?: string;
  main_domain?: string;
  onClickLink?: () => void;
  object_section?: string;
}

const SectionTitle: React.FC<Props> = ({
  Icon,
  children,
  color,
  is_partner_plus_only,
  is_partner_plus,
  link,
  description,
  main_domain,
  onClickLink,
  object_section,
}) => {
  const history = useHistory();
  const prefix = useShopPrefix();
  const shop_prefix = main_domain ? `/shop/${main_domain}${prefix}` : prefix;
  const { t } = useTranslation('main_frame');
  const { sendImpression, sendClick } = useSendDailyReportPbl(is_partner_plus);
  const { targetRef, isRevealed } = useOnceReveal();

  useEffect(() => {
    if (isRevealed && object_section) {
      sendImpression({ object_id: 'report_impression', object_section });
    }
  }, [isRevealed]);

  return (
    <VStack spacing={6} ref={targetRef}>
      <HStack spacing={4} alignment='center'>
        <Icon size={18} />
        <BaseText kind='Heading_17_Bold' color={color}>
          {children}
        </BaseText>
        {is_partner_plus_only && (
          <Badge color='blue' size='small'>
            {t('플러스 전용')}
          </Badge>
        )}
        {link && (
          <HStack ml='auto'>
            <TextButton
              underline
              size='small'
              onClick={() => {
                object_section &&
                  sendClick({
                    object_id: 'view_detail',
                    object_section,
                  });
                scrollTop();
                onClickLink?.();
                history.push(`${shop_prefix}${link}`);
              }}
            >
              {t('상세보기')}
            </TextButton>
          </HStack>
        )}
      </HStack>
      <BaseText kind='Body_14_Regular'>{description}</BaseText>
    </VStack>
  );
};

export default SectionTitle;
