import { BaseText, Button, Confirm, semantic_colors, VStack } from '@croquiscom/pds';
import React from 'react';
import { useQueryClient } from 'react-query';
import { stopPriceActiveFail, stopPriceActiveSucess } from '../alert_templates';
import { BOOSTING_PRODUCT_LIST_QUERY_KEY } from '../constants';
import { ACTION_STATE } from '../utils/getBoostingPriceState';
import { usePbl } from '@/hooks/usePbl';
import { ShopProductApplyingBestPriceInfo, useStopBulkShopProductApplyingBestPriceMutation } from 'api';

interface BoostingCancelButtonProps {
  applying_best_price_info: ShopProductApplyingBestPriceInfo;
}

const BoostingCancelButton: React.FC<BoostingCancelButtonProps> = ({ applying_best_price_info }) => {
  const client = useQueryClient();
  const disabled = !applying_best_price_info?.id || !applying_best_price_info?.date_started;
  const loading = applying_best_price_info && applying_best_price_info.action_state !== ACTION_STATE.NORMAL;
  const { pbl } = usePbl();
  const { mutate, isLoading } = useStopBulkShopProductApplyingBestPriceMutation({
    onSuccess() {
      stopPriceActiveSucess({ onClose: () => client.invalidateQueries(BOOSTING_PRODUCT_LIST_QUERY_KEY) });
    },
    onError(error?: { message?: string }) {
      stopPriceActiveFail({ message: error?.message });
    },
  });
  return (
    <VStack spacing={4}>
      {!disabled && <BaseText>추천가 적용 중</BaseText>}
      {applying_best_price_info && applying_best_price_info.action_state !== ACTION_STATE.NORMAL && (
        <BaseText color={semantic_colors.content.disabled}>추천가 적용 중지 중</BaseText>
      )}
      <Button
        kind='outlined_negative'
        size='xsmall'
        disabled={disabled}
        loading={loading || isLoading}
        onClick={() => {
          if (applying_best_price_info?.id) {
            Confirm({
              title: '추천가 적용을 중지할까요?',
              confirmText: '중지',
              confirmButtonProps: { kind: 'negative' },
              onConfirm() {
                pbl({
                  navigation: 'price_optimization',
                  category: 'click',
                  navigation_sub: {
                    page: 'active',
                  },
                  object_section: 'price_cancel',
                });
                mutate({ input: { shop_product_applying_best_price_id_list: [applying_best_price_info.id] } });
              },
            });
          }
        }}
      >
        추천가 적용 중지하기
      </Button>
    </VStack>
  );
};

export default BoostingCancelButton;
