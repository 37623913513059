import React, { useLayoutEffect, useMemo } from 'react';
import { SellerResource } from '@/api';
import { useLocation, useHistory } from '@/components/route/LegacyRouterCompat';

import { useInfo } from '@/hooks/useInfo';

export interface ResourceCheckerProps {
  resources?: SellerResource | SellerResource[] | null | undefined;
  is_brand_allowed?: boolean;
  children: React.ReactNode;
}

export function ResourceChecker(props: ResourceCheckerProps): React.ReactElement | null {
  const { resources, children } = props;
  const { info, isAllowed } = useInfo();
  const location = useLocation();
  const history = useHistory();
  const is_resource_allowed = useMemo(() => {
    if (resources == null) return true;
    return isAllowed(resources);
  }, [resources, isAllowed]);

  useLayoutEffect(() => {
    if (info.needs_update_payment_and_tax_info && !['/extra_shop_info', '/smart_bill'].includes(location.pathname)) {
      history.push('/extra_shop_info');
    } else if (!is_resource_allowed) {
      history.push('/home');
    }
  }, [location, info, is_resource_allowed]);

  if (!is_resource_allowed) {
    return null;
  }

  return <>{children}</>;
}
