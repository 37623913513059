import { LineTab, LineTabs, PageHeader, SectionTitle } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ZIGZIN_FEE_PROMOTION_TABS, ZigzinFeePromotionTabId } from './constant';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { WarehousedInUrl } from 'rui/components/URL';

interface PromotionPageHeaderProps {
  current_tab: ZigzinFeePromotionTabId;
}

const PromotionPageHeader: React.FC<PromotionPageHeaderProps> = ({ current_tab }) => {
  const { t } = useTranslation('zigzin');
  const { info } = useInfo();
  const history = useHistory();
  const shop_prefix = useShopPrefix();

  const is_allow_fee_promotion = info.is_fulfillment_promotion_target || info.is_fulfillment_promotion_vip_target;

  const handleChangeTabId = (tab_id: ZigzinFeePromotionTabId) => {
    history.push(
      `${shop_prefix}${
        tab_id === 'zigzin_outlet' ? WarehousedInUrl.ZIGZIN_OUTLET_PROMOTION : WarehousedInUrl.ZIGZIN_FEE_DISCOUNT
      }`,
    );
  };

  return (
    <>
      <PageHeader border={false}>
        <SectionTitle title={t('물류 프로모션')} mb={0} />
      </PageHeader>
      <LineTabs activeTabId={current_tab} onChange={handleChangeTabId}>
        {is_allow_fee_promotion ? (
          ZIGZIN_FEE_PROMOTION_TABS.map((tab) => (
            <LineTab key={tab.value} id={tab.value}>
              {tab.label()}
            </LineTab>
          ))
        ) : (
          <LineTab id={'zigzin_outlet'}>{t('직진아울렛 프로모션')}</LineTab>
        )}
      </LineTabs>
    </>
  );
};

export default PromotionPageHeader;
