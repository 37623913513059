import { BaseText, colors, IconArrowChevronDown, IconX, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import React, { useMemo } from 'react';
import { help_popup_atom } from './atom';
import Divider from 'rui/pastel/unstable/Divider';
import { Portal } from 'rui/pastel/unstable/portal';

export const HelpPopup = () => {
  const [help_popup, setHelpPopup] = useAtom(help_popup_atom);
  const { contents, current_index, opened } = help_popup;
  const {
    title: current_title = '',
    contents: current_contents = [],
    description: current_description = '',
  } = useMemo(() => {
    const content = contents[current_index];
    return content ?? contents[0] ?? {};
  }, [help_popup]);

  const handleTabClick = (current_index: number) => {
    setHelpPopup({
      ...help_popup,
      current_index,
    });
  };

  const handlePrevClick = () => {
    const prev_index = help_popup.current_index;
    setHelpPopup({
      ...help_popup,
      current_index: prev_index === 0 ? prev_index : prev_index - 1,
    });
  };

  const handleNextClick = () => {
    const prev_index = help_popup.current_index;
    setHelpPopup({
      ...help_popup,
      current_index: prev_index === help_popup.contents.length - 1 ? prev_index : prev_index + 1,
    });
  };

  const handleCloseClick = () => {
    setHelpPopup({
      ...help_popup,
      opened: false,
      current_index: 0,
    });
  };

  return (
    <>
      {opened && (
        <Portal>
          <SC.Container>
            <SC.Header>
              <BaseText kind='Body_14_SemiBold' color={colors.white}>
                이 페이지의 도움말
              </BaseText>
              <SC.CloseButton onClick={handleCloseClick} type='button'>
                <IconX color={colors.white} size={16} />
              </SC.CloseButton>
            </SC.Header>
            <SC.TabContainer>
              <SC.Tab>
                {contents.map(({ title }, index) => (
                  <SC.TabItem
                    key={index}
                    ref={(ref) => {
                      if (ref && index === current_index) {
                        ref.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                      }
                    }}
                    current={index === current_index}
                    tabIndex={0}
                    onClick={() => handleTabClick(index)}
                  >
                    {title}
                  </SC.TabItem>
                ))}
              </SC.Tab>
              <SC.Blur />
              <SC.TabController>
                <SC.TabControlButton onClick={handlePrevClick}>
                  <SC.ArrowIcon direction='left' color={colors.gray500} />
                </SC.TabControlButton>
                <SC.TabControlButton onClick={handleNextClick}>
                  <SC.ArrowIcon direction='right' color={colors.gray500} />
                </SC.TabControlButton>
              </SC.TabController>
            </SC.TabContainer>
            <SC.Body>
              <BaseText kind='Heading_18_Bold'>{current_title}</BaseText>
              <Divider color={colors.gray300} size={20} />
              {current_description && <SC.Description>{current_description}</SC.Description>}
              {(current_contents?.length ?? 0) > 0 && (
                <SC.ContentList>
                  {current_contents?.map((content, index) => <SC.ContentItem key={index}>{content}</SC.ContentItem>)}
                </SC.ContentList>
              )}
            </SC.Body>
          </SC.Container>
        </Portal>
      )}
    </>
  );
};

const SC = {
  Container: styled.div`
    position: fixed;
    top: 40px;
    right: 30px;
    width: 350px;
    height: 629px;
    max-height: calc(100% - 80px);
    overflow: hidden;
    border-radius: 4px;
    background-color: ${colors.gray100};
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  `,
  Header: styled.div`
    display: flex;
    align-item: center;
    padding: 8px 0 8px 15px;
    background-color: ${colors.gray900};
  `,
  CloseButton: styled.button`
    margin-left: auto;
    margin-right: 9px;
    width: 24px;
    height: 24px;
    background: none;
    border: 0;
    cursor: pointer;
  `,
  TabContainer: styled.div`
    position: relative;
    border-bottom: 1px solid ${colors.gray300};
    background-color: ${colors.white};
  `,
  Tab: styled.div`
    display: inline-flex;
    width: calc(100% - 78px);
    margin: 20px 64px 20px 12px;
    overflow-x: scroll;
    white-space: nowrap;
    ${text_styles.Body_13_SemiBold};
    line-height: 14px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  TabItem: styled.div<{ current: boolean }>`
    cursor: pointer;
    padding: 0 8px;
    color: ${({ current }) => (current ? colors.zigzag_pink500 : colors.gray700)};

    &:not(:first-child) {
      border-left: 1px solid ${colors.gray350};
    }
  `,
  Blur: styled.div`
    width: 2px;
    height: 16px;
    position: absolute;
    top: 19px;
    right: 63px;
    background: rgba(0, 0, 0, 0.3);
    filter: blur(4px);
  `,
  TabController: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px 10px;
    display: flex;
    background-color: ${colors.white};
  `,
  TabControlButton: styled.button`
    width: 22px;
    height: 22px;
    border: 0;
    cursor: pointer;
    background-color: ${colors.white};
  `,
  ArrowIcon: styled(IconArrowChevronDown)<{ direction: 'left' | 'right' }>`
    transform: ${({ direction }) => (direction === 'left' ? `rotate(90deg)` : `rotate(-90deg)`)};
  `,
  Body: styled.div`
    padding: 20px;
    height: calc(100% - 96px);
    overflow-y: auto;

    .underline {
      text-decoration: underline;
    }
  `,
  Description: styled.div`
    ${text_styles.Body_13_Regular};
    strong {
      font-weight: 600;
    }
    ul {
      padding-left: 18px;
      list-style-type: disc;
    }
    li {
      margin-top: 8px;
    }
  `,
  ContentList: styled.ul`
    padding-left: 18px;
    list-style-type: disc;
    ${text_styles.Body_13_Regular};
  `,
  ContentItem: styled.li`
    &:not(:first-child) {
      margin-top: 8px;
    }

    strong {
      font-weight: 600;
    }
    ul {
      padding-left: 10px;
      list-style-type: '- ';
    }
  `,
};
