import { VStack, BaseText, colors, Button } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSendDailyReportPbl } from './hooks/useSendDailyReportPbl';
import NotificationReceiverModal from './NotificationReceiverModal';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useAccount } from '@/hooks/useAccount';
import { useModalState } from 'rui/pastel/unstable/modal';

const Disclaimer: React.FC = () => {
  const { is_agency } = useAccount();
  const { t } = useTranslation('main_frame');
  const [{ isOpen, onOpen, onClose }] = useModalState(false);
  const { is_partner_plus } = useGetPlan();
  const { sendClick } = useSendDailyReportPbl(is_partner_plus);

  return (
    <Container spacing={8} alignment='leading'>
      <VStack spacing={2}>
        <BaseText kind='Heading_15_Bold' color={colors.blue700}>
          {t('주목해야 할 변화, 매일 오전 모바일로 받아보세요!')}
        </BaseText>
        <BaseText kind='Body_12_Medium' color={colors.blue700}>
          <Trans t={t}>
            * 수신자 설정을 꼭 완료해주세요.
            <br />* 파트너플러스 구독사는 [파트너플러스 인사이트] 요약까지 확인할 수 있어요.
          </Trans>
        </BaseText>
      </VStack>
      {!is_agency && (
        <>
          <Button
            kind='primary'
            size='small'
            onClick={() => {
              sendClick({
                object_id: 'manage_receivers',
              });
              onOpen();
            }}
          >
            {t('데일리 리포트 수신자 설정')}
          </Button>
          <NotificationReceiverModal opened={isOpen} onCancel={onClose} onConfirm={onClose} />
        </>
      )}
    </Container>
  );
};

export default Disclaimer;

const Container = styled(VStack)`
  padding: 16px;
  background-color: ${colors.blue50};
`;
