import { semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { SubMenuItem } from './SubMenuItem';
import { SubMenuEntry } from './types';
import { isApp } from '@/utils/app_utils/conditions';

export interface SubMenuListProps {
  entries: SubMenuEntry[];
  selected_id?: string | null;
  onClick?: (entry: SubMenuEntry) => void;
}

export const SubMenuList: React.FC<SubMenuListProps> = ({ entries, selected_id, onClick }) => {
  return (
    <StyledMenuList>
      {entries
        .filter((entry) => entry.is_allowed !== false)
        .filter((entry) => (isApp() ? entry.is_support_app : true))
        .map((entry) => (
          <SubMenuItem key={entry.id} entry={entry} onClick={onClick} is_selected={selected_id === entry.id} />
        ))}
    </StyledMenuList>
  );
};

const StyledMenuList = styled.div`
  padding: 10px 8px;
  background-color: ${semantic_colors.background.surface};
`;
