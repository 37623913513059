import { useQuery } from 'react-query';
import {
  api_getDailyReportSalesConversion,
  api_getMetaDailyReportSalesConversion,
  api_getMetaDailyReportSalesConversionBlur,
  GetDailyReportSalesConversionQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportSalesConversionQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportSalesConversion = ({
  date_ymd,
  use_free_view_count,
  main_domain = '',
  token = '',
}: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportSalesConversion', { date_ymd, use_free_view_count, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportSalesConversion({ date_ymd, use_free_view_count }, { no_alert: true })
        : use_free_view_count
          ? await api_getMetaDailyReportSalesConversion(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            )
          : await api_getMetaDailyReportSalesConversionBlur(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
