import { semantic_colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState, useEffect } from 'react';
import { AnimatedAccordion } from './AnimatedAccordion';
import { MenuChildItem } from './MenuChildItem';
import { MenuParentItemButton } from './MenuParentItemButton';
import { MenuEntry } from './types';

export interface MenuParentItemProps {
  entry: MenuEntry;
  selected_id: string | null;
  is_open?: boolean;
  is_searched?: boolean;
  onOpenChange?: (entry: MenuEntry | null) => void;
  onClick?: (entry: MenuEntry) => void;
}

export const MenuParentItem: React.FC<MenuParentItemProps> = ({
  entry,
  selected_id,
  is_open: is_open_prop,
  is_searched,
  onClick,
}) => {
  const [is_open, setOpen] = useState(false);
  useEffect(() => {
    if (is_open_prop) {
      setOpen(is_open_prop);
    }
  }, [is_open_prop]);
  const handleClick = useCallback(() => {
    entry.onClick?.();
    onClick?.(entry);
    if (entry.items != null) {
      setOpen(!is_open);
    }
  }, [entry, onClick, is_open]);
  return (
    <StyledMenuContainer is_searched={is_searched}>
      <StyledMenuParentItemButton entry={entry} onClick={handleClick} is_selected={is_open} is_open={is_open} />
      {entry.items != null && (
        <AnimatedAccordion is_open={is_open ?? false}>
          <StyledMenuChildrenList>
            {entry.items.map((child) => (
              <MenuChildItem entry={child} onClick={onClick} key={child.id} selected_id={selected_id} />
            ))}
          </StyledMenuChildrenList>
        </AnimatedAccordion>
      )}
    </StyledMenuContainer>
  );
};

const StyledMenuParentItemButton = styled(MenuParentItemButton)<{ is_open?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 0px solid ${semantic_colors.border.default};
  transition:
    0.1s background-color linear,
    0.1s color linear,
    0s border-bottom-width linear 0.2s;
  ${({ is_open }) =>
    is_open &&
    css`
      border-bottom-width: 1px;
      transition:
        0.1s background-color linear,
        0.1s color linear;
    `}
`;

const StyledMenuContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'is_searched' })<{
  is_searched?: boolean;
}>`
  ${({ is_searched }) =>
    !is_searched &&
    `
      margin: 0 0 8px;
      &:last-of-type {
        margin: 0;
      }
    `}
`;

const StyledMenuChildrenList = styled.div`
  background-color: ${semantic_colors.background.disabled};
  border-bottom: 1px solid ${semantic_colors.border.default};
  padding: 14px 8px;
`;
