import styled from '@emotion/styled';
import React, { forwardRef, useMemo } from 'react';
import { TableNoData } from './TableNoData';
import { TableTh } from './TableTh';
import { defaultLine } from 'styles';

interface SubLabel {
  id: string;
  width?: number;
  text: React.ReactNode;
  content?: React.ReactNode;
  styles?: React.CSSProperties;
  sortable?: boolean;
}

export interface TableLabel {
  id: string;
  width?: number;
  text: React.ReactNode;
  content?: React.ReactNode;
  sortable?: boolean;
  styles?: React.CSSProperties;
  subLabels?: SubLabel[];
  fixed_column?: boolean;
  column_position?: number;
}

export interface TableSortProps {
  sortBy?: string;
  sortDirection?: string;
  onSelectSortBy?: (sortBy: string) => void;
}

interface TableProps extends TableSortProps {
  labels: TableLabel[];
  prevRows?: React.ReactNode;
  rows: React.ReactNode[];
  loading?: boolean;
  fixedWidth?: number;
  className?: string;
  emptyIcon?: React.ReactNode;
  emptyText?: React.ReactNode;
  emptyFixedHeight?: number;
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      labels,
      prevRows = null,
      rows,
      loading = false,
      sortBy,
      sortDirection,
      onSelectSortBy,
      fixedWidth,
      className,
      emptyIcon,
      emptyText,
      emptyFixedHeight,
    },
    ref,
  ) => {
    const cols = useMemo(
      () =>
        labels.reduce((accumulator, { id, width, subLabels }) => {
          return [
            ...accumulator,
            ...(subLabels && subLabels.length > 0
              ? subLabels.map((subLabel) => ({
                  id: subLabel.id,
                  width: subLabel.width ?? (width ? width / subLabels.length : undefined),
                }))
              : [{ id, width }]),
          ];
        }, []),
      [labels],
    );

    const subRows = useMemo(
      () =>
        labels.reduce((accumulator, { subLabels }) => {
          if ((subLabels?.length ?? 0) > 0) {
            return [...accumulator, ...(subLabels ?? [])];
          }
          return accumulator;
        }, []),
      [labels],
    );

    const hasSubRows = subRows.length > 0;

    return (
      <>
        <StyledTable ref={ref} fixedWidth={fixedWidth} className={className}>
          <colgroup>
            {cols.map(({ id, width }) => (
              <col key={id} span={1} style={width ? { width } : {}} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {labels.map(
                ({ id, text, content, width, styles, sortable, subLabels, fixed_column, column_position }) => (
                  <TableTh
                    key={id}
                    width={width}
                    fixed_column={fixed_column}
                    column_position={column_position}
                    onClick={() => {
                      onSelectSortBy && sortable && onSelectSortBy(id);
                    }}
                    text={text}
                    content={content}
                    sorted={sortBy === id}
                    sort_direction={sortDirection}
                    sortable={sortable}
                    styles={styles}
                    {...(hasSubRows &&
                      ((subLabels?.length ?? 0) > 0 ? { col_span: subLabels?.length } : { row_span: 2 }))}
                  />
                ),
              )}
            </tr>
            {hasSubRows && (
              <tr>
                {subRows.map(({ id, text, width, content, styles, sortable }) => (
                  <TableTh
                    key={id}
                    onClick={() => {
                      onSelectSortBy && sortable && onSelectSortBy(id);
                    }}
                    text={text}
                    content={content}
                    sorted={sortBy === id}
                    sort_direction={sortDirection}
                    sortable={sortable}
                    styles={width ? { ...styles, width } : styles}
                  />
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {rows.length !== 0 && prevRows}
            {!loading && rows}
          </tbody>
        </StyledTable>
        {(loading || rows.length === 0) && (
          <TableNoData loading={loading} height={emptyFixedHeight} icon={emptyIcon} text={emptyText} />
        )}
      </>
    );
  },
);

export const StyledTable = styled.table<{ fixedWidth?: number }>`
  position: relative;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-left: ${defaultLine};
  ${(props) =>
    props.fixedWidth &&
    `
    width: ${props.fixedWidth}px;
    table-layout: fixed;
  `}

  tbody {
    font-size: 13px;
  }

  thead > tr:nth-of-type(2) {
    & > th {
      top: 40px;
      border-top: 0px;

      &:first-of-type {
        border-left: 0px;
      }
    }
  }

  td {
    position: relative;
    padding: 0;
    height: 74px;
    vertical-align: top;
    border: ${defaultLine};
    border-left: 0px;
    border-top: 0px;
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .number-cell,
  .text-cell {
    height: 100%;
    padding: 13px 14px;
    line-height: 14px;
    font-size: 13px;
    letter-spacing: -0.36px;
  }
  .text-cell {
    text-align: left;
  }
  .number-cell {
    text-align: right;
  }
`;

export default Table;
