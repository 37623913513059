import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

interface HoverIconProps {
  className?: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

const HoverIcon: React.FC<HoverIconProps> = ({ className, icon, content }) => {
  const [hovered, setHovered] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const timeOutId = useRef<NodeJS.Timeout | undefined>(undefined);

  const popperRef = useRef(null);

  const { styles, attributes, state } = usePopper(popperRef.current, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });

  return (
    <StyledHoverIcon hovered={hovered} className={classNames('hover-icon', className)}>
      <div
        className='hover-icon__icon'
        ref={popperRef}
        onMouseEnter={() => {
          if (timeOutId.current) {
            clearTimeout(timeOutId.current);
          }
          setHovered(true);
        }}
        onMouseLeave={() => {
          if (timeOutId.current) {
            clearTimeout(timeOutId.current);
          }
          timeOutId.current = setTimeout(() => {
            setHovered(false);
          }, 250);
        }}
      >
        {icon}
      </div>
      {hovered &&
        createPortal(
          <StyledTooltip
            ref={setPopperElement}
            style={{ ...styles.popper, ...{ zIndex: 1000 } }}
            {...attributes.popper}
            onMouseEnter={() => {
              if (timeOutId.current) {
                clearTimeout(timeOutId.current);
              }
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >
            {content}
            <div id='arrow' className={state?.placement} ref={setArrowElement} style={styles.arrow} />
          </StyledTooltip>,
          document.body,
        )}
    </StyledHoverIcon>
  );
};
const StyledHoverIcon = styled.div<{ hovered: boolean }>`
  position: relative;

  .hover-icon__icon {
    display: flex;
    align-items: center;
  }
`;

const StyledTooltip = styled.div`
  border: solid 1px ${colors.gray900};
  background: ${colors.white};
  z-index: 1;
  #arrow,
  #arrow::before {
    position: absolute;
    width: 12px;
    height: 12px;
    background: inherit;
  }

  #arrow {
    visibility: hidden;
  }

  #arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    border-right: solid 1px ${colors.gray900};
    border-bottom: solid 1px ${colors.gray900};
    background: ${colors.white};
  }
  &[data-popper-placement^='top'] > #arrow {
    bottom: -6px;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -6px;
  }
  &[data-popper-placement^='bottom'] > #arrow::before {
    transform: rotate(-135deg);
  }

  &[data-popper-placement^='left'] > #arrow {
    right: -6px;
  }
  &[data-popper-placement^='left'] > #arrow::before {
    transform: rotate(-45deg);
  }

  &[data-popper-placement^='right'] > #arrow {
    left: -6px;
  }
  &[data-popper-placement^='right'] > #arrow::before {
    transform: rotate(135deg);
  }
`;

export default HoverIcon;
