import {
  IconBoxFbk,
  IconBoxPosty,
  IconBoxZigzag,
  IconBoxUsa,
  IconBoxJapan,
  IconBoxCanada,
  IconBoxKakaotalkShopping,
} from '@croquiscom/pds';
import React from 'react';
import { ShopSiteCountry } from './constants';
import { Icon } from './styled';

interface ChannelIconProps {
  shop_site: ShopSiteCountry;
}

export const ChannelIcon: React.FC<ChannelIconProps> = ({ shop_site }) => {
  const { site_id, country } = shop_site;
  switch (site_id) {
    case 1:
      if (country === 'US') {
        return <Icon as={IconBoxUsa} />;
      }
      if (country === 'JP') {
        return <Icon as={IconBoxJapan} />;
      }
      if (country === 'CA') {
        return <Icon as={IconBoxCanada} />;
      }
      return <Icon as={IconBoxZigzag} />;
    case 2:
      return <Icon as={IconBoxPosty} />;
    case 3:
      return <Icon as={IconBoxFbk} />;
    case 7:
      return <Icon as={IconBoxKakaotalkShopping} />;
    default:
      return null;
  }
};
