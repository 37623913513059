import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router'; // LegacyCompat 변환 X

import { useAccount } from '@/hooks/useAccount';

export interface LoginCheckerProps {
  isRequired: boolean;
  children: React.ReactNode;
}

export function LoginChecker(props: LoginCheckerProps): React.ReactElement | null {
  const { isRequired, children } = props;
  const { is_logined } = useAccount();
  const history = useHistory();
  useLayoutEffect(() => {
    if (isRequired && !is_logined) {
      const search = history.location.search;
      const redirectUri =
        new URLSearchParams(search).get('redirect_uri') ??
        encodeURIComponent(history.location.pathname + history.location.search + history.location.hash);
      const is_allowed_redirect = !['/alert_box', '/chatbot'].some((path) => history.location.pathname?.includes(path));
      history.push(`/login${redirectUri && is_allowed_redirect ? `?redirect_uri=${redirectUri}` : ''}`);
    }
  }, [isRequired, is_logined, history]);

  if (isRequired && !is_logined) {
    return null;
  }

  return <>{children}</>;
}
