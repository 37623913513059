import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AlertItemType } from '../types';
import { checkAlertDismiss, setAlertDismiss } from '../utils';
import { SellerResource, ShopSolution, ShopSyncStatus, api_getAlertList } from '@/api';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { SHOP_SOLUTION_NAME } from 'components/info/shop_sync/constants';
import { PartnerPlusNavUrl } from 'rui/components/URL';

export const GET_ALERT_LIST_QUERY_KEY = 'getAlertList';

export function useGetAlertList() {
  const { info, isAllowed } = useInfo();
  const { is_mobile } = useMediaQuery();

  const { data, error, refetch } = useQuery(
    [GET_ALERT_LIST_QUERY_KEY],
    async () => api_getAlertList({}, { no_alert: true }),
    {
      select: ({ data }) => data,
      onError: () => {},
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 60000,
      refetchInterval: 5 * 60 * 1000,
    },
  );

  const shopScorePanelty: AlertItemType | null = useMemo(
    () =>
      info?.is_penalty_state
        ? {
            title: '판매자서비스등급 페널티 상태를 해제하세요.',
            description: '스토어 및 스토어 전상품이 미노출됩니다.',
            link: '/shop_score/penalty_release',
            important: true,
          }
        : null,
    [info],
  );

  const shopSyncStatus: AlertItemType | null = useMemo(() => {
    const { shop_sync } = data ?? {};
    if (!shop_sync) return null;

    const hasError = shop_sync.error !== null && shop_sync.error !== undefined;
    const isActivated = shop_sync.activated;
    const isApproved = [
      ShopSyncStatus.APPROVED,
      ShopSyncStatus.APPROVED_AND_AWAITING_REVIEW,
      ShopSyncStatus.APPROVED_AND_REJECTED,
    ].includes(shop_sync.status);
    const isAppDeleted = shop_sync.status === ShopSyncStatus.APP_DELETED;

    if ((isApproved && isActivated && hasError) || isAppDeleted) {
      return {
        title: is_mobile
          ? '상품 연동 중단 알림'
          : `${SHOP_SOLUTION_NAME[shop_sync.solution]} 상품 연동이 중단되었어요.`,
        description: `상품 연동이 중단되어 자사몰에서 판매가, 재고를 포함한 상품정보가 변경되어도 카카오스타일에 반영되지 않으며 신규 등록 상품도 연동되지 않습니다. ${
          shop_sync.solution === ShopSolution.CAFE24 ? '카페24 연동앱을 재설치해 주세요.' : '연동키를 재설치해 주세요.'
        }`,
        link: '/info/shop_sync',
        important: true,
      };
    }
    return null;
  }, [data]);

  const customerExtension: AlertItemType | null = useMemo(() => {
    const { getCustomerExpansionStateOfShop: customer_extension_state } = data ?? {};
    if (!customer_extension_state?.allotment_state && !customer_extension_state?.period_allotment_state) {
      return null;
    }
    if (customer_extension_state?.allotment_state) {
      return {
        title: '고객 확장프로그램 분담 약정에 동의해 주세요.',
        description: '프로그램 진행을 위해 분담률 약정 동의가 필요합니다.',
        link: '/seller_support_program/customer_extension#allotment',
        important: true,
      };
    } else {
      return {
        title: '고객 확장 프로그램 기간제 분담률 적용에 대한 동의가 필요해요.',
        description: '',
        link: '/seller_support_program/customer_extension#period-allotment',
        important: true,
      };
    }
  }, [data]);

  const metaContract: AlertItemType | null = useMemo(() => {
    const { getShopMetaInternalContractListOfShop: meta_contract } = data ?? {};

    const access_allowed = isAllowed([SellerResource.ALL]);
    if (!access_allowed) return null;
    const current_meta_contract = meta_contract?.item_list?.[0];
    if (!current_meta_contract) return null;

    return {
      title: '참여한 기획전의 쿠폰 분담율 승인이 필요해요.',
      description: '기간 내 미동의시 쿠폰 발급이 불가능합니다.',
      extra_text:
        meta_contract.total_count > 1
          ? `${current_meta_contract.title} 외 ${meta_contract.total_count - 1}건`
          : current_meta_contract.title,
      link: '/shop_internal_contract?is_pending=1',
      important: true,
    };
  }, [data]);

  const contract: AlertItemType | null = useMemo(() => {
    const { getSellerNotificationQueue: notification_queue } = data ?? {};

    const access_allowed = isAllowed([SellerResource.ALL]);
    if (!access_allowed) return null;
    const current_shop_contract = notification_queue?.[0];
    if (!current_shop_contract) return null;

    const url = '/shop_contract?is_unsigned=1';
    return current_shop_contract.shop_contract_id
      ? {
          title: '기한 내 계약 확인 및 서명이 필요해요.',
          description: '체결 기간이 지나기 전에 계약 내용을 검토하고 서명해주세요.',
          extra_text: `계약번호 : ${current_shop_contract.shop_contract_id}`,
          link: `${url}&id=${current_shop_contract.shop_contract_id}`,
          important: true,
        }
      : {
          title: '기한 내 계약 확인 및 서명이 필요해요.',
          description: '여러건의 계약이 대기중입니다. 체결 기간이 지나기 전에 계약 내용을 검토하고 서명해주세요.',
          link: url,
          important: true,
        };
  }, [data]);

  const subscription: AlertItemType | null = useMemo(() => {
    const { shop_subscription } = data ?? {};

    if (shop_subscription?.current_payment_status !== 'FAILED') return null;
    if (!['SUBSCRIBED', 'CANCELLING'].includes(shop_subscription.status)) return null;
    const access_allowed = isAllowed([
      SellerResource.SALES_INSIGHT_READ,
      SellerResource.KEYWORD_INSIGHT_READ,
      SellerResource.CUSTOMER_INSIGHT_READ,
      SellerResource.COMPETITOR_INSIGHT_READ,
    ]);
    if (!access_allowed) return null;
    return {
      title: '파트너플러스 구독료 결제가 실패했어요.',
      description: '파트너플러스 인사이트 이용이 불가능합니다. 결제 정보를 확인하세요.',
      extra_text: `결제 실패 구독료 ${shop_subscription.price.toLocaleString()}원`,
      link: PartnerPlusNavUrl.MANAGEMENT_SUBSCRIPTION,
    };
  }, [data]);

  const adBalance: AlertItemType | null = useMemo(() => {
    const { ad_running_shop_info } = data ?? {};
    const notification = ad_running_shop_info?.notification;
    const is_running = Boolean(notification?.alert_min_budget_enabled);

    if (!is_running) return null;
    const access_allowed = isAllowed([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT]);
    if (!access_allowed) return null;
    const wallet = info?.wallet;
    const total_balance =
      (wallet?.cash ?? 0) +
      (wallet?.ad_display_point ?? 0) +
      (wallet?.attention_ad_point || 0) +
      (wallet?.message_point || 0) +
      (wallet?.fbk_ad_point || 0);
    const lack = total_balance <= 0;
    if (!lack) return null;
    return {
      title: '광고비가 모두 소진되었습니다.',
      description: '광고 코인을 충전하고 광고를 원활하게 진행하세요.',
      extra_text: `광고비 잔액 ${total_balance.toLocaleString()}원`,
      link: '/wallet',
      dismiss_key: 'ad_balance_lack',
    };
  }, [data]);

  return {
    data: [shopScorePanelty, shopSyncStatus, customerExtension, metaContract, contract, subscription, adBalance].filter(
      (item: AlertItemType | null) => {
        return item?.dismiss_key ? !checkAlertDismiss(item.dismiss_key) : Boolean(item);
      },
    ) as AlertItemType[],
    error,
    refetch,
    updateDismiss: setAlertDismiss,
  };
}
