import { SITE_ID_ZIGZAG } from '../attention/constants';
import { useGetAdCenterShopVipPredictionGradeQuery, useGetAdRunningShopInfoQuery } from '@/api';
import { useInfo } from '@/hooks/useInfo';
import { ShopType } from '@/types/info';

export enum AD_GRADE {
  PLATINUM = '플래티넘',
  GOLD = '골드',
  ROYAL = '로얄',
}

export enum AdGradeScore {
  PLATINUM = 3,
  GOLD = 2,
  ROYAL = 1,
}

export const useGetCanProceedAd = () => {
  const { info } = useInfo();
  return info.can_proceed_ad || false;
};

export const useGetIsZpayShop = () => {
  const { info } = useInfo();
  return (info.type && info.type === ShopType.ZPAY) || false;
};

export const useGetAdGradeInfo = (site_id: string = SITE_ID_ZIGZAG) => {
  const { data: vip_predction_grade_data } = useGetAdCenterShopVipPredictionGradeQuery({ site_id });
  const { data: running_shop_info } = useGetAdRunningShopInfoQuery();

  const { zigzag_shop_grade: current } = running_shop_info?.ad_running_shop_info ?? {};
  const { grade: predicated } = vip_predction_grade_data?.ad_center_shop_vip_prediction_grade ?? {};
  const current_shop_grade: string | undefined = current && AD_GRADE[current];
  const predicted_grade: string | undefined = predicated && AD_GRADE[predicated];
  const current_score: number = (current && AdGradeScore[current]) ?? 0;
  const predicted_score: number = (predicated && AdGradeScore[predicated]) ?? 0;

  const diff = predicted_score - current_score;

  return {
    current_shop_grade,
    predicted_grade,
    diff,
  };
};
