import { BaseText, HStack, IconColoredWarningRed, VStack, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import { BOOSTING_PRICE_STATE } from '../utils/getBoostingPriceState';

interface PriceWithShippingFeeProps {
  price: number;
  shipping_fee: number;
  boosting_price_state?: BOOSTING_PRICE_STATE;
}

const PriceWithShippingFee = ({ price, shipping_fee, boosting_price_state }: PriceWithShippingFeeProps) => {
  return (
    <VStack>
      <HStack alignment='center' spacing={4}>
        {boosting_price_state === BOOSTING_PRICE_STATE.NEED_RESET && <IconColoredWarningRed size={14} />}
        <BaseText>{price.toLocaleString()}원</BaseText>
      </HStack>
      <BaseText color={semantic_colors.content.secondary}>
        ({shipping_fee > 0 ? `${shipping_fee.toLocaleString()}원` : '무료배송'})
      </BaseText>
    </VStack>
  );
};

export default PriceWithShippingFee;
