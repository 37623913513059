import React from 'react';
import { Trans } from 'react-i18next';

import { DefaultFunnelData } from './types';
import { Task } from '@/types/onboarding';

export const PRODUCT_REGISTRATION_PATH = {
  SELECT_PRODUCT_REGISTRATION_TYPE: '/catalog/item_list',
  PRODUCT_EXCEL_REGISTRATION: '/excel_upload?tab=catalog',
  PRODUCT_REGISTRATION: '/catalog/item_detail/new',
};

const PRODUCT_REGISTRATION_TASKS: Task[] = [
  {
    task: 'SELECT_PRODUCT_REGISTRATION_TYPE',
    name: '',
    order: -1,
    page_path: PRODUCT_REGISTRATION_PATH.SELECT_PRODUCT_REGISTRATION_TYPE,
    is_completed: true,
    tooltips: [
      {
        id: 'select_product_type',
        message: <Trans ns='onboarding'>버튼을 눌러 상품을 바로 등록해보세요!</Trans>,
        placement: 'top',
      },
    ],
    is_hidden: true,
  },
  {
    task: 'PRODUCT_EXCEL_REGISTRATION',
    name: '',
    order: 0,
    page_path: PRODUCT_REGISTRATION_PATH.PRODUCT_EXCEL_REGISTRATION,
    is_completed: true,
    tooltips: [
      {
        id: 'add_excel_product',
        message: <Trans ns='onboarding'>엑셀 양식을 먼저 다운받아 작성후에 업로드해주세요.</Trans>,
        placement: 'top',
      },
    ],
    is_hidden: true,
  },
  {
    task: 'PRODUCT_REGISTRATION',
    name: <Trans ns='onboarding'>상품 정보 등록</Trans>,
    order: 1,
    page_path: PRODUCT_REGISTRATION_PATH.PRODUCT_REGISTRATION,
    tooltips: [],
    is_completed: false,
  },
];

export const PRODUCT_REGISTRATION_FUNNEL: DefaultFunnelData = {
  step: 'PRODUCT_REGISTRATION',
  name: <Trans ns='onboarding'>상품 등록하기</Trans>,
  description: (is_completed) => {
    const title = !is_completed ? (
      <Trans ns='onboarding'>판매할 상품을 등록해주세요.</Trans>
    ) : (
      <Trans ns='onboarding'>판매할 상품을 등록하였습니다.</Trans>
    );
    const sub_title = !is_completed ? (
      <Trans ns='onboarding'>
        직접 상품 정보를 등록해서 재고를 관리하거나 단독 상품을 판매할 수 있어요.
        <br />
        물론, 스마트스토어 등에서 판매중인 상품 정보를 가져올 수도 있습니다.
      </Trans>
    ) : (
      <Trans ns='onboarding'>
        상품이 노출되고 판매가 가능합니다. 상품 관리 메뉴에서 등록한 상품을 관리할 수 있습니다.
      </Trans>
    );
    return {
      title,
      sub_title,
      tags: [
        {
          type: 'badge',
          label: <Trans ns='onboarding'>추천 콘텐츠</Trans>,
        },
        {
          type: 'link',
          label: <Trans ns='onboarding'>Z결제 할인가 설정</Trans>,
          path: '/catalog/item_list',
        },
        {
          type: 'link',
          label: <Trans ns='onboarding'>상품 쿠폰 등록</Trans>,
          path: '/coupon/add',
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'coupon',
            object_type: 'button',
          },
        },
      ],
      actions: [
        {
          kind: 'primary',
          type: 'link',
          label: <Trans ns='onboarding'>상품 직접 등록하기</Trans>,
          path: PRODUCT_REGISTRATION_PATH.SELECT_PRODUCT_REGISTRATION_TYPE,
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'product_direct',
            object_type: 'button',
          },
        },
        {
          kind: 'secondary',
          type: 'link',
          label: <Trans ns='onboarding'>스마트스토어 연동하기</Trans>,
          path: '/catalog/import_external_catalog',
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'product_solution',
            object_type: 'button',
          },
        },
      ],
      shortcut: null,
      controller: {
        title: <Trans ns='onboarding'>상품 등록</Trans>,
        sub_title: (
          <Trans ns='onboarding'>
            상품에 필요한 정보를 등록해주세요!
            <br />
            등록이 어렵다면 도움말을 확인해주세요.
          </Trans>
        ),
      },
    };
  },
  order: 3,
  is_completed: false,
  date_completed: null,
  in_progress_task: null,
  tasks: [...PRODUCT_REGISTRATION_TASKS],
};
