import { IconLoadingDonuts } from '@croquiscom/pds';
import React from 'react';
import { SATISFACTION_TYPE } from './constants';
import { FeedbackChipButton } from './styled';
import { chat_bot_session } from './utils/session';
import { usePbl } from '@/hooks/usePbl';
import { useCreateShopChatbotFeedbackMessageMutation } from 'api';

interface AnswerFeedbackChipProps {
  message_id: string;
  satisfaction_type?: SATISFACTION_TYPE;
  is_cs_connect: boolean;
  is_no_answer: boolean;
  object_id: string;
  children?: React.ReactNode;
  onSuccess?: () => void;
}

const navigation = 'partner_center_chatbot';

export const AnswerFeedbackChip = ({
  message_id,
  is_cs_connect,
  is_no_answer,
  satisfaction_type,
  object_id,
  children,
  onSuccess,
}: AnswerFeedbackChipProps) => {
  const { pbl } = usePbl();
  const { mutate, isLoading } = useCreateShopChatbotFeedbackMessageMutation({
    onError() {},
    onSuccess() {
      pbl({
        navigation,
        navigation_sub: {
          sub: 'chat',
        },
        object_id,
        category: 'click',
      });
      onSuccess?.();
    },
  });

  return (
    <FeedbackChipButton
      onClick={() => {
        if (isLoading) return;
        if (satisfaction_type) {
          mutate({
            input: {
              session_id: chat_bot_session.getKey(),
              message_id,
              satisfaction_type,
              is_cs_connect,
              is_no_answer,
            },
          });
        } else {
          onSuccess?.();
        }
      }}
    >
      {isLoading ? <IconLoadingDonuts /> : children}
    </FeedbackChipButton>
  );
};
