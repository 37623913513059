import { CONTENT_REGISTRATION_FUNNEL } from './content_registration';
import { ENTRY_COMPLETE_FUNNEL } from './entry_complete';
import { PRODUCT_REGISTRATION_FUNNEL } from './product_registration';
import { STORE_SETTING_FUNNEL } from './store_setting';
import { GenericStatusFn } from './types';
import { Funnel, OnboardingFunnelStep } from '@/types/onboarding';

const DEFAULT_FUNNELS = [
  ENTRY_COMPLETE_FUNNEL,
  STORE_SETTING_FUNNEL,
  PRODUCT_REGISTRATION_FUNNEL,
  CONTENT_REGISTRATION_FUNNEL,
];

export const getDefaultFunnelData = (target_step: OnboardingFunnelStep): GenericStatusFn<Funnel> => {
  const { description, ...rest_funnel } =
    DEFAULT_FUNNELS.find(({ step }) => step === target_step) ?? ENTRY_COMPLETE_FUNNEL;
  return (is_completed) => ({
    ...rest_funnel,
    description: description(is_completed),
  });
};
