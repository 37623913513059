import { useQuery } from 'react-query';
import { PartnerLoungeArticle } from './types';
import { Config } from '@/config';
import axios from '@/utils/axios';

export function useGetPartnerLoungeNewsDailyQuery() {
  return useQuery(['GetPartnerLoungeNewsDaily'], async () => {
    const endpoint =
      Config.alpha || Config.dev
        ? 'https://alpha.partnerlounge.kakaostyle.com'
        : 'https://partnerlounge.kakaostyle.com';
    const res = await axios.get(endpoint + '/api/articles/partner-daily');
    return res.data as { articles: PartnerLoungeArticle[] };
  });
}
