import { BaseText } from '@croquiscom/pds';
import React from 'react';
import UpDownDataBadge from '@/components/common/UpDownDataBadge';

interface Props {
  value: number;
  prefix_text?: React.ReactNode;
  unit?: string;
  josa?: string;
  size?: 'small' | 'medium';
}

const ComparisonBadge: React.FC<Props> = ({ value, prefix_text, unit = '%', josa = '과', size = 'medium' }) => {
  if (value === 0) {
    return (
      <BaseText kind={size === 'small' ? 'Caption_11_SemiBold' : 'Body_12_SemiBold'}>
        {prefix_text}
        {josa} 동일
      </BaseText>
    );
  }
  return <UpDownDataBadge data={value} unit={unit} prefix={`${prefix_text}보다`} size={size} />;
};

export default ComparisonBadge;
