import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { ENTRY_REQUEST_ROUTES } from './entry_request';
import { POLICY_ROUTES } from './policy';

const ChangePasswordPage = lazy(() => import('@/pages/auth/change_password/ChangePasswordPage'));
const CognitoCallbackPage = lazy(() => import('@/pages/auth/CognitoCallback'));
const ErrorPage = lazy(() => import('@/pages/error'));
const HelpPage = lazy(() => import('@/pages/meta/help'));
const MetaHomeMainPage = lazy(() => import('@/pages/meta/home'));
const InvitePage = lazy(() => import('@/pages/meta/invite'));
const LoginPage = lazy(() => import('@/pages/meta/login'));
const Cafe24AppPage = lazy(() => import('@/pages/meta/shop_sync/Cafe24AppPage'));
const Cafe24AppRedirectPage = lazy(() => import('@/pages/meta/shop_sync/Cafe24AppRedirectPage'));
const Cafe24AppRedirectMultiShopPage = lazy(() => import('@/pages/meta/shop_sync/Cafe24AppRedirectMultiShopPage'));
const RedirectShop = lazy(() => import('@/pages/redirect_shop/RedirectShop'));
const ForbiddenPage = lazy(() => import('@/pages/error/Forbidden'));
const ChatBotKakaoConnectShopPage = lazy(() => import('@/pages/meta/chat_bot/kakao_connect_shop'));
const InsightIntroPage = lazy(() => import('@/pages/insight/InsightIntroPage'));
const PartnerPlusIntroPage = lazy(() => import('@/pages/partner_plus/PartnerPlusIntroPage'));
const MetaDailyReportPage = lazy(() => import('@/pages/daily_report/MetaDailyReportPage'));

export const META_ROUTES: RoutesType[] = [
  ...ENTRY_REQUEST_ROUTES,
  ...POLICY_ROUTES,
  {
    current_menu: '',
    path: '/home',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <MetaHomeMainPage />,
  },
  {
    current_menu: '',
    path: '/home/:tab_id',
    params: '',
    component: <MetaHomeMainPage />,
  },
  {
    current_menu: '',
    path: '/login',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    is_fullscreen: true,
    component: <LoginPage />,
  },
  {
    current_menu: '',
    path: '/-chat-bot/kakao/connect-shop',
    params: '',
    is_support_mobile: true,
    is_fullscreen: true,
    component: <ChatBotKakaoConnectShopPage />,
  },
  {
    current_menu: '',
    path: '/:type/invite',
    params: '',
    component: <InvitePage />,
  },
  {
    current_menu: '',
    path: '/errors',
    params: '',
    component: <ErrorPage message='잘못된 접근입니다.' />,
  },
  {
    current_menu: '',
    path: '/forbidden',
    params: '',
    component: <ForbiddenPage />,
  },
  {
    current_menu: '',
    path: '/cognito_callback',
    params: '',
    is_support_app: true,
    component: <CognitoCallbackPage />,
  },
  {
    current_menu: '',
    path: '/help',
    params: '',
    hide_footer: true,
    hide_accountbar: true,
    component: <HelpPage />,
  },
  {
    current_menu: '',
    path: '/ext/cafe24',
    params: '',
    component: <Cafe24AppPage />,
  },
  {
    current_menu: '',
    path: '/ext/cafe24/app_redirect',
    params: '',
    component: <Cafe24AppRedirectPage />,
  },
  {
    current_menu: '',
    path: '/ext/cafe24/multi_shop_app_redirect',
    params: '',
    component: <Cafe24AppRedirectMultiShopPage />,
  },
  {
    current_menu: '',
    path: '/change_password',
    params: '',
    component: <ChangePasswordPage />,
  },
  {
    current_menu: '',
    path: '/redirect_shop',
    params: '',
    exact: false,
    component: <RedirectShop />,
  },
  {
    current_menu: '',
    path: '/insight/intro',
    params: '',
    is_fullscreen: true,
    hide_accountbar: true,
    hide_footer: true,
    component: <InsightIntroPage />,
  },
  {
    current_menu: '',
    path: '/partner-plus/intro',
    params: '',
    is_fullscreen: true,
    hide_accountbar: true,
    hide_footer: true,
    component: <PartnerPlusIntroPage />,
  },
  {
    current_menu: '',
    path: '/daily-report',
    params: '',
    is_fullscreen: true,
    hide_accountbar: true,
    hide_footer: true,
    component: <MetaDailyReportPage />,
  },
];
