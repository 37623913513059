import { useUpdateAtom } from 'jotai/utils';
import React, { useEffect } from 'react';

import { drawer_menu_open_atom, drawer_menu_presence_atom } from '@/app/rui/components/main_frame/atom';
import AsideMenu from '@/app/rui/components/main_frame/gnb/AsideMenu';
import DrawerMenu from '@/app/rui/components/main_frame/gnb/DrawerMenu';
import useMediaQuery from '@/hooks/useMediaQuery';

export interface GnbProps {
  children: React.ReactNode;
}

export const Gnb: React.FC<GnbProps> = ({ children }) => {
  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);
  const setDrawerPresence = useUpdateAtom(drawer_menu_presence_atom);

  useEffect(() => {
    onDrawerOpen(false);
  }, [is_desktop]);

  useEffect(() => {
    setDrawerPresence(true);
    return () => {
      setDrawerPresence(false);
    };
  }, []);

  if (!is_desktop) {
    return <DrawerMenu>{children}</DrawerMenu>;
  }

  return <AsideMenu>{children}</AsideMenu>;
};
