import React from 'react';
import { Trans } from 'react-i18next';

import { DefaultFunnelData } from './types';
import { Task } from '@/types/onboarding';

export const STORE_SETTING_PATH = {
  STORE_EXPOSURE_INFO: '/info/trait?tab=store-info',
  CS_CENTER_INFO: '/info/trait?tab=cs-info',
  ENTER_SHIPPING_INFO: '/info/shipping_address',
  ENTER_CONTACT_INFO: '/info/contact',
};

const STORE_SETTING_TASKS: Task[] = [
  {
    task: 'STORE_EXPOSURE_INFO',
    name: <Trans ns='onboarding'>스토어 노출 정보 입력</Trans>,
    order: 1,
    page_path: STORE_SETTING_PATH.STORE_EXPOSURE_INFO,
    is_completed: false,
    tooltips: [
      {
        id: 'shop_name',
        message: <Trans ns='onboarding'>스토어명 정보를 입력해주세요.</Trans>,
        placement: 'right',
      },
    ],
  },
  {
    task: 'CS_CENTER_INFO',
    name: <Trans ns='onboarding'>고객센터 정보 입력</Trans>,
    order: 2,
    page_path: STORE_SETTING_PATH.CS_CENTER_INFO,
    is_completed: false,
    tooltips: [
      {
        id: 'customer_center_tel',
        message: <Trans ns='onboarding'>고객센터 번호를 입력 후 인증해주세요.</Trans>,
        placement: 'right',
      },
    ],
  },
  {
    task: 'ENTER_SHIPPING_INFO',
    name: <Trans ns='onboarding'>배송 정보 입력</Trans>,
    order: 3,
    page_path: STORE_SETTING_PATH.ENTER_SHIPPING_INFO,
    is_completed: false,
    tooltips: [
      {
        id: 'shipping_info',
        message: <Trans ns='onboarding'>고객에게 노출되는 배송 정보입니다. 하나씩 정확히 입력해주세요!</Trans>,
        placement: 'right',
      },
    ],
  },
  {
    task: 'ENTER_CONTACT_INFO',
    name: <Trans ns='onboarding'>담당자 정보 입력</Trans>,
    order: 4,
    page_path: STORE_SETTING_PATH.ENTER_CONTACT_INFO,
    is_completed: false,
    tooltips: [
      {
        id: 'contact_info',
        message: <Trans ns='onboarding'>담당자 정보를 모두 채워주세요. 겸직인 경우 중복 입력해도 상관없어요!</Trans>,
        placement: 'right',
      },
    ],
  },
];

export const STORE_SETTING_FUNNEL: DefaultFunnelData = {
  step: 'STORE_SETTING',
  name: <Trans ns='onboarding'>스토어 정보 설정하기</Trans>,
  description: (is_completed) => {
    const title = !is_completed ? (
      <Trans ns='onboarding'>스토어 정보를 설정해주세요.</Trans>
    ) : (
      <Trans ns='onboarding'>스토어 정보 설정이 완료되었습니다.</Trans>
    );
    const sub_title = !is_completed ? (
      <Trans ns='onboarding'>
        고객과의 만남과 원활한 상품 판매를 위해서 정확한 스토어 정보의 입력이 필요합니다.
        <br />
        스토어 정보 설정으로 이동하셔서 필수 정보들을 입력해주세요.
      </Trans>
    ) : (
      <Trans ns='onboarding'>
        입력하신 정보가 성공적으로 저장되었습니다. 스토어 정보는 언제든지 수정할 수 있습니다.
      </Trans>
    );
    return {
      title,
      sub_title,
      tags: [
        {
          type: 'link',
          label: <Trans ns='onboarding'>스토어 정보 바로가기</Trans>,
          path: '/info/trait',
        },
      ],
      actions: [
        {
          kind: 'primary',
          type: 'next_task',
          label: <Trans ns='onboarding'>스토어 정보 설정하기</Trans>,
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'shop_info',
            object_type: 'button',
          },
        },
      ],
      shortcut: {
        title: <Trans ns='onboarding'>필수 입력 정보</Trans>,
      },
      controller: {
        title: <Trans ns='onboarding'>스토어 정보 설정</Trans>,
        sub_title: (
          <Trans ns='onboarding'>
            스토어 정보의 필수 정보를 입력해주세요.
            <br />
            툴팁을 따라 이동하면서 입력해주세요.
          </Trans>
        ),
      },
    };
  },
  order: 2,
  is_completed: false,
  date_completed: null,
  in_progress_task: null,
  tasks: [...STORE_SETTING_TASKS],
};
