import { Footer as PDSFooter, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { fixed_bottom_area_atom } from '../atom';

export const Footer: FC = () => {
  const { t } = useTranslation('common');
  const fixed_bottom_area = useAtomValue(fixed_bottom_area_atom);

  return (
    <Root mb={fixed_bottom_area}>
      <PDSFooter.Container>
        <PDSFooter.Address>
          <PDSFooter.List>
            <li>{t('(주)카카오스타일')}</li>
            <li>{t('대표: 서정훈')}</li>
            <li>{t('사업자등록번호: 214-88-91525')}</li>
            <li>{t('13494 경기도 성남시 분당구 판교역로 235 에이치스퀘어 N동 6층')}</li>
            <li>
              {t('고객센터: ')}
              <PDSFooter.Link href='tel:02-1670-8051' rel='noreferrer'>
                02-1670-8051
              </PDSFooter.Link>
            </li>
          </PDSFooter.List>
        </PDSFooter.Address>
        <PDSFooter.List>
          <li>
            <PDSFooter.Link strong href='/privacy_policy' target='_blank' rel='noreferrer'>
              {t('개인정보 처리방침')}
            </PDSFooter.Link>
          </li>
          <li>
            <PDSFooter.Link href='/service_policy' target='_blank' rel='noreferrer'>
              {t('서비스 이용약관')}
            </PDSFooter.Link>
          </li>
          <li>
            <PDSFooter.Link href='/zigzin_policy_terms' target='_blank' rel='noreferrer'>
              {t('직진배송 이용약관')}
            </PDSFooter.Link>
          </li>
        </PDSFooter.List>
      </PDSFooter.Container>
    </Root>
  );
};

const Root = styled(VStack)`
  background-color: ${semantic_colors.background.base};

  ${PDSFooter.Link}:visited {
    color: inherit;
  }
`;
