import { Options, useHotkeys } from 'react-hotkeys-hook';
import { hotkey_map } from '@/constants/keyMap';

interface Handlers {
  onConfirm?: () => void;
  onClose?: () => void;
}
interface ModalHotkeysArgs {
  handlers: Handlers;
  options?: Options;
}

const useModalHotkeys = (args: ModalHotkeysArgs) => {
  const {
    handlers: { onClose, onConfirm },
    options,
  } = args;

  useHotkeys<HTMLDivElement>(
    `${hotkey_map.CONFIRM}, ${hotkey_map.CLOSE}`,
    (_, handler) => {
      switch (handler.key) {
        case hotkey_map.CONFIRM:
          onConfirm?.();
          break;
        case hotkey_map.CLOSE:
          onClose?.();
          break;
      }
    },
    options,
    [options?.enabled],
  );
};

export default useModalHotkeys;
