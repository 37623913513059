import { IconCounselor } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId, isNotAllowedByShopDepartment } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment, ShopType } from '@/types/info';
import { SellerResource } from 'api';
import { IMPORT_EXTERNAL_REVIEW_URL, OrderLinkedListUrl, PRODUCT_REVIEW_LIST_URL } from 'rui/components/URL';

export function createInquiryMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  const is_inquiry_allowed =
    is_site_id_allowed && is_zpay && isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]);
  // 직잭 메이트(16871)는 연동 관리 항상 노출
  const is_linked_allowed = isAllowedMenuBySiteId(info, [SiteId.카카오톡_쇼핑하기]) || info.id === '16871';
  const is_available_import_external_review = info.department_list.item_list.some((item) => {
    if (item.country_code === 'KR' && item.site_id === SiteId.지그재그) {
      return item.department === ShopDepartment.BEAUTY;
    }
    return false;
  });

  return [
    {
      id: 'inquiry',
      label: t('main_frame::문의/리뷰 관리'),
      icon: <IconCounselor size={16} color='currentColor' />,
      is_allowed: true,
      is_support_app: true,
      items: [
        {
          id: 'order_inquiry',
          label: t('main_frame::주문 문의'),
          href: '/order_inquiry',
          is_allowed:
            is_inquiry_allowed && checkRes([SellerResource.ZPAY_ORDER_INQUIRY_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_linked_management_list_question',
          label: t('main_frame::연동 주문 문의'),
          href: OrderLinkedListUrl.QUESTION,
          is_allowed: is_linked_allowed && checkRes([SellerResource.OMS_QNA_MANAGEMENT], info.allowed_resource_list),
        },
        {
          id: 'item_question',
          label: t('main_frame::상품 문의'),
          href: '/item_question',
          is_allowed:
            is_inquiry_allowed && checkRes([SellerResource.PRODUCT_QNA_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'shop_center_inquiry',
          label: t('main_frame::고객센터 문의'),
          href: '/shop_center_inquiry',
          is_allowed:
            is_inquiry_allowed && checkRes([SellerResource.CUSTOMER_INQUIRY_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'product_review',
          label: t('main_frame::고객 리뷰'),
          href: PRODUCT_REVIEW_LIST_URL,
          is_allowed:
            is_inquiry_allowed &&
            checkRes(
              [SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ, SellerResource.PRODUCT_REVIEW_MANAGEMENT_WRITE],
              info.allowed_resource_list,
            ),
        },
        {
          id: 'import_external_review',
          label: t('main_frame::외부 리뷰 연동하기'),
          href: IMPORT_EXTERNAL_REVIEW_URL,
          is_allowed:
            is_available_import_external_review &&
            is_inquiry_allowed &&
            checkRes(
              [SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ, SellerResource.PRODUCT_REVIEW_MANAGEMENT_WRITE],
              info.allowed_resource_list,
            ),
        },
      ],
    },
  ];
}
