import { useQuery } from 'react-query';
import {
  api_getDailyReportSalesCompetitor,
  api_getMetaDailyReportSalesCompetitor,
  api_getMetaDailyReportSalesCompetitorBlur,
  GetDailyReportSalesCompetitorQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportSalesCompetitorQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportSalesCompetitor = ({
  date_ymd,
  use_free_view_count,
  main_domain = '',
  token = '',
}: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportSalesCompetitor', { date_ymd, use_free_view_count, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportSalesCompetitor({ date_ymd, use_free_view_count }, { no_alert: true })
        : use_free_view_count
          ? await api_getMetaDailyReportSalesCompetitor(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            )
          : await api_getMetaDailyReportSalesCompetitorBlur(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
