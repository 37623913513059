import { BaseText, HStack, PageIndicator, Switch, VStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import type { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSendDailyReportPbl } from '../hooks/useSendDailyReportPbl';
import ProductCard from './ProductCard';
import { ProductRankCard } from './styles';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { DailyReportProductStat } from 'api';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

interface Props {
  width: number;
  product_ranking_list: Array<DailyReportProductStat>;
  children?: React.ReactNode;
  is_blurred?: boolean;
  is_partner_plus?: boolean;
  type: 'order' | 'sales' | 'claim';
  base_date?: Dayjs;
  object_section?: string;
}

const ProductSwiper: React.FC<Props> = ({
  width,
  product_ranking_list,
  is_blurred,
  is_partner_plus,
  children,
  type,
  base_date,
  object_section,
}) => {
  const { t } = useTranslation('main_frame');
  const ref = useRef<HTMLDivElement>(null);
  const [is_show_order_count, setIsShowOrderCount] = useState(type !== 'sales');
  const [current_page, setCurrentPage] = useState(0);
  const { screen_size } = useResizeObserver(ref.current);
  const slidesPerView = screen_size ? Math.min(screen_size / width, 1.1) : 1.1;
  const page_count = Math.ceil(product_ranking_list.length / 5);
  const { sendClick, sendImpression } = useSendDailyReportPbl(is_partner_plus);
  const { isRevealed, targetRef } = useOnceReveal({ threshold: 0.8 });

  useEffect(() => {
    if (isRevealed && object_section) {
      sendImpression({
        object_id: 'item_list_swipe',
        object_section,
      });
    }
  }, [isRevealed]);

  return (
    <VStack spacing={8}>
      <ProductRankCard>
        {type === 'sales' && (
          <HStack alignment='center'>
            <HStack alignment='center' spacing={4}>
              <BaseText kind='Body_12_SemiBold'>{t('상품정보 보기')}</BaseText>
              <Switch
                size='small'
                onText=''
                offText=''
                isOn={is_show_order_count}
                onClick={() => {
                  sendClick({
                    object_id: 'click_toggle',
                    object_section: '베스트 상품 판매 분석',
                  });
                  setIsShowOrderCount(!is_show_order_count);
                }}
              />
            </HStack>
            <HStack ml='auto'>
              <BaseText kind='Caption_11_Medium' color={semantic_colors.content.secondary}>
                {is_show_order_count ? t('(주문수: 최근 7일 기준)') : t('(어제 주문수와 비교)')}
              </BaseText>
            </HStack>
          </HStack>
        )}
        <Container ref={ref} pb={product_ranking_list.length === 2 ? 16 : 0}>
          <Swiper
            slidesPerView={slidesPerView}
            onSlideChange={(e) => {
              setCurrentPage(e.activeIndex);
            }}
          >
            <SwiperSlide key={0}>
              <VStack spacing={8}>
                {product_ranking_list
                  ?.slice(0, 5)
                  .map((product, index) => (
                    <ProductCard
                      type={type}
                      is_blur={(is_blurred && index > 0) || (is_blurred && product_ranking_list.length === 1)}
                      is_conversion={!is_show_order_count}
                      key={product.ranking}
                      product={product}
                      base_date={base_date}
                    />
                  ))}
              </VStack>
            </SwiperSlide>
            {page_count > 1 && (
              <SwiperSlide key={1}>
                <VStack spacing={8} ref={targetRef}>
                  {product_ranking_list
                    ?.slice(5)
                    .map((product) => (
                      <ProductCard
                        is_blur={is_blurred}
                        is_conversion={!is_show_order_count}
                        type={type}
                        key={product.ranking}
                        product={product}
                        base_date={base_date}
                      />
                    ))}
                </VStack>
              </SwiperSlide>
            )}
          </Swiper>
        </Container>
        {children}
      </ProductRankCard>
      {page_count > 1 && <PageIndicator currentPage={current_page + 1} totalPages={page_count} />}
    </VStack>
  );
};

export default ProductSwiper;

const Container = styled.div<{ pb?: number }>`
  overflow: hidden;
`;
