import { getAutoColorCode, HStack, semantic_colors, text_styles } from '@croquiscom/pds';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const RoundIconContainer = styled.div<{ size?: 'large' | 'small' }>`
  display: flex;
  ${({ size }) =>
    size === 'large'
      ? `
      width: 64px;
      height: 64px;
      padding: 8px;
    `
      : size === 'small'
        ? `
      width: 32px;
      height: 32px;
      padding: 4px;

      `
        : `
      width: 42px;
      height: 42px;
      padding: 5px;
    `}
  align-items: center;
  justify-content: center;
  background-color: #fff6db;
  border-radius: 50%;
`;

export const FeedbackChipButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 16px;
  ${text_styles.Body_13_Medium};
  border-radius: 100px;
  border: 1px solid ${semantic_colors.border.default};
  cursor: pointer;
  &:hover {
    background-color: ${getAutoColorCode(semantic_colors.background.surface).hover};
    border-color: ${getAutoColorCode(semantic_colors.border.default).hover};
  }
  &:active {
    background-color: ${getAutoColorCode(semantic_colors.background.surface).pressed};
    border-color: ${getAutoColorCode(semantic_colors.border.default).pressed};
  }
`;

const FloatingButtonText = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 118px;
  }
`;

export const ChatBotText = styled(HStack)`
  margin-top: 5px;
  overflow: hidden;
  animation: ${FloatingButtonText} 0.3s ease-in-out forwards;
`;
export const ChatBotTextShadow = styled(HStack)`
  width: 142px;
  height: 24px;
  justify-content: center;
`;
