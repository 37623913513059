import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { RoundedButton } from './RoundedButton';
import { help_popup_atom, show_floating_button_atom } from 'rui/components/widget/help_popup/atom';

export const HelpPopupButton = () => {
  const show_floating_button = useAtomValue(show_floating_button_atom);
  const [help_popup, setHelpPopup] = useAtom(help_popup_atom);

  const handleOpenToggle = () => {
    setHelpPopup({
      ...help_popup,
      opened: !help_popup.opened,
    });
  };

  if (!show_floating_button) {
    return null;
  }

  return <RoundedButton onClick={handleOpenToggle}>이 페이지의 도움말</RoundedButton>;
};
