import {
  text_styles,
  VStack,
  IconColoredFaceVeryBad,
  IconColoredFaceBad,
  IconColoredFaceNeutral,
  IconColoredFaceGood,
  IconColoredFaceVeryGood,
  semantic_colors,
  colors,
} from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InsightFeedbackScoreProps {
  score: number;
  is_selected: boolean;
}

const SCORE_SET: { icon: React.ReactNode; label: string }[] = [
  {
    icon: <IconColoredFaceVeryBad size={24} />,
    label: '아주 나빠요',
  },
  {
    icon: <IconColoredFaceBad size={24} />,
    label: '나빠요',
  },
  {
    icon: <IconColoredFaceNeutral size={24} />,
    label: '보통이에요',
  },
  {
    icon: <IconColoredFaceGood size={24} />,
    label: '좋아요',
  },
  {
    icon: <IconColoredFaceVeryGood size={24} />,
    label: '아주 좋아요',
  },
];

export const InsightFeedbackScore = ({ score, is_selected }: InsightFeedbackScoreProps) => {
  const { t } = useTranslation();
  const index = score - 1;
  return (
    <ScoreWrapper is_selected={is_selected} role='button'>
      <VStack width='56px' spacing='4px' alignment='center' pt='8px' pb='8px'>
        {SCORE_SET[index].icon}
        <span>{t(SCORE_SET[index].label)}</span>
      </VStack>
    </ScoreWrapper>
  );
};

const ScoreWrapper = styled.div<{ is_selected: boolean }>`
  ${text_styles.Caption_11_Medium}
  border-radius: 8px;
  color: ${semantic_colors.content.secondary};

  ${({ is_selected }) =>
    is_selected &&
    css`
      ${text_styles.Caption_11_SemiBold}
      color: ${semantic_colors.accent.primary};
      background-color: ${colors.blue100};
    `}
  &:hover {
    ${text_styles.Caption_11_SemiBold}
    color: ${semantic_colors.accent.primary};
  }
`;
