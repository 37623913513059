import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

/**
 * 파라미터 스키마의 각 값이 어떻게 동작해야 하는지 나타내는 객체입니다.
 */
export interface ParamsSchemaAtom<T> {
  get(value: string[], record: Record<string, string | string[]>): T;
  set(value: T | undefined, record: Record<string, unknown>): string[];
}

function createSchemaAtom<T>(
  get: (value: string[], record: Record<string, string | string[]>) => T,
  set: (value: T | undefined, record: Record<string, unknown>) => string[],
): ParamsSchemaAtom<T> {
  return { get, set };
}

const params_schema = {
  /**
   * 해당 값이 string을 나타내도록 합니다.
   * allow_empty가 false (기본값)인 경우 빈 문자열은 undefined로 반환됩니다.
   */
  string: (allow_empty = false) =>
    createSchemaAtom<string | undefined>(
      (v) => (allow_empty ? v[0] : v[0] || undefined),
      (v) => (v != null && (v !== '' || allow_empty) ? [v] : []),
    ),
  /**
   * 해당 값이 허용된 문자열들 중 하나를 나타내도록 합니다. 허용된 문자열 이외의 값이 들어오는
   * 경우 무시됩니다.
   * @param allowed_values 허용된 문자열 목록
   */
  enum: <T extends string>(allowed_values: readonly T[]) =>
    createSchemaAtom<T | undefined>(
      (v) => {
        if (v.length > 0 && allowed_values.includes(v[0] as T)) {
          return v[0] as T;
        }
        return undefined;
      },
      (v) => (v != null ? [v] : []),
    ),
  /**
   * 해당 값이 정수를 나타내도록 합니다. 소수점을 사용해야 하는 경우 float을 사용해주세요.
   */
  number: () =>
    createSchemaAtom<number | undefined>(
      (v) => {
        if (v.length > 0 && v[0] !== '') {
          const result = parseInt(v[0]);
          if (!isNaN(result)) {
            return result;
          }
        }
        return undefined;
      },
      (v) => (v != null ? [String(v)] : []),
    ),
  /**
   * 해당 값이 부동소수점을 나타내도록 합니다.
   */
  float: () =>
    createSchemaAtom<number | undefined>(
      (v) => {
        if (v.length > 0 && v[0] !== '') {
          const result = parseFloat(v[0]);
          if (!isNaN(result)) {
            return result;
          }
        }
        return undefined;
      },
      (v) => (v != null ? [String(v)] : []),
    ),
  /**
   * 해당 값이 boolean을 나타내도록 합니다. 쿼리 파라미터에서는 1/0으로 표현됩니다.
   */
  boolean: () =>
    createSchemaAtom<boolean | undefined>(
      (v) => {
        if (v.length > 0) {
          if (v[0] === '1') {
            return true;
          } else if (v[0] === '0') {
            return false;
          }
        }
        return undefined;
      },
      (v) => (v != null ? [v ? '1' : '0'] : []),
    ),
  /**
   * 해당 값이 지정된 스키마의 배열을 나타내도록 합니다. 쿼리 파라미터에서는 ","로 구분되는 값으로 표현됩니다.
   * 값에 ","가 이미 들어가 있는 경우, ",," 로 이스케이프 처리됩니다.
   * 배열이 비어 있는 경우 빈 배열이 아닌 undefined를 반환합니다.
   * @param schema 배열의 각 값을 나타내는 스키마입니다.
   */
  array: <T>(schema: ParamsSchemaAtom<T>): ParamsSchemaAtom<Array<Exclude<T, undefined>> | undefined> =>
    createSchemaAtom(
      (v, r) => {
        const items = v.flatMap((i) => i.match(/([^,]|,,)+/g) ?? []);
        const result = items
          .map((i) => schema.get([i.replace(/,,/g, ',')], r))
          .filter((i): i is Exclude<T, undefined> => i != null);
        if (result.length > 0) {
          return result;
        }
        return undefined;
      },
      (v, r) =>
        v != null && v.length > 0
          ? [
              v
                .flatMap((i) => schema.set(i, r))
                .map((i) => i.replace(/,/g, ',,'))
                .join(','),
            ]
          : [],
    ),
  /**
   * 해당 값이 날짜 (dayjs)를 나타내도록 합니다. 쿼리 파라미터에서는 ISO 문자열 (2024-01-01T00:00:00Z)
   * 로 표현됩니다.
   */
  date: () =>
    createSchemaAtom<Dayjs | undefined>(
      (v) => {
        if (v.length > 0 && v[0] !== '') {
          const result = dayjs(v[0]);
          if (result.isValid()) {
            return result;
          }
        }
        return undefined;
      },
      (v) => (v != null ? [v.toISOString()] : []),
    ),
  /**
   * 해당 값이 지정되지 않은 경우 (스키마가 undefined를 반환하는 경우), 대신 기본 값을 반환합니다.
   * 쿼리 파라미터로 다시 변경할 때에는, 지정된 값과 기본 값이 일치하는 경우 해당 값을 파라미터에
   * 포함하지 않도록 합니다.
   * @param schema 값을 나타내는 스키마입니다.
   * @param value 값이 undefined일 때 사용할 기본 값입니다.
   */
  default: <T, V>(schema: ParamsSchemaAtom<T>, value: V) =>
    createSchemaAtom<NonNullable<T> | V>(
      (v, r) => schema.get(v, r) ?? value,
      (v, r) => {
        if (
          Array.isArray(value) &&
          Array.isArray(v) &&
          value.length === v.length &&
          v.every((entry) => value.includes(entry))
        ) {
          return schema.set(undefined, r);
        }
        if (v === value) {
          return [];
        }
        return schema.set(v as T, r);
      },
    ),
  /**
   * check()가 true를 반환하는 경우에만 스키마의 값을 그대로 반환하고, false를 반환하면 undefined를
   * 반환합니다. check는 쿼리 파라미터 값을 Record<string, any> 형태로 받습니다.
   * type=id&id=1234 와 같이, 다른 값과 연결되어 동작해야 하는 경우에 사용합니다.
   * @param schema check 검사 성공 시 값을 나타내는 스키마입니다.
   * @param check 값을 설정할 지 여부를 결정하는 함수입니다.
   * @example p.onlyIf(p.number(), (v) => String(v.type) === 'id')
   */
  onlyIf: <T>(schema: ParamsSchemaAtom<T>, check: (value: Record<string, unknown>) => boolean) =>
    createSchemaAtom<T | undefined>(
      (v, r) => (check(r) ? schema.get(v, r) : undefined),
      (v, r) => (check(r) ? schema.set(v, r) : []),
    ),
};

function mapValue(value: string | string[] | undefined): string[] {
  if (value == null) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

/**
 * 쿼리 파라미터 맵을 해석해서 T 타입으로 변환하고, 반대로 T 타입을 쿼리 파라미터 맵으로 변경합니다.
 */
export interface ParamsSchema<T extends Record<string, any>> {
  get(value: Record<string, string[]>): T;
  set(value: Partial<T>): Record<string, string[]>;
}

/**
 * 새롭게 쿼리 파라미터 정의를 생성합니다.
 * @param createRecords 매개변수로 주어지는 p 변수를 사용해서 p.number() 등을 사용해 타입을 정의합니다.
 * @example createParamsSchema((p) => ({ page: p.number(), per: p.default(p.number(), 20) }))
 */
export function createParamsSchema<T extends Record<string, any>>(
  createRecords: (p: typeof params_schema) => {
    [K in keyof T]: ParamsSchemaAtom<T[K]>;
  },
): ParamsSchema<T> {
  const records = createRecords(params_schema);
  return {
    get: (value) => {
      const output: any = {};
      for (const key in records) {
        output[key] = records[key].get(mapValue(value[key]), value);
      }
      return output;
    },
    set: (value) => {
      const output: any = {};
      for (const key in records) {
        output[key] = records[key].set(value[key], value);
      }
      return output;
    },
  };
}

/**
 * 쿼리 파라미터 정의를 이용해, 쿼리 파라미터를 useState와 동일한 방식으로 사용할 수 있도록
 * 도와주는 훅입니다.
 * @param schema createParamsSchema()를 통해 생성한 쿼리 파라미터 정의
 */
export function useValidateParams<T extends Record<string, any>>(
  schema: ParamsSchema<T>,
): [T, (value: Partial<T>, replace?: boolean) => void] {
  const history = useHistory();
  const location = useLocation();

  const value = useMemo(() => {
    const search_params = new URLSearchParams(location.search);
    let params: Record<string, string[]> = {};
    search_params.forEach((_, key) => {
      params = {
        ...params,
        [key]: search_params.getAll(key),
      };
    });
    return schema.get(params);
  }, [schema, location.search]);

  const setValue = useCallback(
    (new_value: Partial<T>, replace?: boolean) => {
      const set_records = schema.set(new_value);
      const new_search_params = new URLSearchParams();
      for (const key in set_records) {
        set_records[key].forEach((v) => {
          new_search_params.append(key, v);
        });
      }
      history[replace ? 'replace' : 'push']({ search: '?' + new_search_params.toString() });
    },
    [schema, history],
  );

  return [value, setValue];
}
