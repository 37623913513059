import {
  IconBeta,
  semantic_colors,
  HStack,
  VStack,
  BaseText,
  PopoverButton,
  IconDotsHorizontal,
  CloseButton,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { ChatBotLogo } from './ChatBotLogo';
import { CHATBOT_PRESET, ChatBotMenuOption, ChatBotMenuOptions } from './constants';
import { SSE_STATE } from './hooks/useChatBotRequest';
import { RoundIconContainer } from './styled';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';
import { useHelpConstants } from '@/hooks/useHelpConstants';

interface ChatBotHeaderProps {
  state: SSE_STATE;
  closeChatBot?: () => void;
  onSelectMenu: (value: ChatBotMenuOption) => void;
}

export const ChatBotHeader = ({ state, closeChatBot, onSelectMenu }: ChatBotHeaderProps) => {
  const { data } = useHelpConstants(CHATBOT_PRESET);
  const preset = Object.entries(data);
  const has_preset = preset.length > 0;
  const is_waiting = ![SSE_STATE.LOADING, SSE_STATE.RECEIVING].includes(state);
  return (
    <HStack width='100%' alignment='center' spacing={16} mb={8}>
      {closeChatBot && <CloseButton size={24} onClick={closeChatBot} />}
      <RoundIconContainer>
        <img width={32} src={ChatBotIcon} alt='' />
      </RoundIconContainer>
      <VStack spacing={2}>
        <HStack spacing={4}>
          <HStack spacing={4} alignment='center' height={20}>
            <ChatBotLogo color={semantic_colors.content.primary} size={100} />
          </HStack>
          <IconBeta size={21} color={semantic_colors.content.tertiary} />
        </HStack>
        <HStack spacing={6} alignment='center'>
          <SC.StatusCircle color={is_waiting ? semantic_colors.state.positive : semantic_colors.state.warning} />
          <BaseText kind='Body_12_Regular' color={semantic_colors.content.secondary}>
            {is_waiting ? '질문 대기 중' : '답변 입력 중'}
          </BaseText>
        </HStack>
      </VStack>
      <HStack ml='auto'>
        <PopoverButton
          useIconButton
          size='small'
          icon={<IconDotsHorizontal />}
          options={ChatBotMenuOptions.filter((option) => has_preset || option.value !== ChatBotMenuOption.CHATBOT_FAQ)}
          onChange={onSelectMenu}
        />
      </HStack>
    </HStack>
  );
};

const SC = {
  StatusCircle: styled.div<{ color?: string }>`
    width: 6px;
    height: 6px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
  `,
};
