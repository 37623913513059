import React from 'react';
import ResponsiveHStack from './ResponsiveHStack';
import ResponsiveVStack, { ResponsiveVStackProps } from './ResponsiveVStack';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsiveFlexProps extends Omit<ResponsiveVStackProps, 'alignment'> {
  alignment?: 'center';
  children: React.ReactNode;
}

export default function ResponsiveFlex({ ...props }: ResponsiveFlexProps) {
  const { is_desktop } = useMediaQuery();

  return is_desktop ? <ResponsiveHStack {...props} /> : <ResponsiveVStack {...props} />;
}
