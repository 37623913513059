import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface NavProps extends React.HTMLAttributes<HTMLElement> {}

const Nav: FC<NavProps> = ({ className, children }) => {
  return (
    <StyledNav className={className}>
      <StyledNavList>{children}</StyledNavList>
    </StyledNav>
  );
};

export default Nav;

const StyledNav = styled.nav`
  position: relative;
`;

const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: ${colors.white};
`;
