import { colors, IconArrowChevronUp } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useLocation } from 'react-router-dom';

const SCROLL_TOP_PATH = ['/insight/partner-plus/report'];

export const ScrollTopButton = () => {
  const location = useLocation();
  const is_show = SCROLL_TOP_PATH.find((path) => location.pathname.includes(path));

  if (!is_show) return null;

  const handleClick = () => {
    document.getElementById('app')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <CircleButton type='button' onClick={handleClick}>
      <IconArrowChevronUp size={30} />
    </CircleButton>
  );
};

const CircleButton = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border: 0;
  border-radius: 25px;
  background-color: ${colors.kakao_yellow400};
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
`;
