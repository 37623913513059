import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface AnimatedAccordionProps {
  is_open: boolean;
  children: React.ReactNode;
}

interface AnimatedAccordionState {
  is_open: boolean;
  is_opening: boolean;
  is_animating: boolean;
  height: number;
}

export const AnimatedAccordion: React.FC<AnimatedAccordionProps> = ({ is_open, children }) => {
  const [state, setState] = useState<AnimatedAccordionState>(() => ({
    is_open,
    is_opening: false,
    is_animating: false,
    height: 0,
  }));
  const isMountedRef = useRef<boolean>(false);
  const innerRef = useRef<HTMLDivElement | null>(null);
  // Closed -> open
  // 1. Render inner content initially (is_opening = 1, height = 0)
  // 2. Set height of the container to the inner content (is_opening = 1, height = 100)
  // 3. Remove relative/absolute once the transition has finished (is_opening = 0, is_open = 1)
  // Open -> closed
  // 1. Set height of the container to the inner content, setting relative/absolute (is_)
  // 2. Set height to 0
  // 3. Remove inner content once the transition has finished
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }
    if (is_open) {
      // 1. Render inner content initially
      setState({ is_open: false, is_opening: true, is_animating: false, height: 0 });
      setTimeout(() => {
        // 2. Set height of the container to the inner content
        const innerEl = innerRef.current;
        if (innerEl == null) return;
        const height = innerEl.offsetHeight;
        setState({ is_open: true, is_opening: true, is_animating: true, height });
      }, 20);
    } else {
      const innerEl = innerRef.current;
      if (innerEl == null) return;
      const height = innerEl.offsetHeight;
      // 1. Set height of the container to the inner content, setting relative/absolute
      setState({ is_open: true, is_opening: true, is_animating: false, height });
      setTimeout(() => {
        // 2. Set height to 0
        setState({ is_open: false, is_opening: true, is_animating: true, height: 0 });
      }, 20);
    }
  }, [is_open]);
  const handleTransitionEnd = useCallback(() => {
    setState((prev) => ({ ...prev, is_opening: false, is_animating: false }));
  }, []);
  const { is_open: is_state_open, is_opening, is_animating, height } = state;
  if (!is_state_open && !is_opening) {
    return null;
  }
  return (
    <StyledContainer
      onTransitionEnd={handleTransitionEnd}
      is_animating={is_animating}
      style={{ height: is_opening ? `${height}px` : undefined }}
    >
      <StyledInner ref={innerRef} is_opening={is_opening}>
        {children}
      </StyledInner>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ is_animating: boolean }>`
  position: relative;
  overflow: hidden;
  ${({ is_animating }) =>
    is_animating &&
    css`
      transition: 0.2s height;
    `}
`;

const StyledInner = styled.div<{ is_opening: boolean }>`
  ${({ is_opening }) =>
    is_opening &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `}
`;
