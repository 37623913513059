import { useQuery } from 'react-query';
import {
  api_getDailyReportShopManaging,
  api_getMetaDailyReportShopManaging,
  GetDailyReportShopManagingQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportShopManagingQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportShopManaging = ({ date_ymd, main_domain = '', token = '' }: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportShopManaging', { date_ymd, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportShopManaging({ date_ymd }, { no_alert: true })
        : await api_getMetaDailyReportShopManaging(
            {
              date_ymd,
              main_domain,
              token,
            },
            { no_alert: true },
          );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
