import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import TransPlainText from '@/components/common/TransPlainText';

export const SUBSCRIPTION_PRODUCT_ID = {
  STARTER: '1',
  BASIC: '2',
  PLUS: '3',
  ADVANCED: '4',
  PARTNER_PLUS: '6',
};

export const INSIGHT_REVIEW = [
  {
    image: 'https://cf.shop.s.zigzag.kr/images/la-room.jpg',
    name: <TransPlainText ns='partner_plus' i18nKey='라룸' />,
    review: <TransPlainText ns='partner_plus' i18nKey='“우리 스토어를 객관적으로 진단해주는 컨설턴트에요”' />,
  },
  {
    image: 'https://cf.shop.s.zigzag.kr/images/dailyjou.jpg',
    name: <TransPlainText ns='partner_plus' i18nKey='데일리쥬' />,
    review: <TransPlainText ns='partner_plus' i18nKey='“비교 분석된 데이터를 보며 안주하지 않고 노력하게 됩니다”' />,
  },
  {
    image: 'https://cf.shop.s.zigzag.kr/images/dalihotel.jpg',
    name: <TransPlainText ns='partner_plus' i18nKey='달리호텔' />,
    review: <TransPlainText ns='partner_plus' i18nKey='“마케팅부터 제작까지 도움주는 올라운드 플레이어에요”' />,
  },
  {
    image: 'https://cf.shop.s.zigzag.kr/images/cq-0mek1xgy.jpg',
    name: <TransPlainText ns='partner_plus' i18nKey='시아흐' />,
    review: <TransPlainText ns='partner_plus' i18nKey='“키워드를 참고해서 상품명에도 적용하고 있어요”' />,
  },
];

export const SELLER_REVIEW_LIST = [
  {
    emoji: '😍',
    name: t('여성 의류 스토어 N', { ns: 'partner_plus' }),
    review: (
      <Trans ns='partner_plus'>
        온라인 상이지만 <strong>동종업계의 사람들의 이야기를 조금이나마 들을수 있어서 혼자만의 고민</strong>이 아닌
        부분들이 힘이 되기도 하고, 자극이 되기도 했습니다! 다양한 부분 참여하면서 재미도 있었고, 뭔가{' '}
        <strong>새로운 소식을 먼저 듣는</strong> 것 같은 기분도 들었구요! 다음 프로그램에도 꼭 초대받고싶습니다.
      </Trans>
    ),
  },
  {
    emoji: '🤩',
    name: t('에슬레저 스토어', { ns: 'partner_plus' }),
    review: (
      <Trans ns='partner_plus'>
        평소 우리가 인사이트를 잘 활용하고 있는지 궁금했었는데,{' '}
        <strong>다양한 업체의 실무자분들과 함께 팁을 나눌 수</strong> 있었습니다. 운영자분들께서도{' '}
        <strong>신기능 팁을 공유해주시고, 질문에도 명쾌하게 대답</strong>해 주셨어요.여담이지만, 익명으로 함께 소통하는{' '}
        경험을 통해 모두 같은 목표를 바라보고 열심히 하고 있구나 자극도 받고, 존경스러웠습니다!
      </Trans>
    ),
  },
  {
    emoji: '😆',
    name: t('여성 의류 스토어 C', { ns: 'partner_plus' }),
    review: (
      <Trans ns='partner_plus'>
        주먹구구식으로 운영했는데, <strong>데이터 기반으로 업무 프로세스를 작성</strong>할수 있게 되었고 직관적인
        의사결정이 가능해졌습니다.
      </Trans>
    ),
  },
  {
    emoji: '😃',
    name: t('여성 의류 스토어 B', { ns: 'partner_plus' }),
    review: (
      <Trans ns='partner_plus'>
        고객들이 보는 저희 브랜드를 데이터를 통해 파악할수 있는 부분을 <strong>명확하게 집어주셔서</strong> 좋았습니다.
      </Trans>
    ),
  },
  {
    emoji: '😊',
    name: t('잡화 스토어 M', { ns: 'partner_plus' }),
    review: (
      <Trans ns='partner_plus'>
        프로그램에서 나눠주신 팁을 정독하니 자습서 같은 느낌을 받았습니다. 일에 하나씩{' '}
        <strong>체계가 생기니 판매인사이트 결과를 빨리 보고 싶은 마음</strong>에 설렘까지 들 정도에요.
      </Trans>
    ),
  },
];
