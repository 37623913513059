import { atomWithQuery } from 'jotai/query';
import Account from '@/types/account';
import axios, { RequestError } from '@/utils/axios';
import gql from '@/utils/gql';

interface AgencyAccountData {
  seller_account: {
    name: string;
    phone_number: string;
  };
}

export const account_atom = atomWithQuery(() => {
  return {
    queryKey: ['useAccountQuery'],
    queryFn: async () => {
      try {
        const [
          { data: account_info },
          {
            data: { seller_account: agency_account },
          },
        ] = await Promise.all([
          axios.request<Account>({
            method: 'GET',
            baseURL: '/api/provider',
            url: 'info',
            params: { time: Date.now() },
          }),
          gql`
            query GetAgencyAccountDataMeta {
              seller_account {
                name
                phone_number
              }
            }
          `<AgencyAccountData>('GetAgencyAccountDataMeta', undefined, null, {
            no_shop_domain: true,
            no_alert: true,
            no_redirect_to_login: true,
          }).catch(() => {
            // 오류 발생했을 때 throw하지 않고 빈 유저 정보 반환
            // (고객센터 계정의 경우에는 위 /api/provider는 200이 날아오나, 아래쪽에서는 로그인을 안했다는 오류가 발생함)
            return { data: { seller_account: {} } };
          }),
        ]);

        const result = {
          ...account_info,
          ...agency_account,
        };

        return result;
      } catch (e) {
        const err = e as RequestError;
        if (err.code === 'route_not_logged_in' || err.code === 'not logged in') {
          return null;
        }
        throw e;
      }
    },
  };
});
