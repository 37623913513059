import {
  HStack,
  Textarea,
  VStack,
  IconButton,
  IconArrowDirectionUp,
  semantic_colors,
  getAutoColorCode,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { SSE_STATE } from './hooks/useChatBotRequest';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ChatBotFooterProps {
  state: SSE_STATE;
  question?: string;
  sendMessage: (question: string) => void;
  setQuestion: (question: string) => void;
}

export const ChatBotFooter = ({ state, question, sendMessage, setQuestion }: ChatBotFooterProps) => {
  const { is_desktop } = useMediaQuery();
  const is_disabled = state === SSE_STATE.LOADING || state === SSE_STATE.RECEIVING;
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!is_disabled && is_desktop) {
      ref.current?.focus();
    }
  }, [is_disabled]);

  return (
    <HStack spacing={8} alignment='bottom'>
      <VStack width='100%'>
        <Textarea
          ref={ref}
          placeholder='메뉴명이나 상황을 질문해보세요.'
          name='question'
          autoComplete='off'
          disabled={is_disabled}
          value={question}
          style={{ borderRadius: '23px' }}
          autoSize={{ minRows: 1, maxRows: 5 }}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && question && !e.shiftKey && question.trim() !== '') {
              sendMessage(question);
            }
          }}
        />
      </VStack>
      <HStack pb={4} pt={4}>
        <SC.Button
          shape='circle'
          icon={<IconArrowDirectionUp />}
          size='small'
          disabled={is_disabled}
          onClick={() => question && sendMessage(question)}
        />
      </HStack>
    </HStack>
  );
};

const SC = {
  Button: styled(IconButton)`
    background-color: ${semantic_colors.accent.primary};
    color: ${semantic_colors.content.on_color};

    &:hover {
      background-color: ${getAutoColorCode(semantic_colors.accent.primary).hover};
      color: ${getAutoColorCode(semantic_colors.content.on_color).hover};
    }
    &:active {
      background-color: ${getAutoColorCode(semantic_colors.accent.primary).pressed};
      color: ${getAutoColorCode(semantic_colors.content.on_color).pressed};
    }

    &:disabled {
      background-color: ${semantic_colors.accent.primary};
      color: ${semantic_colors.content.on_color};
      opacity: 0.2;
    }
  `,
};
