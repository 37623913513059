import { PLAN_NAME } from '../constants';
import { useInfo } from '@/hooks/useInfo';

export function useGetPlan(plan_name?: string) {
  const { info } = useInfo();
  const name = plan_name ?? info.shop_subscription_resource.name;

  const is_partner_plus = name?.toUpperCase().startsWith(PLAN_NAME.PARTNER_PLUS);
  const has_subscription = !!info.shop_subscription_resource.name;

  return { is_partner_plus, has_subscription };
}
