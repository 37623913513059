import { isDevelopment } from './conditions';

interface ParamsLogger {
  ios_param: any;
  aos_param: any;
}

export const devLogger = (function_name: string, ...params: any) => {
  if (isDevelopment()) {
    console.info(function_name, ...params);
  }
};

export const devParamsLogger = <P extends ParamsLogger>(function_name: string, params: P) => {
  if (isDevelopment()) {
    const { ios_param, aos_param } = params;
    console.groupCollapsed(`%cpartners`, 'color: #ADB5BD', function_name);
    console.info('%cIOS', 'color: #147EFB', ios_param);
    console.info('%cAOS', 'color: #32DE84', aos_param);
    console.groupEnd();
  }
  return params;
};
