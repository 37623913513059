import { Divider, HStack, IconCircleInfo, TextButton, VStack } from '@croquiscom/pds';
import React from 'react';
import { Trans } from 'react-i18next';
import { INSIGHT_GUIDE_OPTIONS } from '../constants';
import { InsightGuideMenuButton } from './InsightGuideMenuButton';
import { HelpConstantLink } from '@/components/common/HelpConstantLink';

interface InsightStarterDescriptionProps {
  menu_type: 'starter' | 'sales' | 'competitor' | 'keyword' | 'customer';
  data_link?: string;
}

const InsightStarterDescription: React.FC<InsightStarterDescriptionProps> = ({ menu_type }) => {
  return (
    <VStack spacing={8}>
      <Trans ns='insight'>최근 6개월 거래액 700 만원 이하 스토어를 위한 기능입니다.</Trans>
      <HStack alignment='center'>
        <InsightGuideMenuButton menu_types={menu_type} />
        <Divider direction='vertical' spacing={8} style={{ height: 12 }} />
        <HelpConstantLink options={INSIGHT_GUIDE_OPTIONS} linkKey='화면 사용법' target='_blank' rel='noreferrer'>
          <TextButton kind='link' size='large' startIcon={<IconCircleInfo />}>
            <Trans ns='insight'>화면 사용법</Trans>
          </TextButton>
        </HelpConstantLink>
        {/*
        //TODO: 데이터 링크 link 삽입 예정
        <Divider direction='vertical' spacing={8} style={{ height: 12 }} />
        <a href={data_link} target='_blank' rel='noreferrer'>
          <TextButton kind='link' startIcon={<IconCircleInfo />}>
            <BaseText kind='Body_14_Regular'>
              <Trans ns='insight'>데이터 활용법</Trans>
            </BaseText>
          </TextButton>
        </a> */}
      </HStack>
    </VStack>
  );
};

export default InsightStarterDescription;
