import dayjs from 'dayjs';
import { useState } from 'react';

const today = dayjs().format('YYYYMMDD');

interface UseNoticeModalStateProps {
  key: string;
  replayFunction?: () => boolean;
}

export const useNoticeModalState = ({ key, replayFunction }: UseNoticeModalStateProps) => {
  const [is_open, setIsOpen] = useState<boolean>(
    replayFunction ? replayFunction : () => localStorage.getItem(key) !== today,
  );
  const [replay_yn, setReplayYn] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const onChangeCheckReplay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplayYn(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem(key, today);
    } else {
      localStorage.removeItem(key);
    }
  };

  return { is_open, replay_yn, onOpen, onClose, onChangeCheckReplay };
};
