import {
  Badge,
  BaseText,
  DataTableColumnsType,
  HStack,
  IconCircleFill,
  IconColoredFire,
  IconColoredWarningRed,
  IconProps,
  semantic_colors,
} from '@croquiscom/pds';
import dayjs from 'dayjs';
import React from 'react';
import BoostingCancelButton from './common/BoostingCancelButton';
import BoostingProductInfo from './common/BoostingProductInfo';
import HelperText from './common/HelperText';
import PriceWithShippingFee from './common/PriceWithShippingFee';
import ProductPerformanceButton from './common/ProductPerformanceButton';
import { ListSubText } from './style';
import { createParamsSchema } from '@/hooks/useValidateParams';
import {
  ShopBoostedProduct,
  ShopPriceOptimizationCompletedProduct,
  ShopPriceOptimizationTargetProductBasicInfo,
  ShopProductDailyImpressionsSummaryDuringOptimization,
} from 'api';

export enum TAB_ITEM {
  ACTIVE = 'ACTIVE',
  LOSS = 'LOSS',
  AVAILABLE = 'AVAILABLE',
  RESULT = 'RESULT',
}

export enum SHIPPING_TYPE {
  ZIGZIN = 'ZIGZIN',
  MERCHANT = 'MERCHANT',
  DIGITAL_DELIVERY = 'DIGITAL_DELIVERY',
}

export const SHIPPING_TYPE_TEXT = {
  [SHIPPING_TYPE.ZIGZIN]: '직진배송',
  [SHIPPING_TYPE.MERCHANT]: '스토어 배송',
  [SHIPPING_TYPE.DIGITAL_DELIVERY]: '디지털',
};

export const BOOSTING_COLUMNS: DataTableColumnsType<ShopPriceOptimizationCompletedProduct> = [
  {
    fixed: true,
    id: 'basic_info',
    text: '상품 정보',
    width: 360,
    cell({
      row,
      value,
    }: {
      row: ShopPriceOptimizationCompletedProduct;
      value: ShopPriceOptimizationTargetProductBasicInfo;
    }) {
      return <BoostingProductInfo basic_info={value} sellable={value.sellable} group_list={row.group_list} />;
    },
  },
  {
    id: 'basic_info',
    text: '노출 확대 성과',
    width: 100,
    cellAlign: 'center',
    cell({ value, row }) {
      return <ProductPerformanceButton basic_info={value} impressions_info={row.impressions_info} />;
    },
  },
  {
    text: '가격/배송비 현황',
    align: 'center',
    columnGroup: [
      {
        id: 'basic_info',
        text: '현재 할인 판매가 (배송비)',
        width: 182,
        cell({ value }) {
          return (
            <PriceWithShippingFee
              price={value.promotion_discount_price}
              shipping_fee={value.current_base_shipping_fee}
            />
          );
        },
      },
      {
        id: 'applying_best_price_info',
        text: (
          <HelperText
            text='추천가 적용 기간'
            content='추천가 적용 시, 상품에 적용되는 기간 (가장 마지막에 적용한 기간만 노출)'
          />
        ),
        width: 182,
        cell({ value }) {
          if (!value) {
            return '-';
          }
          const start = dayjs(value.date_started).format('YYYY.MM.DD HH:mm:ss');
          const end = dayjs(value.date_ended).format('YYYY.MM.DD HH:mm:ss');
          return `${start}
          ~ ${end}`;
        },
      },
      {
        id: 'applying_best_price_info',
        text: (
          <HelperText
            text='추천가 적용 중지'
            content={
              <>
                <BaseText kind='Body_13_Bold'>
                  · 중지 가능
                  <br />
                  <ListSubText>직접 추천가를 적용한 상품</ListSubText>
                </BaseText>
                <br />
                <BaseText kind='Body_13_Bold'>
                  · 중지 불가능
                  <br />
                  <ListSubText>최적의 가격이 적용된 상품 (자동 노출 확대)</ListSubText>
                </BaseText>
              </>
            }
          />
        ),
        align: 'center',
        width: 130,
        cell({ value }) {
          return <BoostingCancelButton applying_best_price_info={value} />;
        },
      },
    ],
  },
  {
    text: '노출 확대 현황',
    align: 'center',
    columnGroup: [
      {
        id: 'impressions_info',
        text: (
          <HelperText
            text='노출 확대 효과'
            content='현재 확대 적용 중인 기간 동안, 기존 노출 수 대비 추가된 노출 비율입니다.'
          />
        ),
        width: 122,
        cell({ value }) {
          return (
            <BaseText color={semantic_colors.state.positive}>
              {value ? `${(value.impressions_increased_rate_bp / 100).toLocaleString()}` : '-'}%
            </BaseText>
          );
        },
      },
      {
        id: 'impressions_info',
        text: <HelperText text='추가 노출 수' content='현재 확대 적용 중인 기간 동안, 추가로 받은 노출 수입니다.' />,
        width: 140,
        cell({ value }) {
          return (
            <BaseText color={semantic_colors.state.positive}>
              {value?.increased_impressions.toLocaleString() ?? '-'}회
            </BaseText>
          );
        },
      },
      {
        id: 'impressions_info',
        text: <HelperText text='확대 적용일' content='현재까지의 노출 확대 적용 일수입니다.' />,
        width: 122,
        cell({ value }) {
          return `${value?.boosting_duration_days.toLocaleString() ?? '-'}일째`;
        },
      },
      {
        id: 'impressions_info',
        text: (
          <HelperText text='확대 적용일 총 노출 수' content='현재 확대 적용 중인 기간 동안, 상품의 총 노출 수입니다.' />
        ),
        width: 160,
        cell({ value }: { value }) {
          return (
            <BaseText color={semantic_colors.content.primary}>
              {value?.total_impressions_in_boosting_period?.toLocaleString() ?? '-'}회
            </BaseText>
          );
        },
      },
    ],
  },
];

export const BOOSTING_HISTORY_COLUMNS: DataTableColumnsType<ShopBoostedProduct> = [
  {
    fixed: true,
    id: 'boosting_info',
    text: '노출 확대 상태',
    width: 130,
    align: 'center',
    cell({ value }) {
      return (
        <HStack spacing={6} alignment='center'>
          <IconCircleFill
            size={10}
            color={value.is_boosted ? semantic_colors.state.positive : semantic_colors.content.tertiary}
          />
          {value.is_boosted ? '노출 확대 중' : '종료'}
        </HStack>
      );
    },
  },
  {
    fixed: true,
    id: 'basic_info',
    text: '상품 정보',
    width: 360,
    cell({ value, row }) {
      return <BoostingProductInfo basic_info={value} sellable={value.sellable} group_list={row.group_list} />;
    },
  },
  {
    id: 'basic_info',
    text: '노출 확대 성과',
    width: 100,
    align: 'center',
    cell({ value, row }) {
      return <ProductPerformanceButton basic_info={value} impressions_info={row.impressions_info} />;
    },
  },
  {
    id: 'basic_info',
    text: '현재 할인 판매가 (배송비)',
    width: 237,
    cell({ value }) {
      return (
        <PriceWithShippingFee price={value.promotion_discount_price} shipping_fee={value.current_base_shipping_fee} />
      );
    },
  },
  {
    text: '노출 현황',
    align: 'center',
    columnGroup: [
      {
        id: 'impressions_info',
        text: (
          <HelperText text='노출 확대 효과' content='노출 확대 적용 기간 동안, 기존 노출 수 대비 추가된 노출 비율' />
        ),
        width: 138,
        cell({ value }) {
          return (
            <BaseText color={semantic_colors.state.positive}>
              {`${((value?.impressions_increased_rate_bp ?? 0) / 100).toLocaleString()}%`}
            </BaseText>
          );
        },
      },
      {
        id: 'impressions_info',
        text: (
          <HelperText
            text='추가 노출 수'
            content='노출 확대 적용 기간 동안, 확대 효과가 적용되어 기존보다 추가로 노출된 횟수'
          />
        ),
        width: 160,
        cell({ value }) {
          return (
            <BaseText color={semantic_colors.state.positive}>
              {`${value?.increased_impressions.toLocaleString() ?? '-'}회`}
            </BaseText>
          );
        },
      },
      {
        id: 'impressions_info',
        text: (
          <HelperText
            text='확대 적용일 총 노출 수'
            content='노출 확대가 적용된 기간 동안, 해당 상품이 고객에게 노출된 총 횟수'
          />
        ),
        width: 160,
        cell({ value }: { value }) {
          return `${value?.total_impressions_in_boosting_period?.toLocaleString() ?? '-'}회`;
        },
      },
      {
        id: 'impressions_info',
        text: <HelperText text='확대 적용일' content='지난 30일간 노출 확대가 적용된 총 일수' />,
        width: 160,
        cell({ value }) {
          return `${value?.boosting_duration_days.toLocaleString() ?? '-'}일`;
        },
      },
      {
        id: 'impressions_info',
        text: (
          <HelperText
            text='총 노출 수'
            content='노출 확대 효과를 적용할 수 있었던 기간 동안, 상품이 노출된 횟수의 총합'
          />
        ),
        width: 160,
        cell({ value }) {
          return `${value?.total_impressions.toLocaleString() ?? '-'}회`;
        },
      },
    ],
  },
];

export const BOOSTING_HISTORY_BY_PRODUCT_COLUMNS: DataTableColumnsType<ShopProductDailyImpressionsSummaryDuringOptimization> =
  [
    {
      id: 'date_ymd',
      text: '날짜',
      cell({ value }) {
        return value ? dayjs(value, 'YYYY-MM-DD').format('YYYY.MM.DD') : '-';
      },
    },
    {
      id: 'is_boosted',
      align: 'center',
      text: (
        <HelperText
          text='확대 적용 여부'
          content={
            <BaseText>
              · 적용 : 확대 효과가 적용된 날입니다.
              <br />· 미적용 : 확대 효과가 적용되지 않은 날입니다.
            </BaseText>
          }
        />
      ),
      cell({ value }: { value: ShopProductDailyImpressionsSummaryDuringOptimization['is_boosted'] }) {
        return (
          <Badge size='medium' color={value ? 'green' : 'gray'} kind='fill'>
            {value ? '적용' : '미적용'}
          </Badge>
        );
      },
    },
    {
      id: 'impressions_summary',
      text: (
        <HelperText
          text='노출 확대 효과'
          content='노출 확대 효과가 적용되기 전 대비, 적용 후 노출이 확대된 변화를 비교한 값'
        />
      ),
      cell({
        value,
        row,
      }: {
        value: ShopProductDailyImpressionsSummaryDuringOptimization['impressions_summary'];
        row: ShopProductDailyImpressionsSummaryDuringOptimization;
      }) {
        return (
          <BaseText color={row.is_boosted ? semantic_colors.state.positive : undefined}>
            {typeof value?.estimated_impressions_increased_rate_bp === 'number'
              ? `${(value.estimated_impressions_increased_rate_bp / 100).toLocaleString()}%`
              : '-'}
          </BaseText>
        );
      },
    },
    {
      id: 'impressions_summary',
      text: (
        <HelperText text='추가 노출 수' content='노출 확대 적용일에 확대 효과가 적용되어 기존보다 추가로 노출된 횟수' />
      ),
      cell({
        value,
        row,
      }: {
        value: ShopProductDailyImpressionsSummaryDuringOptimization['impressions_summary'];
        row: ShopProductDailyImpressionsSummaryDuringOptimization;
      }) {
        return (
          <BaseText color={row.is_boosted ? semantic_colors.state.positive : undefined}>
            {typeof value?.estimated_increased_impressions === 'number'
              ? `${value.estimated_increased_impressions.toLocaleString()}회`
              : '-'}
          </BaseText>
        );
      },
    },
    {
      id: 'impressions_summary',
      text: <HelperText text='총 노출 수' content='상품이 노출된 횟수의 총합' />,
      cell({ value }: { value: ShopProductDailyImpressionsSummaryDuringOptimization['impressions_summary'] }) {
        return typeof value?.total_impressions === 'number' ? `${value.total_impressions.toLocaleString()}회` : '-';
      },
    },
  ];

export const DEFAULT_PAGE_SIZE = 100;

export const PAGE_SIZE_OPTIONS = [
  { value: 20, label: '20개씩 보기' },
  { value: 50, label: '50개씩 보기' },
  { value: 100, label: '100개씩 보기' },
];
export const CARD_PAGE_SIZE_OPTIONS = [
  { value: 30, label: '30개씩 보기' },
  { value: 60, label: '60개씩 보기' },
];

export enum APPLIED_STATE {
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
}

export enum APPLY_RESULT_TYPE {
  'NONE' = 'NONE', //적용 X(할인판매가 적용 요청 안함)
  'SUCCESS' = 'SUCCESS', //적용 성공
  'FAILED' = 'FAILED', //적용 실패
}

export const BOOSTING_PRODUCT_LIST_QUERY_KEY = 'GetBoostingProductList';
export const BOOSTING_AVAILABLE_LIST_QUERY_KEY = 'GetBoostingAvailableProductList';
export const DEBOOSTING_LIST_QUERY_KEY = 'getDeboostingProductList';

export const FAQ_CONTENTS = [
  {
    q: '추천가는 어떻게 결정되나요?',
    a: `추천가는 고객이 가격을 쉽게 비교할 수 있도록 다양한 요소를 고려하여 결정됩니다. ‘구매자의 가격 비교 가능성, 상품의 과거 판매 이력 중 가장 효율이 높았던 가격, 유사 카테고리 상품의 판매 데이터와 고객 반응’ 등을 반영해 가장 적합한 가격을 추천합니다.`,
  },
  {
    q: '추천가를 적용하면 노출 확대가 즉시 적용되나요?',
    a: `추천가를 적용하면 노출 확대 효과는 최대 3시간 내에 적용되며, 성과 데이터는 다음 날에 집계됩니다. 추천가 금액이 변동되면 효과가 적용되지 않을 수 있으니, 노출 확대 가능 상품에서 새로운 추천가를 확인하고 적용해 보세요.`,
  },
  {
    q: '추천가 적용 기간 동안 가격을 변경할 수 있나요?',
    a: `추천가 적용은 언제든 중지할 수 있습니다. 기획전이나 할인 판매 관리 기능에서도 가격을 변경할 수 있으며, 이때 가격이 추천 가격과 유사하면 노출 확대 효과가 자동으로 발생할 수 있습니다.`,
  },
  {
    q: '노출 확대 적용 중인데, 노출량이 떨어질 수 있나요?',
    a: `네. ‘상품이 통합되거나 판매 불가 또는 미진열’ 등 특수한 상황의 경우, 노출 확대 효과가 적용되지 않을 수 있습니다. 노출량은 최신 트렌드나 기획전에도 영향을 받으니, 주기적으로 상품의 가격을 최적화하는 것을 추천드립니다.`,
  },
  {
    q: '노출 확대 효과가 너무 적어요.',
    a: `노출 확대 적용 기간이 짧을수록 ‘노출 확대 효과’의 비교가 어려울 수 있습니다. 또는 경쟁 상품의 노출 증가로 인해 효과가 감소할 수도 있으니, 지속적으로 상품의 가격 최적화를 통해 노출량을 보호해 주세요.`,
  },
];

export enum PRODUCT_GROUP_KEY {
  NEW_PRODUCT = 'NEW_PRODUCT',
  PROMOTION_PRODUCT = 'PROMOTION_PRODUCT',
}

export enum FILTER_TYPE {
  ALL = 'ALL',
  PROMOTION_PRODUCT = 'PROMOTION_PRODUCT',
  REAPPLY = 'REAPPLY',
  NEW_PRODUCT = 'NEW_PRODUCT',
  ZIGZIN = 'ZIGZIN',
}

export const FILTER_TYPE_TEXT: Record<FILTER_TYPE, { text: string; icon?: React.ReactElement<IconProps> }> = {
  [FILTER_TYPE.NEW_PRODUCT]: { text: '신상품' },
  [FILTER_TYPE.ZIGZIN]: { text: '직진배송' },
  [FILTER_TYPE.REAPPLY]: { text: '재조정 필요', icon: <IconColoredWarningRed /> },
  [FILTER_TYPE.PROMOTION_PRODUCT]: { text: '기획전', icon: <IconColoredFire /> },
  [FILTER_TYPE.ALL]: { text: '전체' },
};

export const LIST_PARAMS_SCHEMA = createParamsSchema((p) => ({
  tab: p.default(p.enum([TAB_ITEM.AVAILABLE, TAB_ITEM.ACTIVE, TAB_ITEM.LOSS, TAB_ITEM.RESULT]), TAB_ITEM.AVAILABLE),
  filter: p.default(
    p.enum([
      FILTER_TYPE.ALL,
      FILTER_TYPE.NEW_PRODUCT,
      FILTER_TYPE.PROMOTION_PRODUCT,
      FILTER_TYPE.REAPPLY,
      FILTER_TYPE.ZIGZIN,
    ]),
    FILTER_TYPE.ALL,
  ),
  per_page: p.default(p.number(), 30),
  page: p.default(p.number(), 1),
}));
