import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { ButtonHTMLAttributes } from 'react';

interface SegmentButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
}

export const SegmentButton = styled.button<SegmentButtonProps>`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  height: 34px;
  padding: 0 12px;
  min-width: 70px;
  background-color: ${colors.white};

  ${text_styles.Body_13_Regular};
  ${({ selected }) =>
    selected
      ? `
    color: ${colors.blue500};
    border: 1px solid ${colors.blue500};
    &:hover,
    &:focus {
      background-color: ${colors.blue100};
    }
    `
      : `
    color: ${colors.gray900};
    border: 1px solid ${colors.gray300};
    &:hover,
    &:focus {
      background-color: ${colors.gray100};
    }
    `}
  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    cursor: not-allowed;
    `
      : `
    cursor: pointer;
    `}

  &:focus {
    outline: 0;
  }
`;
