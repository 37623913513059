import { Alert, HStack } from '@croquiscom/pds';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

interface Props {
  children: React.ReactNode;
}

const VaildPaymentChecker = ({ children }: Props) => {
  const { info } = useInfo();
  const { t } = useTranslation('insight');
  const history = useHistory();
  const prefix = useShopPrefix();

  useEffect(() => {
    if (!info.shop_subscription_resource.service_available) {
      Alert({
        kind: 'error',
        title: t('파트너플러스 구독료 정기 결제를 실패하여 파트너플러스 인사이트를 사용할 수 없습니다.'),
        text: t('결제 실패한 카드 정보를 변경하고 결제를 다시 시도해보세요.'),
        confirmText: t('결제 정보 확인'),
        onClose() {
          history.push(`${prefix}${PartnerPlusNavUrl.MANAGEMENT_SUBSCRIPTION}`);
        },
      });
    }
  }, []);
  if (!info.shop_subscription_resource.service_available) {
    return <HStack height={500}></HStack>;
  } else {
    return <>{children}</>;
  }
};

export default VaildPaymentChecker;
