import qs from 'qs';
import React, { ComponentType, FC, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { mapOrder } from '../../utils';
import { AdCreativeFormProps } from './index';

import {
  api_checkAdCenterContentValid,
  api_createShopDetailShortUrlInAppsflyer,
  api_getAdCenterAdAttention,
  api_getAdCenterCatalogProductInfo,
  api_getAdCenterCreativeAttention,
  api_getAdCenterProductBenefit,
  api_getAdCenterStoreHomeBenefit,
  api_getAdCenterStorePick,
  api_getAdCenterStorePickBenefit,
  api_getStorePickListByStoreInAd,
  StorePickBannerDisplayStatus,
  StorePickStatus,
  api_searchedProductListInAd,
  api_getAdCenterContentBenefit,
  api_getShopUxCollectionInAd,
  api_getAdCenterAdExhibition,
  AdCenterCreativeBackgroundColorType,
  GetAdCenterCreativeAttentionQuery,
  GetAdCenterProductBenefitQuery,
  GetAdCenterStorePickQuery,
  GetStorePickListByStoreQuery,
  SearchAdCenterCatalogProductListAttentionQuery,
  SearchedProductListInAdQuery,
} from '@/api';

import { AdCreativeFormMode, Landing } from '@/components/advertisement/attention/AddAdCreative/types';
import { AD_TYPE } from '@/components/advertisement/attention/AddAdGroup';
import { useLocation, useParams } from '@/components/route/LegacyRouterCompat';
import { SiteId } from '@/constants/site';

// TODO: GetAdCenterStorePick_ad_center_store_pick, { id: string | null } 케이스 확인 필요
export type SelectedItem =
  | SearchAdCenterCatalogProductListAttentionQuery['ad_center_catalog_product_info_list']['item_list'][number]
  | GetStorePickListByStoreQuery['getStorePickListByStore']['item_list'][number]
  | { banner: NonNullable<GetAdCenterStorePickQuery['ad_center_store_pick']> }
  | NonNullable<GetAdCenterStorePickQuery['ad_center_store_pick']>
  | { id: string | null }
  | null;

export interface FormType {
  previewSrc?: string | null;
  mainCopy?: string | null;
  subCopy?: string | null;
  landingType?: Landing | null;
  selectedItem: SelectedItem;
  imageFile?: File | null;
  badgeBenefit?: NonNullable<NonNullable<GetAdCenterProductBenefitQuery['ad_center_product_benefit']>[number]> | null;
  benefits?: Array<NonNullable<
    NonNullable<GetAdCenterProductBenefitQuery['ad_center_product_benefit']>[number]
  > | null> | null;
  selectedBenefitIdx: {
    main: number;
    sub: number;
  } | null;
  inspectionComment: string | null;
  adCopy: string | null;
  imageUrl?: string | null;
  backgroundColor?: AdCenterCreativeBackgroundColorType;
  badgeColor?: string;
  bridgePage: {
    display_category_product: boolean;
    use: boolean;
    product_list: SearchedProductListInAdQuery['searched_product_list']['item_list'][number][];
    text_data: string;
  };
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type AddAdCreativePageQuery = {
  type: AD_TYPE;
  reference_creative_id: string;
  reference_ad_id: string;
};

export interface AddAdCreativeParams {
  id: string;
  creativeId: string;
}

interface Props {
  Form: ComponentType<AdCreativeFormProps>;
}

export const AdCreativeFormContext: FC<Props> = ({ Form }) => {
  const { search, pathname } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true }) as AddAdCreativePageQuery;
  const params = useParams<AddAdCreativeParams>();

  const initial_form_state = useRef<FormType>({
    previewSrc: undefined,
    mainCopy: '',
    subCopy: '',
    landingType: undefined,
    selectedItem: null,
    imageFile: undefined,
    benefits: undefined,
    badgeBenefit: undefined,
    selectedBenefitIdx: {
      main: -1,
      sub: -1,
    },
    inspectionComment: null,
    adCopy: null,
    imageUrl: null,
    backgroundColor: undefined,
    badgeColor: undefined,
    bridgePage: {
      display_category_product: true,
      use: false,
      product_list: [],
      text_data: '',
    },
  });
  const form = useForm<FormType>({ defaultValues: initial_form_state.current });

  // props 생성
  const form_mode = getFormMode(pathname, query);
  const creative_id = form_mode === AdCreativeFormMode.EDIT ? params.creativeId : query.reference_creative_id;
  const ad_id = form_mode === AdCreativeFormMode.EDIT ? params.id : query.reference_ad_id;
  const ad_set_id = form_mode === AdCreativeFormMode.EDIT ? undefined : params.id;
  const type = query.type;
  const props = {
    creative_id,
    ad_id,
    ad_set_id,
    type,
    form_mode,
    initial_form_state,
  } as AdCreativeFormProps;

  useQuery(['getCreativeFormInitialData', creative_id, ad_id], () => getCreativeFormInitialData(ad_id!, creative_id!), {
    enabled: !!ad_id && !!creative_id,
    onSuccess: (result) => {
      const { setValue, setError } = form;
      const non_exposure_reason = Object.entries(result.validation)?.[1]?.[1];

      Object.entries(result.form).forEach(([name, value]) => setValue(name as keyof FormType, value));
      Object.entries(result.validation).forEach(
        ([name, message]) =>
          message &&
          setError(name as keyof FormType, {
            type: 'custom',
            message,
          }),
      );
      if (form_mode === AdCreativeFormMode.EDIT) {
        initial_form_state.current = {
          ...initial_form_state.current,
          ...result.form,
        };
      }
      if (non_exposure_reason === 'LANDING_URL_DELETED') {
        setValue('selectedItem', null);
      }
    },
    onError: (error) => {
      // TODO: 데이터 초기화 실패 처리하기
      console.error(error);
    },
  });

  return (
    <FormProvider {...form}>
      <Form {...props} />
    </FormProvider>
  );
};

const getFormMode = (pathname: string, query: AddAdCreativePageQuery) => {
  if (pathname.includes('/edit')) {
    return AdCreativeFormMode.EDIT;
  }
  if (query.reference_creative_id && query.reference_ad_id) {
    return AdCreativeFormMode.COPY;
  }
  return AdCreativeFormMode.CREATE;
};

type BenefitsAndContentsFormData =
  | Pick<FormType, 'benefits' | 'badgeBenefit' | 'landingType' | 'selectedItem' | 'bridgePage'>
  | undefined;

const getPDPBenefitsAndContentsData = async (
  product_id: string,
  benefit_info?: NonNullable<
    NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['benefit_info']
  > | null,
  bridge_info?: NonNullable<NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['bridge_info']> | null,
) => {
  // 혜택관련 정보
  const {
    data: { ad_center_product_benefit },
  } = await api_getAdCenterProductBenefit({
    site_id: SiteId.지그재그,
    product_id,
  });
  const is_valid_benefit_badge = ad_center_product_benefit?.find(
    (benefit) => benefit && benefit.benefit_type === benefit_info?.benefit_type,
  );

  // 랜딩 페이지 정보
  const {
    data: { ad_center_catalog_product_info },
  } = await api_getAdCenterCatalogProductInfo({
    site_id: SiteId.지그재그,
    catalog_product_id: product_id,
  });
  const selectedItem = ad_center_catalog_product_info
    ? {
        ...ad_center_catalog_product_info,
        landing_url: ad_center_catalog_product_info?.url,
      }
    : undefined;

  const product_id_list =
    bridge_info?.product_id_list &&
    (await api_searchedProductListInAd({
      input: {
        product_id_list: bridge_info?.product_id_list,
        site_id_list: [SiteId.지그재그],
        limit_count: 50,
      },
    }));

  const re_orderd_by_ids = mapOrder(
    product_id_list?.data.searched_product_list.item_list ?? [],
    bridge_info?.product_id_list,
    'id',
  );

  return {
    landingType: Landing.PRODUCT,
    benefits: ad_center_product_benefit,
    badgeBenefit: is_valid_benefit_badge ? benefit_info : undefined,
    selectedItem: selectedItem as SelectedItem,
    bridgePage: {
      use: bridge_info?.is_active ?? false,
      product_list: re_orderd_by_ids,
      display_category_product: bridge_info?.display_category_product ?? false,
      text_data: bridge_info?.text_data ?? '',
    },
  } as BenefitsAndContentsFormData;
};

const getStoreHomeBenefitsAndContentsData = async (
  shop_id: string,
  benefit_info?: NonNullable<
    NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['benefit_info']
  > | null,
) => {
  // 혜택관련 정보
  const {
    data: { ad_center_store_home_benefit },
  } = await api_getAdCenterStoreHomeBenefit({
    site_id: SiteId.지그재그,
    shop_id,
  });
  const is_valid_benefit_badge = ad_center_store_home_benefit?.find((benefit) => {
    return benefit && benefit.benefit_type === benefit_info?.benefit_type;
  });

  // 랜딩 페이지 정보
  const { data } = await api_createShopDetailShortUrlInAppsflyer();
  const selectedItem = {
    id: shop_id,
    landing_url: data.createShopDetailShortUrlInAppsflyer?.short_url,
  } as SelectedItem;

  return {
    landingType: Landing.STORE_HOME,
    benefits: ad_center_store_home_benefit,
    badgeBenefit: is_valid_benefit_badge ? benefit_info : undefined,
    selectedItem,
  } as BenefitsAndContentsFormData;
};

const getStorePickBenefitsAndContentsData = async (
  store_pick_id: string,
  benefit_info?: NonNullable<
    NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['benefit_info']
  > | null,
) => {
  // 혜택관련 정보
  const {
    data: { ad_center_store_pick_benefit },
  } = await api_getAdCenterStorePickBenefit({
    site_id: SiteId.지그재그,
    store_pick_id,
  });
  const is_valid_benefit_badge = ad_center_store_pick_benefit?.find((benefit) => {
    return benefit && benefit.benefit_type === benefit_info?.benefit_type;
  });

  // 랜딩 페이지 정보
  const [
    {
      data: { ad_center_store_pick },
    },
    {
      data: {
        getStorePickListByStore: { item_list },
      },
    },
  ] = await Promise.all([
    api_getAdCenterStorePick({
      site_id: SiteId.지그재그,
      store_pick_id,
    }),
    api_getStorePickListByStoreInAd({
      status_in: [StorePickStatus.APPROVE],
      banner_display_status_in: [StorePickBannerDisplayStatus.DISPLAY],
    }),
  ]);
  const store_pick_detail = item_list.find((detail) => `${detail.id}` === ad_center_store_pick?.store_pick_id);
  const selectedItem = ad_center_store_pick
    ? {
        id: ad_center_store_pick.store_pick_id,
        banner: ad_center_store_pick,
        landing_url: store_pick_detail?.content_short_url,
      }
    : undefined;

  return {
    landingType: Landing.STORE_PICK,
    benefits: ad_center_store_pick_benefit,
    badgeBenefit: is_valid_benefit_badge ? benefit_info : undefined,
    selectedItem: selectedItem as SelectedItem,
  } as BenefitsAndContentsFormData;
};

const getLandingBenefitsAndContentsData = async (
  type: string,
  object_id: string,
  benefit_info?: NonNullable<
    NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['benefit_info']
  > | null,
  landing_url?: string | null,
) => {
  // 혜택관련 정보
  const {
    data: { ad_center_content_benefit },
  } = await api_getAdCenterContentBenefit({
    site_id: SiteId.지그재그,
    object_id,
    landing_type: type,
  });
  const is_valid_benefit_badge = ad_center_content_benefit?.find((benefit) => {
    return benefit && benefit.benefit_type === benefit_info?.benefit_type;
  });

  let selectedItem;
  // 랜딩 페이지 정보
  if (type === 'COLLECTION') {
    const data = await api_getShopUxCollectionInAd({ id: object_id });
    selectedItem = data.data.shop_ux_collection;
  } else {
    // EXHIBITIONS
    const data = await api_getAdCenterAdExhibition({ exhibition_id: object_id, site_id: SiteId.지그재그 });
    selectedItem = data.data.ad_center_ad_exhibition;
  }

  return {
    landingType: type === 'COLLECTION' ? Landing.COLLECTION : Landing.EXHIBITION,
    benefits: ad_center_content_benefit,
    badgeBenefit: is_valid_benefit_badge ? benefit_info : undefined,
    selectedItem: { ...selectedItem, landing_url } as SelectedItem,
  } as BenefitsAndContentsFormData;
};

const getValidationData = async (id: string, content_type: string) => {
  const {
    data: { ad_center_content_valid_check },
  } = await api_checkAdCenterContentValid({
    site_id: SiteId.지그재그,
    content_type,
    id,
  });

  let message: string | undefined = undefined;
  if (ad_center_content_valid_check && !ad_center_content_valid_check.is_valid) {
    message = '사용할 수 없는 랜딩페이지 입니다. 제거하고 다시 선택 해 주세요.';
    switch (ad_center_content_valid_check.non_exposure_reason) {
      case 'STORE_PICK_NOT_IN_PROGRESS':
      case 'INVALID_STORE_PICK_PERIOD':
        message += "\n사유: 등록하신 스토어픽의 상태가 '진행중'이 아닙니다.";
        break;
      case 'PRODUCT_HIDDEN':
        message += '\n사유: 파트너센터, 혹은 자사 사이트에서 삭제 또는 미진열 처리된 상품입니다.';
        break;
      case 'PRODUCT_SOLD_OUT':
        message += '\n사유 : 품절된 상품입니다.';
        break;
      case 'PRODUCT_INEXPENSIVE':
        message += '\n사유: 상품 가격이 1,000원 미만 또는 500,000원 이상입니다.';
        break;
      case 'PRODUCT_DELETED':
        message += '\n사유: 상품이 제거되었습니다.';
        break;
      case 'LANDING_URL_DELETED':
        message += '\n사유: 삭제된 랜딩 페이지입니다.';
        break;
      case 'UNKNOWN':
      default:
        message += '\n사유: 알 수 없는 문제 (문의전화 : 1670-8051 > 내선2번)';
    }
  }

  return { selectedItem: message, non_exposure_reason: ad_center_content_valid_check?.non_exposure_reason };
};

// 만료된 혜텍 코드를 - 로 교체
const replaceExpiredBenefitCode = (
  text: string,
  mapping_info: (NonNullable<
    NonNullable<NonNullable<GetAdCenterCreativeAttentionQuery['ad_center_creative']>['mapping_info']>[number]
  > | null)[],
) => {
  return mapping_info.reduce(
    (acc, mapper) => (mapper?.value === '-' ? acc.replaceAll(mapper.alternate_text, mapper.value) : acc),
    text,
  );
};

const getCreativeFormInitialData = async (ad_id: string, creative_id: string) => {
  const [creative_query_result, ad_query_result] = await Promise.all([
    api_getAdCenterCreativeAttention({ creative_id }),
    api_getAdCenterAdAttention({ site_id: SiteId.지그재그, ad_id }),
  ]);

  const { ad_center_creative } = creative_query_result.data;
  const { ad_center_ad } = ad_query_result.data;
  if (!ad_center_creative || !ad_center_ad) {
    throw Error(`
      Fail to init form data.
      One of following is null or undefined for given ad_id ${ad_id} and creative_id ${creative_id}.
      ad_center_creative: ${ad_center_creative}
      ad_center_ad: ${ad_center_ad}
    `);
  }

  const {
    image_url,
    text_data,
    sub_text_data,
    benefit_info,
    inspection_comment,
    mapping_info,
    bridge_info,
    background_color,
    badge_color,
  } = ad_center_creative;
  const { landing_type, object_id, landing_url } = ad_center_ad;

  let form: FormType = {
    previewSrc: image_url,
    imageUrl: image_url,
    adCopy: replaceExpiredBenefitCode(text_data ?? '', mapping_info ?? []),
    mainCopy: replaceExpiredBenefitCode(text_data ?? '', mapping_info ?? []),
    subCopy: replaceExpiredBenefitCode(sub_text_data ?? '', mapping_info ?? []),
    inspectionComment: inspection_comment,
    backgroundColor: background_color,
    badgeColor: badge_color,
  } as FormType;

  if (object_id) {
    if (landing_type === 'PDP') {
      form = {
        ...form,
        ...(await getPDPBenefitsAndContentsData(object_id, benefit_info, bridge_info)),
      };
    }
    if (landing_type === 'STORE_PICK') {
      form = {
        ...form,
        ...(await getStorePickBenefitsAndContentsData(object_id, benefit_info)),
      };
    }
    if (landing_type === 'STORE_HOME') {
      form = {
        ...form,
        ...(await getStoreHomeBenefitsAndContentsData(object_id, benefit_info)),
      };
    }
    if (landing_type === 'COLLECTION' || landing_type === 'EXHIBITIONS') {
      form = {
        ...form,
        ...(await getLandingBenefitsAndContentsData(landing_type, object_id, benefit_info, landing_url)),
      };
    }
  }

  let validation: Partial<Record<keyof FormType, string>> = {};
  if (object_id && landing_type !== 'STORE_HOME' && landing_type !== 'EXHIBITIONS' && landing_type) {
    validation = await getValidationData(object_id, landing_type === 'PDP' ? 'CATALOG' : landing_type);
  }

  return { form, validation };
};
