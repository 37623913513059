export enum Landing {
  PRODUCT,
  STORE_PICK,
  STORE_HOME,
  EXHIBITION,
  COLLECTION,
}

export const enum AdCreativeFormMode {
  CREATE = 'create',
  COPY = 'copy',
  EDIT = 'edit',
}
