import { LineTab, LineTabs } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetInsightStaterRevenue } from '../intro/hooks/useGetInsightStaterRevenue';
import useEachPbl from '@/hooks/useEachPbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { InsightNavUrl } from 'rui/components/URL';

export enum INSIGHT_STARTER_PLAN {
  CHECKLIST = 'CHECKLIST',
  ITEM_INSIGHT = 'ITEM_INSIGHT',
}

interface InsightStarterPlanTabProps {
  current_tab: INSIGHT_STARTER_PLAN;
}

const InsightStarterPlanTab: React.FC<InsightStarterPlanTabProps> = ({ current_tab }) => {
  const { t } = useTranslation('insight');
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { eachPbl } = useEachPbl();
  const { data: shop_insight_growth_checklist } = useGetInsightStaterRevenue();

  const onChange = (tab_id: INSIGHT_STARTER_PLAN) => {
    const tab = tab_id === INSIGHT_STARTER_PLAN.CHECKLIST ? 'check_list' : 'daily_top20';

    eachPbl({
      navigation: 'insight_starter',
      navigation_sub: {
        revenue_tier: shop_insight_growth_checklist?.description ?? '',
        tab,
      },
      category: 'click',
      object_type: 'tab',
      object_id: tab,
    });

    history.push(
      `${shop_prefix}${tab_id === INSIGHT_STARTER_PLAN.CHECKLIST ? InsightNavUrl.CHECKLIST : InsightNavUrl.ITEM}`,
    );
  };

  return (
    <LineTabs activeTabId={current_tab} onChange={onChange}>
      <LineTab id={INSIGHT_STARTER_PLAN.CHECKLIST}>{t('목표 매출 체크리스트')}</LineTab>
      <LineTab id={INSIGHT_STARTER_PLAN.ITEM_INSIGHT}>{t('데일리 TOP20')}</LineTab>
    </LineTabs>
  );
};

export default InsightStarterPlanTab;
