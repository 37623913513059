import { IconColoredRanking } from '@croquiscom/pds';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Nodata from './common/Nodata';
import ProductSwiper from './common/ProductSwiper';
import SectionTitle from './common/SectionTitle';
import { ContentContainer } from './common/styles';
import { useGetDailyReportOrderTopRanking } from './hooks/useGetDailyReportOrderTopRanking';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const OrderTop: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const { data } = useGetDailyReportOrderTopRanking({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: false,
    main_domain,
    token,
  });
  const order_data = data?.partner_plus_daily_report?.yesterday_order_top_ranking_report;
  const has_data = order_data && order_data.product_ranking_list.length > 0;

  return (
    <ContentContainer>
      <SectionTitle
        object_section='어제 주문 TOP 10'
        onClickLink={onClose}
        main_domain={main_domain}
        Icon={IconColoredRanking}
        link='/product_stats?order_type=ORDER_COUNT_RANKING_DESC'
        is_partner_plus_only={order_data?.plus_only}
        is_partner_plus={is_partner_plus}
        description={t('주문수가 많은 상품을 확인하고 재고나 배송을 미리 체크해보세요.')}
      >
        {t('어제 주문 TOP 10')}
      </SectionTitle>
      {has_data ? (
        <ProductSwiper
          is_partner_plus={is_partner_plus}
          object_section='어제 주문 TOP 10'
          type='order'
          width={248}
          product_ranking_list={order_data?.product_ranking_list || []}
          base_date={date}
        ></ProductSwiper>
      ) : (
        <Nodata />
      )}
    </ContentContainer>
  );
};

export default OrderTop;
