import styled from '@emotion/styled';

const StyledAspectRatioWrapper = styled.div<{ ratio?: number }>`
  position: relative;
  width: 100%;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc(${({ ratio }) => ratio || 1} * 100%);
  }
`;

export default StyledAspectRatioWrapper;
