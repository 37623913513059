import { useQuery } from 'react-query';
import {
  api_getDailyReportSalesChangeTopRanking,
  api_getMetaDailyReportSalesChangeTopRanking,
  api_getMetaDailyReportSalesChangeTopRankingBlur,
  GetDailyReportSalesChangeTopRankingQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportSalesChangeTopRankingQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportSalesChangeTopRanking = ({
  date_ymd,
  use_free_view_count,
  main_domain = '',
  token = '',
}: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportSalesChangeTopRanking', { date_ymd, use_free_view_count, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportSalesChangeTopRanking({ date_ymd, use_free_view_count }, { no_alert: true })
        : use_free_view_count
          ? await api_getMetaDailyReportSalesChangeTopRanking(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            )
          : await api_getMetaDailyReportSalesChangeTopRankingBlur(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
