import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router'; // LegacyCompat 변환 X
import { useInfo } from '@/hooks/useInfo';
import analytics from '@/utils/analytics';

export function PageViewLogger(): null {
  const location = useLocation();
  const { info } = useInfo();

  useEffect(() => {
    ReactGA.initialize(CUSTOM_VARS.ga_id);
  }, []);

  useEffect(() => {
    analytics.sendPageView(location.pathname + location.search, info);
  }, [location]);

  return null;
}
