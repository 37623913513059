import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import Card from 'pastel/Card';
import { defaultLine } from 'styles';

interface NotificationDialogContentProps {
  title?: React.ReactNode;
  text?: React.ReactNode;
  subtext?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  width?: number;
}

/*
자주 사용되는 간단한 모달의 내용은 요 컴포넌트를 사용하시면 됩니다.

모달명

제목
상세한 네용

동작 버튼(단일/다수)
*/
const NotificationDialogContent = forwardRef<HTMLDivElement, NotificationDialogContentProps>(
  ({ title, text, subtext, actions, width, ...rest }, ref) => {
    return (
      <StyledNotificationDialogContent
        {...rest}
        ref={ref}
        width={width}
        className='local-dialog-content'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <StyledCard title={title}>
          <div className='text-container'>
            {text && <div className='text'>{text}</div>}
            {subtext && <div className='subtext'>{subtext}</div>}
          </div>
          {actions && <div className='text-buttons'>{actions}</div>}
        </StyledCard>
      </StyledNotificationDialogContent>
    );
  },
);

const StyledNotificationDialogContent = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '460px')};

  .text-container {
    width: 100%;
    padding: 30px;
    color: ${colors.gray900};
  }

  .text {
    display: flex;
    align-items: center;
    line-height: 1.5;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.5px;
    color: ${colors.gray900};
    white-space: pre-wrap;
    margin-bottom: 20px;
  }

  .subtext {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.3px;
    color: ${colors.gray900};
    white-space: pre-wrap;
  }

  .text-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
    padding: 0 28px;
    height: 72px;
    border-top: ${defaultLine};

    button {
      margin: 0 0 0 6px;
      min-width: 80px;
    }

    button:first-of-type {
      margin: 0;
    }
  }
`;

const StyledCard = styled(Card)`
  margin: 0;
  border: 0;

  .card-title {
    display: flex;
    align-items: center;
    padding: 0 25px;
    height: 50px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: ${colors.gray900};
  }
  .card-content {
    flex-direction: column;
  }
`;

export default NotificationDialogContent;
