import { colors, HStack, IconNew, LineTab, LineTabs } from '@croquiscom/pds';
import React from 'react';
import TransPlainText from '@/components/common/TransPlainText';
import { useHistory } from '@/components/route/LegacyRouterCompat';
import { WarehousedInUrl } from 'rui/components/URL';

interface ZigzinInsightNavItemData {
  id: 'today' | 'result' | 'stock' | 'recommended';
  label: React.ReactNode;
  href: string;
}

const NAV_ITEMS: ZigzinInsightNavItemData[] = [
  {
    id: 'today',
    label: <TransPlainText ns='zigzin' i18nKey='오늘의 인사이트' />,
    href: `${WarehousedInUrl.INSIGHT_TODAY}?card=lack_of_sales`,
  },
  {
    id: 'result',
    label: <TransPlainText ns='zigzin' i18nKey='판매/노출 성과' />,
    href: '/zigzin/insight/performance',
  },
  {
    id: 'stock',
    label: <TransPlainText ns='zigzin' i18nKey='재고 통계' />,
    href: '/zigzin/insight/stock',
  },
  {
    id: 'recommended',
    label: (
      <HStack alignment='center' spacing={2.5}>
        <TransPlainText ns='zigzin' i18nKey='등록 권장 상품' />
        <IconNew size={8} color={colors.blue500} />
      </HStack>
    ),
    href: '/zigzin/insight/recommended',
  },
];

export interface ZigzinInsightNavProps {
  tab?: ZigzinInsightNavItemData['id'];
}

export const ZigzinInsightNav: React.FC<ZigzinInsightNavProps> = ({ tab }) => {
  const history = useHistory();
  return (
    <LineTabs activeTabId={tab}>
      {NAV_ITEMS.map((item) => (
        <LineTab
          key={item.id}
          id={item.id}
          onClick={() => {
            history.push(item.href);
          }}
        >
          {item.label}
        </LineTab>
      ))}
    </LineTabs>
  );
};
