import React from 'react';
import { FEEDBACK_OPTIONS, FEEDBACK_TYPE } from '../constants';
import { Feedback } from './Feedback';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';

interface ChatBotFeedbackProps {
  opened: boolean;
  onClose?: () => void;
}

export const ChatBotFeedback = ({ opened, onClose }: ChatBotFeedbackProps) => {
  const { pbl } = usePbl();
  const { is_desktop } = useMediaQuery();
  return (
    <Feedback
      opened={opened}
      has_done_with_button={!is_desktop}
      onClose={onClose}
      detail_options={FEEDBACK_OPTIONS}
      onDone={({ type, text, score }) => {
        pbl({
          navigation: 'partner_center_chatbot',
          navigation_sub: {
            sub: 'survey',
          },
          category: 'click',
          object_id: score >= 3 ? 'bad' : 'good',
          extra: JSON.stringify({
            score,
            feedback_type: type,
            comments: type === FEEDBACK_TYPE.CUSTOM_INPUT ? text : '',
          }),
        });
      }}
      score_title='파트너센터 챗봇 어떠셨나요?'
      trigger={<div></div>}
    />
  );
};
