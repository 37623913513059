import { chain, isEmpty } from 'lodash';
import { CountryCode, GetSiteCountryListQuery } from '@/api';
import { SiteId } from '@/constants/site';

type ShopSiteCountryList = GetSiteCountryListQuery['shop_site_country_list'][number];

export type ShopSiteCountry = Pick<ShopSiteCountryList, 'site_id' | 'country_code'>;
type SiteCountryIterator<TResult> = (value: ShopSiteCountry) => TResult;
type SiteCountryKey = keyof ShopSiteCountry;

const defaultSiteCountryIterator = (key: SiteCountryKey) => (value: ShopSiteCountry) => value[key];

const getUniqSiteCountryListFactory =
  (key: SiteCountryKey) =>
  <TResult>(site_country_list: ShopSiteCountry[], iterator?: SiteCountryIterator<TResult>) => {
    const chain_func = chain(site_country_list).uniqBy(key);
    return (iterator ? chain_func.map(iterator) : chain_func.map(defaultSiteCountryIterator(key))).value();
  };

export const getUniqSiteIdList = getUniqSiteCountryListFactory('site_id');
export const getUniqCountryCodeList = getUniqSiteCountryListFactory('country_code');

export type SiteCountryStringKey =
  | `${ShopSiteCountry['site_id']}_${ShopSiteCountry['country_code']}`
  | `${ShopSiteCountry['site_id']}_${ShopSiteCountry['country_code']}/${ShopSiteCountry['site_id']}_${ShopSiteCountry['country_code']}`;

export const getSiteCountrybyKey = (site_country_key: SiteCountryStringKey): ShopSiteCountry[] => {
  return site_country_key
    .split('/')
    .map((key) => {
      const [site_id, country] = key.split('_');

      if (!Object.values(SiteId).some((_site_id) => _site_id === site_id)) {
        return null;
      }

      if (!Object.values(CountryCode).some((country_code) => country_code === country)) {
        return null;
      }

      return {
        site_id,
        country_code: CountryCode[country],
      };
    })
    .filter((site_country): site_country is NonNullable<ShopSiteCountry> => !isEmpty(site_country));
};
