import { IconBarChart } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { CountryCode, SellerResource } from 'api';

export function createInsightMenuTree({ info, account }: CreateMenuTreeOptions): MenuEntry[] {
  const is_insight_allowed =
    account?.is_zigzag ||
    (isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]) &&
      isAllowedMenuByCountry(info, [CountryCode.KR]));
  return [
    {
      id: 'insight',
      label: t('main_frame::파트너플러스 인사이트'),
      icon: <IconBarChart size={16} color='currentColor' />,
      is_allowed: is_insight_allowed,
      items: [
        {
          id: 'insight_realtime',
          label: t('main_frame::실시간 성과'),
          href: '/partner-plus/realtime',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          is_new: true,
        },
        {
          id: 'insight_starter_plan',
          label: t('main_frame::스타터 성장 가이드'),
          href: checkRes([SellerResource.STARTER_INSIGHT_READ], info.shop_subscription_resource.resources)
            ? '/insight/starter-plan/checklist'
            : '/insight/starter-plan/intro',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'insight_sales_performance',
          label: t('main_frame::판매 인사이트'),
          href: checkRes([SellerResource.SALES_INSIGHT_READ], info.shop_subscription_resource.resources)
            ? '/insight/sales-performance?ug=growth-target'
            : '/insight/sales-performance/intro',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'insight_keyword',
          label: t('main_frame::키워드 인사이트'),
          href: checkRes([SellerResource.KEYWORD_INSIGHT_READ], info.shop_subscription_resource.resources)
            ? '/insight/keyword?ug=recent-keywords'
            : '/insight/keyword/intro',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'insight_customer',
          label: t('main_frame::고객 인사이트'),
          href: checkRes([SellerResource.CUSTOMER_INSIGHT_READ], info.shop_subscription_resource.resources)
            ? '/insight/customer?ug=old-n-news'
            : '/insight/customer/intro',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
        {
          id: 'insight_competitors',
          label: t('main_frame::경쟁사 인사이트'),
          href: checkRes([SellerResource.COMPETITOR_INSIGHT_READ], info.shop_subscription_resource.resources)
            ? '/insight/competitors?ug=store-level'
            : '/insight/competitors/intro',
          is_allowed: isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
        },
      ],
    },
  ];
}
