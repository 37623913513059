import { useQuery } from 'react-query';
import {
  api_getDailyReportClaimTopRanking,
  api_getMetaDailyReportClaimTopRanking,
  api_getMetaDailyReportClaimTopRankingBlur,
  GetDailyReportClaimTopRankingQueryVariables,
} from '@/api';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Params extends GetDailyReportClaimTopRankingQueryVariables {
  token?: string;
  main_domain?: string;
}

export const useGetDailyReportClaimTopRanking = ({
  date_ymd,
  use_free_view_count,
  main_domain = '',
  token = '',
}: Params) => {
  const shop_prefix = useShopPrefix();
  return useQuery(
    ['useGetDailyReportClaimTopRanking', { date_ymd, use_free_view_count, main_domain, token }],
    async () => {
      const { data } = shop_prefix
        ? await api_getDailyReportClaimTopRanking({ date_ymd, use_free_view_count }, { no_alert: true })
        : use_free_view_count
          ? await api_getMetaDailyReportClaimTopRanking(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            )
          : await api_getMetaDailyReportClaimTopRankingBlur(
              {
                date_ymd,
                main_domain,
                token,
              },
              { no_alert: true },
            );
      return data;
    },
    { staleTime: 30000, cacheTime: 30000, keepPreviousData: true },
  );
};
