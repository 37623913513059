import React from 'react';
import { RoundedButton } from './RoundedButton';
import { SellerSurveyStatus, useGetSellerSurveyListQuery } from '@/api';

export const SurveyButton = () => {
  const { data } = useGetSellerSurveyListQuery({
    status: SellerSurveyStatus.ONGOING,
    limit_count: 1,
    skip_count: 0,
  });
  if (!data || data.seller_survey_list.item_list.length < 1) return null;

  const handleClick = () => {
    window.open(data.seller_survey_list.item_list[0].survey_url, '_blank', 'noreferrer');
  };
  return <RoundedButton onClick={handleClick}>설문조사</RoundedButton>;
};
