import React from 'react';
import { isAllowedMenuBySiteId } from '../utils/menu_list';
import { ImageNoticePopupProps } from './ImageNoticePopup';
import FeeDiscountImage from './img/fee_discount_popup.jpg';
import { TextNoticePopupProps } from './NoticePopup';
import { Config } from '@/config';
import { SiteId } from '@/constants/site';
import { ShopDepartment } from '@/types/info';

export enum PopupType {
  IMAGE = 'IMAGE',
  CONTENT = 'CONTENT',
}

export const TextNoticePopupList: TextNoticePopupProps[] = [
  {
    notice_key: () => `NoticePopupView.pointGenerator.${Config.shop_main_domain}.LastConfirmed`,
    title: '포인트 생성 기능 오픈 안내',
    description: (
      <>
        <p>
          <strong>
            스토어에서 직접 발행하는 포인트를 통해 유저에게
            <br />
            양질의 혜택 제공과 마케팅 수단으로 활용할 수 있도록
            <br />
            포인트 지급 및 관리 기능이 오픈 되었습니다.
          </strong>
        </p>
        <br />
        <p>
          자세한 내용은 아래를 참고하여 마케팅 효과를
          <br />한 단계 더 업그레이드 해 보시길 바랍니다.
        </p>
        <br />
        <ul>
          <li>
            <strong>오픈 일정:</strong> 2023년 02월 06일 (상세 일정 공지사항 참고)
          </li>
          <li>
            <strong>설정 경로:</strong> 혜택 관리 &gt; 포인트 생성
          </li>
        </ul>
      </>
    ),
    text_button_label: '자세히 보기',
    landing_url: '/notice/2182',
    start_date: '2023-02-07 00:00:00',
    end_date: '2023-02-17 23:59:59',
  },
];

export const ImageNoticePopupList: ImageNoticePopupProps[] = [
  {
    notice_key: () => `FeeDiscount.${Config.shop_main_domain}.LastConfirmed`,
    title: '중소형 파트너 수수료 할인 프로그램',
    image_url: FeeDiscountImage,
    popup_type: PopupType.IMAGE,
    start_date: '2023-01-02:14:05:00',
    end_date: '2023-01-09:23:59:59',
    links: [
      {
        pos: [41, 423],
        size: [202, 57],
        url: '/notice/2038',
        label: '자세히보기',
      },
      {
        pos: [256, 423],
        size: [220, 57],
        url: 'https://forms.gle/DMyjKLDuBD42oLL66',
        label: '바로 신청하기',
      },
    ],
    is_allowed: (info) =>
      isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
      info.department_list.item_list.some(({ department }) => department === ShopDepartment.SOHO_FASHION),
  },
];
