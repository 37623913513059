import {
  semantic_colors,
  HStack,
  VStack,
  BaseText,
  Button,
  BottomSheet,
  CloseButton,
  RadioGroup,
  Textarea,
} from '@croquiscom/pds';
import React, { useState } from 'react';
import { FEEDBACK_OPTIONS, FEEDBACK_TEXT, FEEDBACK_TYPE, SATISFACTION_TYPE } from './constants';
import { chat_bot_session } from './utils/session';
import { useCreateShopChatbotFeedbackMessageMutation } from 'api';

interface ChatbotQnaFeedbackBottomSheetProps {
  opened: boolean;
  message_id: string;
  onClose: () => void;
  onDone?: () => void;
}

export const ChatbotQnaFeedbackBottomSheet = ({
  opened,
  message_id,
  onClose,
  onDone,
}: ChatbotQnaFeedbackBottomSheetProps) => {
  const { mutate, isLoading } = useCreateShopChatbotFeedbackMessageMutation({
    onError() {},
    onSuccess() {
      onDone?.();
    },
  });
  const [feedback, setFeedback] = useState<FEEDBACK_TYPE>();
  const [feedback_text, setFeedbackText] = useState<string>();

  return (
    <BottomSheet noUsePortal opened={opened} onClose={onClose}>
      <VStack spacing={16}>
        <HStack>
          <BaseText kind='Heading_18_Bold'>답변이 만족스럽지 않은 이유는 무엇인가요?</BaseText>
          <HStack ml='auto'>
            <CloseButton size={20} color={semantic_colors.content.primary} onClick={onClose} />
          </HStack>
        </HStack>
        <VStack spacing={8} width='100%'>
          <RadioGroup
            spacing={16}
            align='vertical'
            value={feedback}
            onChange={(value) => {
              setFeedback(value);
              setFeedbackText(FEEDBACK_TEXT[value]);
            }}
            items={FEEDBACK_OPTIONS}
          />
          {feedback === FEEDBACK_TYPE.CUSTOM_INPUT && (
            <Textarea
              value={feedback_text}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder='내용을 입력해주세요.'
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          )}
        </VStack>
        <VStack mt={24}>
          <Button
            kind='primary'
            size='medium'
            fill
            onClick={() => {
              mutate({
                input: {
                  session_id: chat_bot_session.getKey(),
                  message_id,
                  satisfaction_type: SATISFACTION_TYPE.UNSATISFACTION,
                  is_cs_connect: false,
                  is_no_answer: false,
                  feedback_type: feedback,
                  comments: feedback_text,
                },
              });
            }}
            disabled={!feedback || (feedback === FEEDBACK_TYPE.CUSTOM_INPUT && !feedback_text)}
            loading={isLoading}
          >
            의견 보내기
          </Button>
        </VStack>
      </VStack>
    </BottomSheet>
  );
};
