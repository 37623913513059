import noop from 'lodash/noop';
import { isDevelopment } from './conditions';

export interface PartnersHandler {
  onInterfaceList: (interface_list: string[]) => void;
  onFcmTokenWithDeviceInfo: (info: {
    fcm_token: string;
    app_version: string;
    os_version: string;
    device_model: string;
    device_language: string;
  }) => void;
  onPushNotificationEnabled: (params: { enabled: boolean }) => void;
}

export const initialHandlers: PartnersHandler = {
  onInterfaceList: noop,
  onFcmTokenWithDeviceInfo: noop,
  onPushNotificationEnabled: noop,
};

export const injectPartnersHandler = <A>(args?: A) => {
  if (!window.partnersHandler) {
    window.partnersHandler = initialHandlers;
  }
  return args;
};

export function partnersHandler<T extends keyof PartnersHandler, H extends PartnersHandler>(name: T, callback: H[T]) {
  if (!window.partnersHandler && !isDevelopment()) {
    return console.warn('Should call injectPartnersHandler before partnersHandler');
  }
  window.partnersHandler[name] = callback;
}
