import {
  BaseText,
  BottomSheet,
  CloseButton,
  FloatingModal,
  FloatingModalProps,
  HStack,
  IconArrowChevronLeft,
  RadioGroupProps,
  semantic_colors,
} from '@croquiscom/pds';
import React, { useEffect, useRef, useState } from 'react';
import { FeedbackDone } from './FeedbackDone';
import { FeedbackPreset } from './FeedbackPreset';
import { FeedbackScore } from './FeedbackScore';
import useMediaQuery from '@/hooks/useMediaQuery';

interface FeedbackProps<T> {
  opened: boolean;
  trigger?: React.ReactNode;
  score_title: string;
  detail_options: RadioGroupProps<T>['items'];
  has_done_with_button?: boolean;
  onDone?: ({ type, text, score }: { type?: T; text?: string; score: number }) => void;
  onClose?: () => void;
}

export const Feedback = <T,>({
  opened,
  trigger,
  score_title,
  detail_options,
  has_done_with_button,
  onClose,
  onDone,
}: FeedbackProps<T>) => {
  const { is_desktop } = useMediaQuery();
  const [current_step, setCurrentStep] = useState(0);
  const selected_score = useRef<number>();
  const STEPS: Pick<FloatingModalProps, 'title' | 'children' | 'backButton' | 'closeButton'>[] = [
    {
      title: score_title,
      children: (
        <FeedbackScore
          onDone={(score) => {
            selected_score.current = score + 1;
            if (1 >= score) {
              onDone?.({ score: selected_score.current || 0 });
              setCurrentStep(2);
              return;
            } else {
              setCurrentStep(1);
            }
          }}
        />
      ),
    },
    {
      backButton: true,
      children: (
        <FeedbackPreset
          options={detail_options}
          onDone={(type, text) => {
            setCurrentStep(2);
            onDone?.({ type, text, score: selected_score.current || 0 });
          }}
        />
      ),
    },
    {
      closeButton: false,
      children: <FeedbackDone has_confirm={has_done_with_button} onDone={onClose} />,
    },
  ];

  useEffect(() => {
    if (opened) {
      setCurrentStep(0);
    }
  }, [opened]);

  return is_desktop ? (
    <FloatingModal
      width={328}
      opened={opened}
      onClose={() => {
        onClose?.();
      }}
      openerTriggerEvent='none'
      trigger={trigger}
      onClickBackButton={() => setCurrentStep((prev) => prev - 1)}
      {...STEPS[current_step]}
    ></FloatingModal>
  ) : (
    <BottomSheet
      opened={opened}
      onClose={() => {
        onClose?.();
      }}
    >
      {(STEPS[current_step].backButton || STEPS[current_step].closeButton || STEPS[current_step].title) && (
        <HStack spacing={8} mt={4} mb={16}>
          {STEPS[current_step].backButton && (
            <span onClick={() => setCurrentStep((prev) => prev - 1)}>
              <IconArrowChevronLeft size={20} />
            </span>
          )}
          <BaseText kind='Heading_17_Bold'>{STEPS[current_step].title}</BaseText>
          {STEPS[current_step].closeButton && (
            <HStack ml='auto'>
              <CloseButton size={20} color={semantic_colors.content.primary} onClick={onClose} />
            </HStack>
          )}
        </HStack>
      )}
      {STEPS[current_step].children}
    </BottomSheet>
  );
};
