import {
  BaseText,
  getAutoColorCode,
  HStack,
  IconColoredFaceBad,
  IconColoredFaceGood,
  IconColoredFaceNeutral,
  IconColoredFaceVeryBad,
  IconColoredFaceVeryGood,
  semantic_colors,
  shapes,
  VStack,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

interface FeedbackScoreProps {
  onDone?: (score: number) => void;
}
const FEEDBACK_ICON = [
  { icon: <IconColoredFaceVeryGood size={24} />, text: '아주 좋아요' },
  { icon: <IconColoredFaceGood size={24} />, text: '좋아요' },
  { icon: <IconColoredFaceNeutral size={24} />, text: '보통이에요' },
  { icon: <IconColoredFaceBad size={24} />, text: '나빠요' },
  { icon: <IconColoredFaceVeryBad size={24} />, text: '아주 나빠요' },
];
export const FeedbackScore = ({ onDone }: FeedbackScoreProps) => {
  const { is_desktop } = useMediaQuery();
  return (
    <HStack alignment='center'>
      {is_desktop ? (
        <HStack width='100%' style={{ flexDirection: 'row-reverse' }}>
          {FEEDBACK_ICON.map(({ text, icon }, index) => (
            <SC.PcFeedbackCard onClick={() => onDone?.(index)}>
              {icon}
              <BaseText kind='Caption_11_Regular'>{text}</BaseText>
            </SC.PcFeedbackCard>
          ))}
        </HStack>
      ) : (
        <VStack width='100%' spacing={8}>
          {FEEDBACK_ICON.map(({ text, icon }, index) => (
            <SC.FeedbackCard onClick={() => onDone?.(index)}>
              {icon}
              <BaseText kind='Body_13_Medium'>{text}</BaseText>
            </SC.FeedbackCard>
          ))}
        </VStack>
      )}
    </HStack>
  );
};

const SC = {
  FeedbackCard: styled.div`
    padding: 12px 16px;
    width: 100%;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    cursor: pointer;
    ${shapes.border_radius.small};
    border: 1px solid ${semantic_colors.border.default};

    &:hover {
      background-color: ${getAutoColorCode(semantic_colors.background.surface).hover};
    }
    &:active {
      background-color: ${getAutoColorCode(semantic_colors.background.surface).pressed};
    }
  `,
  PcFeedbackCard: styled.div`
    padding: 8px 0;
    width: 100%;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    ${shapes.border_radius.small};

    &:hover {
      background-color: ${getAutoColorCode(semantic_colors.background.surface).hover};
    }
    &:active {
      background-color: ${getAutoColorCode(semantic_colors.background.surface).pressed};
    }
  `,
};
