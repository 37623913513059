import { colors } from '@croquiscom/pds';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const Spinner = () => {
  return (
    <StyledSpinnerWrapper>
      <div className='item bounce1'></div>
      <div className='item bounce2'></div>
      <div className='item bounce3'></div>
    </StyledSpinnerWrapper>
  );
};

export default Spinner;

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const StyledSpinnerWrapper = styled.div`
  margin: 0 auto;
  width: 70px;
  text-align: center;
  .item {
    width: 8px;
    height: 8px;
    background-color: ${colors.gray500};
    border-radius: 100%;
    display: inline-block;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    animation-delay: -0.32s;
  }
  .bounce2 {
    animation-delay: -0.16s;
    margin: 0 4px;
  }
`;
