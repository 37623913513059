import React, { lazy } from 'react';
import { RoutesType } from '../types';
const PrivacyPolicyPage = lazy(() => import('@/pages/policy/PrivacyPolicy'));
const ServicePolicyPage = lazy(() => import('@/pages/policy/ServicePolicy'));
const ZigzinPolicyTermsPage = lazy(() => import('@/pages/policy/ZigzinPolicyTerms'));

export const POLICY_ROUTES: RoutesType[] = [
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/privacy_policy',
    params: '',
    component: <PrivacyPolicyPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/service_policy',
    params: '',
    component: <ServicePolicyPage />,
  },
  {
    not_used_main_frame: true,
    current_menu: '',
    path: '/zigzin_policy_terms',
    params: '',
    component: <ZigzinPolicyTermsPage />,
  },
];
