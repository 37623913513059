export const Path = {
  library: 'https://partnerlounge.kakaostyle.com/advertisement/all', // 광고 라이브러리
  faq: 'https://partners.kakaostyle.com/help?id=970', // 광고 도움말(자주 묻는 질문)
  intro:
    'https://docs.google.com/presentation/d/1PLUqNbEzFColcWPfYaZ0cxsvKvcHecM01b9lN5Qp9IQ/edit#slide=id.g27431310c23_0_0', // 지그재그 광고 소개서
  operation:
    'https://docs.google.com/presentation/d/1KGRrNjTBtX3ecyIjvDrt3mJJxAv-OpsvRjxr8FIVqyk/edit#slide=id.g294b77c8547_0_0', // 지그재그 광고 운영 가이드
  inspection:
    'https://docs.google.com/presentation/d/1bcXuXFYKa0d_Unxzm6d5BBAdot6kjL7QijSlqDE2ptk/edit#slide=id.g276f4bda7d8_3_0', // 지그재그 광고 검수 가이드
};
