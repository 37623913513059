import { Badge, HStack, VStack, colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSubscriptionPriceForPartnerPlus } from '@/components/partner_plus/intro/hooks/useGetSubscriptionPriceForPartnerPlus';
import IntroSubscribeButton from '@/components/partner_plus/intro/IntroSubscribeButton';
import useEachPbl from '@/hooks/useEachPbl';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

interface InsightIntroHeaderProps {
  badges: string[];
  title: string;
  navigation:
    | 'insight_starter_intro'
    | 'insight_sales_intro'
    | 'insight_keyword_intro'
    | 'insight_customer_intro'
    | 'insight_competitors_intro';
  button_ref?: (instance: Element | null) => void;
  is_button_visible?: boolean;
  description?: string;
}

const InsightIntroHeader: React.FC<InsightIntroHeaderProps> = ({
  badges,
  title,
  description,
  navigation,
  button_ref,
  is_button_visible,
}) => {
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { is_desktop } = useMediaQuery();
  const { eachPbl } = useEachPbl();
  const { data } = useGetSubscriptionPriceForPartnerPlus(true);
  const { info } = useInfo();

  return (
    <Container spacing={is_desktop ? 40 : 20} is_desktop={is_desktop}>
      <VStack spacing={is_desktop ? 12 : 8} alignment={is_desktop ? 'center' : 'stretch'}>
        <HStack spacing={8}>
          {badges.map((label) => (
            <Badge color='blue' kind='fill' size='large'>
              {label}
            </Badge>
          ))}
        </HStack>
        <p className='intro_title'>{title}</p>
        {description && <Description is_desktop={is_desktop}>{description}</Description>}
      </VStack>
      <VStack width={is_desktop ? '200px' : '182px'} ref={button_ref}>
        {!is_button_visible && (
          <IntroSubscribeButton
            fill
            button_kind='primary'
            issued_coupon={data?.issued_coupon}
            has_subscription={!!info.shop_subscription_resource.name}
            has_tooltip
            promotion_price={data?.promotion_price}
            onSubscribeButtonClick={() => {
              eachPbl({ navigation, category: 'click', object_id: 'partnerplus_subscribe_click' });
              history.push(`${shop_prefix}${PartnerPlusNavUrl.SUBSCRIPTION}`);
            }}
          />
        )}
      </VStack>
    </Container>
  );
};

export default InsightIntroHeader;

const Container = styled(VStack)<{ is_desktop?: boolean }>`
  ${text_styles.Heading_24_Bold}
  padding: 60px 24px;
  background: #363644;
  color: ${colors.white};
  ${({ is_desktop }) =>
    is_desktop &&
    `align-items: center;
    font-size: 36px;
    line-height:46px;
    font-weight: 800;
    padding: 80px 24px;
  `};

  .intro_title {
    ${({ is_desktop }) =>
      is_desktop &&
      `
    font-size: 36px;
    line-height:46px;
    font-weight: 800;
  `};
  }
`;

const Description = styled.p<{ is_desktop?: boolean }>`
  margin: 8px 0 0;
  white-space: pre-line;
  word-break: keep-all;
  ${({ is_desktop }) => (is_desktop ? text_styles.Heading_17_Regular : text_styles.Body_14_Medium)};
  ${({ is_desktop }) => is_desktop && 'text-align: center'};
`;
