import { useQuery } from 'react-query';
import { api_getCustomerExpansionStateOfShop } from 'api';

export function useGetCustomerExpansionStateOfShop(enabled?: boolean) {
  return useQuery(
    ['useGetCustomerExpansionStateOfShop'],
    async () => {
      const { data } = await api_getCustomerExpansionStateOfShop(undefined, { no_alert: true });
      return data.getCustomerExpansionStateOfShop;
    },
    {
      enabled,
      staleTime: 30000,
      cacheTime: 30000,
      onError() {},
    },
  );
}
