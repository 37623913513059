import axios, { AxiosError, AxiosInstance } from 'axios';
import analytics from './analytics';
import { RequestOptions } from 'api/request';

export interface OriginalError {
  message?: string;
  locations?: any[];
  path?: string[];
  extensions?: {
    description?: string;
    code?: string;
    message?: string;
    errorValue?: string;
  };
  description?: string;
  error?: string;
  main_domain?: string;
}

export interface RequestError extends Error {
  code: string;
  original_response: OriginalError;
  is_request_error: boolean;
}

function handleError(error: OriginalError, default_message = '알 수 없는 에러입니다.'): Error {
  const message = error.message ?? error.description ?? error.extensions?.description ?? error.error ?? default_message;
  const code = error.extensions?.code ?? error.error ?? 'unexpected error';
  const wrapped_error = new Error(message) as RequestError;
  wrapped_error.code = code;
  wrapped_error.original_response = error;
  wrapped_error.is_request_error = true;
  return wrapped_error;
}

export function handleCatchedErrorDefault(error: Error, options?: RequestOptions): never {
  // 이전에 핸들러에 의해 핸들링된 경우 추가 처리 없이 이동
  if ((error as any).isHandledByDefaultHandler) {
    throw error;
  }
  const handled = handleCatchedErrorCommon(error, options);
  if (!handled && !options?.no_alert) {
    alert(error.message);
  }
  // 기본 핸들러에 의해 처리되었다고 마킹
  (error as any).isHandledByDefaultHandler = true;
  throw error;
}

export function handleCatchedErrorCommon(error: Error, options?: RequestOptions): boolean {
  if (!isRequestError(error)) return false;
  if (error.code === 'not logged in' || error.code === 'route_not_logged_in') {
    const current = window.location.pathname + window.location.search + window.location.hash;
    if (!options?.no_redirect_to_login) {
      const is_allowed_redirect = !['/alert_box', '/chatbot'].some((path) => current?.includes(path));
      if (is_allowed_redirect) {
        window.location.href = '/login?redirect_uri=' + encodeURIComponent(current);
      } else {
        window.location.href = '/login';
      }
    }
    return true;
  } else if (error.code === 'redirect') {
    window.location.href = '/' + error.original_response.main_domain;
    return true;
  } else if (error.code === 'invalid access' || error.code === 'missing api') {
    analytics.sendEvent('오류', error.message);
    window.location.href = '/#!/errors';
    return true;
  }
  return false;
}

export function isRequestError(error: any): error is RequestError {
  return !!(error as RequestError).is_request_error;
}

export function setupInterceptors(axios_instance: AxiosInstance): AxiosInstance {
  axios_instance.interceptors.response.use(
    (res) => {
      if (res.config.url?.includes('/graphql')) {
        // MEMO: GraphQL 호출중에 에러가 발생해도 HTTP 호출은 200 성공으로 들어오기 때문에 여기서 에러처리를 합니다.
        if (res.data?.errors?.length > 0) {
          const error = res.data.errors[0];
          throw handleError(error);
        }
      }
      if (res.headers['content-type']?.startsWith('text/html')) {
        // CloudFront 설정 상 요청이 HTML로 반환되면 서버에서 403/404를 반환한 케이스입니다.
        throw new Error('권한이 없거나 존재하지 않습니다.');
      }
      return res;
    },
    (error: AxiosError) => {
      if (error.response?.data) {
        throw handleError(error.response.data as OriginalError);
      }
      throw error;
    },
  );
  return axios_instance;
}

const axios_instance = axios.create();
setupInterceptors(axios_instance);

export default axios_instance;
