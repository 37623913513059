import { BaseText, HStack, IconArrowChevronLeft, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface MoblieBackButtonBarProps {
  title?: string;
}

export const MoblieBackButtonBar: React.FC<MoblieBackButtonBarProps> = ({ title }) => {
  const history = useHistory();
  return (
    <SC.Container p={16} spacing={27} alignment='center'>
      <VStack onClick={() => history.goBack()}>
        <IconArrowChevronLeft size={24} />
      </VStack>
      <VStack width='100%' alignment='center'>
        <BaseText kind='Heading_17_Bold'>{title}</BaseText>
      </VStack>
      <VStack width={24}></VStack>
    </SC.Container>
  );
};

const SC = {
  Container: styled(HStack)`
    background-color: ${semantic_colors.background.surface};
  `,
};
