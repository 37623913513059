import { useUpdateAtom } from 'jotai/utils';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { drawer_menu_open_atom } from '../atom';
import { useGetSellerInfoQuery } from '../hooks/useGetSellerInfoQuery';
import { SubMenuList } from './SubMenuList';
import { createSubMenuTree } from './tree/submenu';
import { SubMenuEntry } from './types';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';

export interface MainSubMenuListContainerProps {
  selected_id?: string | null;
}

export const MainSubMenuListContainer: React.FC<MainSubMenuListContainerProps> = ({ selected_id }) => {
  const { info } = useInfo();
  const account = useAccount();
  const { pbl } = usePbl();
  useTranslation('main_frame');
  const { data: has_new_notice } = useGetSellerInfoQuery();
  const entries = useMemo(
    () =>
      createSubMenuTree({
        info,
        account,
        has_new_notice,
      }),
    [info, account, has_new_notice],
  );

  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);
  const handleClick = useCallback(
    (entry: SubMenuEntry) => {
      if (!is_desktop && entry.href) {
        onDrawerOpen(false);
      }
      pbl({
        navigation: 'gnb_n_top_bar',
        category: 'click',
        object_id: 'pages',
        object_section: 'navigations',
        extra: JSON.stringify({ item: entry.label }),
      });
    },
    [pbl],
  );
  return <SubMenuList entries={entries} onClick={handleClick} selected_id={selected_id} />;
};
