import { KAKAOTALK_SHOPPING_KR_KEY, POSTY_KR_KEY } from '../detail/constants';
import { CountryCode, Country } from '@/api';
import { COUNTRY_NAME_I18N, SiteId, SiteName, SITE_NAME_I18N } from '@/constants/site';
import { ShopSiteCountry } from '@/types/info';
import { canGetChannelName } from 'rui/components/shop';

export interface CatalogSiteCountry {
  site_id: number;
  country: CountryCode;
}

export type ShopSiteCountryKey = `${ShopSiteCountry['site_id']}_${ShopSiteCountry['country_code']}`;
export function getShopSiteCountryKey({
  site_id,
  country_code,
}: Pick<ShopSiteCountry, 'site_id' | 'country_code'>): ShopSiteCountryKey {
  return `${site_id}_${country_code}`;
}

export type CatalogSiteCountryKey = `${CatalogSiteCountry['site_id']}_${CatalogSiteCountry['country']}`;
export function getCatalogSiteCountryKey({ site_id, country }: CatalogSiteCountry): CatalogSiteCountryKey {
  return `${site_id}_${country}`;
}

export function getBasicSiteCountryByKey(
  shop_site_country_key: ShopSiteCountryKey,
): { site_id: number; country: CountryCode } | null {
  const [site_id, country] = shop_site_country_key.split('_');
  if (!Object.values(CountryCode).some((country_code) => country_code === country)) {
    return null;
  }

  return {
    site_id: Number(site_id),
    country: CountryCode[country!],
  };
}

export function getSelectableSiteCountryKeys(
  site_country_list: Array<Pick<ShopSiteCountry, 'site_id' | 'country_code'>> = [],
): ShopSiteCountryKey[] {
  return site_country_list.reduce((accumulator, site_country) => {
    if (!canGetChannelName({ site_id: site_country.site_id, country: site_country.country_code })) {
      return accumulator;
    }
    return [...accumulator, getShopSiteCountryKey(site_country)];
  }, []);
}

export interface SelectableShopSiteCountry {
  value: ShopSiteCountryKey;
  label: string;
  disabled?: boolean;
}

interface SelectableSiteCountry {
  site_id: number | string;
  country_code?: string;
  country?: CountryCode;
}

export const DEFAULT_SELECTABLE_SITE_COUNTRY: SelectableShopSiteCountry[] = [
  { label: SiteName[SiteId.지그재그], value: `${SiteId.지그재그}_${Country.KR}` },
];
export function getSelectableSiteCountries(
  site_country_list: SelectableSiteCountry[],
  predicateForDisabled?: (site_county: { site_id: string; country: CountryCode }) => boolean,
): SelectableShopSiteCountry[] {
  return site_country_list.reduce((accumulator, shop_site_country) => {
    const { site_id, country_code: shop_country, country } = shop_site_country;
    const country_code = shop_country ?? country ?? '';

    if (!canGetChannelName({ site_id: Number(site_id), country: country_code })) {
      return accumulator;
    }

    const label =
      (country_code === Country.KR
        ? SITE_NAME_I18N[site_id]?.()
        : `${SITE_NAME_I18N[site_id]?.()} ${COUNTRY_NAME_I18N[country_code]?.()}`) ?? '-';

    return [
      ...accumulator,
      {
        value: getShopSiteCountryKey({ site_id: String(site_id), country_code }),
        label,
        disabled: predicateForDisabled?.({ site_id: String(site_id), country: country_code as CountryCode }),
      },
    ];
  }, []);
}

export function isKakaotalkShopping(key: `${string}_${string}`) {
  return key === KAKAOTALK_SHOPPING_KR_KEY;
}

export function isPosty(key?: `${string}_${string}`) {
  return key === POSTY_KR_KEY;
}
