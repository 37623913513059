import { useState } from 'react';

const useModalState = (
  defaultValue: boolean = false,
): [{ isOpen: boolean; onOpen: () => void; onClose: () => void }, (value: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  const onToggle = (value: boolean) => setIsOpen(value);

  return [{ isOpen, onClose, onOpen }, onToggle];
};

export default useModalState;
