import { QueryKey, UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import {
  GetPartnerCenterNotificationListQuery,
  GetPartnerCenterNotificationListQueryVariables,
  api_getPartnerCenterNotificationList,
} from '@/api';
import { useAccount } from '@/hooks/useAccount';

const PARTNER_CENTER_NOTIFICATION_LIST_QUERY_KEY = 'PartnerCenterNotificationList';
const LIMIT_COUNT = 20;

export const usePartnerCenterNotificationListQuery = (
  variables?: Omit<GetPartnerCenterNotificationListQueryVariables, 'limit_count' | 'skip_count'>,
  options?: UseInfiniteQueryOptions<
    unknown,
    unknown,
    NonNullable<GetPartnerCenterNotificationListQuery['getPartnerCenterNotificationList']>
  >,
) => {
  const { is_zigzag } = useAccount();
  return useInfiniteQuery(
    [PARTNER_CENTER_NOTIFICATION_LIST_QUERY_KEY, variables] as QueryKey,
    async ({ pageParam = null }) => {
      const { data } = await api_getPartnerCenterNotificationList(
        {
          ...variables,
          limit_count: LIMIT_COUNT,
          skip_count: pageParam * LIMIT_COUNT,
        },
        {
          no_alert: true,
        },
      );
      return data.getPartnerCenterNotificationList;
    },
    {
      ...options,
      enabled: options?.enabled !== false && !is_zigzag,
      getNextPageParam: ({ total_count }, all_pages) => {
        if (all_pages.length * LIMIT_COUNT >= total_count) {
          return undefined;
        }

        return all_pages.length;
      },
      staleTime: 3000,
      cacheTime: 3000,
    },
  );
};
