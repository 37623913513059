import { LineTabs, LineTab, HStack } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useHistory } from '@/components/route/LegacyRouterCompat';
import useMediaQuery from '@/hooks/useMediaQuery';
import { InsightNavUrl } from 'rui/components/URL';

interface InsightIntroNavItemData {
  id: 'intro' | 'plan';
  label: React.ReactNode;
  badge?: React.ReactNode;
  href: string;
}

const NAV_ITEMS: InsightIntroNavItemData[] = [
  {
    id: 'intro',
    label: '인사이트 소개',
    href: InsightNavUrl.INTRO,
  },
  {
    id: 'plan',
    label: '주요 기능 소개',
    href: InsightNavUrl.PLAN,
  },
];

interface InsightIntroNavProps {
  current_tab?: 'intro' | 'plan';
}

const InsightIntroNav: FC<InsightIntroNavProps> = ({ current_tab }) => {
  const history = useHistory();
  const { is_desktop } = useMediaQuery();
  return (
    <LineTabs activeTabId={current_tab} dense={!is_desktop}>
      {NAV_ITEMS.map((item) => (
        <LineTab
          key={item.id}
          id={item.id}
          onClick={() => {
            history.push(item.href);
          }}
        >
          <HStack spacing={2} alignment='center'>
            {item.label}
            {item.badge}
          </HStack>
        </LineTab>
      ))}
    </LineTabs>
  );
};

export default InsightIntroNav;
