import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { fixed_bottom_area_atom } from '../atom';
import { ChatBotButton } from './chat_bot/ChatBotButton';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export const FloatingMobile = () => {
  const fixed_bottom_area = useAtomValue(fixed_bottom_area_atom);
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const is_not_allowed = ['/partner-plus/report', '/alert_box'].some((path) =>
    history.location.pathname.includes(path),
  );
  if (is_not_allowed) {
    return null;
  }
  return (
    <Container fixed_bottom_area={fixed_bottom_area}>
      <ChatBotButton onClick={() => history.push(`${shop_prefix}/chatbot`)} />
    </Container>
  );
};

const Container = styled.div<{ fixed_bottom_area: number }>`
  position: fixed;
  right: 20px;
  bottom: ${({ fixed_bottom_area }) => 20 + fixed_bottom_area}px;
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  z-index: 100;
`;
