import { getUrlByEnvironment } from './getUrlByEnvironment';
import { SiteId } from 'rui/../../constants/site';

function getPDPPreviewPath(product_id: string, site_id: string): string {
  switch (site_id) {
    case SiteId.포스티:
      return `/products/${product_id}/preview`;
    case SiteId.패션_바이_카카오:
      return `/catalog/products/${product_id}?preview=true`;
    case SiteId.지그재그:
    default:
      return `/catalog/__preview/${product_id}`;
  }
}

export function getPDPPreviewUrl(product_id: string, site_id: string): string {
  return getUrlByEnvironment(site_id, getPDPPreviewPath(product_id, site_id));
}
