import { IconProps } from '@croquiscom/pds';
import { PropsWithChildren, isValidElement, cloneElement } from 'react';

export const ButtonIcon = ({ size, color, children }: PropsWithChildren<IconProps>) => {
  return isValidElement(children)
    ? cloneElement<any>(children, {
        'color': color ?? children.props.color,
        size,
        'aria-hidden': true,
        'focusable': false,
      })
    : null;
};
