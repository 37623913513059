import { BaseText, HStack, IconCheckBold, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InsightIntroHeader from '../../common/InsightIntroHeader';
import img_mobile_01 from '@/assets/images/insight/intro/customer/img_mobile_01.png';
import img_mobile_02 from '@/assets/images/insight/intro/customer/img_mobile_02.png';
import img_mobile_03 from '@/assets/images/insight/intro/customer/img_mobile_03.png';
import img_pc_01 from '@/assets/images/insight/intro/customer/img_pc_01.png';
import img_pc_02 from '@/assets/images/insight/intro/customer/img_pc_02.png';
import img_pc_03 from '@/assets/images/insight/intro/customer/img_pc_03.png';

interface InsightCustomerIntroBodyProps {
  button_ref?: (instance: Element | null) => void;
  is_revealed: boolean;
  is_desktop: boolean;
}

export const InsightCustomerIntroBody: FC<InsightCustomerIntroBodyProps> = ({
  button_ref,
  is_revealed,
  is_desktop,
}) => {
  const { t } = useTranslation('insight');
  return (
    <>
      <InsightIntroHeader
        badges={[t('고객연령대'), t('고객별 재구매 비율'), t('고객별 주문단가')]}
        title={t('내 스토어의 고객들은 누구일까요?')}
        navigation='insight_customer_intro'
        is_button_visible={is_revealed}
        button_ref={button_ref}
      />
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('첫구매 vs 재구매')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 13px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_01 : img_mobile_01} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  홍보 전략과 상품 구성에 따라 어떤 고객이 늘고 줄어드는지 확인하고 지속적으로 최적화하는 것이 중요해요.
                  <br />
                  첫구매 비율이 높은 상품을 살펴보고 광고나 프로모션 계획에 참고하세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.base}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('단가 분석을 통한 적정 할인율 설정')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_02 : img_mobile_02} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.surface}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  시즌별로 상품 구성이 변하면 주문 단가도 함께 변해요.
                  <br />
                  쿠폰 발행 조건, 합구매를 유도할 수 있는 상품의 가격대와 신규 상품 가격 설정에도 주문 단가를
                  참고해보세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('고객 연령대 분석')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_03 : img_mobile_03} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  실제 내 상품을 구매하는 고객의 연령대 한 눈에 살펴보세요.
                  <br />
                  시기별로 고객 연령대가 달라졌는지, 어떤 연령대에 중점을 둬야 하는지 등 스토어 운영 전략에
                  반영해보세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
    </>
  );
};

const Section = styled(VStack)<{ background?: string; border_color?: string }>`
  width: 100%;
  align-items: center;
  ${({ background }) => background && `background: ${background}`};
  ${({ border_color }) =>
    border_color &&
    `
    border-top: 1px solid ${border_color};
    border-bottom: 1px solid ${border_color};
    `}
`;

export const Image = styled.img<{ is_desktop: boolean }>`
  width: 100%;
  max-width: ${({ is_desktop }) => (is_desktop ? '900px' : '600px')};
`;

const InsightIntroSectionCard = styled(VStack)<{ background?: string; is_desktop?: boolean }>`
  padding: 24px;
  margin-top: 30px;
  ${({ background }) => background && `background: ${background}`};
  ${({ is_desktop }) =>
    is_desktop &&
    `padding: 40px;
    margin-top: 40px;
    width: 910px;
  `};
  border-radius: 20px;
`;

const InsightIntroSectionCardIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colors.purple500};
  text-align: start;
  margin-top: 4px;
`;
