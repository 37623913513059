import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from 'api';

const ShopContractPage = lazy(() => import('@/pages/shop_contract/contract/ShopContractPage'));
const ShopInternalContractPage = lazy(() => import('@/pages/shop_contract/internal_contract/ShopInternalContractPage'));

export const SHOP_CONTRACT_ROUTES: RoutesType[] = [
  {
    current_menu: 'shop_contract',
    path: '/shop_contract',
    params: '',
    component: (
      <>
        <ShopContractPage />
      </>
    ),
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'shop_contract',
    path: '/shop_internal_contract',
    params: '',
    component: (
      <>
        <ShopInternalContractPage />
      </>
    ),
    res: [SellerResource.ALL],
  },
];
