import { Config } from '@/config';

export const isDevelopment = () => {
  return !Config.production;
};

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const isAndroid = (user_agent = navigator.userAgent) => {
  return Boolean(user_agent && /(android)/i.test(user_agent));
};

export const isIOS = (user_agent = navigator.userAgent) => {
  return Boolean(user_agent && /iPhone|iPad|iPod/gi.test(user_agent));
};

export const isApp = (user_agent = navigator.userAgent) => {
  return /Partners\/([0-9.]*)/.test(user_agent);
};
