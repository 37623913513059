import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAccount } from './useAccount';
import { useInfo } from './useInfo';
import useMediaQuery from './useMediaQuery';
import { useShopPrefix } from './useShopPrefix';
import { SellerResource } from '@/api';
import { onboarding_atom } from '@/atoms/onboarding';
import { OnboardingFunnelStep, OnboardingFunnelTask, Task } from '@/types/onboarding';

export function useOnboarding() {
  const prefix = useShopPrefix();
  const history = useHistory();
  const { is_seller, is_zigzag } = useAccount();
  const { info } = useInfo();
  const { is_desktop } = useMediaQuery();
  const [onboarding, dispatch] = useAtom(onboarding_atom);
  const is_allow_onboarding =
    onboarding.is_displayed &&
    is_desktop &&
    (is_seller || is_zigzag) &&
    info.allowed_resource_list?.includes(SellerResource.ALL);

  const requestOnboarding = useCallback(() => {
    if ((onboarding.last_fetch_time ?? 0) + 500 < dayjs().valueOf()) {
      return dispatch({ type: 'refetch' });
    }

    return Promise.resolve();
  }, [onboarding, dispatch]);

  const hasPossibleFunnel = (step: OnboardingFunnelStep) => {
    const { last_funnel, funnels } = onboarding;

    if (last_funnel.step === step) {
      return true;
    }

    const target_funnel = funnels.find(({ step: _step }) => _step === step)!;
    return target_funnel.is_completed;
  };

  const getNextFunnelStep = (current_step: OnboardingFunnelStep) => {
    const { total_funnel_count, funnels } = onboarding;
    const current_funnel_index = funnels.findIndex(({ step }) => step === current_step);

    if (current_funnel_index === total_funnel_count) {
      return funnels.slice(-1)[0].step;
    }

    return funnels[current_funnel_index + 1].step;
  };

  const moveNextOnboarding = (in_progress_task: OnboardingFunnelTask) => {
    const next_task = [...onboarding.funnels].reduce((acc, funnel, index, funnels) => {
      const { tasks } = funnel;
      const uncompleted_tasks = tasks.filter(({ is_completed, is_hidden }) => !is_hidden && !is_completed);
      const task_count = uncompleted_tasks.length;
      const task_index = uncompleted_tasks.findIndex(({ task }) => task === in_progress_task);

      if (task_index >= 0) {
        const next_funnels = funnels.slice(index + 1);
        funnels.splice(1);
        if (task_count === 1 && uncompleted_tasks[0].task === in_progress_task) {
          return next_funnels.find(({ is_completed }) => !is_completed)?.in_progress_task;
        }

        return uncompleted_tasks.filter(({ task }) => task !== in_progress_task)[0];
      }

      return acc;
    }, {} as Task);

    if (next_task?.task) {
      history.push(`${prefix}${next_task.page_path}`, { onboarding: next_task.task });
    } else {
      history.push(`${prefix}/stats`);
    }
  };

  return {
    onboarding,
    is_allow_onboarding,
    requestOnboarding,
    hasPossibleFunnel,
    getNextFunnelStep,
    moveNextOnboarding,
  };
}
