import { Location } from 'history';
import { useLocation, matchPath } from 'react-router'; // LegacyCompat 변환 X

export function getShopPrefix(location: Location): string {
  const match = matchPath<{ shop_id: string }>(location.pathname, { path: '/shop/:shop_id', exact: false });
  if (match != null) {
    return `/shop/${match.params.shop_id}`;
  } else {
    return '';
  }
}

export function useShopPrefix(): string {
  const location = useLocation();
  return getShopPrefix(location);
}
