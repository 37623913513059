import { colors, text_styles, VStack, IconArrowChevronRight, semantic_colors, TextButton } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button } from '@/components/pdstop';
import { Link } from '@/components/route/LegacyRouterCompat';
import Checkbox from 'pastel/Checkbox';
import Modal from 'rui/pastel/Modal';

const today = dayjs().format('YYYYMMDD');
export interface TextNoticePopupProps {
  notice_key: () => string;
  title: React.ReactElement | string;
  description: React.ReactElement | string;
  text_button_label: string;
  landing_url: string;
  start_date: string;
  end_date: string;
}

export const NoticePopup: React.FC<TextNoticePopupProps> = ({
  notice_key,
  title,
  description,
  text_button_label,
  landing_url,
  start_date,
  end_date,
}) => {
  const notice_key_value = notice_key();
  const [isOpen, setIsOpen] = useState<boolean>(
    localStorage.getItem(notice_key_value) !== today && dayjs().isBetween(start_date, end_date),
  );

  const [replayYn, setReplayYn] = useState<boolean>(false);

  const is_external_link = landing_url.startsWith('http');

  const handleCheckboxClick = (checked: boolean) => {
    setReplayYn(checked);
    if (checked) {
      localStorage.setItem(notice_key_value, today);
    } else {
      localStorage.removeItem(notice_key_value);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledModal isOpen={isOpen} width={500} onClose={handleClose}>
      <Header>
        <p>공지사항</p>
        <h2>{title}</h2>
      </Header>
      <Section m='0 30px' p='20px 0'>
        <Description>{description}</Description>
        <br />
        {is_external_link ? (
          <a href={landing_url} target='_blank' rel='noreferrer' style={{ outline: 'none' }}>
            <StyledButton kind='primary' endIcon={<IconArrowChevronRight />} onClick={handleClose} size='large'>
              {text_button_label}
            </StyledButton>
          </a>
        ) : (
          <Link to={landing_url} style={{ outline: 'none' }}>
            <StyledButton kind='primary' endIcon={<IconArrowChevronRight />} onClick={handleClose} size='large'>
              {text_button_label}
            </StyledButton>
          </Link>
        )}
      </Section>
      <Footer>
        <Checkbox checked={replayYn} onChange={(e) => handleCheckboxClick(e.currentTarget.checked)}>
          오늘 하루동안 이 창을 열지 않음
        </Checkbox>
        <TextButton onClick={handleClose}>닫기</TextButton>
      </Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 6px;
  }
`;

const Header = styled.div`
  margin: 0 30px;
  padding: 20px 0;
  border-bottom: 1px solid ${colors.gray300};

  p {
    ${text_styles.Body_13_Bold};
    margin: 0;
  }

  h2 {
    margin-top: 10px;
    color: ${colors.gray900};
    ${text_styles.Heading_24_Bold};
  }
  strong {
    color: ${colors.zigzag_pink500};
  }
`;

const Section = styled(VStack)`
  color: ${colors.gray900};
  border-bottom: 1px solid ${colors.gray300};
`;

const Description = styled.div`
  p,
  li {
    word-break: keep-all;
    ${text_styles.Body_14_Regular};
  }
  strong {
    ${text_styles.Body_14_SemiBold}
  }
  .sub,
  .sub strong {
    ${text_styles.Body_13_Regular};
    color: ${colors.gray600};
  }
  strong.pink {
    color: ${colors.zigzag_pink500};
  }
  ul {
    padding-left: 13px;
  }
  li {
    list-style-type: square;
  }
  a {
    color: ${semantic_colors.accent.primary};
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  background: ${colors.zigzag_pink500};
  &:hover {
    background: ${colors.zigzag_pink500};
    color: white;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  padding: 20px 0;
`;
