import curry from 'lodash/curry';
import flow from 'lodash/flow';
import { devParamsLogger } from './devLogger';
import { injectPartnersHandler } from './handlers';

function handleArgs<A>(function_name: string, args: A) {
  return {
    ios_param: {
      name: function_name,
      ...(args && { parameters: args }),
    },
    aos_param: args ? [JSON.stringify(args)] : [],
  };
}

function callAppInterface(function_name: string, { ios_param, aos_param }) {
  if (window.partners) {
    window.partners[function_name]?.(...aos_param);
  }
  if (window.webkit?.messageHandlers?.partners?.postMessage) {
    window.webkit.messageHandlers.partners.postMessage(ios_param);
  }
}

export function callPartnersFunction(function_name: string) {
  return flow(
    injectPartnersHandler,
    curry(handleArgs)(function_name),
    curry(devParamsLogger)(function_name),
    curry(callAppInterface)(function_name),
  );
}
