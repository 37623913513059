import dayjs from 'dayjs';
import { sanitize } from 'dompurify';
import { Config } from '@/config';

const ALERT_DISMISS_CHECK = `AlertDismissCheck.${Config.shop_main_domain}`;

export const checkAlertDismiss = (dismiss_key: string) => {
  const text_data = localStorage.getItem(ALERT_DISMISS_CHECK);
  const json_data = JSON.parse(text_data ?? '{}');
  return json_data[dismiss_key] && dayjs().isBefore(json_data[dismiss_key]);
};

export const setAlertDismiss = (dismiss_key: string) => {
  const text_data = localStorage.getItem(ALERT_DISMISS_CHECK);
  const json_data = JSON.parse(text_data ?? '{}');
  const new_data = {
    ...json_data,
    [dismiss_key]: dayjs().endOf('day').add(6, 'day').format(),
  };
  localStorage.setItem(ALERT_DISMISS_CHECK, JSON.stringify(new_data));
};

export function sanitizeAlertHTML(html: string | Node) {
  return sanitize(html, {
    ALLOWED_TAGS: ['b', 'strong', 'p', 'div', 'br'],
  });
}
