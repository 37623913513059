import { request, RequestOptions } from 'api/request';

const GRAPHQL_URL = '/graphql';

// MEMO: 해당 패치모듈은 api중 일부 스키마에 없는 필드가 있는 경우에 사용하고 있습니다.
// 서버쪽에서 each와 meta로 스키마를 머지할 때 일부 필드가 누락되고 있고, 이를 바로 개선하기는 어려워서 apigen 에러를 막고자 직접 리터럴로 쿼리를 호출합니다.
export default function gql(literals: TemplateStringsArray, ...placeholders: any[]) {
  const query =
    placeholders.reduce((memo, placeholder, idx) => {
      return memo + literals[idx] + placeholder;
    }, '') + literals[literals.length - 1];
  return <R, V = Record<string, any>>(
    name: string,
    variables?: V,
    appendFormdata?: ((form_data: FormData) => void) | null,
    options?: RequestOptions,
  ) => {
    let data: any = { query, variables };
    if (appendFormdata != null) {
      const form_data = new FormData();
      form_data.append('operations', JSON.stringify(data));
      appendFormdata(form_data);
      data = form_data;
    }
    const _options: RequestOptions = options ?? {
      no_alert: false,
      no_shop_domain: false,
    };

    return request<R>(
      {
        method: 'POST',
        url: `${GRAPHQL_URL}/${name}`,
        data,
      },
      _options,
    );
  };
}
