import { createFocusTrap, FocusTrap, Options as FocusTrapOptions } from 'focus-trap';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface FocusTrapProps {
  options?: FocusTrapOptions;
}

export function useFocusTrap({ options }: FocusTrapProps) {
  const [activate, setActivate] = useState<boolean>();

  const focus_trap_ref = useRef<FocusTrap>();
  const focus_container_element_ref = useRef<HTMLElement | null>();
  const focus_escape_element_ref = useRef<HTMLElement | null>();

  const getTrapContainerElement = useCallback((ref: HTMLElement | null) => {
    focus_container_element_ref.current = ref;
  }, []);

  const getTrapEscapeElement = useCallback((ref: HTMLElement | null) => {
    focus_escape_element_ref.current = ref;
  }, []);

  const handleDeactivate = useCallback(() => {
    focus_escape_element_ref.current?.focus();
    setActivate(false);
  }, [focus_escape_element_ref.current]);

  const activateFocusTrap = useCallback(() => {
    setActivate(true);
  }, []);

  const deactivateFocusTrap = useCallback(() => {
    setActivate(false);
  }, []);

  useEffect(() => {
    if (focus_container_element_ref.current) {
      if (!focus_trap_ref.current) {
        focus_trap_ref.current = createFocusTrap(focus_container_element_ref.current, {
          clickOutsideDeactivates: true,
          fallbackFocus: focus_container_element_ref.current,
          onDeactivate: handleDeactivate,
          ...options,
        });
      } else {
        focus_trap_ref.current?.updateContainerElements(focus_container_element_ref.current);
        return () => {
          focus_trap_ref.current?.deactivate();
        };
      }
    }
  }, [focus_trap_ref.current, focus_container_element_ref.current]);

  useEffect(() => {
    if (activate) {
      focus_trap_ref.current?.activate();
    }
    if (activate === false) {
      focus_trap_ref.current?.deactivate();
    }
  }, [activate]);

  return {
    focus_container: focus_container_element_ref.current,
    getTrapEscapeElement,
    getTrapContainerElement,
    activateFocusTrap,
    deactivateFocusTrap,
  };
}
