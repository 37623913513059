import { BaseText, colors, IconX } from '@croquiscom/pds';
import styled from '@emotion/styled';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import useModalHotkeys from '@/hooks/useModalHotkeys';
import { FocusTrapProps, useFocusTrap } from 'rui/hooks/useFocusTrap';
import { defaultLine } from 'styles';

export interface ModalProps {
  title?: React.ReactElement | string;
  isOpen: boolean;
  width?: number;
  children?: React.ReactNode;
  onClose?: () => void;
  onExited?: () => void;
  target?: HTMLElement | null;
  hasDnd?: boolean;
  className?: string;
  focusTrapOptions?: FocusTrapProps;
  focusTrapEnabled?: boolean;
}

const DEFAULT_FOCUS_TRAP_OPTIONS = {
  options: { escapeDeactivates: false },
};

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  width,
  children,
  onClose,
  onExited,
  target,
  hasDnd = false,
  className,
  focusTrapOptions,
  focusTrapEnabled = true,
}) => {
  const [isOpened, setIsOpened] = useState(isOpen);
  const { getTrapContainerElement, activateFocusTrap, deactivateFocusTrap } = useFocusTrap({
    ...merge(DEFAULT_FOCUS_TRAP_OPTIONS, focusTrapOptions),
  });

  useModalHotkeys({
    handlers: {
      onClose,
    },
    options: {
      enabled: isOpened,
    },
  });

  useEffect(() => {
    if (!focusTrapEnabled) return;

    if (isOpen) {
      activateFocusTrap();
      document.documentElement.style.overflow = 'hidden';
    } else {
      deactivateFocusTrap();
      document.documentElement.style.overflow = '';
    }
    return () => {
      deactivateFocusTrap();
      document.documentElement.style.overflow = '';
    };
  }, [isOpen, focusTrapEnabled]);

  if (isOpen && !isOpened) {
    setIsOpened(true);
    return null;
  }

  if (!isOpened) {
    return null;
  }

  return createPortal(
    <CSSTransition
      in={isOpen}
      timeout={500}
      appear
      onExited={() => {
        setIsOpened(false);
        onExited?.();
      }}
      classNames={{
        appear: 'modal-enter',
        appearActive: 'modal-enter-active',
        enter: 'modal-enter',
        enterActive: 'modal-enter-active',
        exit: 'modal-exit',
        exitActive: 'modal-exit-active',
        enterDone: 'modal-enter-done',
      }}
    >
      <StyledModal
        width={width}
        isTarget={!!target}
        onClick={(e) => {
          e.stopPropagation();
          if (onClose) {
            onClose();
          }
        }}
        hasDnd={hasDnd}
        className={className}
      >
        <div className='modal-content-wrap'>
          <div
            ref={getTrapContainerElement}
            className='modal-content'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {title && (
              <div className='modal-content__header'>
                <BaseText kind='Heading_17_Bold' as='div'>
                  {title}
                </BaseText>
                {onClose && (
                  <button type='button' className='modal-content__icon' onClick={onClose}>
                    <IconX size={24} />
                  </button>
                )}
              </div>
            )}
            <div className='modal-content__content'>{children}</div>
          </div>
        </div>
      </StyledModal>
    </CSSTransition>,
    target || document.body,
  );
};

const StyledModal = styled.div<{
  isTarget: boolean;
  width?: number;
  hasDnd?: boolean;
}>`
  top: 0;
  left: 0;
  z-index: 101;
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  overflow: hidden;
  width: ${({ isTarget }) => (isTarget ? '100%' : '100vw')};
  height: ${({ isTarget }) => (isTarget ? '100%' : '100vh')};
  background: rgba(0, 0, 0, 0.4);
  &.modal-enter {
    background: rgba(0, 0, 0, 0);
  }
  &.modal-enter-active {
    background: rgba(0, 0, 0, 0.4);
    transition: background 0.5s;
  }
  &.modal-exit {
    background: rgba(0, 0, 0, 0.4);
  }
  &.modal-exit-active {
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s;
    pointer-events: none;
  }
  ${({ hasDnd }) =>
    !hasDnd &&
    `
    .modal-content-wrap {
      transform: translateY(-150px);
    }
    &.modal-enter .modal-content-wrap {
      transform: translateY(-150px);
    }
    &.modal-enter-active .modal-content-wrap {
      transform: none;
      transition: transform 0.5s;
    }
    &.modal-enter-done .modal-content-wrap {
      transform: none;
    }
    &.modal-exit .modal-content-wrap {
      transform: none;
    }
    &.modal-exit-active .modal-content-wrap {
      transform: translateY(-150px);
      transition: transform 0.5s;
    }
  `}
  &.modal-enter .modal-content {
    opacity: 0;
  }
  &.modal-enter-active .modal-content {
    opacity: 1;
    transition: opacity 0.5s;
  }
  &.modal-exit .modal-content {
    opacity: 1;
  }
  &.modal-exit-active .modal-content {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .modal-content-wrap {
    flex: 0 0 auto;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 10px;
    transition: transform 0.5s;
  }
  .modal-content {
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : 'min-content')};
    background-color: ${colors.white};
    margin: 0 auto;
    &__header {
      display: flex;
      align-items: center;
      padding: 0 15px 0 25px;
      border-bottom: ${defaultLine};
      height: 50px;
    }
    &__icon {
      background: none;
      box-shadow: none;
      border: 0;
      padding: 0 0 0 12px;
      margin-left: auto;
      flex: none;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
`;

export default Modal;
