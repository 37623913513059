import { BaseText, HStack, IconCheckBold, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InsightIntroHeader from '../../common/InsightIntroHeader';
import img_mobile_01 from '@/assets/images/insight/intro/sales/img_mobile_01.png';
import img_mobile_02 from '@/assets/images/insight/intro/sales/img_mobile_02.png';
import img_mobile_03 from '@/assets/images/insight/intro/sales/img_mobile_03.png';
import img_mobile_04 from '@/assets/images/insight/intro/sales/img_mobile_04.png';
import img_mobile_05 from '@/assets/images/insight/intro/sales/img_mobile_05.png';
import img_pc_01 from '@/assets/images/insight/intro/sales/img_pc_01.png';
import img_pc_02 from '@/assets/images/insight/intro/sales/img_pc_02.png';
import img_pc_03 from '@/assets/images/insight/intro/sales/img_pc_03.png';
import img_pc_04 from '@/assets/images/insight/intro/sales/img_pc_04.png';
import img_pc_05 from '@/assets/images/insight/intro/sales/img_pc_05.png';

interface InsightSalesIntroBodyProps {
  button_ref?: (instance: Element | null) => void;
  is_desktop: boolean;
  is_revealed: boolean;
}

export const InsightSalesIntroBody: FC<InsightSalesIntroBodyProps> = ({ button_ref, is_revealed, is_desktop }) => {
  const { t } = useTranslation('insight');
  return (
    <>
      <InsightIntroHeader
        badges={[t('목표달성'), t('상품분석'), t('경쟁력분석'), t('리뷰분석')]}
        title={t('내 스토어 성과의 A to Z')}
        navigation='insight_sales_intro'
        button_ref={button_ref}
        is_button_visible={is_revealed}
      />
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('월간 목표와 달성 관리')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 13px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_01 : img_mobile_01} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  최근 성과를 바탕으로 월간 목표를 제안해드려요. 원하는 목표로 수정도 가능해요. <br />
                  수정된 목표에 맞춰서 필요한 노출량 목표를 분석해드려요.
                </Trans>
              </BaseText>
            </HStack>
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t('채널별 유입량 분석에서는 성과 개선을 위해, 어떤 부분을 더 신경써야하는지 알 수 있어요.')}
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.base}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('스토어 성과를 한눈에')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 8px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_02 : img_mobile_02} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.surface}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans
                  i18nKey={
                    '내 스토어의 전반적인 성과를 요약해서 알려드려요. <br />주문 성과, 전환율, 클릭수, 즐겨찾기, 방문자 등 자세한 지표 별로 내 스토어의 컨디션을 체크해보세요.'
                  }
                  t={t}
                />
              </BaseText>
            </HStack>
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t(
                  '주문, 반품률, 찜, 장바구니 수 등 성과에 직결되는 지표 별로 최고의 상품이 무엇인지 한눈에 볼 수 있어요.',
                )}
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('상품별 성과 분석')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 11px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_03 : img_mobile_03} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t('재고관리, 광고집행, 상품설정 변경 등의 모든 액션의 시작입니다.')}
              </BaseText>
            </HStack>
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  모든 상품에 대해 13가지 이상의 업계 최상급 상세 데이터를 제공해드려요.
                  <br />
                  최대 12개월 동안의 분석된 클릭률, 구매율, 반품률 등으로 상품의 성과와 컨디션을 체크하고 조율하세요!
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.base}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('신상품 경쟁력 분석')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 50px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_04 : img_mobile_04} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.surface}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  Ai 기술로 최근 30일 내 등록된 신상품의 경쟁력을 분석해드려요. 데이터를 바탕으로 설정된 비교 그룹
                  안에서 <br />내 상품의 관심도와 가격, 판매량을 분석하여 상품 노출이나 프로모션 전략에 활용할 수
                  있어요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('리뷰, 취소, 반품 반응 분석')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 20px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_05 : img_mobile_05} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans
                  t={t}
                  i18nKey={
                    '상품에 문제가 있다면 리뷰나 취소 반품 분석 기능으로 빠르게 알 수 있어요.<br />어떤 항목에서 부정적인 반응이 많은지, 어떤 상품의 반품률과 취소율이 높은지 체크하고 대응해보세요.'
                  }
                />
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
    </>
  );
};

const Section = styled(VStack)<{ background?: string; border_color?: string }>`
  width: 100%;
  align-items: center;
  ${({ background }) => background && `background: ${background}`};
  ${({ border_color }) =>
    border_color &&
    `
    border-top: 1px solid ${border_color};
    border-bottom: 1px solid ${border_color};
    `}
`;

const Image = styled.img<{ is_desktop: boolean }>`
  width: 100%;
  max-width: ${({ is_desktop }) => (is_desktop ? '900px' : '600px')};
`;

const InsightIntroSectionCard = styled(VStack)<{ background?: string; is_desktop?: boolean }>`
  padding: 24px;
  margin-top: 30px;
  ${({ background }) => background && `background: ${background}`};
  ${({ is_desktop }) =>
    is_desktop &&
    `padding: 40px;
    margin-top: 40px;
    width: 910px;
  `};
  border-radius: 20px;
`;

const InsightIntroSectionCardIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colors.purple500};
  text-align: start;
  margin-top: 4px;
`;
