import { BaseText, colors } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CatalogDetailCrawlingDescription: FC = () => {
  const { t } = useTranslation('catalog');
  return (
    <BaseText color={colors.gray700}>
      <BaseText color={colors.red500}>*</BaseText>{' '}
      {t('표시는 필수로 입력해야 할 항목입니다. 연동형 상품의 경우 연동되는 정보만 수정 가능합니다.')}
    </BaseText>
  );
};

export default CatalogDetailCrawlingDescription;
