import { colors, text_styles, ImageKakaostyleBlk } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom'; // LegacyCompat 변환 X

const NotFoundPage: React.FC = () => {
  const location = useLocation();
  // /, /#!/... 리디렉션
  if (location.pathname === '/') {
    const hash_match = /^#!\/(.*)$/.exec(location.hash);
    if (hash_match != null && hash_match[1] !== '') {
      return <Redirect to={`/${hash_match[1]}`} />;
    } else {
      return <Redirect to='/home' />;
    }
  }
  // /crealfit/#!/... 리디렉션
  const shop_match = /^\/([^/#]+)\/?$/.exec(location.pathname);
  if (shop_match != null) {
    const hash_match = /^#!\/(.*)$/.exec(location.hash);
    if (hash_match != null) {
      return <Redirect to={`/shop/${shop_match[1]}/${hash_match[1]}`} />;
    } else {
      return <Redirect to={`/shop/${shop_match[1]}`} />;
    }
  }
  return (
    <Root>
      <Wrap>
        <ImageKakaostyleBlk color={colors.kakao_yellow400} size={115} />
        <Message>페이지가 존재하지 않습니다.</Message>
        <SubText>링크를 잘못 입력하셨거나 페이지가 삭제/이동되었을 수 있습니다.</SubText>
        <StyledLink to='/home'>홈으로 이동</StyledLink>
      </Wrap>
    </Root>
  );
};

export default NotFoundPage;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  margin-top: 20px;
  ${text_styles.Heading_20_SemiBold};
`;

const SubText = styled.span`
  margin-top: 10px;
  ${text_styles.Body_14_Regular};
`;

const StyledLink = styled(Link)`
  margin-top: 20px;
  text-decoration: underline;
  ${text_styles.Heading_15_SemiBold};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: underline;
  }
`;
