import { VStack, IconColoredDocument, semantic_colors, BaseText } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SectionTitle from './common/SectionTitle';
import { useGetDailyReportTodayNotice } from './hooks/useGetDailyReportTodayNotice';
import { useSendDailyReportPbl } from './hooks/useSendDailyReportPbl';
import { useGetPartnerLoungeNewsDailyQuery } from '@/components/dashboard/main/partner_lounge_news_status/useGetPartnerLoungeNewsDailyQuery';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const News: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const { data } = useGetDailyReportTodayNotice({ date_ymd: date.format('YYYY-MM-DD'), main_domain, token });
  const { data: partner_lounge_data } = useGetPartnerLoungeNewsDailyQuery();
  const history = useHistory();
  const prefix = useShopPrefix();
  const shop_prefix = main_domain ? `/shop/${main_domain}${prefix}` : prefix;
  const { sendClick, sendImpression } = useSendDailyReportPbl(is_partner_plus);
  const news = data?.partner_plus_daily_report?.today_notice;
  const partner_lounge_news = partner_lounge_data?.articles;

  useEffect(() => {
    if ((partner_lounge_news ?? []).length > 0) {
      partner_lounge_news?.forEach((article) => {
        sendImpression({
          object_id: 'partnerlounge',
          object_section: '오늘의 새소식',
          extra: { url: article.url },
        });
      });
    }
  }, [partner_lounge_news]);

  if ((news ?? []).length === 0 && (partner_lounge_news ?? []).length === 0) {
    return null;
  }

  return (
    <Container spacing={8}>
      <SectionTitle
        Icon={IconColoredDocument}
        color={semantic_colors.content.on_color}
        is_partner_plus={is_partner_plus}
        main_domain={main_domain}
      >
        {t('오늘의 새소식')}
      </SectionTitle>
      <List>
        {news?.map((item) => (
          <li
            onClick={() => {
              sendClick({
                object_id: 'view_detail',
                object_section: '오늘의 새소식',
                extra: { id: item.id, title: item.title },
              });
              onClose?.();
              history.push(`${shop_prefix}/notice/${item.id}`);
            }}
          >
            <BaseText kind='Body_14_Regular'>{item.title}</BaseText>
          </li>
        ))}
        {partner_lounge_news?.slice(0, 2)?.map((item) => (
          <li>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={item.url + '?utm_source=partnercenter&utm_medium=dailyreport'}
              target='_blank'
              onClick={() => {
                sendClick({
                  object_id: 'partnerlounge',
                  object_section: '오늘의 새소식',
                  extra: { url: item.url },
                });
              }}
            >
              <BaseText kind='Body_14_Regular'>[라운지] {item.title}</BaseText>
            </a>
          </li>
        ))}
      </List>
    </Container>
  );
};

export default News;

const Container = styled(VStack)`
  background-color: ${semantic_colors.background.base_inverse};
  padding: 24px 16px;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-left: 16px;
  font-size: 12px;
  list-style: disc;
  list-style-position: outside;
  color: ${semantic_colors.content.on_color};

  > li {
    cursor: pointer;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;
