const CONVERTED_IMAGE_FORMATS = ['webp', 'jpeg', 'png'] as const;
const ORIGIN_IMAGE_FORMATS = ['webp', 'jpeg', 'png', 'gif'] as const;

export type ConvertedImageFormat = (typeof CONVERTED_IMAGE_FORMATS)[number];
export type DynamicImageType = 'fill' | 'inside' | 'outside';
type OriginImageFormat = (typeof ORIGIN_IMAGE_FORMATS)[number];

interface ImageParameters {
  image_url: string;
  width: number;
  height: number;
  quality: number;
  format: ConvertedImageFormat;
  type?: DynamicImageType;
}

const ORIGIN_IMAGE_FORMATS_REGEXP = new RegExp(`.(${ORIGIN_IMAGE_FORMATS.join('|')})$`);

export function getImageFormat(image_url: string) {
  return image_url.match(ORIGIN_IMAGE_FORMATS_REGEXP)?.[0]?.slice(1) as OriginImageFormat | undefined;
}

export function getDynamicImageUrl({ image_url, ...params }: ImageParameters) {
  const parsed_url = new URL(image_url);
  const query_params = new URLSearchParams(parsed_url.search);
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && !query_params.has(key)) {
      query_params.set(key, value.toString());
    }
  }
  parsed_url.search = query_params.toString();
  return parsed_url.toString();
}

export function getProductThumbUrl({
  image_url,
  ratio,
}: { ratio?: number } & Omit<ImageParameters, 'width' | 'height' | 'quality' | 'format'>) {
  return image_url
    ? getDynamicImageUrl({
        image_url,
        width: 400,
        height: ratio ? Math.round(400 / ratio) : 400,
        quality: 80,
        format: getImageFormat(image_url) === 'gif' ? 'webp' : 'jpeg',
      })
    : '';
}
