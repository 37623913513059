import { colors, text_styles, IconArrowChevronRight } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export interface PageHeaderStepperProps {
  className?: string;
  steps: React.ReactNode[];
  active_index: number;
}

export const PageHeaderStepper: React.FC<PageHeaderStepperProps> = ({ className, steps, active_index }) => {
  return (
    <StyledStepperNav className={className}>
      <StyledStepperList>
        {steps.map((text, index) => (
          <StyledStepperItem key={index} is_active={index === active_index}>
            <em>{`0${index + 1}`.slice(-2)}</em>
            <span>{text}</span>
            {steps.length > index + 1 && <IconArrowChevronRight color={colors.gray500} size={18} />}
          </StyledStepperItem>
        ))}
      </StyledStepperList>
    </StyledStepperNav>
  );
};

const StyledStepperNav = styled.nav`
  margin: -10px 0 0;
  padding: 0 0 22px 50px;
  background: ${colors.white};
`;

const StyledStepperList = styled.ol`
  display: flex;
`;

const StyledStepperItem = styled.li<{ is_active: boolean }>`
  display: flex;
  align-items: center;
  color: ${colors.gray500};
  ${text_styles.Heading_15_SemiBold};

  em {
    margin: 0 4px 0 0;
    ${text_styles.Heading_18_SemiBold};
  }

  svg {
    margin: 0 9px 0;
  }

  ${({ is_active }) =>
    is_active &&
    css`
      em {
        color: ${colors.zigzag_pink500};
      }

      span {
        color: ${colors.gray900};
      }
    `}
`;
