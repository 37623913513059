import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RenderHandle, useAdminV2 } from 'rui/hooks/useAdminV2';

export function AdminV2Route(): React.ReactElement {
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const url = pathname + search + hash;
  const admin_v2 = useAdminV2();
  const divRef = useRef<HTMLDivElement | null>(null);
  const handleRef = useRef<RenderHandle | null>(null);
  useEffect(() => {
    const divEl = divRef.current;
    if (admin_v2 != null && divEl != null) {
      handleRef.current = admin_v2.createRenderer(divEl, {
        onHistoryUpdate: (action, location) => {
          switch (action) {
            case 'PUSH':
              history.push(location);
              break;
            case 'REPLACE':
              history.replace(location);
              break;
            case 'POP':
              history.goBack();
              break;
          }
        },
      });
      return () => {
        handleRef.current?.unmount?.();
        handleRef.current = null;
      };
    }
  }, [admin_v2, history]);
  useEffect(() => {
    if (admin_v2 != null && handleRef.current != null) {
      handleRef.current.render(url, {});
    }
  }, [admin_v2, url]);
  return <div ref={divRef} />;
}
