import { LineTab, LineTabs } from '@croquiscom/pds';
import React, { FC } from 'react';
import TransPlainText from '@/components/common/TransPlainText';
import { useHistory } from '@/components/route/LegacyRouterCompat';

interface ManagementNavItemData {
  id: 'benefit' | 'subscription' | 'history' | 'notice';
  label: React.ReactNode;
  href: string;
}

const NAV_ITEMS: ManagementNavItemData[] = [
  {
    id: 'benefit',
    label: <TransPlainText ns='partner_plus' i18nKey='구독 혜택 및 안내' />,
    href: '/partner-plus/management/benefit',
  },
  {
    id: 'subscription',
    label: <TransPlainText ns='partner_plus' i18nKey='구독 관리' />,
    href: '/partner-plus/management/subscription',
  },
  {
    id: 'history',
    label: <TransPlainText ns='partner_plus' i18nKey='결제 내역' />,
    href: '/partner-plus/management/history',
  },
  {
    id: 'notice',
    label: <TransPlainText ns='partner_plus' i18nKey='전용 게시판' />,
    href: '/partner-plus/management/notice',
  },
];

export interface ManagementNavProps {
  current_tab?: 'benefit' | 'subscription' | 'history' | 'notice';
}

const ManagementNav: FC<ManagementNavProps> = ({ current_tab }) => {
  const history = useHistory();
  return (
    <LineTabs activeTabId={current_tab}>
      {NAV_ITEMS.map((item) => (
        <LineTab
          key={item.id}
          id={item.id}
          onClick={() => {
            history.push(item.href);
          }}
        >
          {item.label}
        </LineTab>
      ))}
    </LineTabs>
  );
};

export default ManagementNav;
