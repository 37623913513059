import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

export interface ErrorPageProps {
  message: React.ReactNode;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  return (
    <ErrorWrap>
      <Message>
        {message}
        <br />
        <br />
        <StyledLink href='/#!/home'>홈으로 이동</StyledLink>
      </Message>
      <Contact>
        이후에도 계속 문제가 발생할시 아래로 연락을 주세요.
        <div>
          고객센터 연락처:{' '}
          <a href='tel:02-1670-8051' rel='noreferrer'>
            02-1670-8051
          </a>
          <br />
          (문의가능시간 10시-18시, 점심 12시-13시)
        </div>
      </Contact>
    </ErrorWrap>
  );
};

export default ErrorPage;

const ErrorWrap = styled.div`
  min-height: 300px;
  margin: 30px auto;
  max-width: 700px;
  padding: 40px;
  background-color: ${colors.white};
  text-align: center;
  border-radius: 4px;
`;

const Message = styled.p`
  margin-top: 30px;
  font-weight: 600;
  ${text_styles.Heading_20_SemiBold};
  white-space: pre-line;
`;

const Contact = styled.p`
  margin-top: 50px;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  ${text_styles.Heading_15_SemiBold};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: underline;
  }
`;
