import { HStack, HStackProps } from '@croquiscom/pds';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ResponsiveStackType } from './type';
import { getReponsiveValue } from './utils';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsiveHStackProps
  extends Omit<HStackProps, 'spacing' | 'p' | 'm' | 'width' | 'height'>,
    ResponsiveStackType<HStackProps>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
}

export default function ResponsiveHStack({ spacing, p, m, height, width, children, ...props }: ResponsiveHStackProps) {
  const { is_desktop } = useMediaQuery();

  return (
    <HStack
      spacing={getReponsiveValue(spacing, is_desktop)}
      m={getReponsiveValue(m, is_desktop)}
      p={getReponsiveValue(p, is_desktop)}
      width={getReponsiveValue(width, is_desktop)}
      height={getReponsiveValue(height, is_desktop)}
      {...props}
    >
      {children}
    </HStack>
  );
}
