import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ onChange, disabled, children, className, ...props }, ref) => (
    <StyledInput disabled={disabled} className={className}>
      <input ref={ref} type='checkbox' disabled={disabled} {...props} onChange={onChange} />
      <span className='checkmark' />
      {children && <span className='checkbox_label'>{children}</span>}
    </StyledInput>
  ),
);

const StyledInput = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  min-width: 24px;
  height: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    background-color: ${colors.white};
    border: solid 1px ${colors.gray350};
    background-color: ${({ disabled }) => (disabled ? colors.gray200 : colors.white)};
  }

  input:checked ~ .checkmark {
    ${({ disabled }) =>
      disabled
        ? `
        background-color: ${colors.blue200};
        border: solid 1px ${colors.blue300};
    `
        : `
        background-color: ${colors.blue500};
        border: solid 1px ${colors.blue600};
    `}
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkbox_label {
    margin-left: 28px;
    color: ${({ disabled }) => (disabled ? colors.gray600 : colors.gray900)};
    ${text_styles.Body_13_Regular};
  }
`;

export default Checkbox;
