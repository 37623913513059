import { VStack, BaseText } from '@croquiscom/pds';
import React, { useRef } from 'react';
import { ChatBotAnswer } from './ChatBotAnswer';
import { ChatBotQuestion } from './ChatBotQuestion';
import { SSE_STATE } from './hooks/useChatBotRequest';
import { RoundIconContainer } from './styled';
import { ChatBotQnA } from './types';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';

interface ChatBotMessageProps {
  messages: ChatBotQnA[];
  current_question?: string;
  current_answer?: string;
  state: SSE_STATE;
  retry?: () => void;
  startNewThread: () => void;
}

export const ChatBotMessage = ({
  state,
  current_question,
  current_answer,
  messages,
  retry,
  startNewThread,
}: ChatBotMessageProps) => {
  const toast_ref = useRef<HTMLDivElement>(null);
  return (
    <VStack alignment='center' width='100%' style={{ flexGrow: 1 }}>
      <div ref={toast_ref}></div>
      {!current_question && messages.length === 0 ? (
        <VStack alignment='center' spacing={16}>
          <RoundIconContainer size='large'>
            <img width={48} src={ChatBotIcon} alt='' />
          </RoundIconContainer>
          <BaseText kind='Heading_17_Bold' alignment='center'>
            안녕하세요{' '}
            <span role='img' aria-label=''>
              👋
            </span>
            <br />
            저는 파트너센터 AI 챗봇이에요.
            <br /> 무엇을 도와드릴까요?
          </BaseText>
        </VStack>
      ) : (
        <VStack width='100%' spacing={24}>
          {messages.map((message, index) => (
            <VStack spacing={24} key={message.message_id || index}>
              {message?.question && (
                <ChatBotQuestion message={message.question.text} timestamp={message.question.timestamp} />
              )}
              {message?.answer && (
                <ChatBotAnswer
                  is_no_answer={message.is_no_answer}
                  message_id={message.message_id}
                  message={message.answer.text}
                  timestamp={message.answer.timestamp}
                  is_current={index === messages.length - 1}
                  startNewThread={startNewThread}
                  toast_ref={toast_ref}
                />
              )}
            </VStack>
          ))}
          {(state === SSE_STATE.RECEIVING || state === SSE_STATE.LOADING || state === SSE_STATE.ERROR) && (
            <>
              {current_question && <ChatBotQuestion key='question' message={current_question} />}
              {current_question && (
                <ChatBotAnswer key='answer' message={current_answer || ''} state={state} retry={retry} />
              )}
            </>
          )}
        </VStack>
      )}
    </VStack>
  );
};
