import { semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { ShopInfoAdGrade } from './ShopInfoAdGrade';
import { ShopInfoCustomerExtension } from './ShopInfoCustomerExtension';
import { ShopInfoDailyReport } from './ShopInfoDailyReport';
import { ShopInfoInsight } from './ShopInfoInsight';
import { ShopInfoLinkInfo } from './ShopInfoLinkInfo';
import { ShopInfoProfile } from './ShopInfoProfile';
import { ShopInfoZCoin } from './ShopInfoZCoin';
import { isApp } from '@/utils/app_utils/conditions';

export interface ShopInfoProps {}

export const ShopInfo: React.FC<ShopInfoProps> = React.memo(() => {
  return (
    <StyledContainer>
      <ShopInfoProfile />
      <ShopInfoLinkInfo />
      <StyledPanel>
        <ShopInfoZCoin />
        <ShopInfoAdGrade />
      </StyledPanel>
      <StyledDivider />
      <StyledPanel>
        {!isApp() && (
          <>
            <ShopInfoInsight />
            <ShopInfoCustomerExtension />
          </>
        )}
        <ShopInfoDailyReport />
      </StyledPanel>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 24px 20px 16px;
  background-color: ${semantic_colors.background.surface};
  border-bottom: 8px solid ${semantic_colors.background.base};
`;

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledDivider = styled.hr`
  display: block;
  margin: 16px 0;
  height: 1px;
  border: none;
  background-color: ${semantic_colors.border.default};
`;
