import {
  BaseText,
  Button,
  colors,
  HStack,
  IconCircleInfo,
  IconLinkExternal,
  IconLoadingDots,
  IconRefresh,
  semantic_colors,
  text_styles,
  VStack,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import Markdown from 'react-markdown';
import { AnswerFeedback } from './AnswerFeedback';
import { ChatBotLogo } from './ChatBotLogo';
import { SSE_STATE } from './hooks/useChatBotRequest';
import { RoundIconContainer } from './styled';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';
import { usePbl } from '@/hooks/usePbl';
import { useGetChatBotReferenceQuery } from 'api';
interface ChatBotAnswerProps {
  message_id?: string;
  message: string;
  timestamp?: number;
  state?: SSE_STATE;
  is_current?: boolean;
  is_no_answer?: boolean;
  toast_ref?: React.RefObject<HTMLDivElement>;
  retry?: () => void;
  startNewThread?: () => void;
}

export const ChatBotAnswer = ({
  message_id,
  message,
  timestamp,
  state,
  is_current,
  is_no_answer,
  toast_ref,
  retry,
  startNewThread = () => {},
}: ChatBotAnswerProps) => {
  const { pbl } = usePbl();
  const { data, refetch, isLoading, isError } = useGetChatBotReferenceQuery(
    {
      message_id: message_id || '',
      query: message,
    },
    {
      onError: () => {},
      enabled: !!message_id && !!timestamp && !is_no_answer,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );
  return (
    <VStack alignment='leading' spacing={8} width='100%'>
      <HStack spacing={12}>
        <RoundIconContainer size='small'>
          <img width={32} src={ChatBotIcon} alt='' />
        </RoundIconContainer>
        <VStack>
          <HStack spacing={4}>
            <HStack spacing={4} alignment='center' height={20}>
              <ChatBotLogo color={semantic_colors.content.primary} />
            </HStack>
          </HStack>
          <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
            {dayjs(timestamp).format('A hh:mm')}
          </BaseText>
        </VStack>
      </HStack>
      {state === SSE_STATE.LOADING ? (
        <HStack pt={8} pb={8}>
          <IconLoadingDots size={30} />{' '}
        </HStack>
      ) : state === SSE_STATE.ERROR ? (
        <SC.ErrorBox>
          일시적인 네트워크 오류로 답변을 작성하지 못했어요. 잠시 후 다시 시도해주세요.
          <Button endIcon={<IconRefresh />} kind='negative' size='xsmall' fill onClick={retry}>
            다시 시도하기
          </Button>
        </SC.ErrorBox>
      ) : (
        <SC.MessageBox>
          <Markdown
            components={{
              a: ({ href, children }) => (
                <a
                  href={href}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    pbl({
                      navigation: 'partner_center_chatbot',
                      navigation_sub: {
                        sub: 'chat',
                      },
                      category: 'click',
                      object_id: 'help_link',
                      extra: JSON.stringify({
                        url: href,
                      }),
                    });
                  }}
                >
                  {children}
                </a>
              ),
            }}
          >
            {message}
          </Markdown>
          <VStack spacing={16}>
            {timestamp && (
              <HStack spacing={4} alignment='center'>
                <IconCircleInfo size={12} color={semantic_colors.content.tertiary} />
                <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
                  챗봇은 실수할 수 있습니다. 답변 내용을 꼭 확인해 주세요.
                </BaseText>
              </HStack>
            )}
            {message_id && ((data && data.shop_chatbot_references.item_list.length > 0) || isLoading || isError) && (
              <HStack spacing={8}>
                <Button
                  endIcon={isError ? <IconRefresh /> : <IconLinkExternal />}
                  loading={isLoading}
                  kind={isError ? 'negative' : 'secondary'}
                  size='xsmall'
                  fill
                  onClick={() => {
                    if (data?.shop_chatbot_references.item_list[0]) {
                      pbl({
                        navigation: 'partner_center_chatbot',
                        navigation_sub: {
                          sub: 'chat',
                        },
                        object_id: 'help_link',
                        category: 'click',
                      });
                      window.open(data?.shop_chatbot_references.item_list[0].url, '_blank');
                    } else {
                      refetch();
                    }
                  }}
                >
                  관련 도움말
                </Button>
              </HStack>
            )}
          </VStack>
        </SC.MessageBox>
      )}

      {is_current && message_id && (
        <AnswerFeedback message_id={message_id} startNewThread={startNewThread} toast_ref={toast_ref} />
      )}
    </VStack>
  );
};

const SC = {
  ErrorBox: styled(VStack)`
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${colors.red200};
    background-color: ${semantic_colors.state.negative_light};
    row-gap: 16px;
    ${text_styles.Body_14_Regular};
    line-height: 1.6;
  `,
  MessageBox: styled(VStack)`
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    word-break: break-all;
    border: 1px solid ${semantic_colors.border.default};
    ${text_styles.Body_14_Regular};
    line-height: 1.6;

    strong {
      ${text_styles.Body_14_SemiBold};
      line-height: 1.6;
    }
    > p {
      margin-bottom: 8px;
    }
    ol,
    ul {
      list-style: auto;
      list-style-position: inside;
      > li {
        margin-bottom: 8px;
        > ul,
        ol {
          padding-left: 16px;
        }
      }
    }
    ul > li {
      list-style-type: disc;
    }
    p {
      display: inline;
    }
    h3 {
      ${text_styles.Heading_15_SemiBold};
      line-height: 1.6;
      margin-bottom: 8px;
    }
    a {
      color: ${semantic_colors.accent.primary};
    }
  `,
};
