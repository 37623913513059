import { Config } from '@/config';

const EXPIRED_TIME = 1000 * 60 * 30;

class ChatBotSession {
  private session_key: string;
  private session_time: number;

  private create() {
    this.session_key = `${Config.shop_main_domain}_${Date.now()}`;
    this.session_time = Date.now();
  }
  private remove() {
    this.session_key = '';
    this.session_time = 0;
  }
  public getKey() {
    if (!this.session_key) {
      this.create();
      return this.session_key;
    }
    return this.session_key;
  }
  public isExpired() {
    if (!this.session_key) {
      return true;
    }
    return Date.now() - this.session_time > EXPIRED_TIME;
  }
  public refreshTime() {
    if (this.isExpired()) {
      this.remove();
      this.create();
    } else {
      if (this.session_key) {
        this.session_time = Date.now();
      }
    }
  }
  public refresh() {
    this.remove();
    this.create();
  }
}
export const chat_bot_session = new ChatBotSession();
