import ReactGA from 'react-ga4';
import Info from '@/types/info';

const sendPageView = (url: string, shop_info?: Info) => {
  const _url = url.replace(/(\?.*)/, '');
  ReactGA.send({ hitType: 'pageview', page: _url, title: `${shop_info?.name ?? '공통페이지'} - ${_url}` });
};

const sendEvent = (category: string, action: string, label?: string, value?: number) => {
  const _label = label ? { eventLabel: label } : {};
  const _value = value ? { eventValue: value } : {};

  ReactGA.event({ category, action, label, value });
};

export default {
  sendPageView,
  sendEvent,
};
