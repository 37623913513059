import { IconCart } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId, isNotAllowedByShopDepartment } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment, ShopType } from '@/types/info';
import { SellerResource } from 'api';
import { OrderItemListUrl, OrderLinkedListUrl } from 'rui/components/URL';

export function createOrderItemMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_main_allowed = isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.도매, SiteId.마이스토어]);
  // 직잭 메이트(16871)는 연동 관리 항상 노출
  const is_linked_allowed = isAllowedMenuBySiteId(info, [SiteId.카카오톡_쇼핑하기]) || info.id === '16871';
  return [
    {
      id: 'order_item',
      label: t('main_frame::주문 배송 관리'),
      icon: <IconCart size={16} color='currentColor' />,
      is_allowed:
        is_zpay && (is_main_allowed || is_linked_allowed) && isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
      is_support_app: true,
      items: [
        {
          id: 'order_item_list_search',
          label: t('main_frame::전체 주문 검색'),
          href: OrderItemListUrl.SEARCH,
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_SEARCH], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_pending',
          label: t('main_frame::대기 주문'),
          href: OrderItemListUrl.PENDING,
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_SEARCH], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_pre_shipment',
          label: t('main_frame::상품준비/배송 관리'),
          href: '/order_item/list/pre_shipment',
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_shipment',
          label: t('main_frame::배송현황 관리'),
          href: OrderItemListUrl.SHIPMENT,
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_confirm',
          label: t('main_frame::구매확정 관리'),
          href: '/order_item/list/confirm',
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_cancel',
          label: t('main_frame::취소 관리'),
          href: OrderItemListUrl.CANCEL,
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_return',
          label: t('main_frame::반품 관리'),
          href: '/order_item/list/return',
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_item_list_exchange',
          label: t('main_frame::교환 관리'),
          href: OrderItemListUrl.EXCHANGE,
          is_allowed: is_main_allowed && checkRes([SellerResource.ZPAY_ORDER_MANAGEMENT], info.allowed_resource_list),
          is_support_app: true,
        },
        {
          id: 'order_linked_management',
          label: t('main_frame::연동 주문 관리'),
          is_allowed: is_linked_allowed && checkRes([SellerResource.OMS_ORDER_MANAGEMENT], info.allowed_resource_list),
          items: [
            {
              id: 'order_linked_management_list_search',
              label: t('main_frame::연동 주문 전체 검색'),
              href: OrderLinkedListUrl.SEARCH,
            },
            {
              id: 'order_linked_management_list_shipment',
              label: t('main_frame::연동 주문 배송 관리'),
              href: OrderLinkedListUrl.SHIPMENT,
            },
            {
              id: 'order_linked_management_list_cancel',
              label: t('main_frame::연동 주문 취소 관리'),
              href: OrderLinkedListUrl.CANCEL,
            },
            {
              id: 'order_linked_management_list_return',
              label: t('main_frame::연동 주문 반품 관리'),
              href: OrderLinkedListUrl.RETURN,
            },
            {
              id: 'order_linked_management_list_exchange',
              label: t('main_frame::연동 주문 교환 관리'),
              href: OrderLinkedListUrl.EXCHANGE,
            },
          ],
        },
      ],
    },
  ];
}
