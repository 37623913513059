import { IconArrowChevronRight, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

export interface ItemRowProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  show_right?: boolean;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const ItemRow: React.FC<ItemRowProps> = ({ icon, title, children, show_right, onClick, to }) => {
  const content = (
    <>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>
        {children}
        {show_right && (
          <StyledRightIcon>
            <IconArrowChevronRight size={14} color={semantic_colors.content.secondary} />
          </StyledRightIcon>
        )}
      </StyledContent>
    </>
  );
  if (to != null) {
    return (
      <StyledLink to={to} onClick={onClick}>
        {content}
      </StyledLink>
    );
  }
  return (
    <StyledRow has_click={!!onClick} onClick={onClick}>
      {content}
    </StyledRow>
  );
};

const StyledRow = styled.div<{ has_click?: boolean }>`
  display: flex;
  align-items: center;
  color: ${semantic_colors.content.primary};
  ${text_styles.Body_12_SemiBold};
  text-decoration: none;
  ${({ has_click }) => has_click && 'cursor: pointer;'}

  &:visited {
    color: ${semantic_colors.content.primary};
  }
`;

const StyledLink = StyledRow.withComponent(Link);

const StyledIcon = styled.span`
  flex: 0 0 auto;
  line-height: 0;
`;

const StyledTitle = styled.span`
  flex: 0 0 auto;
  margin-left: 4px;
`;

const StyledContent = styled.span`
  flex: 1 0 0px;
  text-align: right;
`;

const StyledRightIcon = styled.span`
  display: inline-block;
  line-height: 0;
  vertical-align: -3px;
`;
