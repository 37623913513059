import * as React from 'react';
import { useEffect } from 'react';

const useClickOutside = (
  refs: React.MutableRefObject<HTMLElement | null>[],
  listen: boolean,
  onClickOutside: () => void,
  enable: boolean = true,
): void => {
  useEffect(() => {
    const isClickable = listen && enable && refs.length;
    if (!isClickable) {
      return;
    }

    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!document.body.contains(target)) {
        return;
      }
      const isClickInSide = refs.reduce((acc, ref) => acc || ref.current?.contains(target), false);
      if (!isClickInSide) {
        onClickOutside();
      }
    };
    document.body.addEventListener('click', handleClickOutside, true);
    return () => {
      document.body.removeEventListener('click', handleClickOutside, true);
    };
  }, [refs, listen, enable]);
};

export default useClickOutside;
