import React from 'react';
import { Trans } from 'react-i18next';

import { DefaultFunnelData } from './types';

export const ENTRY_COMPLETE_FUNNEL: DefaultFunnelData = {
  step: 'ENTRY_COMPLETE',
  name: <Trans ns='onboarding'>카카오스타일 입점하기</Trans>,
  description: () => {
    return {
      title: <Trans ns='onboarding'>카카오스타일 입점하기.</Trans>,
      sub_title: (
        <Trans ns='onboarding'>
          카카오스타일에 입점하신 것을 환영합니다.
          <br />
          판매를 시작하기 위해서 스토어 정보와 상품 등록이 필요합니다.
          <br />
          카카오스타일에서 성공적인 활동을 기원합니다!
        </Trans>
      ),
      tags: [],
      actions: [],
      shortcut: null,
      controller: null,
    };
  },
  order: 1,
  is_completed: false,
  date_completed: null,
  in_progress_task: null,
  tasks: [],
};
