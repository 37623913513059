import { useCallback, useEffect } from 'react';

/**
 * 중복해서 불러오는 상황을 방지할 수 있도록, 동시에 1번만 로더 함수를 호출할 수 있도록 Promise를
 * 합쳐주는 함수를 생성합니다.
 * 기본적으로 한 번 로더 함수가 실행되고 반환된 Promise를 계속 기억해주는 기능을 가지고 있습니다.
 * 로더 함수가 resolve하고 나면, 해당 값은 계속해서 기억되어 사용됩니다.
 * 로더 함수가 reject하면, 기억된 Promise를 지우고 다음 번 함수를 호출할 때에는 새로 로더 함수를
 * 호출합니다.
 */
function createLoader<TArgs extends unknown[], TValue>(
  loadFunc: (...args: TArgs) => Promise<TValue>,
): (...args: TArgs) => Promise<TValue> {
  let ongoing_promise: Promise<TValue> | null = null;
  return (...args) => {
    if (ongoing_promise != null) {
      return ongoing_promise;
    }
    ongoing_promise = loadFunc(...args);
    ongoing_promise.catch(() => {
      ongoing_promise = null;
    });
    return ongoing_promise;
  };
}

export interface Airbridge {
  init(config: { app: string; webToken: string; user?: unknown }): void;
  openDeeplink(config: {
    type: string;
    deeplinks: Record<'ios' | 'android' | 'desktop', string>;
    fallbacks: Record<'ios' | 'android', string>;
  }): void;
  events: {
    send(category: string, attributes?: unknown): void;
  };
}

declare global {
  interface Window {
    airbridge: Airbridge;
  }
}

function initAirbridge(): void {
  if (process.env.STAGE === 'production') {
    window.airbridge.init({
      app: 'partnercenter',
      webToken: '344d74cad1b5498881671c457e97ffb9',
    });
  } else if (process.env.STAGE === 'beta') {
    window.airbridge.init({
      app: 'partnercenterbeta',
      webToken: '1c5bc8ea893242698d570258cd118d91',
    });
  } else {
    window.airbridge.init({
      app: 'partnercenteralpha',
      webToken: 'e35f85b89099449a9a00dbffea83a018',
    });
  }
}

/**
 * @see https://help.airbridge.io/en/developers/web-sdk
 */
export const getAirbridgeSdk = createLoader((): Promise<any> => {
  if (window.airbridge != null) {
    initAirbridge();
    return Promise.resolve(window.airbridge);
  }
  return new Promise((resolve, reject) => {
    const temp_obj: any = {
      queue: [],
      get isSDKEnabled() {
        return false;
      },
    };
    [
      'init',
      'startTracking',
      'fetchResource',
      'setBanner',
      'setDownload',
      'setDownloads',
      'openDeeplink',
      'setDeeplinks',
      'sendWeb',
      'setUserAgent',
      'setUserAlias',
      'addUserAlias',
      'setMobileAppData',
      'setUserId',
      'setUserEmail',
      'setUserPhone',
      'setUserAttributes',
      'clearUser',
      'setDeviceAlias',
      'removeDeviceAlias',
      'clearDeviceAlias',
      'setDeviceIFV',
      'setDeviceIFA',
      'setDeviceGAID',
      'events.send',
      'events.signIn',
      'events.signUp',
      'events.signOut',
      'events.purchased',
      'events.addedToCart',
      'events.productDetailsViewEvent',
      'events.homeViewEvent',
      'events.productListViewEvent',
      'events.searchResultViewEvent',
      'events.wait',
      'createTouchpoint',
    ].forEach((c) => {
      const a = c.split('.');
      const h = a.pop()!;
      a.reduce((p, q) => (p[q] = p[q] || {}), temp_obj)[h] = (...args: any[]) => {
        temp_obj.queue.push([c, args]);
      };
    });
    window.airbridge = temp_obj;
    initAirbridge();

    const script_elem = document.createElement('script');
    script_elem.onerror = (e) => reject(e);
    script_elem.onload = () => {
      const airbridge = window.airbridge;
      if (airbridge == null) {
        reject(new Error('Airbridge API를 불러올 수 없습니다.'));
      }
      resolve(airbridge);
    };
    script_elem.async = true;
    script_elem.src = '//static.airbridge.io/sdk/latest/airbridge.min.js';
    document.body.appendChild(script_elem);
  });
});

export function useAirbridgeSdk(enabled: boolean = true) {
  useEffect(() => {
    if (enabled) {
      getAirbridgeSdk();
    }
  }, [enabled]);
  const handleGet = useCallback(() => getAirbridgeSdk(), []);
  const handleOpen = useCallback(() => {
    const open = () => {
      window.airbridge.openDeeplink({
        type: 'redirect',
        deeplinks: (() => {
          if (process.env.STAGE === 'production') {
            return {
              ios: 'partners://',
              android: 'partners://',
              desktop: 'https://partners.kakaostyle.com/',
            };
          } else if (process.env.STAGE === 'beta') {
            return {
              ios: 'partners-beta://',
              android: 'partners-beta://',
              desktop: 'https://beta.partners.kakaostyle.com/',
            };
          } else {
            return {
              ios: 'partners-alpha://',
              android: 'partners-alpha://',
              desktop: 'https://alpha.partners.kakaostyle.com/',
            };
          }
        })(),
        fallbacks: {
          ios: 'itunes-appstore',
          android: 'google-play',
        },
      });
    };
    if (window.airbridge != null) {
      open();
    } else {
      getAirbridgeSdk().then(() => open());
    }
  }, []);
  return { get: handleGet, open: handleOpen };
}
