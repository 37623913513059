import { semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteId } from '@/constants/site';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import { getUniqSiteIdList } from '@/utils';
import { SellerResource } from 'api';
import { ChannelIcon } from 'rui/components/shop';

export interface AccountBarAccountShopInfoProps {}

export const AccountBarAccountShopInfo: React.FC<AccountBarAccountShopInfoProps> = () => {
  const { t } = useTranslation('main_frame');
  const { is_agency, is_zigzag } = useAccount();
  const { info, isAllowed } = useInfo();
  const site_country_list = (info.is_parent ? info.child_shop_site_country_list : info.site_country_list) ?? [];
  const site_id_list = getUniqSiteIdList<SiteId>(site_country_list);
  const is_master = isAllowed([SellerResource.ALL]);
  return (
    <>
      <StyledShopHeader>
        <StyledShopTitle>{info.name}</StyledShopTitle>
        <StyledShopInfo>
          <StyledShopPermission>
            {(() => {
              if (is_zigzag) return t('지그재그 계정');
              if (is_master) return t('마스터 계정');
              if (is_agency) return t('대행사 계정');
              return t('매니저 계정');
            })()}
          </StyledShopPermission>
          <StyledShopIcon>
            {site_id_list.map((site_id) => (
              <ChannelIcon shop_site={{ site_id: Number(site_id) }} key={site_id} />
            ))}
          </StyledShopIcon>
        </StyledShopInfo>
      </StyledShopHeader>
      <StyledDivider />
    </>
  );
};

const StyledShopHeader = styled.div`
  padding: 8px 12px;
`;

const StyledShopTitle = styled.p`
  margin: 0 0 4px;
  padding: 0;
  color: ${semantic_colors.content.primary};
  ${text_styles.Body_14_SemiBold};
  overflow-wrap: anywhere;
`;

const StyledShopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledShopPermission = styled.p`
  color: ${semantic_colors.content.tertiary};
  ${text_styles.Body_12_Medium};
`;

const StyledShopIcon = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledDivider = styled.hr`
  display: block;
  height: 1px;
  margin: 8px 12px;
  border: none;
  background-color: ${semantic_colors.border.default};
`;
