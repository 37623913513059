import dayjs from 'dayjs';
import numeral from 'numeral';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from '../common/styles';

interface Props {
  date_created: number;
  date_ym: number;
  download_url: string;
  total_monthly_settlement_amount: number;
}
const TableRowItem: React.FC<Props> = ({ date_created, date_ym, download_url, total_monthly_settlement_amount }) => {
  const { t } = useTranslation('settlement');

  return (
    <TableRow>
      <td>{dayjs(date_created).format('YYYY.MM.DD')}</td>
      <td>
        <a href={download_url}>{dayjs(String(date_ym), 'YYYYMM').format(t('YYYY년 MM월 정산 기준 내역'))}</a>
      </td>
      <td className='align-right'>
        {t('{{ currency }}원', { currency: numeral(total_monthly_settlement_amount).format('0,0') })}
      </td>
    </TableRow>
  );
};

export default TableRowItem;
