import { AxiosRequestConfig } from 'axios';
import { Config } from '@/config';
import axios, { handleCatchedErrorDefault, OriginalError } from '@/utils/axios';

export interface RequestOptions {
  no_alert?: boolean;
  no_shop_domain?: boolean;
  no_redirect_to_login?: boolean;
}

export const BETA_MUTATION_CONFIRMED_KEY = 'beta_is_mutation_confirmed';

const getUrl = (url: string = '', no_shop_domain: boolean = false) => {
  if (/^https?:\/\//.test(url)) {
    return url;
  }
  let main_domain = (no_shop_domain ? '' : Config.shop_main_domain).trim();
  if (main_domain) {
    main_domain = `/${main_domain}`;
  }
  return `${Config.api_base_url}${main_domain}${url}`;
};

export const request = <R>(config: AxiosRequestConfig, options: RequestOptions) => {
  const { no_alert, no_shop_domain, no_redirect_to_login } = options;
  const { url: _url, ...restConfig } = config;
  const url = getUrl(_url, no_shop_domain);

  return axios
    .request<{ data: R; errors?: OriginalError[] }>({
      url,
      ...restConfig,
    })
    .then((result) => result.data)
    .catch((error) => {
      handleCatchedErrorDefault(error, {
        no_alert,
        no_redirect_to_login,
      });
    });
};

export interface GqlRequestOptions extends RequestOptions {
  appendFormdata?: (form_data: FormData) => void;
  context?: any;
  signal?: AbortSignal;
}

export function gql_request<R, V = {}>(
  operation_name: string,
  query: string,
  variables?: V,
  options: GqlRequestOptions = {},
) {
  const { appendFormdata, context, signal, ...restOptions } = options;
  let data: any = { query, variables };
  if (process.env.STAGE === 'beta' && query.includes('mutation')) {
    const mutation_confirmed_time = sessionStorage.getItem(BETA_MUTATION_CONFIRMED_KEY);
    const valid_time = Date.now() - 1000 * 60 * 60;
    if (!mutation_confirmed_time || parseInt(mutation_confirmed_time) < valid_time) {
      if (
        window.confirm('실제 데이터가 변경되는 작업입니다. 실제 셀러와 유저에게 영향을 주게 됩니다. 계속 하시겠습니까?')
      ) {
        sessionStorage.setItem(BETA_MUTATION_CONFIRMED_KEY, Date.now().toString());
      } else {
        return Promise.reject({ message: '요청이 취소되었습니다.' });
      }
    }
  }
  if (appendFormdata != null) {
    const form_data = new FormData();
    form_data.append('operations', JSON.stringify(data));
    appendFormdata(form_data);
    data = form_data;
  }
  const headers: any = {};

  if (context) {
    headers.cookie = context.headers.cookie;
  }

  const result = request<R>(
    {
      method: 'POST',
      headers,
      url: `/graphql/${operation_name}`,
      data,
      signal,
    },
    restOptions,
  );
  return result;
}

const OPERATION_NAME_REGEXP = /(?:query\s|mutation\s)(?<operation_name>\w+)(?=\(|\s*\{)/;

export function gql_fetcher<R, V>(query: string, variables?: V, options?: GqlRequestOptions) {
  const operation_name = query.match(OPERATION_NAME_REGEXP)?.groups?.operation_name ?? '';
  return () => gql_request<R, V>(operation_name, query, variables, options);
}
