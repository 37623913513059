import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoundedButton } from './RoundedButton';

export const FeedbackOutlinkButton = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.open('https://forms.gle/DZDoHi32yPbqojtu5', '_blank', 'noreferrer');
  };
  return <RoundedButton onClick={handleClick}>{t('피드백')}</RoundedButton>;
};
