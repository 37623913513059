import { BaseText, Button, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import logo from './assets/logo.svg';
import { useAirbridgeSdk } from './hooks/useAirbridgeSdk';
import { useIsBannerShown } from './hooks/useIsBannerShown';

export interface AppNudgingBannerProps {
  className?: string;
  footer?: React.ReactNode;
}

export const AppNudgingBanner: React.FC<AppNudgingBannerProps> = ({ className, footer }) => {
  const is_banner_shown = useIsBannerShown();
  const { open } = useAirbridgeSdk(is_banner_shown);
  if (!is_banner_shown) {
    return null;
  }
  return (
    <>
      <StyledContainer className={className}>
        <StyledImage src={logo} alt='' width={38} height={38} />
        <VStack mr='auto'>
          <StyledHeader kind='Body_14_Bold' color={semantic_colors.content.primary}>
            매번 번거로운 로그인
          </StyledHeader>
          <BaseText kind='Body_12_Regular' color={semantic_colors.content.secondary}>
            파트너센터 앱으로 해결해보세요.
          </BaseText>
        </VStack>
        <Button kind='primary' size='small' onClick={() => open()}>
          앱 다운로드
        </Button>
      </StyledContainer>
      {footer}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: ${semantic_colors.background.surface};
  word-break: keep-all;
`;

const StyledImage = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
`;

const StyledHeader = styled(BaseText)`
  margin: 0 0 -2px;
`;
