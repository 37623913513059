import { Alert, BaseText, Checkbox, colors, Dropdown, FormField, Input, Modal, VStack } from '@croquiscom/pds';
import { checkMobileTelNumber, getTelNumberWithHypen } from '@croquiscom/zigzag-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeSellerAccountInfoMutation } from '@/api';
import { gql_request } from '@/api/request';
import { useAccount } from '@/hooks/useAccount';
import { checkPassword } from '@/utils/passwordUtils';

interface AccountInfoEditorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountInfoEditorModal: React.FC<AccountInfoEditorModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('account');
  const [name, setName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [mfa_method, setMfaMethod] = useState('');
  const [password, setPassword] = useState('');
  const [change_password, setChangePassword] = useState(false);
  const [new_password, setNewPassword] = useState('');
  const [new_password_confirm, setNewPasswordConfirm] = useState('');

  const { identifier, is_agency_marketer, requestAccount } = useAccount();
  const { mutate: changeSellerAccountInfo } = useChangeSellerAccountInfoMutation({
    onSuccess: ({ changeSellerAccountInfo }) => {
      if (changeSellerAccountInfo) {
        Alert({ title: t('수정완료'), text: t('계정 정보가 수정되었습니다.') });
        onClose();
        requestAccount();
      }
    },
    onError: (error: { message: string }) => {
      Alert({ title: t('수정실패'), text: error.message });
      setPassword('');
    },
  });

  useEffect(() => {
    if (isOpen) {
      setName('');
      setPhoneNumber('');
      setMfaMethod('');
      setPassword('');
      setNewPassword('');
      setNewPasswordConfirm('');
      (async () => {
        try {
          const query = 'query GetSellerAccountInfo { seller_account { identifier name phone_number mfa_method } }';
          const result = await gql_request<
            {
              seller_account:
                | {
                    identifier: string;
                    name?: string | null;
                    phone_number?: string | null;
                    mfa_method?: string | null;
                  }
                | null
                | undefined;
            },
            null
          >('GetSellerAccountInfo', query, undefined, {
            no_shop_domain: true,
            no_redirect_to_login: true,
            no_alert: true,
          });
          setName(result.data.seller_account?.name ?? '');
          setPhoneNumber(result.data.seller_account?.phone_number ?? '');
          setMfaMethod(result.data.seller_account?.mfa_method ?? '');
        } catch (error) {
          return null;
        }
      })();
    }
  }, [isOpen]);

  const phone_number_error_message = phone_number ? checkMobileTelNumber(phone_number) : t('연락처를 입력해주세요.');
  const new_password_error_message = checkPassword(new_password);
  const new_password_confirm_error = new_password !== new_password_confirm && new_password_confirm.length > 0;

  const canConfirm = () => {
    const is_agency_info_valid = name.length > 0 && !phone_number_error_message;
    const is_valid = change_password
      ? password.length > 0 &&
        new_password.length > 0 &&
        new_password_confirm.length > 0 &&
        !new_password_error_message &&
        !new_password_confirm_error
      : password.length > 0;

    return is_agency_marketer ? is_agency_info_valid && is_valid : is_valid;
  };

  const updateInfo = () => {
    changeSellerAccountInfo({
      input: {
        name,
        phone_number,
        mfa_method,
        current_password: password,
        new_password: change_password ? new_password : null,
      },
    });
  };

  return (
    <Modal
      width={460}
      title={t('계정 정보 수정')}
      opened={isOpen}
      contentProps={{ scrollable: true }}
      onCancel={onClose}
      cancelText={t('닫기')}
      confirmText={t('수정')}
      onConfirm={updateInfo}
      confirmButtonProps={{ disabled: !canConfirm() }}
    >
      <VStack spacing={24}>
        <FormField label={t('아이디')}>
          <Input width={165} disabled value={identifier} />
        </FormField>
        <FormField label={t('기존 비밀번호')} required>
          <Input
            width={165}
            type='password'
            status={password.length < 1 ? 'error' : 'info'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {!password && (
            <BaseText as='div' kind='Body_12_Regular' color={colors.red500}>
              {t('기존 비밀번호를 입력해주세요.')}
            </BaseText>
          )}
        </FormField>
        <FormField label={t('이름')}>
          <Input
            width={165}
            status={is_agency_marketer && !name ? 'error' : 'info'}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {is_agency_marketer && !name && (
            <BaseText as='div' kind='Body_12_Regular' color={colors.red500}>
              {t('이름을 입력해주세요')}.
            </BaseText>
          )}
        </FormField>
        <FormField label={t('연락처')}>
          <Input
            width={165}
            status={is_agency_marketer && !phone_number_error_message ? 'error' : 'info'}
            value={phone_number}
            onChange={(e) => setPhoneNumber(getTelNumberWithHypen(e.target.value))}
          />
          {is_agency_marketer && !phone_number_error_message && (
            <BaseText as='div' kind='Body_12_Regular' color={colors.red500}>
              {phone_number_error_message}
            </BaseText>
          )}
        </FormField>
        <FormField label={t('이차 인증 방법')}>
          <Dropdown
            options={[
              { label: '이차 인증 없음', value: '' },
              { label: '문자', value: 'SMS' },
            ]}
            value={mfa_method}
            onChange={(value: string) => setMfaMethod(value)}
          />
        </FormField>
        <FormField label={t('비밀번호 변경')}>
          <Checkbox checked={change_password} onChange={(e) => setChangePassword(e.currentTarget.checked)} />
        </FormField>
        {change_password && (
          <>
            <FormField label={t('신규 비밀번호')}>
              <Input
                width={165}
                type='password'
                status={new_password_error_message ? 'error' : 'info'}
                value={new_password}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {new_password_error_message && (
                <BaseText as='div' kind='Body_12_Regular' color={colors.red500}>
                  {new_password_error_message}
                </BaseText>
              )}
            </FormField>
            <FormField label={t('비밀번호 확인')}>
              <Input
                width={165}
                type='password'
                status={new_password_confirm_error ? 'error' : 'info'}
                value={new_password_confirm}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />
              {new_password_confirm_error && (
                <BaseText as='div' kind='Body_12_Regular' color={colors.red500}>
                  {t('비밀번호가 일치하지 않습니다.')}
                </BaseText>
              )}
            </FormField>
          </>
        )}
      </VStack>
    </Modal>
  );
};

export default AccountInfoEditorModal;
