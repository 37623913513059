import { BaseText, VStack, IconCircleWarning, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from './styles';

interface Props {
  children?: React.ReactNode;
}

const Nodata: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('main_frame');

  return (
    <Card>
      <Container spacing={4}>
        <IconCircleWarning size={24} color={semantic_colors.content.tertiary} />
        <BaseText kind='Body_12_Medium' color={semantic_colors.content.tertiary} alignment='center'>
          <Trans t={t}>조회 가능한 데이터가 없어요.</Trans>
        </BaseText>
        {children}
      </Container>
    </Card>
  );
};

export default Nodata;

const Container = styled(VStack)`
  padding: 8px;
  align-items: center;
  justify-content: center;
`;
