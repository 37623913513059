import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export const WAITING_UPDATE_TEXT = '데이터 업데이트를 기다리고 있어요 (~정오)';
export const API_ERROR_TEXT = '데이터를 불러올 수 없어요';
export const NO_DATA_TEXT = '조회기간의 데이터가 없어요';

export const PLAN_NAME = {
  PARTNER_PLUS: 'PARTNER PLUS',
};

export const INSIGHT_GUIDE_OPTIONS = createHelpConstantOptions({
  name: '파트너플러스 인사이트 가이드 URL',
  defaults: {
    '화면 사용법': 'https://www.notion.so/croquis/Top-5-906dde34a29b4697b77f99dd82b5a137',
  },
});
