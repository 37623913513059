import { Config } from '@/config';

export const TERMS = {
  이용약관: Config.alpha
    ? 'https://cf.res.s.alpha.zigzag.kr/zigzag/terms/seller/ko/insight_service.html'
    : 'https://cf.res.s.zigzag.kr/zigzag/terms/seller/ko/insight_service.html',
  운영정책: Config.alpha
    ? 'https://cf.res.s.alpha.zigzag.kr/zigzag/terms/seller/ko/subscription_service_policy.html'
    : 'https://cf.res.s.zigzag.kr/zigzag/terms/seller/ko/subscription_service_policy.html',
};
