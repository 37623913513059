import dayjs from 'dayjs';
import { useState } from 'react';

interface UseOnceTooltipParams {
  key: string;
  end_date?: string;
  compare_value?: string;
}

export const useOnceTooltip = ({ key, end_date, compare_value = 'true' }: UseOnceTooltipParams) => {
  const is_expired = end_date ? dayjs().isAfter(dayjs(end_date)) : false;
  const default_show = localStorage.getItem(key) !== compare_value && !is_expired;
  const [is_show, setIsShow] = useState(default_show);

  const hideTooltip = () => {
    if (is_show) {
      localStorage.setItem(key, compare_value);
      setIsShow(false);
    }
  };
  return {
    is_show,
    hideTooltip,
  };
};
