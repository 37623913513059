import { semantic_colors, BaseText, VStack, IconArrowChevronUp, TextButton } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NoMoreDataProps {
  getScrollParent: () => HTMLElement | null;
}

export const NoMoreData: React.FC<NoMoreDataProps> = ({ getScrollParent }) => {
  const { t } = useTranslation('main_frame');

  const handleToTopClick = () => {
    const target = getScrollParent();
    if (target) {
      target.scrollTop = 0;
    }
  };

  return (
    <NoMoreText>
      <BaseText kind='Body_14_Medium'>{t('지난 30일 동안 받은 알림은 여기까지입니다')}</BaseText>
      <TextButton
        color={semantic_colors.content.tertiary}
        endIcon={<IconArrowChevronUp size={14} color='currentColor' />}
        onClick={handleToTopClick}
      >
        {t('최상단으로 이동')}
      </TextButton>
    </NoMoreText>
  );
};

const NoMoreText = styled(VStack)`
  padding: 10px 26px 26px;
  align-items: center;
  color: ${semantic_colors.content.tertiary};
`;
