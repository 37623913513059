import { Badge, IconArrowDecrease, IconArrowIncrease, VStack } from '@croquiscom/pds';
import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface RollingBadgeData {
  label: string;
  data: number;
}
export interface RollingBadgeProps {
  data_list: RollingBadgeData[];
}

export const RollingBadge: React.FC<RollingBadgeProps> = ({ data_list }) => {
  return (
    <VStack height={20}>
      <Swiper
        direction='vertical'
        loop
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
      >
        {data_list.map((data) => (
          <SwiperSlide>
            <Badge key={data.label} color={data.data >= 0 ? 'green' : 'red'} size='small' kind='fill'>
              {data.label} {Math.abs(Math.round(data.data))}%
              {data.data >= 0 ? (
                <IconArrowIncrease size={8} color='currentColor' />
              ) : (
                <IconArrowDecrease size={8} color='currentColor' />
              )}
            </Badge>
          </SwiperSlide>
        ))}
      </Swiper>
    </VStack>
  );
};
