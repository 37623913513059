import { Divider, HStack, IconCircleInfo, PageHeader, SectionTitle, TextButton } from '@croquiscom/pds';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ExampleModal from '../modal/ExampleModal';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { useModalState } from 'rui/pastel/unstable/modal';

const PriceOptimizationPageHeader = () => {
  const [{ isOpen, onClose, onOpen }] = useModalState(false);
  const { is_desktop } = useMediaQuery();
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  return (
    <PageHeader dense={!is_desktop}>
      <SectionTitle
        title='가격 최적화 관리'
        description={
          <>
            제안된 추천가로 적용 시, 타 경쟁사 대비 한 단계 더 높은 노출 기회를 통해 상품의 경쟁력을 향상시킬 수
            있습니다.
            <br />※ 추천가 적용은 노출 증대를 위한 추가 기능이며, 적용 여부는 판매자의 선택에 달려 있습니다.
          </>
        }
        textButton={
          <HStack spacing={6} alignment='center'>
            <TextButton kind='link' onClick={onOpen} startIcon={<IconCircleInfo />}>
              노출 확대 안내
            </TextButton>
            <Divider direction='vertical' length={12} />
            <TextButton
              kind='link'
              onClick={() => history.push(`${shop_prefix}/promotion/price_optimization/intro`)}
              startIcon={<IconCircleInfo />}
            >
              서비스 소개
            </TextButton>
          </HStack>
        }
        mb={0}
      />
      <ExampleModal opened={isOpen} onClose={onClose} />
    </PageHeader>
  );
};

export default PriceOptimizationPageHeader;
