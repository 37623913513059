import { colors, IconArrowChevronLeft } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC, HTMLAttributes } from 'react';

interface SidebarToggleButtonProps extends HTMLAttributes<HTMLButtonElement> {
  is_fold: boolean;
}

const SidebarToggleButton: FC<SidebarToggleButtonProps> = ({ is_fold, ...props }) => {
  return (
    <StyledButton is_fold={is_fold} {...props}>
      <StyledIconArrow color={colors.white} is_fold={is_fold} />
    </StyledButton>
  );
};

export default SidebarToggleButton;

const StyledButton = styled.button<{ is_fold?: boolean }>`
  position: absolute;
  top: 50vh;
  left: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: left 0.2s;
  width: 18px;
  height: 40px;
  margin-top: -20px;
  background-color: ${colors.gray600};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0px 6px 6px 0px;
  padding: 0;
  z-index: 100;
`;

const StyledIconArrow = styled(IconArrowChevronLeft, {
  shouldForwardProp: (propName) => propName !== 'is_fold',
})<{ is_fold?: boolean }>`
  transition: transform 0.2s;
  transform: ${({ is_fold }) => (is_fold ? 'rotate(180deg)' : 'rotate(-0deg)')};
`;
