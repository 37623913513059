import { VStack, BaseText, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import type dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroImage from '@/assets/images/partner-plus/report/hero.svg';

interface Props {
  date: dayjs.Dayjs;
}

const Hero: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation('main_frame');

  return (
    <Container spacing={10}>
      <HeroTitle color={semantic_colors.accent.primary}>{t('Daily Report')}</HeroTitle>
      <BaseText>{date.format('YYYY.MM.DD(dd)')}</BaseText>
    </Container>
  );
};

export default Hero;

const Container = styled(VStack)`
  background-color: ${semantic_colors.background.surface};
  background-image: url(${HeroImage});
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 300px;
  flex-shrink: 0;
  padding: 40px 16px;
`;
const HeroTitle = styled(BaseText)`
  font-size: 36px;
  font-weight: 700;
  line-height: 30px;
`;
