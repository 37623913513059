import { PageHeader, SectionTitle } from '@croquiscom/pds';
import { t } from 'i18next';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import ResponsivePageHeader from '@/components/common/responsive/ResponsivePageHeader';
import InsightDescription from '@/components/insight/common/InsightDescription';
import InsightIntroNav from '@/components/insight/common/InsightIntroNav';
import InsightStarterDescription from '@/components/insight/common/InsightStarterDescription';
import InsightStarterPlanTab, { INSIGHT_STARTER_PLAN } from '@/components/insight/starter/InsightStarterPlanTab';
import VaildPaymentChecker from '@/components/insight/VaildPaymentChecker';

const InsightCompetitorPage = lazy(() => import('@/pages/insight/InsightCompetitorPage'));
const InsightCustomerPage = lazy(() => import('@/pages/insight/InsightCustomerPage'));
const InsightIntroPage = lazy(() => import('@/pages/insight/InsightIntroPage'));
const InsightKeywordPage = lazy(() => import('@/pages/insight/InsightKeywordPage'));
const InsightPlanPage = lazy(() => import('@/pages/insight/InsightPlanPage'));
const InsightSalesPage = lazy(() => import('@/pages/insight/InsightSalesPage'));
const InsightStarterPlanGuidePage = lazy(() => import('@/pages/insight/InsightStarterPlanGuidePage'));
const InsightItemPage = lazy(() => import('@/pages/insight/InsightItemPage'));
const PartnerPlusYearsReportPage = lazy(() => import('@/pages/insight/PartnerPlusYearsReportPage'));
const InsightStarterPlanGuideIntroPage = lazy(() => import('@/pages/insight/intro/InsightStarterPlanGuideIntroPage'));
const InsightSalesIntroPage = lazy(() => import('@/pages/insight/intro/InsightSalesIntroPage'));
const InsightKeywordIntroPage = lazy(() => import('@/pages/insight/intro/InsightKeywordIntroPage'));
const InsightCustomerIntroPage = lazy(() => import('@/pages/insight/intro/InsightCustomerIntroPage'));
const InsightCompetitorIntroPage = lazy(() => import('@/pages/insight/intro/InsightCompetitorIntroPage'));
interface InsightRoutesType extends RoutesType {
  title: string;
  hasInsightFeedback?: boolean;
}

const INSIGHT_TITLES = {
  INTRO: t('파트너플러스 인사이트 소개', { ns: 'insight' }),
  STARTER_PLAN: t('스타터 성장 가이드', { ns: 'insight' }),
  SALES_PERFORMANCE: t('판매 인사이트', { ns: 'insight' }),
  COMPETITORS: t('경쟁사 인사이트', { ns: 'insight' }),
  KEYWORD: t('키워드 인사이트', { ns: 'insight' }),
  CUSTOMER: t('고객 인사이트', { ns: 'insight' }),
  SUBSCRIPTION: t('파트너플러스 인사이트 구독 신청', { ns: 'insight' }),
  SUBSCRIPTION_MANAGEMENT: t('구독 및 결제 관리', { ns: 'insight' }),
  SUBSCRIPTION_CANCEL: t('인사이트 구독 해지 신청', { ns: 'insight' }),
  PARTNER_PLUS: t('파트너플러스 인사이트 플랜 개편 안내', { ns: 'insight' }),
  YEARS_REPORT: t('2023 연말 리포트', { ns: 'insight' }),
};

export const INSIGHT_ROUTES: InsightRoutesType[] = [
  {
    current_menu: 'partner_plus_years_report',
    path: '/insight/partner-plus/report',
    title: INSIGHT_TITLES.YEARS_REPORT,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title={INSIGHT_TITLES.YEARS_REPORT} mb={0} />
        </PageHeader>
        <PartnerPlusYearsReportPage />
      </>
    ),
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_starter_plan',
    path: '/insight/starter-plan/intro',
    title: INSIGHT_TITLES.STARTER_PLAN,
    hasInsightFeedback: true,
    is_support_mobile: true,
    params: '',
    component: <InsightStarterPlanGuideIntroPage />,
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_starter_plan',
    path: '/insight/starter-plan/checklist',
    title: INSIGHT_TITLES.STARTER_PLAN,
    hasInsightFeedback: true,
    is_support_mobile: true,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title={INSIGHT_TITLES.STARTER_PLAN}
            description={<InsightStarterDescription menu_type='starter' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightStarterPlanTab current_tab={INSIGHT_STARTER_PLAN.CHECKLIST} />
          <InsightStarterPlanGuidePage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.STARTER_INSIGHT_READ],
  },
  {
    current_menu: 'insight_starter_plan',
    path: '/insight/starter-plan/item',
    title: INSIGHT_TITLES.STARTER_PLAN,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title={INSIGHT_TITLES.STARTER_PLAN}
            description={<InsightStarterDescription menu_type='starter' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightStarterPlanTab current_tab={INSIGHT_STARTER_PLAN.ITEM_INSIGHT} />
          <InsightItemPage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.STARTER_INSIGHT_READ],
  },
  {
    current_menu: 'insight_sales_performance',
    path: '/insight/sales-performance/intro',
    title: INSIGHT_TITLES.SALES_PERFORMANCE,
    hasInsightFeedback: true,
    is_support_mobile: true,
    params: '',
    component: <InsightSalesIntroPage />,
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_sales_performance',
    path: '/insight/sales-performance',
    title: INSIGHT_TITLES.SALES_PERFORMANCE,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={INSIGHT_TITLES.SALES_PERFORMANCE}
            description={<InsightDescription menu_type='sales' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightSalesPage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.SALES_INSIGHT_READ],
  },
  {
    current_menu: 'insight_competitors',
    path: '/insight/competitors/intro',
    title: INSIGHT_TITLES.COMPETITORS,
    hasInsightFeedback: true,
    is_support_mobile: true,
    params: '',
    component: <InsightCompetitorIntroPage />,
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_competitors',
    path: '/insight/competitors',
    inactive_content_scroll: true,
    title: INSIGHT_TITLES.COMPETITORS,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={INSIGHT_TITLES.COMPETITORS}
            description={<InsightDescription menu_type='competitor' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightCompetitorPage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.COMPETITOR_INSIGHT_READ],
  },
  {
    current_menu: 'insight_keyword',
    path: '/insight/keyword/intro',
    title: INSIGHT_TITLES.KEYWORD,
    hasInsightFeedback: true,
    is_support_mobile: true,
    params: '',
    component: <InsightKeywordIntroPage />,
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_keyword',
    path: '/insight/keyword',
    title: INSIGHT_TITLES.KEYWORD,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={INSIGHT_TITLES.KEYWORD}
            description={<InsightDescription menu_type='keyword' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightKeywordPage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.KEYWORD_INSIGHT_READ],
  },
  {
    current_menu: 'insight_customer',
    path: '/insight/customer/intro',
    title: INSIGHT_TITLES.CUSTOMER,
    is_support_mobile: true,
    hasInsightFeedback: true,
    params: '',
    component: <InsightCustomerIntroPage />,
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_customer',
    path: '/insight/customer',
    title: INSIGHT_TITLES.CUSTOMER,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title={INSIGHT_TITLES.CUSTOMER}
            description={<InsightDescription menu_type='customer' />}
            mb={0}
          />
        </PageHeader>
        <VaildPaymentChecker>
          <InsightCustomerPage />
        </VaildPaymentChecker>
      </>
    ),
    res: [SellerResource.CUSTOMER_INSIGHT_READ],
  },
  {
    current_menu: 'insight_intro',
    path: '/insight/intro',
    is_support_mobile: true,
    title: INSIGHT_TITLES.INTRO,
    params: '',
    component: (
      <>
        <ResponsivePageHeader border={false} title={INSIGHT_TITLES.INTRO} />
        <InsightIntroNav current_tab='intro' />
        <InsightIntroPage />
      </>
    ),
    is_brand_allowed: false,
  },
  {
    current_menu: 'insight_intro',
    path: '/insight/plan',
    title: INSIGHT_TITLES.INTRO,
    params: '',
    component: (
      <>
        <ResponsivePageHeader border={false} title={INSIGHT_TITLES.INTRO} />
        <InsightIntroNav current_tab='plan' />
        <InsightPlanPage />
      </>
    ),
    is_brand_allowed: false,
  },
];
