import { colors, semantic_colors, text_styles } from '@croquiscom/pds';
import { css } from '@emotion/react';
import { mix, rgba } from 'polished';
import { ButtonSize, ButtonKind, IconButtonKind } from './types';

export const button_base_css = css`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  border: 1px solid transparent;
  transition:
    background-color 0.1s linear,
    border-color 0.1s linear,
    color 0.1s linear;
  &:focus {
    outline: 3px solid ${semantic_colors.tab_focus};
    box-shadow: none;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

function buildCSSWithMixedColor(
  color_property_key: 'color' | 'background-color' | 'border-color',
  color_property_value: string,
  mix_weight?: number,
  color_to_mix = 'black',
  reversed = false,
) {
  const color_value = !mix_weight
    ? color_property_value
    : reversed
      ? mix(mix_weight, color_property_value, color_to_mix)
      : mix(mix_weight, color_to_mix, color_property_value);
  return css`
    ${color_property_key}: ${color_value};
  `;
}

function getButtonStyle(
  { color, background_color, border_color }: { color?: string; background_color?: string; border_color?: string },
  mix_weight?: number,
  color_to_mix?: string,
  reversed?: boolean,
) {
  return css`
    ${color && buildCSSWithMixedColor('color', color, mix_weight, color_to_mix, reversed)}
    ${background_color &&
    buildCSSWithMixedColor('background-color', background_color, mix_weight, color_to_mix, reversed)}
    ${border_color && buildCSSWithMixedColor('border-color', border_color, mix_weight, color_to_mix, reversed)}
  `;
}

function getFilledButtonStyle(
  color: string,
  background_color: string,
  disabled_background_color: string,
  border_color?: string,
  disabled_border_color?: string,
) {
  return css`
    ${getButtonStyle({ color, background_color, border_color })}
    &:hover {
      ${getButtonStyle({ color, background_color, border_color }, 0.1)}
    }
    &:active {
      ${getButtonStyle({ color, background_color, border_color }, 0.2)}
    }
    &:disabled {
      ${getButtonStyle({ color, background_color: disabled_background_color, border_color: disabled_border_color })}
    }
  `;
}

function getOutlinedButtonStyle(color: string, disabled_color: string, disabled_background_color?: string) {
  return css`
    ${getButtonStyle({ color, border_color: color, background_color: semantic_colors.background.surface })}
    &:hover {
      ${getButtonStyle({ background_color: color }, 0.05, 'white', true)}
    }
    &:active {
      ${getButtonStyle({ background_color: color }, 0.1, 'white', true)}
    }
    &:disabled {
      ${getButtonStyle({
        color: disabled_color,
        border_color: disabled_color,
        background_color: disabled_background_color ?? semantic_colors.background.surface,
      })}
    }
  `;
}

function getDefaultButtonStyle(color: string) {
  const border_color = semantic_colors.border.default;
  return css`
    ${getButtonStyle({
      color,
      background_color: semantic_colors.background.surface,
      border_color,
    })}
    &:hover {
      ${buildCSSWithMixedColor('background-color', border_color, 0.2, 'white', true)}
      ${buildCSSWithMixedColor('color', border_color, 0.2, color, true)}
    }
    &:active {
      ${buildCSSWithMixedColor('background-color', border_color, 0.4, 'white', true)}
      ${buildCSSWithMixedColor('color', border_color, 0.4, color, true)}
    }
    &:disabled {
      ${getButtonStyle({
        color: semantic_colors.content.disabled,
        background_color: semantic_colors.background.disabled,
        border_color,
      })}
    }
  `;
}

export const getButtonKind = (kind?: ButtonKind | IconButtonKind) => {
  switch (kind) {
    case 'brand':
      return getFilledButtonStyle(
        semantic_colors.content.on_color,
        semantic_colors.brand.primary,
        semantic_colors.brand.disabled,
      );
    case 'primary':
      return getFilledButtonStyle(
        semantic_colors.content.on_color,
        semantic_colors.accent.primary,
        semantic_colors.accent.disabled,
      );
    case 'black':
      return getFilledButtonStyle(
        semantic_colors.content.on_color,
        colors.gray700,
        colors.gray400,
        colors.gray800,
        colors.gray500,
      );
    case 'negative':
      return getFilledButtonStyle(
        semantic_colors.content.on_color,
        semantic_colors.state.negative,
        semantic_colors.state.negative_disabled,
      );
    case 'outlined_primary':
      return getOutlinedButtonStyle(semantic_colors.accent.primary, semantic_colors.accent.disabled);
    case 'outlined_negative':
      return getOutlinedButtonStyle(semantic_colors.state.negative, semantic_colors.state.negative_disabled);
    case 'outlined_black':
      return getOutlinedButtonStyle(
        semantic_colors.content.primary,
        semantic_colors.content.disabled,
        semantic_colors.background.disabled,
      );
    case 'outlined_white':
      return css`
        ${getButtonStyle({
          color: semantic_colors.content.on_color,
          border_color: semantic_colors.border.default_inverse,
          background_color: 'transparent',
        })}
        &:hover {
          background-color: ${rgba(semantic_colors.border.default_inverse, 0.05)};
        }
        &:active {
          background-color: ${rgba(semantic_colors.border.default_inverse, 0.1)};
        }
        &:disabled {
          ${getButtonStyle({
            color: semantic_colors.content.on_color,
            border_color: semantic_colors.border.default_inverse,
            background_color: 'transparent',
          })}
          opacity: 0.4;
        }
      `;
    case 'dotted':
      return css`
        ${getDefaultButtonStyle(semantic_colors.content.tertiary)}
        border-style: dashed;
      `;
    case 'outlined_secondary':
    default:
      return getDefaultButtonStyle(semantic_colors.content.primary);
  }
};

export const button_size_css: Record<ButtonSize, string> = {
  xsmall: `
    height: 20px;
    padding: 0 8px;
    column-gap: 2px;
    ${text_styles.Body_12_Regular};
  `,
  small: `
    height: 28px;
    padding: 0 10px;
    column-gap: 4px;
    ${text_styles.Body_13_Regular};
  `,
  medium: `
    height: 34px;
    padding: 0 14px;
    column-gap: 4px;
    ${text_styles.Body_13_Regular};
  `,
  large: `
    height: 40px;
    padding: 0 16px;
    column-gap: 8px;
    ${text_styles.Body_14_Medium};
  `,
  xlarge: `
    height: 50px;
    padding: 0 20px;
    column-gap: 8px;
    ${text_styles.Heading_17_Medium};
  `,
};

export const button_icon_size_css: Record<ButtonSize, number> = {
  xsmall: 12,
  small: 16,
  medium: 16,
  large: 20,
  xlarge: 20,
};
