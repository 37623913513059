import { colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';

export const TableContainer = styled.div<{ noHeight?: boolean }>`
  overflow: auto;
  height: ${({ noHeight }) => !noHeight && '400px'};
  border: 1px solid ${colors.gray300};

  table {
    thead th {
      border-top: none;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      border-left: none;
    }
    th:nth-last-of-type(1),
    td:nth-last-of-type(1) {
      border-right: none;
    }
    ${({ noHeight }) =>
      noHeight &&
      css`
        tr:last-child td {
          border-bottom: none;
        }
      `}
  }
`;

export const TableRow = styled.tr`
  td {
    vertical-align: middle !important;
    height: 40px !important;
    padding: 8px 12px !important;
    white-space: normal;

    &.align-right {
      text-align: right;
    }
  }
`;

export const tableStyle: Record<string, CSSProperties> = {
  th: {
    textAlign: 'left',
  },
};
