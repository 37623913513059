import { Badge, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { useRef } from 'react';
import { main_domain_atom } from '@/atoms/main_domain';
import { ShopLink } from '@/components/route/ShopLink';
import { SiteId } from '@/constants/site';
import { useInfo } from '@/hooks/useInfo';

export interface ShopInfoProfileProps {}

export const ShopInfoProfile: React.FC<ShopInfoProfileProps> = () => {
  const main_domain = useAtomValue(main_domain_atom);
  const { info } = useInfo();
  const divRef = useRef<HTMLDivElement | null>(null);
  return (
    <StyledProfile to='/home'>
      <StyledImage src={`/api/provider/${main_domain}/typical_image?size=132`} alt='' />
      <StyledContent ref={divRef}>
        <StyledName>{info.name}</StyledName>
        {info.site?.id !== SiteId.지그재그 && (
          <StyledTag>
            <Badge kind='outline' color='blue' size='small'>
              {info.site?.name}
            </Badge>
          </StyledTag>
        )}
      </StyledContent>
    </StyledProfile>
  );
};

const StyledProfile = styled(ShopLink)`
  display: block;
  margin: 0 0 16px;
  text-decoration: none;
`;

const StyledImage = styled.img`
  display: block;
  margin: 0 auto 12px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  margin: 0 auto;
`;

const StyledName = styled.div`
  ${text_styles.Heading_17_Bold};
  color: ${semantic_colors.content.primary};
  word-break: keep-all;
  overflow-wrap: anywhere;
  text-align: center;
`;

const StyledTag = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 0 0;
`;
