import {
  semantic_colors,
  HStack,
  VStack,
  BaseText,
  Button,
  BottomSheet,
  IconColoredThumbsUp,
  IconColoredWrite,
  IconColoredWarning,
  text_styles,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

interface ChatbotGuideBottomSheetProps {
  opened: boolean;
  onClose: () => void;
}

export const ChatbotGuideBottomSheet = ({ opened, onClose }: ChatbotGuideBottomSheetProps) => {
  return (
    <BottomSheet noUsePortal opened={opened} onClose={onClose}>
      <VStack spacing={24}>
        <VStack spacing={8}>
          <BaseText kind='Heading_20_Bold'>파트너센터 AI 챗봇 이용 안내</BaseText>
          <BaseText color={semantic_colors.content.secondary}>
            셀러분들의 파트너센터 이용을 도와드리는 AI 챗봇입니다. 간혹 정확하지 않은 답변을 제공할 수 있으므로
            유의해주세요.
          </BaseText>
        </VStack>
        <VStack spacing={24}>
          <VStack spacing={4}>
            <HStack spacing={8} alignment='center'>
              <IconColoredThumbsUp size={20} />
              <BaseText kind='Heading_15_SemiBold'>이런 상황에서 유용해요</BaseText>
            </HStack>
            <ul>
              <SC.ListItem>혼자 해결하기 어려운 문제가 발생했을 때</SC.ListItem>
              <SC.ListItem>고객센터 답변을 기다릴 여유가 없을 때</SC.ListItem>
            </ul>
          </VStack>
          <VStack spacing={4}>
            <HStack spacing={8} alignment='center'>
              <IconColoredWrite size={20} />
              <BaseText kind='Heading_15_SemiBold'>이렇게 질문해보세요</BaseText>
            </HStack>
            <ul>
              <SC.ListItem>파트너센터의 궁금한 기능 또는 이용 방법</SC.ListItem>
              <SC.ListItem>구체적인 메뉴명 또는 상황 설명</SC.ListItem>
            </ul>
          </VStack>
          <VStack spacing={4}>
            <HStack spacing={8} alignment='center'>
              <IconColoredWarning size={20} />
              <BaseText kind='Heading_15_SemiBold'>이런 답변은 어려워요</BaseText>
            </HStack>
            <ul>
              <SC.ListItem>특정 상품/주문 상태에 대한 해석 또는 처리 요청</SC.ListItem>
              <SC.ListItem>특정 개인 정보가 포함된 질문</SC.ListItem>
            </ul>
          </VStack>
        </VStack>
        <VStack mt={24}>
          <Button kind='primary' size='medium' fill onClick={onClose}>
            확인
          </Button>
        </VStack>
      </VStack>
    </BottomSheet>
  );
};

const SC = {
  ListItem: styled.li`
    display: flex;
    padding-left: 8px;
    gap: 8px;

    &::before {
      content: '';
      display: inline-block;
      margin-top: 11px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${semantic_colors.content.secondary};
    }
    ${text_styles.Body_14_Regular};
    color: ${semantic_colors.content.secondary};
  `,
};
