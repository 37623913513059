import { SetStateAction, atom } from 'jotai';
import { atomFamily, atomWithReset } from 'jotai/utils';
import { EntryRequestStepsType, EntryRequestForm, EntryRequestSteps } from '@/components/entry_requests/types';

export const entry_request_form_atom = atomWithReset<EntryRequestForm>({ ADDITIONAL_SELLER_ENTRY_REQUEST: false });

export const entry_request_family = atomFamily((key: EntryRequestStepsType) =>
  atom(
    (get) => get(entry_request_form_atom)[key],
    (get, set, form: SetStateAction<EntryRequestForm[keyof EntryRequestForm]>) => {
      set(entry_request_form_atom, { ...get(entry_request_form_atom), [key]: form });
    },
  ),
);

const target_step_atom = atom<EntryRequestStepsType | undefined>(undefined);

export const has_continue_with_step_atom = atom<boolean, EntryRequestStepsType | undefined>(
  (get) => {
    const keys = Object.keys(EntryRequestSteps);
    const target_step = get(target_step_atom);

    if (!target_step) {
      return true;
    }

    const index = keys.findIndex((k) => k === target_step);
    const prev_steps = (Object.keys(EntryRequestSteps) as Array<EntryRequestStepsType>)
      .slice(undefined, index)
      .filter((step) => !(['GUIDE', 'STORE_CATEGORY_STYLE'] as EntryRequestStepsType[]).includes(step));

    return prev_steps.every((step) => {
      if (step === 'ACCOUNT' && get(entry_request_family('ADDITIONAL_SELLER_ENTRY_REQUEST'))) {
        return true;
      }

      return !!get(entry_request_family(step));
    });
  },
  (get, set, target_step) => {
    set(target_step_atom, target_step);
  },
);
