import { isApp, isDevelopment } from './conditions';

export async function initVConsole() {
  if (isApp() && isDevelopment()) {
    try {
      const vConsole = (await import('vconsole')).default;
      new vConsole();
    } catch (error) {
      console.error(error);
    }
  }
}
