import { useQuery } from 'react-query';
import { PartnerOutlandingPosition, api_getPartnerOutlandingList } from '@/api';

export const useGetPartnerPlusGnbTooltipText = (enabled?: boolean) => {
  return useQuery(
    ['useGetPartnerPlusGnbTooltipText'],
    async () => {
      const { data } = await api_getPartnerOutlandingList({
        position: PartnerOutlandingPosition.GNB_SPEECH_BALLOON,
        limit_count: 1,
      });
      return data.partner_outlanding_list.item_list[0];
    },
    { staleTime: 30000, cacheTime: 30000, enabled },
  );
};
