import { useScrollDebounce } from '../../hooks/useScrollDebounce';
import { useGetCustomerExtensionTooltip } from './useGetCustomerExtensionTooltip';
import { useGetPartnerPlusTooltip } from './useGetPartnerPlusTooltip';

export enum LNB_TOOLTIP_TYPE {
  PARTNER_PLUS,
  CUSTOMER_EXTENSION,
}

export function useGetLnbTooltip(type: LNB_TOOLTIP_TYPE) {
  const { text, is_show: is_show_customer_tooltip, hideTootip: hideTooltipCustomer } = useGetCustomerExtensionTooltip();
  const {
    text: customer_extension_text,
    is_show: is_show_partner_plus_tooltip,
    hideTootip: hideTooltipPartnerPlus,
  } = useGetPartnerPlusTooltip(!is_show_customer_tooltip && type === LNB_TOOLTIP_TYPE.PARTNER_PLUS);
  const is_show = useScrollDebounce(
    type === LNB_TOOLTIP_TYPE.PARTNER_PLUS
      ? !is_show_customer_tooltip && is_show_partner_plus_tooltip
      : is_show_customer_tooltip,
  );

  return {
    text: type === LNB_TOOLTIP_TYPE.PARTNER_PLUS ? customer_extension_text : text,
    is_show,
    hideTooltip: type === LNB_TOOLTIP_TYPE.PARTNER_PLUS ? hideTooltipPartnerPlus : hideTooltipCustomer,
  };
}
