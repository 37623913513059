import { BaseText, colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';

export interface CardHeaderProps {
  title: ReactNode;
  description?: ReactNode;
  hideLine?: boolean;
  trailing?: ReactNode;
  className?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ title, description, trailing, hideLine, className }) => {
  return (
    <CardHeaderWrapper hideLine={hideLine} className={className}>
      <div>
        <BaseText kind='Heading_17_Bold' color={colors.gray900} as='div'>
          {title}
        </BaseText>
        {description && (
          <BaseText kind='Body_13_Regular' color={colors.gray700}>
            {description}
          </BaseText>
        )}
      </div>
      {trailing && <div>{trailing}</div>}
    </CardHeaderWrapper>
  );
};

export default CardHeader;

const CardHeaderWrapper = styled.div<{ hideLine?: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px 28px;
  box-sizing: border-box;
  ${(props) => (props.hideLine ? '' : `border-bottom:1px solid ${colors.gray300}`)}
`;
