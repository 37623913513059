import React, { lazy } from 'react';
import { RoutesType } from '../types';
const EntryApplicationPage = lazy(() => import('@/pages/entry_requests/EntryApplicationPage'));
const EntryRequestsPage = lazy(() => import('@/pages/entry_requests'));

export const ENTRY_REQUEST_ROUTES: RoutesType[] = [
  {
    current_menu: '',
    path: '/entry_requests/signup',
    params: '',
    has_mobile_back_button: true,
    title: '입점 신청',
    component: <EntryApplicationPage />,
  },
  {
    current_menu: '',
    path: '/entry_requests',
    params: '',
    exact: false,
    is_support_mobile: true,
    is_support_app: true,
    is_fullscreen: true,
    has_mobile_back_button: true,
    title: '입점 신청',
    component: <EntryRequestsPage />,
  },
];
