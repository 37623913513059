import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { VIDEO_DETAIL_URL, VIDEO_LIST_URL } from 'rui/components/URL';

const VideoListPage = lazy(() => import('@/pages/video/VideoListPage'));
const VideoDetailPage = lazy(() => import('@/pages/video/VideoDetailPage'));

export const VIDEO_ROUTES: RoutesType[] = [
  {
    current_menu: 'video_list',
    path: VIDEO_LIST_URL,
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <VideoListPage />,
    res: SellerResource.VIDEO_MANAGEMENT,
  },
  {
    current_menu: 'video_list',
    path: VIDEO_DETAIL_URL,
    params: '/:id',
    is_support_mobile: true,
    is_support_app: true,
    component: <VideoDetailPage />,
    res: SellerResource.VIDEO_MANAGEMENT,
  },
];
