import { IconVvip, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemRow } from './ItemRow';
import { PredictedAdGrade } from './PredictedAdGrade';
import { useGetAdGradeInfo } from '@/components/advertisement/hooks/shop';

export interface ShopInfoAdGradeProps {}

export const ShopInfoAdGrade: React.FC<ShopInfoAdGradeProps> = () => {
  const { t } = useTranslation('main_frame');
  const { current_shop_grade, predicted_grade } = useGetAdGradeInfo();
  const isEmpty = !current_shop_grade && !predicted_grade;
  if (isEmpty) {
    return null;
  }

  return (
    <>
      <ItemRow icon={<IconVvip size={16} color={semantic_colors.brand.primary} />} title={t('광고 등급')}>
        {current_shop_grade}
      </ItemRow>
      {predicted_grade != null && <PredictedAdGrade />}
    </>
  );
};
