import * as Sentry from '@sentry/react';
import { callPartnersFunction } from './callPartnersFunction';
import { isApp } from './conditions';
import { devLogger } from './devLogger';
import { PartnersHandler, partnersHandler } from './handlers';
import { Config } from '@/config';

interface PartnersBridgeAvailableFunctions {
  setUserAgent?(params: string): void;
  setVisibleBottomNavi?(params: boolean): void;
  startAppNotificationSettings?(): void;
  getInterfaceList?(): void;
  getFcmTokenWithDeviceInfo?(): void;
  getPushNotificationEnabled?(): void;
}

declare global {
  interface Window {
    partnersHandler: PartnersHandler;

    /** Android 브릿지 */
    partners?: PartnersBridgeAvailableFunctions;

    /** iOS 브릿지 */
    webkit?: {
      messageHandlers?: {
        partners?: {
          postMessage?: (message: Record<string, any>) => void;
        };
      };
    };
  }
}

function getInterfaceList() {
  return new Promise<string[]>((resolve) => {
    callPartnersFunction('getInterfaceList')();
    partnersHandler('onInterfaceList', (interface_list: string[]) => {
      devLogger('onInterfaceList', interface_list);
      resolve(interface_list);
    });
  });
}

function createPartnersBridge() {
  let interface_list: string[] = [];

  const init = () =>
    getInterfaceList()
      .then((list) => {
        interface_list = list;
      })
      .catch((err) => {
        const error = new Error(`앱 인터페이스 목록을 불러오는데 실패했습니다. ${err.message} `);
        console.error(error);
        Sentry.captureException(error);
      });

  const callAvailableFunction = (function_name: keyof PartnersBridgeAvailableFunctions, params?: any) => {
    if (interface_list.includes(function_name)) {
      callPartnersFunction(function_name)(params);
    } else {
      const error = new Error(`앱 인터페이스가 존재하지 않습니다: ${function_name} `);
      console.error(error);
      Sentry.captureException(error);
    }
  };

  function getFcmTokenWithDeviceInfo() {
    return new Promise<Parameters<PartnersHandler['onFcmTokenWithDeviceInfo']>[0]>((resolve) => {
      callAvailableFunction('getFcmTokenWithDeviceInfo');
      partnersHandler('onFcmTokenWithDeviceInfo', (info) => {
        devLogger('onFcmTokenWithDeviceInfo', info);
        resolve(info);
      });
    });
  }

  function getPushNotificationEnabled() {
    return new Promise<Parameters<PartnersHandler['onPushNotificationEnabled']>[0]>((resolve) => {
      callAvailableFunction('getPushNotificationEnabled');
      partnersHandler('onPushNotificationEnabled', (enabled) => {
        devLogger('onPushNotificationEnabled', enabled);
        resolve(enabled);
      });
    });
  }

  function callDeepLink(browser_path: 'open/browser' | 'open/inapp_browser' | 'external') {
    const scheme = Config.alpha ? 'partners-alpha' : Config.beta ? 'partners-beta' : 'partners';

    return (url: string) => {
      if (isApp()) {
        window.location.assign(`${scheme}://${browser_path}?url=${encodeURIComponent(url)}`);
      } else {
        window.open(url, '_blank', 'noopener noreferrer');
      }
    };
  }

  return {
    init,
    setUserAgent: (user_agent: string) => callAvailableFunction('setUserAgent', { userAgent: user_agent }),
    setVisibleBottomNavi: (is_visible: boolean) =>
      callAvailableFunction('setVisibleBottomNavi', { isVisible: is_visible }),
    startAppNotificationSettings: () => callAvailableFunction('startAppNotificationSettings'),
    getFcmTokenWithDeviceInfo,
    getPushNotificationEnabled,
    callBrowserDeepLink: callDeepLink('open/browser'),
    callInAppBrowserDeepLink: callDeepLink('open/inapp_browser'),
    callSystemBrowserDeepLink: callDeepLink('external'),
  };
}

export const PartnersBridge = createPartnersBridge();
