import { Alert, BaseText, HStack } from '@croquiscom/pds';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RoundCard from './RoundCard';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

interface PartnerPlusCheckerProps {
  children: React.ReactNode;
}

const PartnerPlusChecker: React.FC<PartnerPlusCheckerProps> = ({ children }) => {
  const { t } = useTranslation('partner_plus');
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { is_partner_plus } = useGetPlan();

  useEffect(() => {
    if (!is_partner_plus) {
      Alert({
        title: t('파트너플러스 회원만 이용 가능합니다.'),
        text: <a href={`${shop_prefix}${PartnerPlusNavUrl.INTRO}`}>{t('파트너플러스 자세히 보러가기')}</a>,
        kind: 'error',
        onClose() {
          history.push(`${shop_prefix}/stats`);
        },
      });
    }
  }, []);

  return (
    <>
      {is_partner_plus ? (
        <>{children}</>
      ) : (
        <HStack width='100%' alignment='center' spacing={8} p={24}>
          <RoundCard max_width='100%'>
            <BaseText kind='Heading_24_Bold' alignment='center'>
              {t('파트너플러스 회원만 이용 가능합니다.')}
            </BaseText>
          </RoundCard>
        </HStack>
      )}
    </>
  );
};

export default PartnerPlusChecker;
