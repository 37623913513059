import { useQuery } from 'react-query';
import { Config } from '@/config';
import { AdDisplayInfo } from '@/types/info';
import axios from '@/utils/axios';

export function useGetAdDisplayInfoQuery() {
  return useQuery(
    ['getAdDisplayInfoQuery'],
    async (): Promise<AdDisplayInfo> => {
      const res = await axios.request({
        method: 'GET',
        url: 'info/ad_display',
        baseURL: `${Config.api_base_url}/${Config.shop_main_domain}`,
        params: {
          time: Date.now(),
        },
      });
      return res.data;
    },
    {
      cacheTime: 0,
    },
  );
}
