import { VStack, semantic_colors, shapes } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

interface RoundCardProps {
  children: React.ReactNode;
  id?: string;
  max_width?: string;
}

const RoundCard: React.FC<RoundCardProps> = ({ children, id, max_width }) => {
  return (
    <StyledCard id={id} max_width={max_width}>
      {children}
    </StyledCard>
  );
};

const StyledCard = styled(VStack)<{ max_width?: string }>`
  max-width: ${(props) => props.max_width || '700px'};
  width: 100%;
  padding: 32px;
  gap: 24px;
  background: ${semantic_colors.background.surface};
  ${shapes.border_radius.large};
`;

export default RoundCard;
