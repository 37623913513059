import { Location, LocationDescriptor } from 'history';
import React, { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom'; // LegacyCompat 변환 X
import { useShopPrefix } from '@/hooks/useShopPrefix';

/**
 * react-router의 Link와 동일하지만, /shop/attrangs와 같은 주소를 알아서 맨 앞에 붙여주어서
 * 샵 경로를 무시하고 /stats와 같은 내부 경로만 입력할 수 있게 해주는 컴포넌트입니다.
 */
export const ShopLink = forwardRef((props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
  const prefix = useShopPrefix();
  const old_to = props.to;
  let to: LocationDescriptor | ((location: Location) => LocationDescriptor) = '';
  if (typeof old_to === 'string') {
    if (old_to.startsWith('/')) {
      to = prefix + old_to;
    } else {
      to = old_to;
    }
  } else if (typeof old_to === 'function') {
    to = (location: Location) => {
      const result = old_to(location);
      if (typeof result === 'string' && result.startsWith('/')) {
        return prefix + result;
      }
      return result;
    };
  }
  return <Link {...props} to={to} ref={ref} />;
});
