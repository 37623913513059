import React, { Suspense, useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router'; // LegacyCompat 변환 X
import LoadingWithBox from '../common/LoadingWithBox';
import { LoginChecker } from '../LoginChecker';
import { MetaMainFrame } from '../main_frame/MetaMainFrame';
import { ResourceChecker } from '../ResourceChecker';
import { AdminV2Route } from './AdminV2Route';
import { ManagementRoute } from './ManagementRoute';
import { MetaRoute } from './MetaRoute';
import NotFoundPage from '@/pages/error/NotFound';
import { META_ROUTES, EACH_ROUTES } from '@/routes';
import { getExternalMenuRoutes, useAdminV2 } from 'rui/hooks/useAdminV2';

function isLoginRequiredRoute(requestedPath: string): boolean {
  const routeKeywordsToSkipLogin = [
    'privacy_policy',
    'service_policy',
    'terms',
    'signup',
    'change_password',
    'ext/cafe24',
    'entry_requests',
    'entry_policy',
    'cognito_callback',
    'invite',
    'login',
    'redirect_shop',
    '/insight/intro',
    '/partner-plus/intro',
    '/daily-report',
  ];
  return routeKeywordsToSkipLogin.every((keyword) => !requestedPath.includes(keyword));
}

export const Routes: React.FC = () => {
  const admin_v2 = useAdminV2();
  const admin_v2_each_routes = useMemo(
    () => getExternalMenuRoutes(admin_v2?.getMenus({ type: 'each' }) ?? []),
    [admin_v2],
  );
  const admin_v2_meta_routes = useMemo(
    () => getExternalMenuRoutes(admin_v2?.getMenus({ type: 'meta' }) ?? []),
    [admin_v2],
  );
  return (
    <Switch>
      {/* 아래와 같은 내용을 반복해서 적는 것을 기대하고 있습니다.
    <ManagementRoute path='/order_inquiry'>
      <div>...</div>
    </ManagementRoute>
    */}
      {admin_v2_each_routes.map((route) => {
        return (
          <ManagementRoute
            path={'/shop/:shop_id' + route.path}
            exact
            current_menu={route.current_menu}
            is_fullscreen={false}
            is_support_mobile
            inactive_content_scroll={false}
            hide_accountbar={false}
          >
            <Suspense fallback={<LoadingWithBox height='500px' />}>
              <AdminV2Route />
            </Suspense>
          </ManagementRoute>
        );
      })}
      {/* 일단은 기존 rui에서 내용을 가져오도록 처리합니다. */}
      {EACH_ROUTES.map((route) => {
        const children = (
          <LoginChecker isRequired={isLoginRequiredRoute(route.path)}>
            <ResourceChecker resources={route.res} is_brand_allowed={route.is_brand_allowed}>
              <Suspense fallback={<LoadingWithBox height='500px' />}>{route.component}</Suspense>
            </ResourceChecker>
          </LoginChecker>
        );

        if (route.not_used_main_frame) {
          return (
            <Route
              // NOTE: 가능하면 마운트된 Route를 재사용할 수 있도록 key를 넣지 않았습니다.
              path={'/shop/:shop_id' + route.path + route.params}
              exact
            >
              {children}
            </Route>
          );
        }

        return (
          <ManagementRoute
            // NOTE: 가능하면 마운트된 Route를 재사용할 수 있도록 key를 넣지 않았습니다.
            path={'/shop/:shop_id' + route.path + route.params}
            exact
            current_menu={route.current_menu}
            is_fullscreen={route.is_fullscreen}
            is_support_mobile={route.is_support_mobile}
            is_support_app={route.is_support_app}
            inactive_content_scroll={route.inactive_content_scroll}
            hide_accountbar={route.hide_accountbar}
          >
            {children}
          </ManagementRoute>
        );
      })}
      <Redirect from='/shop/:shop_id' to='/shop/:shop_id/home' exact />
      <Redirect from='/shop/:shop_id/login' to='/shop/:shop_id/home' exact />
      {admin_v2_meta_routes.map((route) => {
        return (
          <MetaRoute path={route.path} exact>
            <Suspense fallback={<LoadingWithBox height='500px' />}>
              <MetaMainFrame
                is_support_mobile
                is_support_app
                is_fullscreen={false}
                hide_accountbar={false}
                hide_footer={false}
              >
                <AdminV2Route />
              </MetaMainFrame>
            </Suspense>
          </MetaRoute>
        );
      })}
      {/* 일단은 기존 rui에서 내용을 가져오도록 처리합니다. */}
      {META_ROUTES.map((route) => {
        return (
          <MetaRoute
            // NOTE: 가능하면 마운트된 Route를 재사용할 수 있도록 key를 넣지 않았습니다.
            path={route.path + route.params}
            exact={route.exact ?? true}
          >
            <LoginChecker isRequired={isLoginRequiredRoute(route.path)}>
              <Suspense fallback={<LoadingWithBox height='500px' />}>
                <MetaMainFrame
                  title={route.title}
                  is_support_mobile={route.is_support_mobile}
                  is_support_app={route.is_support_app}
                  is_fullscreen={route.is_fullscreen}
                  hide_accountbar={route.hide_accountbar}
                  hide_footer={route.hide_footer}
                  has_mobile_back_button={route.has_mobile_back_button}
                >
                  {route.component}
                </MetaMainFrame>
              </Suspense>
            </LoginChecker>
          </MetaRoute>
        );
      })}
      {/* <Redirect from='/' to='/home' exact /> */}
      <Route path='*' component={NotFoundPage}></Route>
    </Switch>
  );
};
