import { colors, IconCircleWarning } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingWithBox from 'rui/components/common/LoadingWithBox';

interface TableNoDataProps {
  loading?: boolean;
  height?: number;
  icon?: ReactNode;
  text?: ReactNode;
}

export const TableNoData: React.FC<TableNoDataProps> = ({ loading, height, icon, text }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <StyledNoData height={height}>
        <LoadingWithBox />
      </StyledNoData>
    );
  }

  return (
    <StyledNoData height={height}>
      {icon || <IconCircleWarning color={colors.gray500} size={50} />}
      <div className='no-result__text'>{text || t('조회 내역이 없습니다.')}</div>
    </StyledNoData>
  );
};

const StyledNoData = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ height }) => (height !== undefined ? `height: ${height}px;` : `margin: 60px 0;`)}

  .no-result__text {
    margin: 18px 0 0;
    color: ${colors.gray500};
    text-align: center;
    font-size: 13px;
    letter-spacing: -0.3px;
  }
`;
