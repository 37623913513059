import { BaseText, HStack, semantic_colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import ResponsiveFlex from '@/components/common/responsive/ResponsiveFlex';

export const SummaryCard = styled(HStack)<{ bg?: string; border_color?: string }>`
  width: 100%;
  padding: 16px;
  gap: 20px;
  background-color: ${({ bg }) => (bg ? bg : semantic_colors.background.disabled)};
  border: 1px solid ${({ border_color }) => (border_color ? border_color : semantic_colors.border.disabled)};
  border-radius: 10px;
`;

export const BoostingDataTableContainer = styled(VStack)`
  width: 100%;

  & table th {
    word-break: keep-all;
  }
`;
export const BoostingCardContainer = styled(ResponsiveFlex)<{ is_desktop?: boolean }>`
  flex-wrap: wrap;
  gap: ${({ is_desktop }) => (is_desktop ? '16px' : '12px')};
  width: 100%;
`;

export const EmptyCard = styled.div`
  width: 580px;
  flex-grow: 1;
`;

export const BoostingBanner = styled(HStack)<{ bg: string; p?: string; m?: string }>`
  position: relative;
  justify-content: center;
  padding: ${({ p }) => p || '12px 24px'};
  margin: ${({ m }) => m || '20px'};
  overflow: hidden;
  background: ${({ bg }) => bg};
  border-radius: 10px;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
export const BannerBgImage = styled.img<{ top?: number; left?: number; right?: number; bottom?: number }>`
  position: absolute;
  ${({ top, left, right, bottom }) => `
    ${top != null ? `top: ${top}px;` : ''}
    ${left != null ? `left: ${left}px;` : ''}
    ${right != null ? `right: ${right}px;` : ''}
    ${bottom != null ? `bottom: ${bottom}px;` : ''}
  `}
`;

export const BoxContainer = styled.div<{ width?: number }>`
  padding: 16px;
  border: 1px solid ${semantic_colors.border.default};
  border-radius: 8px;
  width: 100%;
`;

export const ListSubText = styled(BaseText)`
  padding-left: 5px;
`;
