import {
  Badge,
  BaseText,
  HStack,
  semantic_colors,
  IconLinkExternal,
  IconColoredFire,
  IconCopy,
  ImageZexpressTextKor,
  IconEyeOn,
  IconCircleWon,
  IconCircleQuestion,
  useToast,
  ToastPosition,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { blockedProduct } from '../alert_templates';
import { PRODUCT_GROUP_KEY } from '../constants';
import ProductThumbImage from '@/components/catalog/common/ProductThumbImage';
import PreviewModal from '@/components/catalog/modal/PreviewModal';
import ResponsiveHStack from '@/components/common/responsive/ResponsiveHStack';
import ResponsiveText from '@/components/common/responsive/ResponsiveText';
import ResponsiveVStack from '@/components/common/responsive/ResponsiveVStack';
import { SiteId } from '@/constants/site';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  CatalogFulfillmentType,
  CountryCode,
  ShopPriceOptimizationTargetProductBasicInfo,
  ShopPriceOptimizationTargetProductStatisticsInfo,
} from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

interface BoostingProductInfoProps {
  basic_info: Omit<
    ShopPriceOptimizationTargetProductBasicInfo,
    'current_base_shipping_fee' | 'promotion_discount_price' | 'shipping_fee_for_free_shipping'
  >;
  sellable?: boolean;
  group_list: string[];
  last_7_days_stats?: ShopPriceOptimizationTargetProductStatisticsInfo | null;
}

const BoostingProductInfo: React.FC<BoostingProductInfoProps> = ({
  basic_info,
  group_list,
  sellable,
  last_7_days_stats,
}) => {
  const [{ isOpen, onOpen, onClose }] = useModalState(false);
  const { is_desktop } = useMediaQuery();
  const is_new = group_list?.includes(PRODUCT_GROUP_KEY.NEW_PRODUCT);
  const is_promotion = group_list?.includes(PRODUCT_GROUP_KEY.PROMOTION_PRODUCT);
  const toast = useToast();

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.show({
          kind: 'success',
          size: 'large',
          position: ToastPosition.BOTTOM_CENTER,
          content: '상품번호 복사 완료',
        });
      },
      () => {
        toast.show({
          kind: 'error',
          size: 'large',
          position: ToastPosition.BOTTOM_CENTER,
          content: '상품번호 복사 실패',
        });
      },
    );
  };

  return (
    <>
      <ResponsiveHStack width='100%' spacing={{ desktop: 16, mobile: 12 }} alignment='top'>
        <SC.ImageWrap>
          <SC.Image image_url={basic_info.image_url} width={72} ratio={1.2} alt='' />
        </SC.ImageWrap>
        <ResponsiveVStack spacing={{ desktop: 8, mobile: 4 }}>
          {(sellable === false || is_promotion || is_new) && (
            <HStack spacing={4} alignment='center'>
              {sellable === false && (
                <SC.ClickableBadge
                  onClick={() => {
                    blockedProduct({ basic_info });
                  }}
                >
                  <Badge
                    endIcon={<IconCircleQuestion size={12} />}
                    color='red'
                    shape='rectangle'
                    kind='outline'
                    size='medium'
                  >
                    판매 불가
                  </Badge>
                </SC.ClickableBadge>
              )}
              {is_promotion && (
                <Badge
                  kind='fill'
                  shape='rectangle'
                  size='medium'
                  color='red'
                  startIcon={<IconColoredFire size={12} />}
                >
                  기획전
                </Badge>
              )}
              {is_new && (
                <Badge kind='fill' shape='rectangle' size='medium' color='blue'>
                  신상품
                </Badge>
              )}
            </HStack>
          )}
          <SC.ProductText kind={{ mobile: 'Body_14_Regular' }}>
            <span onClick={() => copyText(basic_info.product_id)}>
              <SC.ProductNumber
                kind={{ desktop: 'Body_13_Medium', mobile: 'Body_14_Medium' }}
                color={semantic_colors.content.secondary}
              >
                {basic_info.product_id}
                <span>
                  <IconCopy color='currentColor' size={is_desktop ? 14 : 16} />
                </span>
              </SC.ProductNumber>
            </span>
            {basic_info.fulfillment_type === CatalogFulfillmentType.ZIGZIN && (
              <SC.ZigzinImage>
                <ImageZexpressTextKor size={45} />
              </SC.ZigzinImage>
            )}
            <SC.ProductPreviewButton onClick={onOpen} title={basic_info.product_name}>
              {basic_info.product_name}
              <span>
                <IconLinkExternal size={is_desktop ? 14 : 16} color={semantic_colors.content.secondary} />
              </span>
            </SC.ProductPreviewButton>
          </SC.ProductText>
          {last_7_days_stats && (
            <HStack spacing={8}>
              <HStack spacing={4} alignment='center' title='누적 노출 수'>
                <IconEyeOn size={14} color={semantic_colors.content.tertiary} />
                <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
                  {last_7_days_stats?.impressions.toLocaleString() ?? '-'}회
                </BaseText>
              </HStack>
              <HStack spacing={4} alignment='center' title='누적 매출액'>
                <IconCircleWon size={14} color={semantic_colors.content.tertiary} />
                <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
                  {last_7_days_stats?.gmv.toLocaleString() ?? '-'}원
                </BaseText>
              </HStack>
            </HStack>
          )}
        </ResponsiveVStack>
      </ResponsiveHStack>
      <PreviewModal
        preview_id={basic_info.product_id}
        site_country_list={[{ site_id: Number(SiteId.지그재그), country: CountryCode.KR }]}
        is_open={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default BoostingProductInfo;

const SC = {
  ProductNumber: styled(ResponsiveText)`
    padding-right: 4px;
    cursor: pointer;
    > span {
      padding-left: 2px;
      vertical-align: middle;
    }
  `,
  ProductPreviewButton: styled.span`
    cursor: pointer;
    > span {
      padding-left: 2px;
      vertical-align: middle;
    }
  `,
  ImageWrap: styled.div`
    position: relative;
    width: 72px;
    height: 86px;
    border-radius: 4px;
    flex-shrink: 0;
    overflow: hidden;
  `,
  Image: styled(ProductThumbImage)`
    flex-shrink: 0;

    picture > img {
      opacity: 1;
    }
  `,
  ZigzinImage: styled.span`
    padding-right: 4px;
  `,
  ProductText: styled(ResponsiveText)`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  `,
  ClickableBadge: styled(HStack)`
    cursor: pointer;
  `,
};
