import { BaseText, HStack, HelpText, IconCircleQuestion, Popover, VStack, semantic_colors } from '@croquiscom/pds';
import React, { ReactNode } from 'react';

interface HelperTextProps {
  text: string;
  helper_title?: string;
  content?: ReactNode;
  size?: 'small' | 'large';
}

const HelperText: React.FC<HelperTextProps> = ({ text, content, helper_title, size = 'small' }) => {
  return (
    <HStack alignment='center' spacing={size === 'small' ? 2 : 4}>
      <BaseText kind='Body_13_Medium'>{text}</BaseText>
      <Popover content={<HelpText contents={[{ text: content }]} title={helper_title || text} />}>
        <VStack alignment='center'>
          <IconCircleQuestion color={semantic_colors.content.tertiary} />
        </VStack>
      </Popover>
    </HStack>
  );
};

export default HelperText;
