import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import { Root } from './SkeletonBase.styled';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  radius?: number;
}

export const SkeletonBase: FC<Props> = (props) => <Root {...props} />;
