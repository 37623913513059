import dayjs from 'dayjs';
import { atomWithQuery } from 'jotai/query';
import { api_getsShopRequiredTermAgreementList } from '@/api';
import { TermAgreementList, TermDisagreementList } from '@/hooks/useAgreement';

export const term_agreement_atom = atomWithQuery<TermAgreementList, Error>(() => ({
  queryKey: ['useTermAgreementQuery'],
  queryFn: async () => {
    const { data } = await api_getsShopRequiredTermAgreementList({ agreed: true });
    return data.shop_required_term_agreement_list.item_list.reduce(
      (o, item) => ({ ...o, [item.type]: dayjs(item.date_created) }),
      {},
    );
  },
}));

export const term_disagreement_atom = atomWithQuery<TermDisagreementList, Error>(() => ({
  queryKey: ['useTermDisagreementQuery'],
  queryFn: async () => {
    const { data } = await api_getsShopRequiredTermAgreementList({ agreed: false });
    return data.shop_required_term_agreement_list.item_list.map((item) => item.type);
  },
}));
