import { Alert } from '@croquiscom/pds';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api_getParentShopSettlementMonthlyList, GetParentShopSettlementMonthlyListQueryVariables } from '@/api';

const useGetParentShopSettlementMonthlyList = (variables: GetParentShopSettlementMonthlyListQueryVariables) => {
  const { t } = useTranslation('settlement');

  return useQuery(
    ['getParentShopSettlementMonthlyList', variables],
    async () => {
      const { data, errors } = await api_getParentShopSettlementMonthlyList(variables);
      if (errors) {
        throw new Error(t('settlement::데이터 가져오기를 실패했습니다.'));
      }
      return data.parent_shop_settlement_monthly_list;
    },
    {
      onError: (error: { message: string }) => {
        Alert({
          title: t('common::알림'),
          text: error.message,
        });
      },
    },
  );
};

export default useGetParentShopSettlementMonthlyList;
