import {
  BaseText,
  HStack,
  IconColoredDocument,
  IconColoredFaceVeryGood,
  IconColoredSiren,
  VStack,
  semantic_colors,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import type dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BlurArea from './common/BlurArea';
import SectionTitle from './common/SectionTitle';
import { Card, ContentContainer } from './common/styles';
import { useGetDailyReportShopManaging } from './hooks/useGetDailyReportShopManaging';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const Management: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const { data, isLoading } = useGetDailyReportShopManaging({
    date_ymd: date.format('YYYY-MM-DD'),
    main_domain,
    token,
  });
  const management_data = data?.partner_plus_daily_report?.shop_managing_report;
  const is_positive =
    management_data?.late_shipping_count === 0 &&
    management_data?.late_order_count === 0 &&
    management_data?.zigzin_count === 0 &&
    management_data?.penalty_product_count === 0;

  return (
    <ContentContainer>
      <SectionTitle
        object_section='운영 관리'
        onClickLink={onClose}
        Icon={IconColoredDocument}
        main_domain={main_domain}
        link='/stats'
        is_partner_plus={is_partner_plus}
        is_partner_plus_only={management_data?.plus_only}
        description={t(
          '스토어 매출이나 서비스 등급에 영향을 줄 수 있는 항목에 대해 알려드려요. 대시보드에서 자세히 확인하세요.',
        )}
      >
        {t('운영 관리')}
      </SectionTitle>
      <BlurArea
        is_partner_plus={is_partner_plus}
        is_loading={isLoading}
        main_domain={main_domain}
        is_blur={management_data?.is_blurred}
        object_section='운영 관리'
        used_free_view_count={management_data?.free_view_count?.used_free_view_count || 0}
        max_free_view_count={management_data?.free_view_count?.max_free_view_count || 0}
      >
        <Card spacing={16}>
          {is_positive ? (
            <HStack spacing={6} alignment='center'>
              <IconColoredFaceVeryGood size={24} />
              <BaseText kind='Heading_15_Bold' color={semantic_colors.state.positive}>
                {t('최고예요')}
              </BaseText>
            </HStack>
          ) : (
            <HStack spacing={6} alignment='center'>
              <IconColoredSiren size={24} />
              <BaseText kind='Heading_15_Bold' color={semantic_colors.state.negative}>
                {t('확인이 필요해요')}
              </BaseText>
            </HStack>
          )}
          <StatusBox>
            <VStack alignment='center'>
              <HStack alignment='center' spacing={2}>
                <BaseText kind='Heading_17_Bold'>{management_data?.late_shipping_count.toLocaleString()}</BaseText>
                <BaseText kind='Body_12_SemiBold'>{t('건')}</BaseText>
              </HStack>
              <BaseText kind='Body_12_SemiBold'>{t('배송지연')}</BaseText>
            </VStack>
            <VStack alignment='center'>
              <HStack alignment='center' spacing={2}>
                <BaseText kind='Heading_17_Bold'>{management_data?.late_order_count.toLocaleString()}</BaseText>
                <BaseText kind='Body_12_SemiBold'>{t('건')}</BaseText>
              </HStack>
              <BaseText kind='Body_12_SemiBold'>{t('처리지연')}</BaseText>
            </VStack>
            <VStack alignment='center'>
              <HStack alignment='center' spacing={2}>
                <BaseText kind='Heading_17_Bold'>{management_data?.zigzin_count.toLocaleString()}</BaseText>
                <BaseText kind='Body_12_SemiBold'>{t('건')}</BaseText>
              </HStack>
              <BaseText kind='Body_12_SemiBold'>{t('직진배송')}</BaseText>
            </VStack>
            <VStack alignment='center'>
              <HStack alignment='center' spacing={2}>
                <BaseText kind='Heading_17_Bold'>{management_data?.penalty_product_count.toLocaleString()}</BaseText>
                <BaseText kind='Body_12_SemiBold'>{t('건')}</BaseText>
              </HStack>
              <BaseText kind='Body_12_SemiBold'>{t('패널티 상품')}</BaseText>
            </VStack>
          </StatusBox>
        </Card>
      </BlurArea>
    </ContentContainer>
  );
};

export default Management;

const StatusBox = styled(HStack)`
  justify-content: space-around;
`;
