import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { useCallback, useState } from 'react';
import { ChatBotQnA } from '../types';
import { Parser } from '../utils/Parser';
import { chat_bot_session } from '../utils/session';
import { Config } from '@/config';

export enum SSE_STATE {
  LOADING,
  ERROR,
  READY,
  RECEIVING,
  DONE,
}
interface ServerSentEventProps {
  onDone?: ({
    answer,
    message_id,
    is_no_answer,
    question,
  }: {
    answer: string;
    message_id: string;
    is_no_answer: boolean;
    question: string;
  }) => void;
}

export const useChatBotRequest = ({ onDone }: ServerSentEventProps) => {
  const [state, setState] = useState<SSE_STATE>(SSE_STATE.READY);
  const [answer, setAnswer] = useState<string>('');
  const sendMessage = useCallback(
    async (message: string, thread: ChatBotQnA[]) => {
      chat_bot_session.refreshTime();

      try {
        let text = '';
        let message_id = '';
        let retry_count = 1;
        let timeout_id;
        const session_id = chat_bot_session.getKey();
        const parser = new Parser();
        parser.onText = (parsed_text) => {
          text += parsed_text;
          setAnswer(text);
        };
        const startTimeout = () =>
          setTimeout(() => {
            ctrl.abort();
            setState(SSE_STATE.ERROR);
          }, 10000);
        class RetriableError extends Error {}

        class FatalError extends Error {}

        const ctrl = new AbortController();

        setState(SSE_STATE.LOADING);
        await fetchEventSource(`/api/provider/${Config.shop_main_domain}/chatbots`, {
          method: 'POST',
          openWhenHidden: true,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            session_id,
            chatbot_message_llm: {
              query: message,
              conversation: thread
                .map((t) => [
                  {
                    role: 'user',
                    content: t.question.text,
                  },
                  {
                    role: 'assistant',
                    content: t.answer?.text || '',
                  },
                ])
                .flatMap((t) => t),
            },
          }),
          signal: ctrl.signal,
          async onopen(response) {
            if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
              timeout_id = startTimeout();
              return;
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
              throw new FatalError();
            } else {
              ctrl.abort();
              setState(SSE_STATE.ERROR);
              throw new RetriableError();
            }
          },
          onmessage({ data, event }) {
            clearTimeout(timeout_id);
            timeout_id = startTimeout();
            if (event === 'FatalError') {
              throw new FatalError(data);
            } else if (event === 'sse') {
              return;
            }
            setState(SSE_STATE.RECEIVING);
            const parsedData = JSON.parse(data);
            message_id = message_id || parsedData?.messageId;
            if (parsedData?.content?.content_type === 'text') {
              const received_message = parsedData.content.parts[0];
              if (received_message === '[DONE]' || received_message === '[NO_ANSWER]') {
                clearTimeout(timeout_id);
                onDone?.({
                  message_id,
                  answer: text,
                  is_no_answer: received_message === '[NO_ANSWER]',
                  question: message,
                });
                chat_bot_session.refreshTime();
                setState(SSE_STATE.DONE);
                return ctrl.abort();
              }
              for (const char of received_message) {
                parser.ingest(char);
              }
            }
          },
          onclose() {
            if (retry_count < 5) {
              retry_count++;
              throw new RetriableError();
            } else {
              throw new FatalError();
            }
          },
          onerror(err) {
            ctrl.abort();
            if (err instanceof FatalError) {
              setState(SSE_STATE.ERROR);
              throw err;
            }
          },
        });
      } catch (error) {
        alert(error);
      }
    },
    [answer, onDone],
  );

  return {
    sendMessage,
    answer,
    state,
  };
};
