import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next);
i18n.init({
  fallbackLng: 'ko',
  lng: 'ko',
  supportedLngs: ['ko', 'ja', 'en'],
  load: 'currentOnly',
  ns: 'common',
  // 자연어 키 사용을 위해 비활성화
  keySeparator: false,
  nsSeparator: '::',
  // debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
