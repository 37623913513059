import { BaseText, VStack, semantic_colors } from '@croquiscom/pds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoneLottie } from '../DoneLottie';

export const InsightFeedbackDone = () => {
  const { t } = useTranslation();
  return (
    <VStack alignment='center'>
      <DoneLottie />
      <VStack>
        <BaseText kind='Heading_15_SemiBold' alignment='center' color={semantic_colors.content.secondary}>
          {t('만족도 제출 완료')}
        </BaseText>
        <BaseText kind='Body_13_Regular' color={semantic_colors.content.secondary}>
          {t('소중한 의견 감사해요')}
        </BaseText>
      </VStack>
    </VStack>
  );
};
