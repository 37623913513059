import { BaseText, BaseTextProps } from '@croquiscom/pds';
import React from 'react';
import { ReponsiveType } from './type';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsiveTextProps extends Omit<BaseTextProps, 'kind'> {
  kind?: ReponsiveType<BaseTextProps['kind']>;
  color?: BaseTextProps['color'];
  children: React.ReactNode;
  className?: string;
}

export default function ResponsiveText({ kind, color, children, ...props }: ResponsiveTextProps) {
  const { is_desktop } = useMediaQuery();

  return (
    <BaseText
      kind={typeof kind === 'object' ? (is_desktop ? kind.desktop : kind.mobile) : kind}
      color={color || 'currentColor'}
      {...props}
    >
      {children}
    </BaseText>
  );
}
