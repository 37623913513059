import { PageHeader } from '@croquiscom/pds';
import { t } from 'i18next';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import ResponsivePageHeader from '@/components/common/responsive/ResponsivePageHeader';
import ManagementNav from '@/components/partner_plus/common/ManagementNav';
import PartnerPlusChecker from '@/components/partner_plus/common/PartnerPlusChecker';
import PartnerPlusGreetingContainer from '@/components/partner_plus/common/PartnerPlusGreetingContainer';
import PartnerPlusIncludesTermsTitle from '@/components/partner_plus/common/PartnerPlusIncludesTermsTitle';

const PartnerPlusIntroPage = lazy(() => import('@/pages/partner_plus/PartnerPlusIntroPage'));
const PartnerPlusManagementBenefitPage = lazy(() => import('@/pages/partner_plus/PartnerPlusManagementBenefitPage'));
const PartnerPlusManagementSubscriptionPage = lazy(
  () => import('@/pages/partner_plus/PartnerPlusManagementSubscriptionPage'),
);
const PartnerPlusManagementHistoryPage = lazy(() => import('@/pages/partner_plus/PartnerPlusManagementHistoryPage'));
const PartnerPlusManagementNoticePage = lazy(() => import('@/pages/partner_plus/PartnerPlusManagementNoticePage'));
const PartnerPlusManagementNoticeDetailPage = lazy(
  () => import('@/pages/partner_plus/PartnerPlusManagementNoticeDetailPage'),
);
const PartnerPlusUnsubscriptionPreventionPage = lazy(
  () => import('@/pages/partner_plus/PartnerPlusUnsubscriptionPreventionPage'),
);
const PartnerPlusUnsubscriptionPage = lazy(() => import('@/pages/partner_plus/PartnerPlusUnsubscriptionPage'));
const PartnerPlusSubscriptionPage = lazy(() => import('@/pages/partner_plus/PartnerPlusSubscriptionPage'));

interface PartnerPlusRoutesType extends RoutesType {
  title: string;
  hasInsightFeedback?: boolean;
}

const TITLES = {
  DAILYREPORT: t('데일리 리포트', { ns: 'partner_plus' }),
  INTRO: t('파트너플러스 소개', { ns: 'partner_plus' }),
  MANAGEMENT: t('파트너플러스 관리', { ns: 'partner_plus' }),
  UNSUBSCRIPTION: t('파트너플러스 구독 해지 신청', { ns: 'partner_plus' }),
  SUBSCRIPTION: t('파트너플러스 구독 신청', { ns: 'partner_plus' }),
};

export const PARTNER_PLUS_ROUTES: PartnerPlusRoutesType[] = [
  {
    current_menu: 'partner_plus_intro',
    path: '/partner-plus/intro',
    hasInsightFeedback: true,
    is_support_mobile: true,
    title: TITLES.INTRO,
    params: '',
    component: (
      <>
        <ResponsivePageHeader border={false} title={TITLES.INTRO} />
        <PartnerPlusIntroPage />
      </>
    ),
  },
  {
    current_menu: 'partner_plus_management',
    path: '/partner-plus/management/benefit',
    title: TITLES.MANAGEMENT,
    params: '',
    hasInsightFeedback: true,
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <ManagementNav current_tab='benefit' />
        <PartnerPlusChecker>
          <PartnerPlusGreetingContainer>
            <PartnerPlusManagementBenefitPage />
          </PartnerPlusGreetingContainer>
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_management',
    path: '/partner-plus/management/subscription',
    title: TITLES.MANAGEMENT,
    params: '',
    hasInsightFeedback: true,
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <ManagementNav current_tab='subscription' />
        <PartnerPlusChecker>
          <PartnerPlusManagementSubscriptionPage />
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_management',
    path: '/partner-plus/management/history',
    title: TITLES.MANAGEMENT,
    params: '',
    hasInsightFeedback: true,
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <ManagementNav current_tab='history' />
        <PartnerPlusChecker>
          <PartnerPlusManagementHistoryPage />
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_unsubscription',
    path: '/partner-plus/subscription/cancel/notification',
    title: TITLES.SUBSCRIPTION,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <PartnerPlusChecker>
          <PartnerPlusUnsubscriptionPreventionPage />
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_unsubscription',
    path: '/partner-plus/subscription/cancel',
    title: TITLES.SUBSCRIPTION,
    hasInsightFeedback: true,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.UNSUBSCRIPTION} />
        </PageHeader>
        <PartnerPlusChecker>
          <PartnerPlusUnsubscriptionPage />
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_subscription',
    path: '/partner-plus/subscription',
    hasInsightFeedback: true,
    title: TITLES.SUBSCRIPTION,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.SUBSCRIPTION} />
        </PageHeader>
        <PartnerPlusSubscriptionPage />
      </>
    ),
  },
  {
    current_menu: 'partner_plus_notice',
    path: '/partner-plus/management/notice',
    title: TITLES.MANAGEMENT,
    params: '',
    hasInsightFeedback: true,
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <ManagementNav current_tab='notice' />
        <PartnerPlusChecker>
          <PartnerPlusManagementNoticePage />
        </PartnerPlusChecker>
      </>
    ),
  },
  {
    current_menu: 'partner_plus_notice',
    path: '/partner-plus/management/notice/:id',
    title: TITLES.MANAGEMENT,
    params: '',
    hasInsightFeedback: true,
    component: (
      <>
        <PageHeader border={false}>
          <PartnerPlusIncludesTermsTitle title={TITLES.MANAGEMENT} />
        </PageHeader>
        <ManagementNav current_tab='notice' />
        <PartnerPlusChecker>
          <PartnerPlusManagementNoticeDetailPage />
        </PartnerPlusChecker>
      </>
    ),
  },
];
