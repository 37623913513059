import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { SUBSCRIPTION_PRODUCT_ID } from '../constants';
import { api_getShopSubscriptionPrice } from '@/api';

export const useGetSubscriptionPriceForPartnerPlus = (is_private: boolean = true, date_ym?: string) => {
  return useQuery(
    ['useGetSubscriptionPriceForPartnerPlus', date_ym],
    async () => {
      const { data } = await api_getShopSubscriptionPrice({
        product_id: SUBSCRIPTION_PRODUCT_ID.PARTNER_PLUS,
        item_id: '16', //구독상품 통일로 고정값
        applied_affiliate_discount: false,
        payment_date_ym: date_ym || dayjs().format('YYYY-MM'),
      });
      return data.getShopSubscriptionPrice;
    },
    { staleTime: 30000, cacheTime: 30000, enabled: is_private },
  );
};
