import { IconHanger } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import {
  checkRes,
  isAllowedByShopDepartment,
  isAllowedMenuByCountry,
  isAllowedMenuBySiteId,
  isNotAllowedByShopDepartment,
} from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment, ShopType } from '@/types/info';
import { CountryCode, SellerResource } from 'api';
import {
  CATALOG_ITEM_DETAIL_NEW_URL,
  CATALOG_ITEM_LIST_URL,
  CATALOG_NOTICE_URL,
  EXCLUSIVE_CATALOG_APPLY,
  IMPORT_EXTERNAL_CATALOG_URL,
  getCatalogExcelUploadURL,
} from 'rui/components/URL';

export function createCatalogMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  return [
    {
      id: 'catalog',
      label: t('main_frame::상품 등록/관리'),
      icon: <IconHanger size={16} color='currentColor' />,
      is_allowed: is_zpay && is_site_id_allowed && isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
      items: [
        {
          id: 'catalog_item_detail_new',
          label: t('main_frame::상품 등록'),
          href: CATALOG_ITEM_DETAIL_NEW_URL,
          is_allowed: !info.is_parent && checkRes([SellerResource.GOODS_SEARCH], info.allowed_resource_list),
        },
        {
          id: 'catalog_item_list',
          label: t('main_frame::상품 조회/수정'),
          href: CATALOG_ITEM_LIST_URL,
          is_allowed: checkRes([SellerResource.GOODS_SEARCH], info.allowed_resource_list),
        },
        {
          id: 'catalog_notice_list',
          label: t('main_frame::상품 공지사항 관리'),
          href: CATALOG_NOTICE_URL,
          is_allowed:
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어, SiteId.포스티]) &&
            isAllowedMenuByCountry(info, [CountryCode.KR]),
        },
        {
          id: 'excel_upload',
          label: t('main_frame::상품 엑셀 등록/수정'),
          href: getCatalogExcelUploadURL('catalog'),
          is_allowed: !info.is_parent && checkRes([SellerResource.GOODS_SEARCH], info.allowed_resource_list),
        },
        {
          id: 'import_external_catalog',
          label: t('main_frame::외부 상품 가져오기'),
          href: IMPORT_EXTERNAL_CATALOG_URL,
          is_allowed: true,
        },
        {
          id: 'exclusive_catalog_apply',
          label: t('main_frame::단독 상품 신청'),
          href: EXCLUSIVE_CATALOG_APPLY,
          is_allowed:
            Boolean(info.exclusive_attribute_list?.length) &&
            isAllowedByShopDepartment(info, [ShopDepartment.SOHO_FASHION]),
        },
      ],
    },
  ];
}
