import { IconColoredWarning } from '@croquiscom/pds';
import type dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Nodata from './common/Nodata';
import ProductSwiper from './common/ProductSwiper';
import SectionTitle from './common/SectionTitle';
import { ContentContainer } from './common/styles';
import { useGetDailyReportClaimTopRanking } from './hooks/useGetDailyReportClaimTopRanking';

interface Props {
  date: dayjs.Dayjs;
  onClose?: () => void;
  main_domain?: string;
  token?: string;
  is_partner_plus?: boolean;
}

const ClaimsTop: React.FC<Props> = ({ date, onClose, main_domain, token, is_partner_plus }) => {
  const { t } = useTranslation('main_frame');
  const { data } = useGetDailyReportClaimTopRanking({
    date_ymd: date.format('YYYY-MM-DD'),
    use_free_view_count: false,
    main_domain,
    token,
  });
  const claims_data = data?.partner_plus_daily_report?.cancel_change_refund_top_ranking_report;
  const has_data = claims_data && claims_data.product_ranking_list.length > 0;

  if (!is_partner_plus) {
    return null;
  }

  return (
    <ContentContainer>
      <SectionTitle
        object_section='어제 취소, 반품 많은 상품'
        onClickLink={onClose}
        Icon={IconColoredWarning}
        main_domain={main_domain}
        is_partner_plus_only
        is_partner_plus={is_partner_plus}
        link='/insight/sales-performance?ug=claims'
        description={t('취소, 반품이 많은 상품과 사유를 확인하고 개선할 부분을 알아보세요.')}
      >
        {t('어제 취소, 반품 많은 상품')}
      </SectionTitle>
      {has_data ? (
        <ProductSwiper
          object_section='어제 취소, 반품 많은 상품'
          width={268}
          is_partner_plus={is_partner_plus}
          product_ranking_list={claims_data?.product_ranking_list || []}
          type='claim'
        ></ProductSwiper>
      ) : (
        <Nodata />
      )}
    </ContentContainer>
  );
};

export default ClaimsTop;
