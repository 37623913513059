import { Alert } from '@croquiscom/pds';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { main_domain_atom } from '@/atoms/main_domain';
import { Config } from '@/config';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import TraitInfo from '@/types/trait';
import { setupInterceptors } from '@/utils/axios';
import {
  api_getZonlyShopContact,
  ShopZonlyStatus,
  useGetZigzinDeliveryStateQuery,
  ZigzinDeliveryApplicationStatus,
} from 'api';

export const useGetZigzinAddressInfo = () => {
  const [shop_main_domain] = useAtom(main_domain_atom);
  const traitApi = useMemo(() => {
    const api = axios.create({
      baseURL: `${Config.api_base_url}/${shop_main_domain}`,
    });
    setupInterceptors(api);
    return api;
  }, [shop_main_domain]);

  const {
    data: zigzin_address_info,
    refetch,
    isLoading,
    isError,
  } = useQuery('traitsContact', async () => {
    const [
      { data: contact_info },
      {
        data: { zonly_release_address, zonly_return_address },
      },
    ] = await Promise.all([
      api_getZonlyShopContact(),
      traitApi.get<TraitInfo>('/traits', { params: { time: Date.now() } }),
    ]);

    return {
      contact_info: contact_info.shop_contact,
      zonly_release_address,
      zonly_return_address,
    };
  });

  return {
    refetch,
    zigzin_address_info,
    isLoading,
    isError,
  };
};

export const useGetZigzinCurrentState = (no_alert?: boolean) => {
  const history = useHistory();
  const shop_prefix = useShopPrefix();

  const { data, refetch, isLoading } = useGetZigzinDeliveryStateQuery(
    {},
    {
      onError: () => {
        if (no_alert) return;
        Alert({
          title: '직진배송 서비스 신청 이용이 불가합니다. 잠시후 다시 시도해주세요.',
          onClose: () => history.push(`${shop_prefix}/stats`),
        });
      },
      cacheTime: 0,
    },
  );
  const { application, zigzin_status } = data?.getZigzinDeliveryState ?? {};

  return {
    // MEMO: is_zigzin(직진배송 가입 여부): info.zigzin_status와 같은 값을 바라봅니다. info.type === 'ZPAY' 체크는 deprecated되어 미사용합니다.
    is_zigzin: zigzin_status === ShopZonlyStatus.NORMAL,
    zigzin_application_status: application?.status,
    // MEMO: 신청 현황 페이지 접근 가능 여부
    is_enable_entry_sign_up_status:
      application?.status === ZigzinDeliveryApplicationStatus.PENDING ||
      application?.status === ZigzinDeliveryApplicationStatus.REJECTED_REAPPLICATION_RESTRICTED,
    application,
    isLoading,
    refetch,
  };
};
