import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

export const ChatBotLogo = ({ size = 85, color = '#ffffff' }: IconProps): JSX.Element => {
  return (
    <svg width={size} height={(14 / 85) * size} viewBox='0 0 85 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 7.81614C0.185292 7.81614 0.365707 7.821 0.541247 7.83072C0.726539 7.83072 0.91183 7.82586 1.09712 7.81614V3.09417H0.263309V1.66592H6.75827V3.09417H5.92446V7.56839C6.13901 7.53924 6.35356 7.51009 6.5681 7.48094C6.78265 7.45179 6.99233 7.41779 7.19712 7.37892L7.35803 8.7926C6.82166 8.90919 6.25116 9.00149 5.64652 9.06951C5.05164 9.1278 4.43725 9.17153 3.80336 9.20067C3.16946 9.22982 2.5307 9.24925 1.88705 9.25897C1.24341 9.26868 0.614388 9.27354 0 9.27354V7.81614ZM9.80096 4.84305H11.7026V6.44619H9.80096V12.9608H7.92854V0.776907H9.80096V4.84305ZM4.08129 3.09417H2.95492V7.787L4.08129 7.7287V3.09417Z'
        fill={color}
      />
      <path
        d='M23.3684 9.5213V11.037H11.6658V9.5213H23.3684ZM13.6113 7.96188V1.41816H21.4082V2.89013H15.5423V3.99776H20.9986V5.38229H15.5423V6.48991H21.5399V7.96188H13.6113Z'
        fill={color}
      />
      <path
        d='M26.1401 1.53475V7.61211C26.501 7.61211 26.8959 7.60239 27.325 7.58296C27.7541 7.56353 28.193 7.53924 28.6416 7.51009C29.0902 7.47123 29.5339 7.4275 29.9728 7.37892C30.4116 7.33034 30.8212 7.27205 31.2015 7.20404L31.3771 8.70516C30.4994 8.8509 29.5193 8.95777 28.4368 9.02578C27.364 9.0938 26.2474 9.1278 25.0869 9.1278H24.297V1.53475H26.1401ZM32.3864 0.776907H34.2589V12.9608H32.3864V5.2074H29.0073V3.66256H32.3864V0.776907Z'
        fill={color}
      />
      <path
        d='M35.0558 7.04372C35.875 6.43161 36.4747 5.74664 36.8551 4.98879C37.2354 4.22123 37.4256 3.31278 37.4256 2.26345V1.15583H39.181V2.40919C39.181 3.11846 39.1078 3.76457 38.9615 4.34753L41.6678 6.78139L40.4097 8.04933L38.4057 5.89238C38.1228 6.41704 37.7913 6.87855 37.4109 7.27691C37.0306 7.67526 36.6064 8.04447 36.1383 8.38453L35.0558 7.04372ZM40.1025 3.03587H41.8141V0.908072H43.5841V9.68161H41.8141V4.537H40.1025V3.03587ZM40.2635 11.5617H46.5097V13.0628H38.391V9.06951H40.2635V11.5617ZM44.4618 0.776907H46.2464V9.95852H44.4618V0.776907Z'
        fill={color}
      />
      <path
        d='M54.8988 9.05493C54.4989 9.13266 54.0503 9.19581 53.5529 9.24439C53.0556 9.29297 52.5338 9.3367 51.9877 9.37556C51.4514 9.40471 50.9004 9.429 50.3347 9.44843C49.7691 9.45815 49.2132 9.463 48.6671 9.463H47.8918V1.60762H53.787V3.12332H49.735V4.75561H53.2604V6.213H49.735V7.94731C50.0568 7.94731 50.4322 7.94245 50.8613 7.93274C51.2904 7.9133 51.7342 7.88901 52.1925 7.85987C52.6509 7.821 53.0995 7.77728 53.5383 7.7287C53.9869 7.68012 54.3819 7.62182 54.7232 7.55381L54.8988 9.05493ZM56.0398 0.776907H57.9122V12.9608H56.0398V5.95067H54.1235V4.4204H56.0398V0.776907Z'
        fill={color}
      />
      <path
        d='M66.6491 1.86996V2.42377C66.6491 2.90957 66.5857 3.36136 66.4589 3.77915C66.3419 4.19694 66.1761 4.58558 65.9616 4.94507L67.8633 6.28587V0.908072H69.6333V3.70628H70.6134V0.776907H72.398V9.20067H70.6134V5.25112H69.6333V8.02018H67.8633V6.40247L66.7661 7.68498L65.0985 6.11099C64.6499 6.59679 64.1623 7.00486 63.6357 7.3352C63.1091 7.65583 62.6166 7.90845 62.1582 8.09305L61.339 6.62108C61.7779 6.4559 62.2021 6.24701 62.6117 5.99439C63.0213 5.73206 63.3821 5.45516 63.6942 5.16368C64.016 4.86248 64.2793 4.56129 64.4841 4.26009C64.6889 3.94918 64.8059 3.6577 64.8352 3.38565H61.8803V1.86996H63.3431V0.5H65.1278V1.86996H66.6491ZM63.6942 11.8094C65.001 11.5179 65.947 11.1147 66.5321 10.5998C67.127 10.0848 67.4244 9.48729 67.4244 8.80717V8.60314L69.2091 8.63229V8.80717C69.2091 9.41928 69.1164 9.94395 68.9311 10.3812L72.7345 11.62L71.9007 13.3543L68.0973 11.6054C67.678 12.0135 67.1514 12.3584 66.5175 12.6401C65.8836 12.9219 65.1424 13.1599 64.294 13.3543L63.6942 11.8094Z'
        fill={color}
      />
      <path
        d='M75.3161 5.70291V0.922646H77.1885V1.97197H81.1235V0.922646H82.9813V5.70291H80.1142V6.73767H85V8.25336H73.2974V6.73767H78.1978V5.70291H75.3161ZM74.6139 11.9843C75.1503 11.8774 75.6379 11.7414 76.0767 11.5762C76.5156 11.4111 76.891 11.2119 77.2031 10.9787C77.5249 10.7455 77.7736 10.4735 77.9492 10.1626C78.1247 9.84193 78.2125 9.47272 78.2125 9.05493V8.7926L80.1142 8.80717V9.11323C80.1142 9.37556 80.0898 9.62332 80.041 9.8565C79.9922 10.08 79.924 10.2986 79.8362 10.5123L83.742 11.8094L82.9959 13.4854L79.0317 11.7074C78.5635 12.1738 78.0028 12.5478 77.3494 12.8296C76.696 13.1211 75.989 13.3445 75.2283 13.5L74.6139 11.9843ZM81.1235 3.38565H77.1885V4.24552H81.1235V3.38565Z'
        fill={color}
      />
    </svg>
  );
};
