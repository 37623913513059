import { BaseText, HStack, IconCheckBold, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InsightIntroHeader from '../../common/InsightIntroHeader';
import img_mobile_01 from '@/assets/images/insight/intro/competitor/img_mobile_01.png';
import img_mobile_02 from '@/assets/images/insight/intro/competitor/img_mobile_02.png';
import img_mobile_03 from '@/assets/images/insight/intro/competitor/img_mobile_03.png';
import img_pc_01 from '@/assets/images/insight/intro/competitor/img_pc_01.png';
import img_pc_02 from '@/assets/images/insight/intro/competitor/img_pc_02.png';
import img_pc_03 from '@/assets/images/insight/intro/competitor/img_pc_03.png';

interface InsightCompetitorIntroBodyProps {
  button_ref?: (instance: Element | null) => void;
  is_desktop: boolean;
  is_revealed: boolean;
}

export const InsightCompetitorIntroBody: FC<InsightCompetitorIntroBodyProps> = ({
  button_ref,
  is_revealed,
  is_desktop,
}) => {
  const { t } = useTranslation('insight');
  return (
    <>
      <InsightIntroHeader
        badges={[t('경쟁사와 비교'), t('경쟁사 분석'), t('내 스토어 경쟁력')]}
        title={t('객관적으로 진단해보세요')}
        navigation='insight_competitors_intro'
        is_button_visible={is_revealed}
        button_ref={button_ref}
      />
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('객관적인 기준 수립')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20} p={'0 13px'}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_01 : img_mobile_01} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t(
                  '내 스토어의 판매액이 성장하려면 어떤 지표가 개선되어야 할까요? 상위 등급, 내 등급 평균과의 비교를 통해 1분만에 확인해 보세요.',
                )}
              </BaseText>
            </HStack>
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                {t('상대적인 등급과 순위를 통해서 시즌 특성에 흔들리지 않고 내 스토어의 성과를 측정할 수 있어요.')}
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.base}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('약점과 강점 파악')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_02 : img_mobile_02} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.surface}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={is_desktop ? 16 : 10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  다양한 지표를 통해서 내 스토어의 약점과 강점을 파악할 수 있어요.
                  <br />내 스토어의 우선 순위를 정하고 개선해보세요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
      <Section background={semantic_colors.background.surface}>
        <VStack p={is_desktop ? '100px 60px' : '60px 16px'} alignment='center'>
          <BaseText className='intro_title' kind={'Heading_20_Bold'}>
            {t('실제 경쟁사 분석')}
          </BaseText>
          <VStack mt={is_desktop ? 40 : 20}>
            <Image is_desktop={is_desktop} src={is_desktop ? img_pc_03 : img_mobile_03} alt='' />
          </VStack>
          <InsightIntroSectionCard
            background={semantic_colors.background.base}
            spacing={is_desktop ? 24 : 10}
            is_desktop={is_desktop}
          >
            <HStack spacing={10} alignment='stretch'>
              <InsightIntroSectionCardIconWrapper>
                <IconCheckBold color={colors.white} size={10} />
              </InsightIntroSectionCardIconWrapper>
              <BaseText kind={is_desktop ? 'Heading_17_SemiBold' : 'Body_14_SemiBold'}>
                <Trans t={t}>
                  고객은 내 상품과 어떤 상품을 비교하고, 같이 구매할까요?
                  <br />
                  장바구니에 같이 담아 둔 상품의 스토어와 실제로 함께 구매하는 상품의 스토어 모두를 살펴보세요.
                  <br />
                  어떤 스토어를 유심히 관찰해야 할지, 어떤 상품을 보완해야 할지 힌트를 얻을 수 있을 거예요.
                </Trans>
              </BaseText>
            </HStack>
          </InsightIntroSectionCard>
        </VStack>
      </Section>
    </>
  );
};

const Section = styled(VStack)<{ background?: string; border_color?: string }>`
  width: 100%;
  align-items: center;
  ${({ background }) => background && `background: ${background}`};
  ${({ border_color }) =>
    border_color &&
    `
    border-top: 1px solid ${border_color};
    border-bottom: 1px solid ${border_color};
    `}
`;

export const Image = styled.img<{ is_desktop: boolean }>`
  width: 100%;
  max-width: ${({ is_desktop }) => (is_desktop ? '900px' : '600px')};
`;

const InsightIntroSectionCard = styled(VStack)<{ background?: string; is_desktop?: boolean }>`
  padding: 24px;
  margin-top: 30px;
  ${({ background }) => background && `background: ${background}`};
  ${({ is_desktop }) =>
    is_desktop &&
    `padding: 40px;
    margin-top: 40px;
    width: 910px;
  `};
  border-radius: 20px;
`;

const InsightIntroSectionCardIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colors.purple500};
  text-align: start;
  margin-top: 4px;
`;
