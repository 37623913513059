import { TOptions } from 'i18next';
import React from 'react';
import { Namespace, TFuncKey, useTranslation } from 'react-i18next';

export type I18nKey<N extends Namespace> = TFuncKey<N> extends infer A ? A : never;
export type I18nValues = TOptions<Record<string, string | number>>;

interface TransPlainTextProps<N extends Namespace> {
  ns: N;
  i18nKey: I18nKey<N>;
  values?: I18nValues;
}

const TransPlainText = <N extends Namespace>({ ns, i18nKey, values }: TransPlainTextProps<N>) => {
  const { t } = useTranslation(ns);
  return <>{t(i18nKey, values)}</>;
};

export default TransPlainText;
