import { IconCircleQuestion, colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import HoverIcon from './HoverIcon';
import SortIndicator from './SortIndicator';
import { defaultLine } from 'styles';

interface TableThProps {
  width?: number;
  text: React.ReactNode;
  content?: React.ReactNode;
  styles?: React.CSSProperties;
  col_span?: number;
  row_span?: number;
  fixed_column?: boolean;
  column_position?: number;
  sortable?: boolean;
  sorted?: boolean;
  sort_direction?: string;
  onClick?: () => void;
}

export const TableTh: React.FC<TableThProps> = ({
  width,
  text,
  content,
  styles,
  col_span,
  row_span,
  fixed_column,
  column_position,
  sortable,
  sorted,
  sort_direction,
  onClick,
}) => {
  return (
    <StyledTh
      scope='col'
      width={width}
      sortable={sortable}
      fixed_column={fixed_column}
      column_position={column_position}
      onClick={onClick}
      colSpan={col_span}
      rowSpan={row_span}
    >
      <div className='header-col' style={styles}>
        <div className='header-col__label'>
          <div className='header-col__text'>{text}</div>
          {content && (
            <div className='header-col__hover-icon'>
              <HoverIcon icon={<IconCircleQuestion color={colors.gray600} />} content={content} />
            </div>
          )}
        </div>
        {sortable && (
          <div className='header-col__sort'>
            <SortIndicator isSorted={sorted} sortDirection={sort_direction} />
          </div>
        )}
      </div>
    </StyledTh>
  );
};

const StyledTh = styled.th<{ width?: number; sortable?: boolean; fixed_column?: boolean; column_position?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  min-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  position: sticky;
  top: 0px;
  z-index: ${({ fixed_column }) => (fixed_column ? 3 : 2)};
  left: ${({ column_position }) => (column_position !== undefined ? `${column_position}px` : 'auto')};
  height: 40px;
  border: ${defaultLine};
  border-left: 0px;
  background-color: ${colors.gray100};
  vertical-align: middle;
  padding: 0;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : '')};

  .header-col {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 9px 12px;
    justify-content: space-between;
    white-space: nowrap;
    &__label {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    &__text {
      ${text_styles.Body_13_SemiBold};
      color: ${colors.gray900};
    }
    &__hover-icon {
      margin-left: 3px;
    }
    &__sort {
      display: flex;
      align-items: center;
      margin-left: 12px;
    }
  }
`;
