import { semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';

import { DESKTOP_MEDIA_QUERY_STRING } from '@/hooks/useMediaQuery';

export const Layout = {
  MainFrame: styled.div<{ fullscreen?: boolean }>`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${semantic_colors.background.base};

    @media ${DESKTOP_MEDIA_QUERY_STRING} {
      min-width: ${({ fullscreen }) => (fullscreen ? '100%' : '1280px')};
    }
  `,
  Container: styled.div<{ hide_accountbar?: boolean }>`
    flex: 1 0 0px;
    display: flex;
    position: relative;
    width: 100%;
    padding-top: ${({ hide_accountbar }) => (!hide_accountbar ? '56px' : '0')};

    @media ${DESKTOP_MEDIA_QUERY_STRING} {
      padding-top: ${({ hide_accountbar }) => (!hide_accountbar ? '42px' : '0')};
    }
  `,
  MetaContainer: styled.div<{ hide_accountbar?: boolean; fullscreen?: boolean }>`
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-top: ${({ hide_accountbar }) => (!hide_accountbar ? '56px' : '0')};

    @media ${DESKTOP_MEDIA_QUERY_STRING} {
      padding-top: ${({ hide_accountbar }) => (!hide_accountbar ? '42px' : '0')};
    }
  `,
  Content: styled.div<{ inactive_content_scroll?: boolean }>`
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
  `,
  ContentWrap: styled.div<{ content_min_width?: string }>`
    flex: 1 0 auto;
    min-width: ${({ content_min_width }) => content_min_width || '100%'};
    background-color: ${semantic_colors.background.base};

    @media ${DESKTOP_MEDIA_QUERY_STRING} {
      min-width: ${({ content_min_width }) => content_min_width || '1280px'};
    }
  `,
};
