import { useQuery } from 'react-query';
import { api_getPartnerCenterNotificationCount } from '@/api';
import { useAccount } from '@/hooks/useAccount';

export const GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY = 'getPartnerCenterNotificationCount';

export function useGetPartnerCenterNotificationCountQuery() {
  const { is_zigzag } = useAccount();

  return useQuery(
    [GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY],
    async () => {
      const { data } = await api_getPartnerCenterNotificationCount({}, { no_alert: true });
      return {
        notification_count: data?.notification_count ?? 0,
        news_count: data?.news_count ?? 0,
        zigzin_count: data?.zigzin_count ?? 0,
      };
    },
    {
      refetchInterval: 60 * 1000,
      enabled: !is_zigzag,
      onError: () => {},
    },
  );
}
