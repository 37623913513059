import {
  VStack,
  BaseText,
  semantic_colors,
  text_styles,
  shapes,
  HStack,
  TextButton,
  colors,
  IconColoredMegaphone,
  IconColoredZDelivery,
  IconColoredRocket,
  Tooltip,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { NOTIFICATION_2ND_CATEGORY_LABEL } from '../constants';
import { GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY } from '../hooks/useGetPartnerCenterNotificationCountQuery';
import { GroupInfo } from '../types';
import { sanitizeAlertHTML } from '../utils';
import NewsImg from '@/assets/images/alert/alert_icon_kakao_style.svg';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PblParams } from '@/utils/pbl';
import {
  PartnerCenterNotification1stCategory,
  PartnerCenterNotification2ndCategory,
  PartnerCenterNotificationInfoFragment,
  useClickPartnerCenterNotificationMutation,
} from 'api';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

export interface NotiCardProps {
  item: PartnerCenterNotificationInfoFragment;
  onClick?: () => void;
  no_group?: boolean;
  onShowGroupClick?: (group_info: Omit<GroupInfo, 'refetch'>) => void;
  pbl_section: string;
}

const ONE_DAY = 24 * 60 * 60 * 1000;

const NOTI_ICON = {
  [PartnerCenterNotification2ndCategory.AD]: <IconColoredRocket />,
  [PartnerCenterNotification2ndCategory.BASIC]: <IconColoredMegaphone />,
  [PartnerCenterNotification2ndCategory.ZIGZIN]: <IconColoredZDelivery />,
};

export const NotificationCard: React.FC<NotiCardProps> = ({
  item: {
    id,
    title,
    contents,
    date_created,
    viewed,
    notification_1st_category,
    notification_2nd_category,
    notification_group_count,
    notification_group_id,
    landing_url,
  },
  onClick,
  no_group,
  onShowGroupClick,
  pbl_section,
}) => {
  const { isRevealed, targetRef } = useOnceReveal({ threshold: 0.8 });
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const prefix = useShopPrefix();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useClickPartnerCenterNotificationMutation({
    onSuccess: () => {
      queryClient.refetchQueries([GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY]);
    },
  });

  const is_group_card = !no_group && notification_group_count > 1;
  const message_category = NOTIFICATION_2ND_CATEGORY_LABEL[notification_2nd_category];
  const dayjs_date_created = dayjs(date_created);

  const shared_pbl_params: Pick<PblParams, 'navigation' | 'object_id' | 'object_section' | 'extra'> = {
    navigation: 'partnercenter_notification_inbox',
    object_id: 'message',
    object_section: pbl_section,
  };

  const icon =
    notification_1st_category === PartnerCenterNotification1stCategory.ZIGZIN ? (
      <IconColoredZDelivery />
    ) : notification_1st_category === PartnerCenterNotification1stCategory.NEWS ? (
      <Icon src={NewsImg} />
    ) : (
      NOTI_ICON[notification_2nd_category]
    );

  const readCard = () => {
    if (isLoading) return;
    onClick?.();
    pbl({
      ...shared_pbl_params,
      category: 'click',
      extra: JSON.stringify({
        message_title: title,
        message_category,
        message_id: id,
        unread: viewed ? 'N' : 'Y',
      }),
    });
    mutate({ input: { id } });
    const url_pattern = new RegExp('^(http|https)://[^ "]+$');
    const is_external_link = url_pattern.test(landing_url);
    const is_internal_link = landing_url.startsWith('/');
    if (is_external_link) {
      window.open(landing_url, '_blank', 'noopener noreferrer');
    } else if (is_internal_link) {
      history.push(`${prefix}${landing_url}`);
    }
  };

  const showMore = () => {
    pbl({
      ...shared_pbl_params,
      category: 'click',
      object_id: 'grouped_message_more',
      extra: JSON.stringify({
        message_title: title,
        message_category,
      }),
    });
    onShowGroupClick?.({
      group_id: notification_group_id,
      count: notification_group_count,
      pbl_section,
    });
  };

  const handleClick = () => {
    if (is_group_card) {
      showMore();
    } else {
      readCard();
    }
  };

  useEffect(() => {
    if (isRevealed) {
      pbl({
        ...shared_pbl_params,
        category: 'impression',
        extra: JSON.stringify({
          message_title: title,
          message_category,
          message_id: id,
          unread: viewed ? 'N' : 'Y',
          grouped: is_group_card ? 'Y' : 'N',
        }),
      });
    }
  }, [isRevealed]);

  return (
    <Container ref={targetRef} onClick={handleClick}>
      {!viewed && <NotiDot />}
      <VStack spacing={8} style={{ opacity: viewed ? 0.6 : 1 }}>
        <HStack spacing={6} alignment='top' as='h1'>
          <span style={{ marginTop: 3 }}>{icon}</span>
          <BaseText kind='Body_14_Bold' style={{ flex: 1 }}>
            {title}
          </BaseText>
          <Tooltip kind='primary' content={dayjs_date_created.format('YYYY.MM.DD HH:mm')} placement='top'>
            <CreatedDate>
              {Date.now() - date_created < ONE_DAY ? dayjs().to(date_created) : dayjs_date_created.format('YY.MM.DD')}
            </CreatedDate>
          </Tooltip>
        </HStack>
        <Content dangerouslySetInnerHTML={{ __html: sanitizeAlertHTML(contents) }} />
      </VStack>
      {is_group_card && (
        <MoreButton kind='text' size='small' style={{ opacity: viewed ? 0.6 : 1 }}>
          {t('{{ count }}건 더보기', { count: notification_group_count - 1 })}
        </MoreButton>
      )}
    </Container>
  );
};

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const Container = styled.button`
  position: relative;
  background-color: ${semantic_colors.background.surface};
  color: ${semantic_colors.content.primary};
  ${text_styles.Body_13_Regular};
  ${shapes.border_radius.small};
  word-break: keep-all;
  padding: 18px 20px;
  row-gap: 6px;
  border: 0;
  text-align: left;
  transition: box-shadow 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 16px 0px #0000001a;
  }
`;

const Content = styled.div`
  white-space: pre-line;

  strong {
    color: ${semantic_colors.accent.primary};
  }
`;

const MoreButton = styled(TextButton)`
  background-color: ${colors.gray200};
  margin-top: 14px;
`;

const NotiDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${semantic_colors.graph.red};
  position: absolute;
  top: 14px;
  right: 10px;
`;

const CreatedDate = styled.span`
  background-color: transparent;
  border: none;
  ${text_styles.Caption_11_Medium}
  color: ${semantic_colors.content.tertiary};
  text-decoration: none;
  margin-top: 3px;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.tertiary};
  }
`;
