import { BaseText, HStack, IconButton, semantic_colors, IconX } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { InsightFeedbackDone } from './InsightFeedbackDone';
import { InsightFeedbackSurvey } from './InsightFeedbackSurvey';
import { useLocation } from '@/components/route/LegacyRouterCompat';

interface FeedbackPopoverProps {
  pageTitle: string;
  isOpen: boolean;
  onClose: () => void;
}

export const InsightFeedbackPopover = ({ isOpen, onClose, pageTitle }: FeedbackPopoverProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [is_done, setIsDone] = useState<boolean>(false);

  useLayoutEffect(() => {
    setIsDone(false);
  }, [location.pathname]);

  return (
    <CSSTransition in={isOpen} timeout={0} mountOnEnter unmountOnExit classNames='feedback'>
      <FloatingPopup>
        <HStack>
          <Stretch>
            <BaseText as='h2' color={semantic_colors.accent.primary} kind='Heading_17_Bold'>
              {pageTitle}
              <br />
              {t('페이지가 마음에 드셨나요?')}
            </BaseText>
          </Stretch>
          <IconButton
            css={[
              `
                width: 28px;
                height: 28px;
                border: none;
                background-color: initial;
                cursor: pointer;
                `,
            ]}
            onClick={() => onClose()}
            icon={<IconX size={20} color={semantic_colors.content.tertiary} />}
          ></IconButton>
        </HStack>
        {is_done ? <InsightFeedbackDone /> : <InsightFeedbackSurvey onSubmit={() => setIsDone(true)} />}
      </FloatingPopup>
    </CSSTransition>
  );
};

const FloatingPopup = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 328px;
  margin-bottom: 18px;
  padding: 24px;
  background-color: ${semantic_colors.background.elevated};
  border: 2px solid ${semantic_colors.accent.primary};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transform-origin: bottom right;

  &.feedback-enter {
  }
  &.feedback-enter-active {
    transform: translateY(0);
    transition: transform 0.15s;
  }
  &.feedback-exit {
    transform: translateY(0);
  }
  &.feedback-exit-active {
    transition: transform 0.15s;
  }
`;
const Stretch = styled.div`
  flex-grow: 1;
`;
