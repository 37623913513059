import { Config } from '@/config';
import { SiteId } from '@/constants/site';

function getEnvironmentValue({
  production,
  alpha,
  beta,
  dev,
}: {
  production: string;
  alpha: string;
  beta: string;
  dev: string;
}) {
  if (window.location.hostname === 'localhost' || Config.alpha) {
    return alpha;
  } else if (Config.beta) {
    return beta;
  } else if (Config.dev) {
    return dev;
  }
  return production;
}

export function getDev() {
  return window.location.hostname.match(/dev-[a-zA-Z0-9-]+(?=\.dev)/)?.[0] ?? '';
}

export function getServiceFQDN(site_id: string) {
  switch (site_id) {
    case SiteId.포스티:
      return {
        production: 'https://posty.kr',
        alpha: 'https://alpha.posty.kr',
        beta: 'https://beta.posty.kr',
        dev: `https://posty-${getDev()}.dev.zigzag.kr`,
      };
    case SiteId.패션_바이_카카오:
      return {
        production: 'https://fashionbykakao.com',
        alpha: 'https://alpha.fashionbykakao.com',
        beta: 'https://beta.fashionbykakao.com',
        dev: `https://fbk-${getDev()}.dev.zigzag.kr`,
      };
    case SiteId.지그재그:
    default:
      return {
        production: 'https://zigzag.kr',
        alpha: 'https://alpha.zigzag.kr',
        beta: 'https://beta.zigzag.kr',
        dev: `https://store-${getDev()}.dev.zigzag.kr`,
      };
  }
}

export function getUrlByEnvironment(site_id, url_path: string) {
  return `${getEnvironmentValue(getServiceFQDN(site_id))}${url_path}`;
}
