import {
  VStack,
  BaseText,
  TextButton,
  IconArrowChevronRight,
  HStack,
  IconCircleWarning,
  semantic_colors,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetInsightNotification } from '@/components/insight/hooks/useGetInsightNotification';
import { useShopPrefix } from '@/hooks/useShopPrefix';

const InsightNotice: React.FC = () => {
  const { t } = useTranslation('main_frame');
  const { data } = useGetInsightNotification();
  const history = useHistory();
  const prefix = useShopPrefix();

  const handleClickLink = () => {
    if (data?.url.startsWith('/')) {
      history.push(`${prefix}${data.url}`);
    } else if (data?.url) {
      window.open(data.url);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Container alignment='leading'>
      <HStack spacing={8}>
        <VStack pt={4}>
          <IconCircleWarning color={semantic_colors.state.negative} size={12} />
        </VStack>
        <VStack alignment='leading'>
          <BaseText kind='Body_12_Medium' color={semantic_colors.state.negative}>
            {data?.text}
          </BaseText>
          <TextButton
            size='small'
            kind='link'
            color={semantic_colors.state.negative}
            underline
            endIcon={<IconArrowChevronRight />}
            onClick={handleClickLink}
          >
            {t('문의하기')}
          </TextButton>
        </VStack>
      </HStack>
    </Container>
  );
};

export default InsightNotice;

const Container = styled(VStack)`
  padding: 16px;
  background-color: ${semantic_colors.state.negative_light};
`;
