import { Tooltip } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
interface ChatBotTooltipProps {
  is_show: boolean;
  text: string;
  hideTooltip?: () => void;
}
export const ChatBotTooltip = ({ is_show, text, hideTooltip }: ChatBotTooltipProps) => {
  return (
    <Tooltip
      opened={is_show}
      content={text}
      closeButton
      kind='primary'
      openerTriggerEvent='none'
      canClickOutside={false}
      size='large'
      zIndex={100}
      placement='top'
      onClose={() => {
        if (is_show) {
          hideTooltip?.();
        }
      }}
    >
      <SC.Target />
    </Tooltip>
  );
};

const SC = {
  Target: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  `,
};
