import React, { lazy } from 'react';
import { RoutesType } from '../types';

const ShopScorePage = lazy(() => import('@/pages/shop_score/ShopScorePage'));
const ShopScorePenaltyReleasePage = lazy(() => import('@/pages/shop_score/ShopScorePenaltyReleasePage'));
const ShopScoreHistoryPage = lazy(() => import('@/pages/shop_score/ShopScoreHistoryPage'));
const ShopScoreNextExpectedGradePage = lazy(() => import('@/pages/shop_score/ShopScoreNextExpectedGradePage'));

export const SHOP_SCORE_ROUTES: RoutesType[] = [
  {
    current_menu: '',
    path: '/shop_score',
    params: '',
    component: <ShopScorePage />,
  },
  {
    current_menu: '',
    path: '/shop_score/penalty_release',
    params: '',
    component: <ShopScorePenaltyReleasePage />,
  },
  {
    current_menu: '',
    path: '/shop_score/history',
    params: '',
    component: <ShopScoreHistoryPage />,
  },
  {
    current_menu: '',
    path: '/shop_score/next_expected_grade',
    params: '',
    component: <ShopScoreNextExpectedGradePage />,
  },
];
