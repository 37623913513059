import { useSetAtom } from 'jotai';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom'; // LegacyCompat 변환 X
import { main_domain_atom } from '@/atoms/main_domain';
import { Config } from '@/config';

export interface ShopDomainProviderProps {
  children: React.ReactNode;
}

export const ShopDomainProvider: React.FC<ShopDomainProviderProps> = ({ children }) => {
  const location = useLocation();
  const match = matchPath<{ shop_id: string }>(location.pathname, { path: '/shop/:shop_id', exact: false });
  const setMainDomain = useSetAtom(main_domain_atom);
  if (match != null) {
    Config.shop_main_domain = match.params.shop_id;
    setMainDomain(match.params.shop_id);
  } else {
    Config.shop_main_domain = '';
    setMainDomain('');
  }
  return <>{children}</>;
};
