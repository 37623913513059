import styled from '@emotion/styled';
import lottie from 'lottie-web';
import React, { useLayoutEffect, useRef } from 'react';
import animationData from '@/components/entry_requests/lotties/partner-center-loading.json';

interface DoneLottieProps {
  onComplete?: () => void;
}

export const DoneLottie = ({ onComplete }: DoneLottieProps) => {
  const lottie_ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const animation = lottie.loadAnimation({
      animationData,
      container: lottie_ref.current!,
      initialSegment: [0, 50],
      autoplay: true,
      loop: false,
    });
    animation.addEventListener('complete', () => {
      onComplete?.();
    });
    return () => {
      animation.destroy();
    };
  }, []);
  return <LottieWrapper ref={lottie_ref} />;
};

const LottieWrapper = styled.div`
  width: 150px;
  height: 150px;
`;
