import { AxiosRequestHeaders } from 'axios';
import { BETA_MUTATION_CONFIRMED_KEY } from './request';
import { Config } from '@/config';
import axios from '@/utils/axios';

const OPERATION_NAME_REGEXP = /(?:query\s|mutation\s)(?<operation_name>\w+)(?=\(|\s*\{)/;

export interface FetcherOptions extends AxiosRequestHeaders {
}

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: FetcherOptions,
): (() => Promise<TData>) => {
  const { api_base_url, shop_main_domain } = Config;
  const operation_name = query.match(OPERATION_NAME_REGEXP)?.groups?.operation_name ?? '';

  const url = `${api_base_url}${shop_main_domain ? `/${shop_main_domain}` : ''}/graphql/${operation_name}`;

  if (process.env.STAGE === 'beta' && query.includes('mutation')) {
    const mutation_confirmed_time = sessionStorage.getItem(BETA_MUTATION_CONFIRMED_KEY);
    const valid_time = Date.now() - 1000 * 60 * 60;
    if (!mutation_confirmed_time || parseInt(mutation_confirmed_time) < valid_time) {
      if (
        window.confirm('실제 데이터가 변경되는 작업입니다. 실제 셀러와 유저에게 영향을 주게 됩니다. 계속 하시겠습니까?')
      ) {
        sessionStorage.setItem(BETA_MUTATION_CONFIRMED_KEY, Date.now().toString());
      } else {
        return () => Promise.reject({ message: '요청이 취소되었습니다.' });
      }
    }
  }
  return async () => {
    const res = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...options,
      },
      data: {
        query,
        variables,
      },
    });
    return res.data.data;
  };
};
