import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import Modal from 'rui/pastel/Modal';

export const MetaStoreNoticeSection: React.FC = () => {
  const { info } = useInfo();
  const { is_agency, is_dev, is_alpha } = useAccount();
  const is_meta_user = !!info.id && !is_agency && info.type !== 'ZPAY';

  if (is_dev || is_alpha || !is_meta_user) {
    return null;
  }

  return (
    <Modal title='알림' isOpen width={500}>
      <StyledText>
        카카오스타일 스토어를 재운영 하시려면 <br />
        카카오스타일 상담센터 (
        <a href='tel:02-1670-8051' rel='noreferrer'>
          02-1670-8051
        </a>
        )로 문의해주세요.
      </StyledText>
    </Modal>
  );
};

const StyledText = styled.p`
  padding: 30px;
  align-items: center;
  ${text_styles.Heading_18_SemiBold};
  color: ${colors.gray900};
`;
