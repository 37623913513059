import React from 'react';
import { AlertItemType } from '../types';
import { ImportantCard } from './ImportantCard';
import { NoAlertData } from './NoAlertData';
import { CardList, ModalBody, NoDataContainer, ScrollArea } from './styles';
import { AppNudgingBanner } from '@/components/app_nudging/AppNudgingBanner';

export interface ImportantCardListProps {
  items: Array<AlertItemType>;
  error?: unknown;
  refetch?: () => void;
  close: () => void;
  dismiss: (key: string) => void;
}

export const ImportantCardList: React.FC<ImportantCardListProps> = ({ items, error, close, refetch, dismiss }) => {
  const handleClickLink = () => {
    close();
  };

  return (
    <ModalBody>
      <ScrollArea>
        <AppNudgingBanner />
        {items.length ? (
          <CardList>
            {items?.map((item, index) => (
              <ImportantCard
                key={index}
                title={item.title}
                description={item.description}
                extra_text={item.extra_text}
                link={item.link}
                important={item.important}
                closeButton={Boolean(item.dismiss_key)}
                onClose={() => {
                  item.dismiss_key && dismiss(item.dismiss_key);
                }}
                onClickLink={handleClickLink}
              />
            ))}
          </CardList>
        ) : (
          <NoDataContainer>
            <NoAlertData error={error} refetch={refetch} />
          </NoDataContainer>
        )}
      </ScrollArea>
    </ModalBody>
  );
};
