const adPromotionShopList = [
  '3825',
  '20014',
  '5774',
  '8669',
  '8156',
  '15013',
  '1733',
  '6087',
  '8088',
  '11153',
  '7676',
  '10046',
  '2063',
  '7078',
  '16891',
  '7005',
  '6494',
  '16214',
  '8080',
  '8583',
  '5780',
  '8681',
  '9025',
  '11832',
  '12198',
  '7846',
  '2677',
  '16203',
  '15444',
  '5311',
  '14597',
  '10952',
  '8940',
  '12243',
  '7147',
  '6095',
  '5376',
  '8430',
  '1280',
  '9979',
  '9407',
  '13031',
  '4999',
  '15019',
  '14389',
  '14129',
  '7276',
  '18831',
  '11904',
  '880',
  '9331',
  '12762',
  '15224',
  '4771',
  '16289',
  '12640',
  '6188',
  '6386',
  '16044',
  '6750',
  '4408',
  '13137',
  '1335',
  '2172',
  '15708',
  '12829',
  '6470',
  '6513',
  '17576',
  '14699',
  '4275',
  '11729',
  '10093',
  '1181',
  '15056',
  '8623',
  '9196',
  '534',
  '9761',
  '13914',
  '6464',
  '6723',
  '12760',
  '8762',
  '7545',
  '5299',
  '962',
  '7513',
  '16128',
  '11727',
  '3979',
  '7749',
  '1516',
  '9883',
  '16040',
  '20573',
  '10391',
  '9574',
  '4436',
  '93',
  '10118',
  '19114',
  '6439',
  '239',
  '8101',
  '8422',
  '13750',
  '9103',
  '4311',
  '9752',
  '10969',
  '6356',
  '632',
  '1403',
  '933',
  '7158',
  '18940',
  '18729',
  '9211',
  '15864',
  '10713',
  '1218',
  '11623',
  '4950',
  '13336',
  '8221',
  '9084',
  '21686',
  '15993',
  '50',
  '5456',
  '18509',
  '9420',
  '7480',
  '3965',
  '5911',
  '7616',
  '5569',
  '15670',
  '8493',
  '17318',
  '1526',
  '5877',
  '7843',
  '1205',
  '10099',
  '23010',
  '9237',
  '15936',
  '7808',
  '799',
  '3388',
  '13207',
  '4533',
  '4190',
  '801',
  '10845',
  '901',
  '11486',
  '6062',
  '9363',
  '13935',
  '16895',
  '4769',
  '5439',
  '14506',
  '15546',
  '11650',
  '7904',
  '6848',
  '5143',
  '12388',
  '12259',
  '20051',
  '17136',
  '5399',
  '9504',
  '10876',
  '8346',
  '4665',
  '19278',
  '7828',
  '11825',
  '5090',
  '5231',
  '12297',
  '19286',
  '15685',
  '8544',
  '18229',
  '2454',
  '19848',
  '17919',
  '19300',
  '2246',
  '11030',
  '14018',
  '14452',
  '15576',
  '12994',
  '15789',
  '18618',
  '8276',
  '8851',
  '6516',
  '11773',
  '9833',
  '8522',
  '22',
  '6817',
  '7177',
  '13640',
  '7768',
  '9204',
  '4758',
  '10098',
  '120',
  '3012',
  '20214',
  '17732',
  '7166',
  '12341',
  '21034',
  '17208',
  '7338',
  '3499',
  '16774',
  '7226',
  '6215',
  '5241',
  '7067',
  '11069',
  '60',
  '14298',
  '6928',
  '6749',
  '19828',
  '4041',
  '1178',
  '1411',
  '450',
  '7197',
  '482',
  '15021',
  '1031',
  '5194',
  '9877',
  '15746',
  '15939',
  '2836',
  '178',
  '5326',
  '11968',
  '14706',
  '4525',
  '5785',
  '5160',
  '19575',
  '21506',
  '20769',
  '17374',
  '11336',
  '4980',
  '6340',
  '7455',
  '5322',
  '8417',
  '10462',
  '9695',
  '5764',
  '8185',
  '8594',
  '1626',
  '18606',
  '16246',
  '10094',
  '5211',
  '873',
  '5108',
  '8908',
  '14493',
  '16852',
  '1752',
  '9064',
  '1166',
  '8777',
  '1452',
  '1111',
  '17408',
  '23584',
  '151',
  '17940',
  '8620',
  '12231',
  '19301',
  '12224',
  '19017',
  '11107',
  '15959',
  '8419',
  '3938',
  '3366',
  '7660',
  '6142',
  '3032',
  '5631',
  '8387',
  '7266',
  '17499',
  '4535',
  '5957',
  '8806',
  '6139',
  '6888',
  '15215',
  '11084',
  '1798',
  '953',
  '5093',
  '16825',
  '11731',
  '724',
  '8466',
  '7050',
  '17175',
  '18761',
  '4688',
  '7472',
  '15935',
  '7413',
  '10038',
  '15950',
  '21830',
  '2666',
  '13578',
  '7350',
  '9153',
  '6354',
  '20508',
  '3',
  '19204',
  '1165',
  '96',
  '10284',
  '4824',
  '9836',
  '16375',
  '7056',
  '21796',
  '6247',
  '6935',
  '9975',
  '1800',
  '1508',
  '10701',
  '10361',
  '101',
  '10575',
  '1272',
  '10619',
  '5714',
  '1108',
  '17600',
  '8653',
  '5659',
  '17425',
  '9915',
  '7773',
  '5709',
  '8178',
  '3378',
  '7646',
  '7814',
  '20768',
  '4205',
  '12244',
  '8196',
  '7216',
  '11427',
  '5312',
  '10050',
  '11687',
  '15995',
  '3647',
  '13574',
  '24033',
  '4965',
  '16728',
  '7388',
  '8521',
  '13133',
  '8832',
  '13330',
  '11956',
  '3432',
  '22979',
  '489',
  '8933',
  '3969',
  '5125',
  '2067',
  '10311',
  '14798',
  '1367',
  '7310',
  '6338',
  '19350',
  '13632',
  '15155',
  '13799',
  '824',
  '11969',
  '10956',
  '10965',
  '9496',
  '14074',
  '6986',
  '10673',
  '8722',
  '13470',
  '6037',
  '21778',
  '11558',
  '6511',
  '13296',
  '8714',
  '2347',
];

export const adPromotionShopListSet = new Set(adPromotionShopList);
