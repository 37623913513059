export type CustomerExpansionProgramState = 'UNAPPLIED' | 'APPLIED';
export type CustomerExpansionDiscountState = 'NONE' | 'TARGET';
export enum MissionType {
  'COUPON' = 'COUPON',
  'STORE_ONBOARDING' = 'STORE_ONBOARDING',
  'ADDON_SERVICE' = 'ADDON_SERVICE',
  'CUSTOMER_EXPANSION' = 'CUSTOMER_EXPANSION',
}

interface CautionType {
  text: React.ReactNode;
}

export const ProgramList = {
  고객_확장_프로그램: 'CUSTOMER_EXPANSION',
  제휴_서비스: '제휴_서비스',
} as const;

export type ProgramType = (typeof ProgramList)[keyof typeof ProgramList];
export interface ProgramCard {
  program_type?;
  mission_type?: MissionType;
  tag: React.ReactNode;
  name: React.ReactNode;
  description: React.ReactNode;
  conditional_notice: React.ReactNode;
  caution_list: Array<CautionType>;
  button_group: Array<{
    type: 'register' | 'move_detail';
    label: React.ReactNode;
    isDisabled?: boolean;
  }>;
}
