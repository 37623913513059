import React from 'react';
import { Route } from 'react-router'; // LegacyCompat 변환 X

export interface MetaRouteProps {
  path: string;
  exact?: boolean;
  // 개발 편의성을 위해 children으로 Route 사용 방법을 통일했습니다.
  children: React.ReactNode;
}

export function MetaRoute(props: MetaRouteProps): React.ReactElement {
  const { path, exact, children } = props;
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
}
