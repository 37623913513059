import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  AdCampaignExposureMethod,
  api_updateAdCampaignExposureStatus,
  AdCampaignStatus,
  UpdateAdCampaignExposureStatusInput,
} from '@/api';

import { campaignListAtom } from '@/components/advertisement/ad_display/atoms/campaginListAtom';
import { Button } from '@/components/pdstop';

import Modal from 'pastel/Modal';
import NotificationDialogContent from 'pastel/NotificationDialogContent';
import Select from 'pastel/Select';

interface CampaignExposureStatusSelectProps {
  campaignStatus: string;
  exposureMethod: string;
  nonExposureReason: string;
  adCampaignId: string;
  width?: number;
  isDetail?: boolean;
}

export enum ExposureStatus {
  ON = 'ON',
  OFF = 'OFF',
}

const CampaignExposureStatusSelect: React.FC<CampaignExposureStatusSelectProps> = ({
  campaignStatus,
  exposureMethod,
  nonExposureReason,
  adCampaignId,
  isDetail,
  width = 67,
}) => {
  const queryClient = useQueryClient();
  const [list, setCampaignList] = useAtom(campaignListAtom);
  const { mutate: mutateAdCampaignExposureStatus } = useMutation(
    async (input: UpdateAdCampaignExposureStatusInput) => {
      await api_updateAdCampaignExposureStatus({ input });
    },
    {
      onSuccess: () => {
        const newList = list?.map((l) => {
          if (l.ad_campaign.ad_campaign_id === adCampaignId) {
            const is_exposed_to =
              l.ad_campaign.status === 'AD_CAMPAIGN_STATUS_BLOCK'
                ? AdCampaignStatus.AD_CAMPAIGN_STATUS_NORMAL
                : AdCampaignStatus.AD_CAMPAIGN_STATUS_BLOCK;
            l.ad_campaign.status = is_exposed_to;
            if (is_exposed_to === 'AD_CAMPAIGN_STATUS_BLOCK') {
              l.ad_campaign.exposure_method = AdCampaignExposureMethod.AD_CAMPAIGN_EXPOSURE_METHOD_NORMAL;
            }
          }
          return l;
        });
        setCampaignList(newList);
        if (isDetail) {
          queryClient.refetchQueries(['GetAdCampaign']);
        }
      },
    },
  );

  const [dialogOpen, setDialogOpen] = useState('');

  const isExposureStatusMatchingCampaignStatus = (exposureStatus: boolean, campaignStatus: string) => {
    if (exposureStatus) {
      return campaignStatus === 'AD_CAMPAIGN_STATUS_NORMAL';
    } else {
      return campaignStatus === 'AD_CAMPAIGN_STATUS_BLOCK';
    }
  };

  const onSelectExposureStatus = (selectedStatus) => {
    const turnOnExposure = selectedStatus === ExposureStatus.ON;

    if (isExposureStatusMatchingCampaignStatus(turnOnExposure, campaignStatus)) {
      // 만약 현상태와 동일한 옵션을 선택하면 아무 동작도 하지않는다. 예시) BOOST -> NORMAL
      return;
    } else if (exposureMethod === 'AD_CAMPAIGN_EXPOSURE_METHOD_BOOST' && !turnOnExposure) {
      // '파워노출' 상태의 상품을, 차단 설정 하는 경우
      setDialogOpen('boostToBlock');
    } else if (nonExposureReason !== 'AD_CAMPAIGN_NONE_EXPOSURE_REASON_NONE' && turnOnExposure) {
      // '노출불가' 상태의 상품을, 노출 설정 하는 경우
      setDialogOpen('campaignStatusFailed');
    } else {
      mutateAdCampaignExposureStatus({
        ad_campaign_id_list: [adCampaignId],
        is_exposed: turnOnExposure,
      });
    }
  };

  const onConfirmBlockingPowerAdProduct = () => {
    mutateAdCampaignExposureStatus({
      ad_campaign_id_list: [adCampaignId],
      is_exposed: false,
    });
    setDialogOpen('');
  };

  const isExposureText = (isTurnOn) => (isTurnOn ? '노출' : '차단');

  // TODO: 다양한 실패 케이스에 대응하기 위해, 에러 메세지를 참조하도록 변경
  return (
    <>
      <Select
        disabled
        value={
          <StyledSelectOption isSelection={true} width={width}>
            {isExposureText(campaignStatus === 'AD_CAMPAIGN_STATUS_NORMAL')}
          </StyledSelectOption>
        }
        onSelect={onSelectExposureStatus}
        options={[ExposureStatus.ON, ExposureStatus.OFF].map((id) => ({
          id,
          text: <StyledSelectOption width={width}>{isExposureText(id === ExposureStatus.ON)}</StyledSelectOption>,
        }))}
      />
      <Modal
        title='알림'
        isOpen={dialogOpen === 'campaignStatusFailed'}
        width={460}
        onClose={() => {
          setDialogOpen('');
        }}
      >
        <NotificationDialogContent
          text="'노출불가' 상품은 '노출' 처리할 수 없습니다."
          subtext={
            <>
              아래의 경우 광고 노출 불가 상태가 됩니다.
              <br />
              <br />
              - 상품이 품절 상태인 경우
              <br />
              - 상품의 가격이 1,000원 미만 또는 50만원 이상일 경우
              <br />
              - 지그재그에 연동된 지 90일이 지난 경우
              <br />
            </>
          }
          actions={
            <Button
              kind='primary'
              onClick={() => {
                setDialogOpen('');
              }}
            >
              확인
            </Button>
          }
        />
      </Modal>
      <Modal
        title='알림'
        isOpen={dialogOpen === 'boostToBlock'}
        width={460}
        onClose={() => {
          setDialogOpen('');
        }}
      >
        <NotificationDialogContent
          text={
            <>
              노출 차단 시, 설정해놓으신 파워 Ai 노출도 종료됩니다.
              <br />
              차단하시겠습니까?
            </>
          }
          actions={
            <>
              <Button kind='primary' onClick={onConfirmBlockingPowerAdProduct}>
                확인
              </Button>
              <Button
                onClick={() => {
                  setDialogOpen('');
                }}
              >
                취소
              </Button>
            </>
          }
        />
      </Modal>
    </>
  );
};

const StyledSelectOption = styled.div<{ isSelection?: boolean; width: number }>`
  display: flex;
  align-items: center;
  padding: 0 0 0 14px;
  width: ${({ isSelection, width }) => (isSelection ? width - 25 : width)}px;
  height: 34px;
  font-size: 13px;
  letter-spacing: -0.3px;
  color: ${colors.gray900};
`;

export default CampaignExposureStatusSelect;
