import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';

export interface DividerProps {
  size?: number;
  color?: string;
}

const Divider: FC<DividerProps> = (props) => {
  return <SC.Wrapper {...props} />;
};

export default Divider;

const SC = {
  Wrapper: styled.div<DividerProps>`
    display: flex;
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;

    margin: 0;
    padding: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${(props) => props.color || colors.gray300};
    margin: ${(props) => props.size ?? 24}px 0;
  `,
};
