import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${colors.gray300};
  padding: 16px 28px;

  & > button {
    margin-left: 8px;
  }
`;
