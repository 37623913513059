import { useState } from 'react';
import { isAllowedByShopDepartment, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { useGetCustomerExpansionStateOfShop } from '@/components/seller_support_program/hooks/useGetCustomerExpansionStateOfShop';
import { Config } from '@/config';
import { SiteId } from '@/constants/site';
import { createHelpConstantOptions, useHelpConstants } from '@/hooks/useHelpConstants';
import { useInfo } from '@/hooks/useInfo';
import { ShopDepartment } from '@/types/info';
import { CountryCode } from 'api';

export enum LNB_TOOLTIP_TYPE {
  PARTNER_PLUS,
  CUSTOMER_EXTENSION,
}

const LNB_TOOLTIP_TEXT = createHelpConstantOptions({
  name: 'LNB툴팁',
  defaults: {
    고객확장프로그램: '',
  },
});

export function useGetCustomerExtensionTooltip() {
  const TOOLTIP_KEYS = `CustomerExtension.${Config.shop_main_domain}.Tooltip`;
  const { info } = useInfo();
  const is_allow_customer =
    isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
    isAllowedMenuByCountry(info, [CountryCode.KR]) &&
    isAllowedByShopDepartment(info, [
      ShopDepartment.SOHO_FASHION,
      ShopDepartment.BEAUTY,
      ShopDepartment.BRAND_FASHION,
      ShopDepartment.LIFE,
    ]);
  const [customer_extension_id, setCustomerExtensionId] = useState(localStorage.getItem(TOOLTIP_KEYS));
  const { data: current_state_of_shop } = useGetCustomerExpansionStateOfShop(is_allow_customer);
  const { data: lnb_data } = useHelpConstants(LNB_TOOLTIP_TEXT);
  const can_request_customer_extension =
    !current_state_of_shop?.current_state_of_shop ||
    current_state_of_shop?.current_state_of_shop?.state === 'WITHDRAWN';

  const is_show =
    is_allow_customer &&
    can_request_customer_extension &&
    Boolean(lnb_data.고객확장프로그램) &&
    customer_extension_id !== lnb_data.고객확장프로그램;

  return {
    text: lnb_data.고객확장프로그램,
    is_show,
    hideTootip() {
      if (lnb_data.고객확장프로그램) {
        setCustomerExtensionId(lnb_data.고객확장프로그램);
        localStorage.setItem(TOOLTIP_KEYS, lnb_data.고객확장프로그램);
      }
    },
  };
}
